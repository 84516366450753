import classnames from "classnames";
import { FieldArray } from "formik";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import {
  CustomLabel,
  FormikToggleSwitch,
  Gap,
  RowItem,
  TritronikInputText,
  TritronikSelect,
} from "./../../../components";
import { useGetSimcardByGsmNumberMutation } from "./../../inventory/simcards";

const FormSIMProfile = ({
  formik,
  quotaUomOptions,
  quotaPackageOptions,
  validateSimcardCircuitNumber,
  unprovisionGsmNumbersOptions,
  validateQuota
}) => {
  const { values, errors, touched } = formik;

  const [getSimcardByGsmNumber] = useGetSimcardByGsmNumberMutation();

  const togglePrimarySIM = (activeIndex, primary) => {
    formik.values.simCards.forEach((sim, idx) => {
      if (idx === activeIndex) {
        formik.setFieldValue(`simCards[${idx}].primary`, primary);
      } else {
        formik.setFieldValue(`simCards[${idx}].primary`, !primary);
      }
    });
  };

  const onSlotNumberChange = (activeIndex, slotNumber) => {
    formik.setFieldValue(`simCards[${activeIndex}].slotNumber`, slotNumber);
    // toggle the other slot if sim cards more than one
    const { simCards } = formik.values;
    if (simCards && simCards.length > 1) {
      const otherIndex = activeIndex === 0 ? 1 : 0;
      formik.setFieldValue(`simCards[${otherIndex}].slotNumber`, slotNumber === 1 ? 2 : 1);
    }
  }

  const addNewSIMCard = (actions) => {
    actions.push({
      id: 0,
      imsi: "",
      circuitNumber: "",
      gsmNumber: "",
      imei: "",
      operator: "",
      ipAddress: "",
      slotNumber: actions.form.values.simCards.length + 1,
      primary: actions.form.values.simCards.length + 1 === 1 ? true : false,
      billingCycle: "",
      quotaPackage: "2",
      quotaPackageUom: "GB",
    });
  };

  const onGsmNumberChange = async (e, index, arrayHelper) => {
    arrayHelper.form.setFieldValue(`simCards[${index}].gsmNumber`, e.value);

    const simDetail = await getSimcardByGsmNumber(e.value).unwrap();
    if (simDetail) {
      arrayHelper.form.setFieldValue(
        `simCards[${index}].id`,
        simDetail.id || 0
      );
      arrayHelper.form.setFieldValue(
        `simCards[${index}].imsi`,
        simDetail.imsi || ""
      );
      arrayHelper.form.setFieldValue(
        `simCards[${index}].operator`,
        simDetail.operator || ""
      );
      arrayHelper.form.setFieldValue(
        `simCards[${index}].billingCycle`,
        simDetail.billingCycle || ""
      );
      arrayHelper.form.setFieldValue(
        `simCards[${index}].circuitNumber`,
        simDetail.circuitNumber || ""
      );
    } else {
      arrayHelper.form.setFieldValue(`simCards[${index}].id`, 0);
      arrayHelper.form.setFieldValue(`simCards[${index}].imsi`, "");
      arrayHelper.form.setFieldValue(`simCards[${index}].operator`, "");
      arrayHelper.form.setFieldValue(`simCards[${index}].billingCycle`, "");
      arrayHelper.form.setFieldValue(`simCards[${index}].circuitNumber`, "");
    }
  };

  return (
    <div>
      <FieldArray name="simCards">
        {(formikHelper) => (
          <div>
            <div className="d-flex justify-content-between">
              <h2 className="ml-4">SIM Profile</h2>
              <Button
                color="link"
                onClick={() => addNewSIMCard(formikHelper)}
                disabled={formik.values.simCards.length === 2}
              >
                Add SIM
                <i className="fa fa-plus-circle ml-2"></i>
              </Button>
            </div>
            {values.simCards &&
              values.simCards.map((simCard, index) => (
                <Row
                  key={`sim-${index}`}
                  className={classnames({
                    "border-top": index > 0,
                    "pt-5 mt-5": index > 0,
                  })}
                >
                  <Col className="col-6 px-5 border-right">
                    <RowItem
                      rightCol="col-8"
                      boldLabel
                      label={`SIM CARD ${index + 1}`}
                      value={
                        values.simCards && values.simCards.length > 1 ? (
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <FormikToggleSwitch
                              checked={formik.values.simCards[index].primary}
                              onChange={() =>
                                togglePrimarySIM(index, !simCard.primary)
                              }
                            />
                            <div className="pb-2 ml-2">Primary SIM</div>
                          </div>
                        ) : null
                      }
                    />
                    <RowItem
                      rightCol="col-8 d-flex justify-content-start"
                      label={<CustomLabel name="Slot Number" required />}
                      value={
                        <div className="w-25">
                          <TritronikSelect
                            name={`simCards[${index}].slotNumber`}
                            value={values.simCards[index].slotNumber}
                            onChange={(e) => onSlotNumberChange(index, e.value)}
                            options={[
                              { value: 1, label: "1" },
                              { value: 2, label: "2" },
                            ]}
                            placeholder="Slot Number"
                            invalid={
                              errors.simCards &&
                              errors.simCards[index] &&
                              errors.simCards[index].slotNumber &&
                              touched.simCards &&
                              touched.simCards[index] &&
                              touched.simCards[index].slotNumber
                            }
                            error={
                              errors.simCards &&
                              errors.simCards[index] &&
                              errors.simCards[index].slotNumber
                            }
                          />
                        </div>
                      }
                    />
                    <RowItem
                      rightCol="col-8"
                      label={<CustomLabel name="GSM Number" required />}
                      value={
                        <TritronikSelect
                          filter
                          name={`simCards[${index}].gsmNumber`}
                          value={values.simCards[index].gsmNumber}
                          onChange={(e) =>
                            onGsmNumberChange(e, index, formikHelper)
                          }
                          options={unprovisionGsmNumbersOptions}
                          placeholder="GSM Number"
                          invalid={
                            errors.simCards &&
                            errors.simCards[index] &&
                            errors.simCards[index].gsmNumber &&
                            touched.simCards &&
                            touched.simCards[index] &&
                            touched.simCards[index].gsmNumber
                          }
                          error={
                            errors.simCards &&
                            errors.simCards[index] &&
                            errors.simCards[index].gsmNumber
                          }
                        />
                      }
                    />
                    <Gap height={10} />
                    <RowItem
                      rightCol="col-8"
                      label={<CustomLabel name="IMSI" required />}
                      value={
                        <TritronikInputText
                          small
                          name={`simCards[${index}].imsi`}
                          placeholder="IMSI"
                          readOnly
                        />
                      }
                    />
                    <Gap height={10} />
                    <RowItem
                      rightCol="col-8"
                      label={<CustomLabel name="SIA" required />}
                      value={
                        <TritronikInputText
                          small
                          name={`simCards[${index}].circuitNumber`}
                          placeholder="SIA"
                          validate={(val) =>
                            validateSimcardCircuitNumber(
                              values.simCards[index].id,
                              val
                            )
                          }
                        />
                      }
                    />
                  </Col>
                  <Col className="col-6 px-5 border-left">
                    <Gap height={50} />
                    <RowItem
                      rightCol="col-8"
                      label={<CustomLabel name="Operator" />}
                      value={
                        <TritronikInputText
                          small
                          name={`simCards[${index}].operator`}
                          placeholder="Operator"
                          readOnly
                        />
                      }
                    />
                    <Gap height={10} />
                    <RowItem
                      rightCol="col-8"
                      label={<CustomLabel name="Billing Date" />}
                      value={
                        <TritronikInputText
                          small
                          name={`simCards[${index}].billingCycle`}
                          placeholder="Billing Date"
                          readOnly
                        />
                      }
                    />
                    <Gap height={10} />
                    <RowItem
                      rightCol="col-8"
                      label={<CustomLabel name="Quota" required />}
                      value={
                        <div>
                          <div className="d-flex w-100 justfiy-content-between">
                            {/* <TritronikSelect
                              name={`simCards[${index}].quotaPackage`}
                              value={values.simCards[index].quotaPackage}
                              onChange={(e) =>
                                formikHelper.form.setFieldValue(
                                  `simCards[${index}].quotaPackage`,
                                  e.value
                                )
                              }
                              options={quotaPackageOptions}
                              placeholder="Package"
                              invalid={
                                errors.simCards &&
                                errors.simCards[index] &&
                                errors.simCards[index].quotaPackage &&
                                touched.simCards &&
                                touched.simCards[index] &&
                                touched.simCards[index].quotaPackage
                              }
                              error={
                                errors.simCards &&
                                errors.simCards[index] &&
                                errors.simCards[index].quotaPackage
                              }
                            /> */}
                            <TritronikInputText
                              small
                              name={`simCards[${index}].quotaPackage`}
                              placeholder="Package"
                              validate={(val) =>
                                validateQuota(
                                  val
                                )
                              }
                            />
                            <Gap width={20} />
                            <TritronikSelect
                              name={`simCards[${index}].quotaPackageUom`}
                              value={values.simCards[index].quotaPackageUom}
                              onChange={(e) =>
                                formikHelper.form.setFieldValue(
                                  `simCards[${index}].quotaPackageUom`,
                                  e.value
                                )
                              }
                              options={quotaUomOptions}
                              placeholder="UOM"
                              invalid={
                                errors.simCards &&
                                errors.simCards[index] &&
                                errors.simCards[index].quotaPackageUom &&
                                touched.simCards &&
                                touched.simCards[index] &&
                                touched.simCards[index].quotaPackageUom
                              }
                              error={
                                errors.simCards &&
                                errors.simCards[index] &&
                                errors.simCards[index].quotaPackageUom
                              }
                            />
                          </div>
                        </div>
                      }
                    />
                    <Gap height={30} />
                    <div className="d-flex justify-content-end">
                      {index > 0 && (
                        <Button
                          className="btn btn-danger px-5 py-1"
                          type="button"
                          onClick={() => formikHelper.remove(index)}
                        >
                          <i className="fa fa-trash mr-2"></i>
                          Remove
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default FormSIMProfile;
