import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDashboardSummary,
  getDeviceUsageOverQuota,
  getDeviceUsageQuotaPercentagePieChart,
  getAlertDataChart
} from "./dashboardApi";

export const requestDashboardData = createAsyncThunk(
  "dashboard/requestData",
  async (args, { rejectWithValue, getState }) => {
    try {
      const { user } = getState().auth;
      const isSuperAdmin = user && user.roleNames && user.roleNames[0] === 'Super Admin';
      const params = { username: user?.username }
      const respWidget = await getDashboardSummary(params);

      const { imsi, ...reportParams } = args; 
      const respQUP = await getDeviceUsageQuotaPercentagePieChart({
        ...reportParams,
        imsi: isSuperAdmin ? null : imsi
      });
      const respOQU = await getDeviceUsageOverQuota({
        ...reportParams,
        fill: 'previous',
        imsi: isSuperAdmin ? null : imsi
      });
      const respAlert = await getAlertDataChart({ username: user ? user.username : null });
      return {
        widget: respWidget.data,
        chart: {
          deviceUsagePercentage: respQUP.data,
          deviceUsageOverQuota: respOQU.data,
          alertByState: respAlert.data,
        },
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const slice = createSlice({
  name: "dashboard",
  initialState: {
    loading: "idle",
    error: null,
    currentRequestId: undefined,
    widget: {
      countCustomer: 0,
      countDevice: 0,
      countOffLimitSimCard: 0,
      countConnectionLostSimCard: 0,
      countDeactivatedSimCard: 0,
      countRegion: 0,
    },
    chart: {
      deviceUsagePercentage: {},
      deviceUsageOverQuota: {},
      alertByState: {},
    },
  },
  reducers: {},
  extraReducers: {
    [requestDashboardData.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [requestDashboardData.fulfilled]: (state, action) => {
      if (
        state.loading === "pending" &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        const { widget, chart } = action.payload;
        state.widget = widget;
        state.chart = chart;
      }
    },
    [requestDashboardData.rejected]: (state, action) => {
      if (
        state.loading === "pending" &&
        state.currentRequestId === action.meta.requestId
      ) {
        state.loading = "idle";
        state.error = action.payload;
        state.currentRequestId = undefined;
      }
    },
  },
});

const reducer = slice.reducer;
export default reducer;
