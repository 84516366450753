import { useMemo } from 'react';
import { useGetReferencesQuery } from '../services/referenceApi';

export function useDeviceType(preOptions = []) {
  const { data, isLoading } = useGetReferencesQuery('device-type');

  const options = useMemo(() => {
    const opts = preOptions && Array.isArray(preOptions) && preOptions.length > 0 ? preOptions : [];

    if (data) {
      data.forEach((opt) => {
        opts.push({ value: opt, label: opt });
      });
    }

    return opts;
  }, [data, preOptions]);

  return {
    data,
    isLoading,
    options,
  };
}