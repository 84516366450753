import React from 'react'
import PropTypes from 'prop-types'
import { DefaultHeader } from './../molecules'
import { Container } from 'reactstrap'

const containerStyle = {
  marginTop: '-12rem'
}

function PageContent(props) {
  return (
    <>
      <DefaultHeader title={props.title} />
      <Container fluid style={containerStyle}>
        {props.children}
      </Container>
    </>
  )
}

PageContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default PageContent;

