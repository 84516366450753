import classNames from 'classnames';
import { NavItemContent } from 'components';
import React from 'react'
import { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import InterfaceUnitOverviewTab from './InterfaceUnitOverviewTab';
import RouterInterfaceUnitConfig from './RouterInterfaceUnitConfig';
import RouterInterfaceUnitSchedule from './RouterInterfaceUnitSchedule';

const RouterInterfaceUnitDetails = (props) => {
    const { data } = props;
    
    const [activeTab, setActiveTab] = useState("1");
  
    const toggle = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    };
  
    return (
      <div>
        <Nav tabs className="row">
          <NavItem className="col">
            <NavLink
              className={classNames({ active: activeTab === "1" })}
              onClick={() => toggle("1")}
            >
              <NavItemContent
                title="Overview"
                icon="fa fa-info-circle"
                active={activeTab === "1"}
              />
            </NavLink>
          </NavItem>
          <NavItem className="col">
            <NavLink
              className={classNames({ active: activeTab === "2" })}
              onClick={() => toggle("2")}
            >
              <NavItemContent
                title="Configuration"
                icon="fa fa-sliders-h"
                active={activeTab === "2"}
              />
            </NavLink>
          </NavItem>
          <NavItem className="col">
            <NavLink
              className={classNames({ active: activeTab === "3" })}
              onClick={() => toggle("3")}
            >
              <NavItemContent
                title="Schedule"
                icon="fas fa-clock"
                active={activeTab === "3"}
              />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <InterfaceUnitOverviewTab data={data} />
          </TabPane>
          <TabPane tabId="2">
            <RouterInterfaceUnitConfig data={data}/>
          </TabPane>
          <TabPane tabId="3">
            <RouterInterfaceUnitSchedule data={data}/>
          </TabPane>
        </TabContent>
      </div>
    );
}

export default RouterInterfaceUnitDetails