import React from 'react';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import PropTypes from 'prop-types';
import './TritronikSelect.scss';
import { Label } from 'reactstrap';

const TritronikSelect = ({ value, options, onChange, optionLabel, optionValue, invalid, error, label, required, className, ...props }) => {
  return (
    <div className={classNames("form-group mb-0 w-100", className)}>
      {label && <Label className="control-label">{label}{required ? <span className="text-danger ml-1">*</span> : null}</Label>}
      <Dropdown
        value={value}
        options={options}
        onChange={onChange}
        optionLabel={optionLabel}
        optionValue={optionValue}
        className={classNames("form-control form-control-sm", {"p-invalid": invalid })}
        {...props}
      />
      {invalid && <small className="p-invalid p-d-block text-warning">{error}</small>}
    </div>
  )
}

TritronikSelect.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
};
TritronikSelect.defaultProps = {
  optionLabel: 'label',
  optionValue: 'value',
  options: []
};

export default TritronikSelect;
