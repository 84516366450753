import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  token: null,
  username: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    updateTokenAndUsername: (state, action) => {
      const { username, token } = action.payload;
      state.token = token;
      state.username = username;
    },
    logout: (state, action) => {
      state.token = null;
      state.username = null;
    }
  },
});

const { reducer } = authSlice;

export const { updateTokenAndUsername, logout } = authSlice.actions;

export const authReducer = persistReducer({
  key: 'auth',
  storage,
  whitelist: ['token','username']
}, reducer);

export default authReducer;