import React from "react";
import { Col, Form, Row } from "reactstrap";
import { Gap, RowItem, TritronikSelect } from "../../../../components";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Button } from "reactstrap";
import { useFormik } from "formik";
import { TabView, TabPanel } from "primereact/tabview";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles2 } from "./../../../../utils/datatableStyles";
import Swal from "sweetalert2";
import { useGetJobBandWidthQuery } from "features/device/service/deviceApi";
import { useCreateJobBandWidthMutation } from "features/device/service/deviceApi";
import { useUpdateJobBandwidthMutation } from "features/device/service/deviceApi";

const DeviceSchedule = (props) => {
  const { deviceId } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const { data: bandwidth } = useGetJobBandWidthQuery(deviceId, {
    skip: !deviceId,
    pollingInterval: 60000,
  });
  const [createJobBandWidth] = useCreateJobBandWidthMutation();
  const [updateJobBandWidth] = useUpdateJobBandwidthMutation();

  const date = new Date();
  const timeStamp = date.getTime();

  const formik = useFormik({
    initialValues: {
      downloadBandwidth: 512,
      uploadBandwidth: 512,
      validityPeriod: {
        beginTs: timeStamp,
        endTs: timeStamp,
      },
    },
    onSubmit: (value) => {
      const body = {
        beginTs: value.validityPeriod.beginTs,
        defaultDownloadKbps: 0,
        defaultUploadKbps: 0,
        demandDownloadKbps: value.downloadBandwidth,
        demandUploadKbps: value.uploadBandwidth,
        endTs: value.validityPeriod.endTs,
        jobStatus: "SCHEDULED",
      };
      Swal.fire({
        text: "Are You Sure want to Apply Configuration?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          createJobBandWidth({ deviceId, body })
            .unwrap()
            .then(() =>
              Swal.fire(
                "Success!",
                "Your configuration has been added.",
                "success"
              )
            )
            .catch(() =>
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              })
            );
        }
      });
    },
  });

  const onCallbackChange = (s, e) => {
    formik.setFieldValue("validityPeriod", {
      beginTs: s._d.getTime(),
      endTs: e._d.getTime(),
    });
  };

  const onCancelled = (content) => {
    const jobId = content.id;
    const body = {
      beginTs: content.beginTs,
      defaultDownloadKbps: content.defaultDownloadKbps,
      defaultUploadKbps: content.defaultUploadKbps,
      demandDownloadKbps: content.defaultDownloadKbps,
      demandUploadKbps: content.demandUploadKbps,
      deviceId: content.deviceId,
      endTs: content.endTs,
      id: content.id,
      jobStatus: "CANCELLED",
      notes: content.notes,
      startedTs: content.startedTs,
      terminatedTs: content.terminatedTs,
      updateModified: content.updateModified,
    };

    Swal.fire({
      text: "Are You Sure Want to Cancel This Schedule?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        updateJobBandWidth({ deviceId, jobId, body })
          .unwrap()
          .then(() =>
            Swal.fire(
              "Success!",
              "Your configuration has been cancelled.",
              "success"
            )
          )
          .catch(() =>
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            })
          );
      }
    });
  };

  const downloadOption = [
    {
      label: "1024 Kbps",
      value: 1024,
    },
    {
      label: "512 Kbps",
      value: 512,
    },
    {
      label: "256 Kbps",
      value: 256,
    },
    {
      label: "128 Kbps",
      value: 128,
    },
  ];

  const uploadOption = [
    {
      label: "1024 Kbps",
      value: 1024,
    },
    {
      label: "512 Kbps",
      value: 512,
    },
    {
      label: "256 Kbps",
      value: 256,
    },
    {
      label: "128 Kbps",
      value: 128,
    },
  ];

  const columnsScheduled = [
    {
      name: "DOWNLOAD BANDWIDTH",
      center: true,
      selector: (row) => `${row.demandDownloadKbps} Kbps`,
    },
    {
      name: "UPLOAD BANDWIDTH",
      center: true,
      selector: (row) => `${row.demandUploadKbps} Kbps`,
    },
    {
      name: "VALIDITY PERIOD",
      center: true,
      selector: (row) =>
        `${new Date(row.beginTs).toLocaleString("en-GB")} - ${new Date(
          row.endTs
        ).toLocaleString("en-GB")}`,
    },
    {
      name: "ACTION",
      button: true,
      cell: (content) =>
        content.jobStatus === "RUNNING" ? (
          <div>{content.jobStatus}</div>
        ) : (
          <div>
            <Button color="link">
              <i
                className="fas fa-trash"
                onClick={() => onCancelled(content)}
              ></i>
            </Button>
          </div>
        ),
    },
  ];

  const columnsHistory = [
    {
      name: "DOWNLOAD BANDWIDTH",
      center: true,
      selector: (row) => `${row.demandDownloadKbps} Kbps`,
    },
    {
      name: "UPLOAD BANDWIDTH",
      center: true,
      selector: (row) => `${row.demandUploadKbps} Kbps`,
    },
    {
      name: "VALIDITY PERIOD",
      center: true,
      selector: (row) =>
        `${new Date(row.beginTs).toLocaleString("en-GB")} - ${new Date(
          row.endTs
        ).toLocaleString("en-GB")}`,
    },
    {
      name: "STATUS",
      button: true,
      cell: ({ jobStatus }) => <div>{jobStatus}</div>,
    },
  ];

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mt-5">
          <Col className="border-right border-bottom px-5 pb-5">
            <h2 className="text-dark mt-4">Bandwidth on Demand Schedule</h2>
            <Gap height={10} />
            <RowItem
              stripped
              label="Download Bandwidth"
              value={
                <TritronikSelect
                  value={formik.values.downloadBandwidth}
                  options={downloadOption}
                  name="downloadBandwidth"
                  onChange={formik.handleChange}
                />
              }
            />
            <RowItem
              label="Upload Bandwidth"
              value={
                <TritronikSelect
                  value={formik.values.uploadBandwidth}
                  options={uploadOption}
                  name="uploadBandwidth"
                  onChange={formik.handleChange}
                />
              }
            />
          </Col>
          <Col className="border-left border-bottom px-5 pb-5">
            <Gap height={70} />
            <RowItem
              label="Validity Period"
              stripped
              value={
                <DateRangePicker
                  initialSettings={{
                    timePicker: true,
                    startDate: moment().startOf("hour").toDate(),
                    endDate: moment().startOf("hour").add(32, "hour").toDate(),
                    locale: {
                      format: "DD-MM-YYYY HH:mm",
                    },
                    minDate: new Date(),
                    timePicker24Hour: true,
                    timePickerIncrement: 5,
                    showDropdowns: true,
                  }}
                  onCallback={onCallbackChange}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="validityPeriod"
                    autoComplete="off"
                    style={{ height: "30px" }}
                    required
                  />
                </DateRangePicker>
              }
            />
            <Gap height={10} />
            <Row className="d-flex justify-content-end">
              <Button
                outline
                color="primary"
                type="reset"
                onClick={formik.resetForm}
              >
                Clear
              </Button>
              <Button color="primary" type="submit">
                Add
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col className="border-left px-5 pb-5">
          <h2 className="text-dark mt-4">Bandwidth on Demand List</h2>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Scheduled">
              <Row>
                <DataTable
                  data={bandwidth?.content
                    .filter((schedule) => {
                      return (
                        schedule.jobStatus === "SCHEDULED" ||
                        schedule.jobStatus === "RUNNING" ||
                        schedule.jobStatus === "SUBMITTED"
                      );
                    })
                    .sort((a, b) => {
                      return a.beginTs - b.beginTs;
                    })}
                  columns={columnsScheduled}
                  striped
                  keyField="id"
                  persistTableHead
                  highlightOnHover
                  noHeader
                  customStyles={customStyles2}
                />
              </Row>
            </TabPanel>
            <TabPanel header="History">
              <Row>
                <DataTable
                  data={bandwidth?.content
                    .filter((schedule) => {
                      return (
                        schedule.jobStatus === "EXPIRED" ||
                        schedule.jobStatus === "CANCELLED"
                      );
                    })
                    .sort((a, b) => {
                      return a.beginTs - b.beginTs;
                    })}
                  columns={columnsHistory}
                  striped
                  keyField="id"
                  persistTableHead
                  highlightOnHover
                  noHeader
                  pagination={true}
                  customStyles={customStyles2}
                />
              </Row>
            </TabPanel>
          </TabView>
        </Col>
      </Row>
    </>
  );
};

export default DeviceSchedule;
