import api from "./../../../services/api";
import { API_BASE_URL_USAGE } from "./../../../config";

export const getDashboardSummary = (params) => {
  return api.get("/dashboard", {
    params
  });
};

export const getDeviceUsageQuotaPercentagePieChart = (params) => {
  return api.get(
    `${API_BASE_URL_USAGE}/device-usage/pie/count-by-limit-percentage`,
    { params }
  );
};

export const getDeviceUsageOverQuota = (params) => {
  return api.get(
    `${API_BASE_URL_USAGE}/device-usage/chart/count-overquota-by-customer-name`,
    { params }
  );
};

export const getDeviceUsageQuotaPercentageDataChart = (params) => {
  return api.get(
    `${API_BASE_URL_USAGE}device-usage/chart/count-by-limit-percentage`,
    { params }
  );
};

export const getAlertDataChart = (params) => {
  return api.get(`/dashboard/alert/pie`, { params });
};
