import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from 'reactstrap';

import { Can, DELETE_GROUP, UPDATE_GROUP } from '../../../config';

const GroupActionButton = ({row, onDelete, onEdit}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const handleOnDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete(row);
    }
  }

  const handleOnEdit = () => {
    if (typeof onEdit === 'function') {
      onEdit(row);
    }
  }

  return (
    <Nav navbar>
      <UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
        <DropdownToggle nav>
          <i className="fas fa-ellipsis-v text-default" />
        </DropdownToggle>
        <DropdownMenu right>
          <Can I="do" this={DELETE_GROUP}>
            {() => (
              <DropdownItem onClick={handleOnDelete}> 
                <span className="text-danger"><i className="fa fa-trash text-danger mr-2"></i> Delete</span>
              </DropdownItem>
            )}
          </Can>
          <Can I="do" this={UPDATE_GROUP}>
            {() => (
              <DropdownItem onClick={handleOnEdit}> 
                <span className="text-default"><i className="fa fa-edit text-default mr-2"></i>Edit</span>
              </DropdownItem>
            )}
          </Can>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
}

export default GroupActionButton;