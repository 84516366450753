import { useCallback, useMemo, useState } from "react";
import { defaultOptions, usePagination } from "./../../../../hooks";
import { useGetSimcardsQuery } from "../services/simcardInventoryApi";

const defaultParams = {
    smartSearch: undefined,
    assetStatus: undefined,
    imsi: undefined,
    operator: undefined,
};

export const useSimcardInventoryDatatables = (params = defaultParams, options = defaultOptions) => {
    const [selectedRows, setSelectedRows] = useState([]);

    const {
        pageNumber,
        pageSize,
        sortFields,
        sortOrder,
        onChangePage,
        onChangeRowsPerPage,
        onSort
    } = usePagination({ pageNumber: 1, pageSize: 10, sortFields: options.sortFields, sortOrder: options.sortOrder });

    const { data: paginatedResult, isLoading, isFetching, refetch } = useGetSimcardsQuery({
        pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
        pageSize,
        sortFields: sortFields ?? undefined,
        sortOrder: sortOrder ?? undefined,
        ...params,
    });

    const onSelectedRowsChange = useCallback(state => {
        setSelectedRows(state.selectedRows);
      }, []);

    const data = useMemo(() => {
        if (!paginatedResult?.content) return [];

        return paginatedResult.content;
    }, [paginatedResult]);

    const totalRows = useMemo(() => {
        if (!paginatedResult) return 0;

        return paginatedResult.totalElements;
    }, [paginatedResult]);

    return {
        data,
        isLoading,
        isFetching,
        pageSize,
        pageNumber,
        totalRows,
        selectedRows,
        refetch,
        onChangeRowsPerPage,
        onChangePage,
        onSort,
        onSelectedRowsChange,
    };
};
