import React from "react";
import { Alert } from "reactstrap";
import { useGetDeviceMissingCIDCountQuery } from "../service/dashboardApi";
import Lottie from "react-lottie";
import animationData from "../../../assets/lottie/pulse";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MissingCIDAlert = () => {
  const { data } = useGetDeviceMissingCIDCountQuery();

  if (!data) return null;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Alert
      color="secondary"
      className="d-flex align-items-center"
      style={{
        backgroundColor: "#FEE7E6",
        border: "0px solid #FEE7E6",
        borderLeftWidth: "6px",
        borderLeftColor: "#F5365C",
      }}
    >
      {/* <i className="mdi mdi-18px mdi-alert text-danger"></i> */}
      <Lottie
        options={defaultOptions}
        height={18}
        width={18}
        style={{ marginInline: 0, marginBottom: "7px", overflow: "show" }}
      />
      <span className="text-danger ml-1">
        <span className="font-weight-bold">Unmonitored Devices.</span>
        <span className="ml-1">
          {data} devices has missing SIA and cannot be monitored.
        </span>
      </span>
      <Link
        to={`/admin/devices?simCardStatus=Missing%20CID`}
        className="text-primary ml-1"
      >
        Click Here
      </Link>
    </Alert>
  );
};

export default MissingCIDAlert;
