import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { CreateDevice, DeviceList, UnactivatedDevicePage } from "./pages";

function DeviceRoutes() {
  return (
    <Switch>
      <Route exact path={`/admin/devices/`} component={DeviceList} />
      <Route exact path={`/admin/devices/new`} component={CreateDevice} />
      <Route exact path={`/admin/devices/unactivated`} component={UnactivatedDevicePage} />
      <Route
        exact
        path={`/admin/devices/region/:regionId`}
        component={DeviceList}
      />
      <Route
        exact
        path={`/admin/devices/region/:regionId/area/:areaId`}
        component={DeviceList}
      />
      <Redirect to="/admin/devices/" />
    </Switch>
  );
}

export default DeviceRoutes;
