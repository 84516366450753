import React, { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';

import { formatBytes } from './../../../../utils';
import AggregateTable from '../AggregateTable';
import { useAggregate } from './../../hooks/useAggregate';

const DeviceUsageEchart = ({ data, simCards }) => {
    const { calculateAggregate } = useAggregate();
    const { options, aggregated } = useMemo(() => {
        const series = [];
        const aggregated = [];


        const getSimCardByImsi = (imsi) => {
            return simCards && _.find(simCards, { 'imsi': imsi });
        }
        const getMarkLineQuotaLimit = (imsi) => {
            const simCard = getSimCardByImsi(imsi);
            if (simCard) {
                return {
                    data: [
                        {
                            name: `SIM 1`,
                            yAxis: simCard.quotaLimitInByte,
                            label: {
                                show: true,
                                formatter: (params) => {
                                    return `${params.name} Limit`
                                }
                            },
                            emphasis: {
                                label: {
                                    position: 'end',
                                    formatter: (params) => {
                                        return `${params.name} Limit: ${formatBytes(params.value)}`
                                    }
                                }
                            }
                        }
                    ]
                }
            }
            return {};
        }

        if (data && data.datasets && data.datasets.length > 0) {
            data.datasets.forEach(dt => {
                const dataSeries = [];

                const datas = dt.data.filter((d) => d > 0);

                const agg = calculateAggregate(datas);

                if (agg !== null) {
                    aggregated.push({
                        name: dt.label,
                        min: formatBytes(agg.min),
                        max: formatBytes(agg.max),
                        avg: formatBytes(agg.avg),
                        total: formatBytes(agg.sum),
                    })
                }

                data.timestamps && data.timestamps.forEach((ts, i) => {
                    const dataSerie = dt.data[i];
                    const value = dataSerie < 0 ? null : dataSerie;
                    dataSeries.push([ts, value]);
                });

                const markLine = getMarkLineQuotaLimit(dt.label);
                const simCard = getSimCardByImsi(dt.label);

                series.push({
                    name: simCard ? `${dt.label} (SIM ${simCard.slotNumber})` : dt.label,
                    data: dataSeries,
                    type: 'line',
                    symbol: 'none',
                    smooth: false,
                    markLine: markLine,
                })
            });
        }

        const options = {
            title: {
                show: true
            },
            legend: {
                show: true
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                },
                {
                    start: 0,
                    end: 100
                }
            ],
            xAxis: {
                type: 'time',
                boundaryGap: false,
                axisLabel: {
                    formatter: {
                        year: '{yyyy}',
                        month: '{MMM} {yyyy}',
                        day: '{d} {MMM}',
                        hour: '{HH}:{mm}',
                        minute: '{HH}:{mm}',
                        second: '{HH}:{mm}:{ss}',
                        millisecond: '{hh}:{mm}:{ss} {SSS}',
                        none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: (value) => formatBytes(value)
                },
                min: 'dataMin',
                max: 'dataMax'
            },
            grid: {
                left: '5%',
                right: '5%',
            },
            series: series
        };

        return { options, aggregated };
    }, [data, simCards, calculateAggregate]);

    return (
        <Card>
            <CardBody style={{ width: '100%', height: '100%' }}>
                <ReactECharts option={options} style={{ width: '100%', height: 400 }} />
                {aggregated && aggregated.length > 0 && <AggregateTable data={aggregated} columns={['IMSI', 'Min', 'Max', 'Avg']} /> }
            </CardBody>
        </Card>
    )
}

export default DeviceUsageEchart
