import * as Yup from 'yup';

export const addNetworkValidationSchema = Yup.object().shape({
	name: Yup.string().required('Network name required.'),
	ipAssignmentPools: Yup.array().of(Yup.object().shape({
		ipRangeStart: Yup.string().required('IP Range Start required.'),
		ipRangeEnd: Yup.string().required('IP Range End required.'),
	})),
	routes: Yup.array().of(Yup.object().shape({
		target: Yup.string().required('Target required.'),
		via: Yup.string().required('Gateway required.'),
	})),
})