import moment from "moment";
import { useMemo, useState } from "react";
import { useOptions } from "./../../../hooks";
import { useAuth } from "./../../auth/hooks";
import {
  useGetDeviceIntegrationReportByUsernameQuery,
  useGetSignalStrengthReportByUsernameQuery,
  useGetUsageReportByUsernameQuery,
  useGetTunnelAvailabilityReportByUsernameQuery,
  useGetDeviceAvailabilityReportByUsernameV2Query,
} from "../service/reportApi";

const signalColorMapping = {
  "No Signal": "#AB0312",
  Poor: "#f5365c",
  Fair: "#fb6340",
  Good: "#ffd600",
  Excellent: "#6ACE61",
};

const slaBackgroundColor = ["#2dce89", "#f5365c"];

const getColorByLabel = (label) => {
  switch (label) {
    case "Onboarding":
    case "Onboard":
      return "#97C33C";
    case "Dismantle":
      return "#E83830";
    case "0-25%":
      return "#97C33C";
    case "26%-50%":
      return "#2065AC";
    case "51%-75%":
      return "#F2931F";
    case "76%-100%":
      return "#E83830";
    case ">100%":
      return "#AC589E";
    default:
      return "#bababa";
  }
};

const defaultParamsState = {
  startTime: moment().startOf("day"),
  endTime: moment().endOf("day"),
};

export const defaultFilters = {
  customerIds: [],
  deviceType: [],
  serialNumber: [],
  operator: [],
  imsi: [],
  location: [],
  areaIds: [],
  availability: null,
  integrationStatus: [],
};

const emptyDataChart = {
  labels: [],
  datasets: [],
};

export function useReportData() {
  const { user } = useAuth();
  const [params, setParams] = useState(defaultParamsState);
  const [filters, setFilters] = useState(defaultFilters);
  // report type
  const [reportType, setReportType] = useState("sla");
  // report template
  const [reportTemplate, setReportTemplate] = useState("");

  const { arrayToStringIds } = useOptions();
  const getTimeGroupping = (startTime, endTime) => {
    const days = endTime.diff(startTime, "days");
    if (days <= 1) {
      return "1h";
    } else {
      return "1d";
    }
  };

  const {
    customerIds,
    operator,
    deviceType,
    serialNumber,
    imsi,
    location,
    areaIds,
    availability,
    integrationStatus,
  } = filters;

  const { data: dataReportUsage, isLoading: loadingReportUsage, isFetching: fetchingReportUsage } =
    useGetUsageReportByUsernameQuery(
      {
        startTs: params.startTime.toDate().getTime(),
        endTs: params.endTime.toDate().getTime(),
        timeGroupping: getTimeGroupping(params.startTime, params.endTime),
        customerIdsAsString: arrayToStringIds(customerIds),
        deviceTypesAsString: arrayToStringIds(deviceType),
        snsAsString: arrayToStringIds(serialNumber),
        username: user.username,
      },
      {
        skip: !user.username || reportType !== "usage",
      }
    );

  const { data: dataReportSignal, isLoading: loadingReportSignal, isFetching: fetchingReportSignal } =
    useGetSignalStrengthReportByUsernameQuery(
      {
        startTs: params.startTime.toDate().getTime(),
        endTs: params.endTime.toDate().getTime(),
        timeGroupping: getTimeGroupping(params.startTime, params.endTime),
        areaIdsAsString: arrayToStringIds(areaIds),
        customerIdsAsString: arrayToStringIds(customerIds),
        deviceTypesAsString: arrayToStringIds(deviceType),
        imsisAsString: arrayToStringIds(imsi),
        locationsAsString: arrayToStringIds(location),
        operatorsAsString: arrayToStringIds(operator),
        snsAsString: arrayToStringIds(serialNumber),
        username: user.username,
      },
      {
        skip: !user.username || reportType !== "signal",
      }
    );

  const { data: dataReportSLA, isLoading: loadingReportSLA, isFetching: fetchingReportSLA } =
    useGetDeviceAvailabilityReportByUsernameV2Query(
      {
        startTs: params.startTime.toDate().getTime(),
        endTs: params.endTime.toDate().getTime(),
        timeGroupping: getTimeGroupping(params.startTime, params.endTime),
        availability: availability ? availability : undefined,
        areaIdsAsString: arrayToStringIds(areaIds),
        customerIdsAsString: arrayToStringIds(customerIds),
        locationsAsString: arrayToStringIds(location),
        snsAsString: arrayToStringIds(serialNumber),
        username: user.username,
      },
      {
        skip: !user.username || reportType !== "sla",
      }
    );

  const { data: dataReportIntegration, isLoading: loadingReportIntegration, isFetching: fetchingReportIntegration } =
    useGetDeviceIntegrationReportByUsernameQuery(
      {
        startTs: params.startTime.toDate().getTime(),
        endTs: params.endTime.toDate().getTime(),
        timeGroupping: getTimeGroupping(params.startTime, params.endTime),
        areaIdsAsString: arrayToStringIds(areaIds),
        customerIdsAsString: arrayToStringIds(customerIds),
        deviceTypesAsString: arrayToStringIds(deviceType),
        snsAsString: arrayToStringIds(serialNumber),
        integrationStatusesAsString: arrayToStringIds(integrationStatus),
        username: user.username,
      },
      {
        skip: !user.username || reportType !== "integration",
      }
    );

  const { data: dataReportSLATunnel, isLoading: loadingReportSLATunnel, isFetching: fecthingReportSLATunnel } =
    useGetTunnelAvailabilityReportByUsernameQuery(
      {
        startTs: params.startTime.toDate().getTime(),
        endTs: params.endTime.toDate().getTime(),
        timeGroupping: getTimeGroupping(params.startTime, params.endTime),
        availability: availability ? availability : undefined,
        areaIdsAsString: arrayToStringIds(areaIds),
        customerIdsAsString: arrayToStringIds(customerIds),
        locationsAsString: arrayToStringIds(location),
        snsAsString: arrayToStringIds(serialNumber),
        username: user.username,
      },
      {
        skip: !user.username || reportType !== "slaTunnel",
      }
    );

  const dataUsage = useMemo(() => {
    if (!dataReportUsage) {
      return {
        overQuotaUsagePercentage: emptyDataChart,
        overQuotaUsageCustomer: emptyDataChart,
        table: [],
      };
    }
    const { pie, chart, table } = dataReportUsage;

    const labels = [];
    const datasets = [];

    // Over Quota Usage Customer Data Mapping
    if (chart.datasets && chart.datasets.length > 0) {
      chart.datasets.forEach((dt) => {
        const color = getColorByLabel(dt.label);
        datasets.push({
          ...dt,
          type: "bar",
          fill: false,
          lineTension: 1,
          borderColor: color,
          backgroundColor: color,
        });
      });
    }

    if (chart.labels && chart.labels.length > 0) {
      chart.labels.forEach((label) => {
        labels.push(moment(label).toDate().getTime());
      });
    }
    const overQuotaUsageCustomer = { labels, datasets };

    // Over Quota Usage Percentage Data Mapping
    const datasetsPercentage = [];
    if (pie && pie.datasets && pie.datasets.length > 0) {
      pie.datasets.forEach((dt) => {
        datasetsPercentage.push({
          ...dt,
          backgroundColor: ["#97C33C", "#2065AC", "#F2931F", "#E83830"],
        });
      });
    }
    const overQuotaUsagePercentage = {
      labels: pie?.labels,
      datasets: datasetsPercentage,
    };

    return { overQuotaUsagePercentage, overQuotaUsageCustomer, table };
  }, [dataReportUsage]);

  const dataSignal = useMemo(() => {
    if (!dataReportSignal) {
      return {
        pieChart: emptyDataChart,
        table: [],
        panel: [],
      };
    }

    const { pie, table, panel } = dataReportSignal;

    // Map pie chart data
    const labels = [];
    const datasets = [];
    const colors = [];

    if (pie && pie.labels && pie.labels.length > 0) {
      pie.labels.forEach((label) => {
        labels.push(label);
        colors.push(signalColorMapping[label]);
      });
    }

    if (pie && pie.datasets && pie.datasets.length > 0) {
      pie.datasets.forEach((dt) => {
        datasets.push({ ...dt, backgroundColor: colors });
      });
    }

    return { pieChart: { labels, datasets }, table, panel };
  }, [dataReportSignal]);

  const dataSLA = useMemo(() => {
    if (!dataReportSLA) {
      return {
        pieChart: emptyDataChart,
        lineChart: emptyDataChart,
        table: [],
        panel: [],
      };
    }

    const { pie, table, panel, chart } = dataReportSLA;

    const labels = pie?.labels || [];
    const datasets = [];

    if (pie && pie.datasets && pie.datasets.length > 0) {
      pie.datasets.forEach((dt) => {
        datasets.push({ ...dt, backgroundColor: slaBackgroundColor });
      });
    }

    return { pieChart: { labels, datasets }, table, panel, lineChart: chart };
  }, [dataReportSLA]);

  const dataIntegration = useMemo(() => {
    if (!dataReportIntegration) {
      return {
        pieChart: emptyDataChart,
        barChart: emptyDataChart,
        table: [],
      };
    }

    const { pie, chart, table } = dataReportIntegration;

    const pieDatasets = [];
    if (pie && pie.datasets && pie.datasets.length > 0) {
      pie.datasets.forEach((dt) => {
        pieDatasets.push({ ...dt, backgroundColor: ["#97C33C", "#E83830"] });
      });
    }

    const barLabels = [];
    const barDatasets = [];

    if (chart && chart.labels && chart.labels.length > 0) {
      chart.labels.forEach((label) => {
        barLabels.push(moment(label).toDate().getTime());
      });
    }

    if (chart && chart.datasets && chart.datasets.length > 0) {
      chart.datasets.forEach((dt) => {
        const color = getColorByLabel(dt.label);
        barDatasets.push({
          ...dt,
          type: "bar",
          fill: false,
          lineTension: 1,
          borderColor: color,
          backgroundColor: color,
        });
      });
    }

    return {
      pieChart: {
        labels: pie.labels || [],
        datasets: pieDatasets,
      },
      barChart: {
        labels: barLabels,
        datasets: barDatasets,
      },
      table,
    };
  }, [dataReportIntegration]);

  const dataSLATunnel = useMemo(() => {
    if (!dataReportSLATunnel) {
      return {
        pieChart: emptyDataChart,
        table: [],
        panel: [],
      };
    }

    const { pie, table, panel } = dataReportSLATunnel;

    const labels = pie?.labels || [];
    const datasets = [];

    if (pie && pie.datasets && pie.datasets.length > 0) {
      pie.datasets.forEach((dt) => {
        datasets.push({ ...dt, backgroundColor: slaBackgroundColor });
      });
    }

    return { pieChart: { labels, datasets }, table, panel };
  }, [dataReportSLATunnel]);

  const data = {
    usage: dataUsage,
    signal: dataSignal,
    sla: dataSLA,
    integration: dataIntegration,
    slaTunnel: dataSLATunnel,
  };

  return {
    data,
    loadingReportIntegration,
    loadingReportSignal,
    loadingReportUsage,
    loadingReportSLA,
    loadingReportSLATunnel,
    fetchingReportSLA,
    fecthingReportSLATunnel,
    fetchingReportIntegration,
    fetchingReportSignal,
    fetchingReportUsage,
    params,
    filters,
    reportType,
    reportTemplate,
    setParams,
    setReportType,
    setFilters,
    setReportTemplate,
  };
}
