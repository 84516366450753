import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Col,
  Row,
} from "reactstrap";
import { Loading } from "components";
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';

import { history } from "./../../../../../utils";

const RowItem = ({ label, value }) => (
  <Row className="mt-3">
    <Col className="text-left">
      <span className="text-dark">{label}</span>
    </Col>
    <Col className="text-right">
      <span className="text-dark">{value}</span>
    </Col>
  </Row>
);

const DeviceAlertCard = ({ loading, data }) => {

  const navigateToAlertByLabel = (label) => {
    switch (_.toLower(label)) {
      case 'open':
      case 'no data':
        history.push({
          pathname: '/admin/alerts',
          search: `?lastState=Open`
        });
        break;
      case 'resolved':
        history.push({
          pathname: '/admin/alerts',
          search: `?lastState=Resolved`
        });
        break;
      default:
        break;
    }
  }

  const onChartClick = (params) => {
    navigateToAlertByLabel(params.name)
  }

  const onEvents = {
    'click': onChartClick
  }

  const options = useMemo(() => {
    let option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '5%',
        left: 'center'
      },
      series: [
        {
          top: '-20%',
          name: 'Alert',
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'inside',
            formatter: '{d} %',
            fontSize: 15
          },
          labelLine: {
            show: false
          },
          data: [
            { value: 0, name: "Open" },
            { value: 0, name: "Resolved" },
          ],
        }
      ]
    };
    const labels = data.labels || [];
    const series = [];

    const getLabel = (lbl) => {
      switch (lbl) {
        case "ALERTING": return "Open";
        case "ACKNOWLEDGE": return "Acknowledged";
        case "OK": return "Resolved";
        case "NO_DATA": return "No Data";
        default: return lbl;
      }
    }

    const getColor = (lbl) => {
      switch (lbl) {
        case "ALERTING": return "#E83830";
        case "ACKNOWLEDGE": return "#F2931F";
        case "OK": return "#97C33C";
        case "NO_DATA": return "#8898aa";
        default: return "#2065AC";
      }
    }

    if (data && data.datasets) {
      data.datasets.forEach(({ data: dataVal }) => {
        const dataValue = [];
        if (dataVal) {
          dataVal.forEach((d, i) => {
            dataValue.push({ value: d, name: getLabel(labels[i] || null), itemStyle: { color: getColor(labels[i] || null) } });
          });
        }
        series.push({
          top: '-20%',
          name: 'Alert',
          type: 'pie',
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'inside',
            formatter: '{d} %',
            fontSize: 15
          },
          labelLine: {
            show: false
          },
          data: dataValue
        })
      });
      option.series = series;
    }

    return option;
  }, [data]);

  return (
    <Card className="card-stats" style={{ height: "650px" }}>
      <CardBody>
        <Row>
          <Col>
            <CardTitle tag="h3" className="text-muted">
              ALERT
            </CardTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="chart px-5">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Loading size="lg" />
                </div>
              ): (
                <div className="w-100 h-100">
                  <ReactECharts option={options} style={{ width: '100%', height: '100%' }} onEvents={onEvents} />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="border-dashed-divider"></div>
        <Row className="mt-3">
          <Col>
            <Row>
              <Col className="text-left font-weight-bold">
                <span>Alert</span>
              </Col>
              <Col className="text-right font-weight-bold">
                <span>Total Alerts</span>
              </Col>
            </Row>
            {data && data.labels && data.labels.length > 0 ?
              _.sortBy(data.labels).map((label,i) => {
                return (
                  <RowItem
                    key={`${label}-${i}`} 
                    label={label} 
                    value={(
                      <a className="text-default" href="#alerts" onClick={(e) => {
                        e.preventDefault();
                        navigateToAlertByLabel(label);
                      }}>
                        {data?.datasets[0]?.data[i] || 0}
                      </a>
                    )} />
                )
              })
              : (
                <>
                  <RowItem label="Acknowledged" value="0" />
                  <RowItem label="Open"  value="0" />
                  <RowItem label="Resolved" value="0" />
                </>
              )
          }
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="p-0 text-right">
        <Button color="link" onClick={() => history.push('/admin/alerts/configuration')}>
          Manage Alert <i className="fa fa-chevron-right fa-1x"></i>
        </Button>
      </CardFooter>
    </Card>
  );
};

DeviceAlertCard.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array,
    datasets: PropTypes.arrayOf(PropTypes.object),
  }),
};

DeviceAlertCard.defaultProps = {
  data: {
    labels: ['Open','Resolved'],
    datasets: [{ data: [0,0] }]
  },
};

export default DeviceAlertCard;
