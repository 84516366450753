import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const alertConfigurationApi = createApi({
  reducerPath: 'alertConfigurationApi',
  baseQuery: baseQuery,
  tagTypes: ['AlertConfiguration', 'SubscribedAlert'],
  endpoints: (builder) => ({
    getAlertConfigurations: builder.query({
      query: (params) => ({
        url: `/alert`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'AlertConfiguration', id })),
            { type: 'AlertConfiguration', id: 'LIST' },
          ]
          : [{ type: 'AlertConfiguration', id: 'LIST' }],
    }),
    getAlertConfiguration: builder.query({
      query: (id) => ({
        url: `/alert/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'AlertConfiguration', id }],
    }),
    createAlertConfiguration: builder.mutation({
      query: ({ body, params }) => ({
        url: `/alert`,
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: [{ type: 'AlertConfiguration', id: 'LIST' }],
    }),
    updateAlertConfiguration: builder.mutation({
      query: ({ body: { id, ...patch }, params }) => ({
        url: `/alert/${id}`,
        method: 'PUT',
        body: patch,
        params
      }),
      async onQueryStarted({ body: { id, ...patch } }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          alertConfigurationApi.util.updateQueryData('getAlertConfiguration', id, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { body: { id } }) => [{ type: 'AlertConfiguration', id }],
    }),
    deleteAlertConfiguration: builder.mutation({
      query(id) {
        return {
          url: `/alert/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'AlertConfiguration', id }, { type: 'AlertConfiguration', id: 'LIST' }],
    }),
    subscribeAlert: builder.mutation({
      query: (username) => ({
        url: `/alert/subscription/${username}`,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'SubscribedAlert', id: 'LIST' }],
    }),
  })
});

export const {
  useGetAlertConfigurationQuery,
  useGetAlertConfigurationsQuery,
  useCreateAlertConfigurationMutation,
  useUpdateAlertConfigurationMutation,
  useDeleteAlertConfigurationMutation,
  useSubscribeAlertMutation,
} = alertConfigurationApi;

