import EChartsReact from "echarts-for-react";
import moment from "moment";
import React, { useMemo } from "react";
import { formatNumber } from "utils";


const ReportEChart = ({ data = [], isLoading = false, unit = "", minInterval = false, dateRange }) => {

  const countDateRange = (dateRanges, range) => {
    const startTime = new Date(dateRanges.startTime);
    const endTime = new Date(dateRanges.endTime);

    const timeDifference = endTime - startTime;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference < range;
  }
  
  const options = useMemo(() => {
    const dataSeries = data && data.datasets ? data.datasets : [];
    const labels = data && data.labels ? data.labels : [];
    const isDaily = countDateRange(dateRange, 1) ? true : false  

    return {
      legend: {
        bottom: 0,
        type: 'scroll',
        icon: 'rect'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: 70,
        right: 30,
        top: 10,
        bottom: 70
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: `{value} ${unit}`,
          },
          axisLine: {
            show: true
          },
          minInterval: 5,
          name:  isDaily ? 'Number of Down Devices' : 'Number of Devices',
          nameLocation: 'center',
          nameGap: 40
        },
      ],
      xAxis: [
        {
          type: isDaily ? "category" : "time",
          axisLabel: {
            hideOverlap: true,
            align: 'center',
          },
          interval: "auto",
          boundaryGap: false,
          splitLine : {
            show: true
          },
          name: isDaily ? 'Time' : 'Date',
          nameLocation: 'center',
          nameGap: 30
        },
      ],
      series: dataSeries.map((dataset, id) => {
        let color = id === 0 ? '#6ace61' : '#f5365c'
        if(isDaily) {
          color = '#f5365c'
        }
        return {
          type: "line",
          name: dataset.label,
          label: {
            show: false,
            fontSize: '11px'
          },
          lineStyle: {
            color: color,
            width: 3
          },
          data: dataset.data.map((d, i) => {
            const label = isDaily ? moment(labels[i]) : labels[i];
            return {
              name: isDaily ? label.format('HH:mm') : label,
              value: isDaily ? [label.format('HH:mm'), d] : [label, d],
            };
          }),
          tooltip: {
            valueFormatter: (value) => value ? formatNumber(value) : value,
          },
          symbol: 'circle',
          symbolSize: 10,
          itemStyle: {
            color: color
          }
        };
      }),
    };
  }, [data, dateRange, unit]);

  return (
    <EChartsReact
      notMerge={true}
      option={options}
      showLoading={isLoading}
      style={{ height: '100%' }}
    />
  );
};

export default ReportEChart;
