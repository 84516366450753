import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  baseQuery: baseQuery,
  tagTypes: ['Group'],
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: (params) => ({
        url: `/group`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'Group', id })),
            { type: 'Group', id: 'LIST' },
          ]
          : [{ type: 'Group', id: 'LIST' }],
    }),
    getGroup: builder.query({
      query: (id) => ({
        url: `/group/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'Group', id }],
    }),
    createGroup: builder.mutation({
      query: (body) => ({
        url: `/group`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Group', id: 'LIST' }],
    }),
    updateGroup: builder.mutation({
      query: (body) => ({
        url: `/group/${body.id}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          groupApi.util.updateQueryData('getGroup', id, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Group', id }],
    }),
    deleteGroup: builder.mutation({
      query(id) {
        return {
          url: `/group/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [{ type: 'Group', id: 'LIST' }],
    }),
  })
});

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = groupApi;

