import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import CombinationChart from "../charts/CombinationChart";
import { usePerformanceDashboard } from "../../context";
import { useGetNumberDevicesPacketLossQuery } from "features/dashboard/service/dashboardApi";

const DeviceWithHighPingPakcetLossChart = () => {
  const { filters } = usePerformanceDashboard();
  const { data, isLoading, isFetching } = useGetNumberDevicesPacketLossQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    regionAsString: filters.region ?? undefined,
  }, {
    skip: !filters.startTs || !filters.endTs
  });
  return (
    <Card>
      <CardHeader className="border-0">
        <h4>
          Number of Device with High Ping Packet Loss
        </h4>
      </CardHeader>
      <CardBody style={{ height: 250 }}>
        <CombinationChart data={data} isLoading={isLoading || isFetching} minInterval={1} clickable stacked type={'packet-loss'}/>
      </CardBody>
    </Card>
  );
};

export default DeviceWithHighPingPakcetLossChart;
