import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import cn from 'classnames';

const ButtonLinkIcon = ({
  label,
  onClick,
  icon,
  iconType,
  fontWeight,
  size,
  className,
  ...props
}) => {
  return (
    <Button
      color="link text-default"
      onClick={onClick}
      className={cn(`font-weight-${fontWeight} p-2`, className)}
      size={size}
      style={{ ...props.style }}
      {...props}
    >
      {label}
      <i className={cn(iconType, icon, { "ml-2": label })}></i>
    </Button>
  );
};

ButtonLinkIcon.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  iconType: PropTypes.string,
  fontWeight: PropTypes.oneOf(["normal", "bold"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

ButtonLinkIcon.defaultProps = {
  label: null,
  onClick: () => {},
  icon: "fa-plus-circle",
  iconType: "fa",
  fontWeight: "normal",
  size: "md",
};

export default ButtonLinkIcon;
