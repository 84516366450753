import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import PageHeader from "../../PageHeader";
import PageToolbar from "../../PageToolbar";
import PingFailureRateTabContent from "./device-issue-tabs/PingFailureRateTabContent";
import PingPacketLossTabContent from "./device-issue-tabs/PingPacketLossTabContent";
import HighPingRTTTabContent from "./device-issue-tabs/HighPingRTTTabContent";
import OverQuotaLimitTabContent from "./device-issue-tabs/OverQuotaLimitTabContent";
import CustomNavItem from "../CustomNavItem";
import { StringParam, useQueryParam } from "use-query-params";
import { history } from "utils";
import { usePerformanceDashboard } from "../../context";
import { useGetSummaryNumberDevicesWithIssueQuery } from "features/dashboard/service/dashboardApi";
import PerformanceKPIOverviewTabContent from "./performance-tabs/PerformanceKPIOverviewTabContent";
import DeviceWithIssueTabContent from "./performance-tabs/DeviceWithIssueTabContent";

const RegionPerformanceView = () => {
  // Initialize state from sessionStorage or default to 'overview'
  const [activeTab, setActiveTab] = useState(sessionStorage.getItem('performanceTab') || 'overview');

  useEffect(() => {
    // Clear sessionStorage on page reload
    const handleUnload = () => {
      sessionStorage.removeItem('performanceTab');
    };
    
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      sessionStorage.setItem('performanceTab', tab);
    }
  };
  const { filters } = usePerformanceDashboard();
  const {data: rtt, isFetching: isRttLoading} = useGetSummaryNumberDevicesWithIssueQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    type: 'avg_rtt',
    region: filters.region ?? undefined,
  }, {
    skip: !filters.startTs || !filters.endTs
  });
  const {data: pl, isFetching: isPlLoading} = useGetSummaryNumberDevicesWithIssueQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    type: 'packet_loss',
    region: filters.region ?? undefined,
  }, {
    skip: !filters.startTs || !filters.endTs
  });
  const {data: fr, isFetching: isFrLoading} = useGetSummaryNumberDevicesWithIssueQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    type: 'failure_rate',
    region: filters.region ?? undefined,
  }, {
    skip: !filters.startTs || !filters.endTs
  });
  const {data: ooq, isFetching: isOoqLoading} = useGetSummaryNumberDevicesWithIssueQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    type: 'out_of_quota_provider',
    region: filters.region ?? undefined,
  }, {
    skip: !filters.startTs || !filters.endTs
  });

  const [tabIssue, setTabIssue] = useQueryParam('tabIssue', StringParam);
  // ping-failure-rate | ping-packet-loss | high-ping-rtt | over-quota-limit

  const toggle = (tab) => {
    if (tabIssue !== tab) setTabIssue(tab);
  };

  const onBack = () => {
    history.replace('/admin/performance-dashboard');
  }

  const findCount = (data) => {
      const countArr = data?.content?.map(obj => {
        return obj.value_
      })
      const count = countArr?.reduce((a,b) => +a + +b, 0)
      return count
  }

  useEffect(() => {
    if (!tabIssue) {
      setTabIssue('ping-failure-rate');
    }
  }, [setTabIssue, tabIssue]);

  return (
    <>
      <div className="header header-dark pb-8 bg-default">
        <Container fluid>
          <PageHeader />
          <Row>
            <Col>
              <PageToolbar onBack={onBack} />
            </Col>
          </Row>
          <h3 className="text-secondary mb-3">Issues Summary</h3>
        </Container>
      </div>
      <Container fluid className="mt--8">
        <Card>
          <CardBody>
            <Nav tabs>
              <CustomNavItem
                active={tabIssue === "ping-failure-rate"}
                onClick={() => toggle("ping-failure-rate")}
                text="Ping Failure Rate"
                count={findCount(fr)}
                loading={isFrLoading}
              />
              <CustomNavItem
                active={tabIssue === "ping-packet-loss"}
                onClick={() => toggle("ping-packet-loss")}
                text="Ping Packet Loss"
                count={findCount(pl)}
                loading={isPlLoading}
              />
              <CustomNavItem
                active={tabIssue === "high-ping-rtt"}
                text="High Ping RTT"
                onClick={() => toggle("high-ping-rtt")}
                count={findCount(rtt)}
              />
              <CustomNavItem
                active={tabIssue === "over-quota-limit"}
                text="Low Quota (≤ 100 MB)"
                onClick={() => toggle("over-quota-limit")}
                count={findCount(ooq)}
                loading={isOoqLoading}
              />
            </Nav>
            <TabContent activeTab={tabIssue}>
              <TabPane tabId={tabIssue ? "ping-failure-rate" : undefined}>
                <PingFailureRateTabContent data={fr} isLoading={isFrLoading}/>
              </TabPane>
              <TabPane tabId="ping-packet-loss">
                <PingPacketLossTabContent data={pl} isLoading={isPlLoading}/>
              </TabPane>
              <TabPane tabId="high-ping-rtt">
                <HighPingRTTTabContent data={rtt} isLoading={isRttLoading}/>
              </TabPane>
              <TabPane tabId="over-quota-limit">
                <OverQuotaLimitTabContent data={ooq} isLoading={isOoqLoading}/>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
      <Container fluid className="mt-4">
        <h3 className="mb-3">Performance Chart</h3>
        <Nav tabs>
          <CustomNavItem
            active={activeTab === "overview"}
            onClick={() => toggleTab("overview")}
            text="Performance KPI Overview"
          />
          <CustomNavItem
            active={activeTab === "device-issue"}
            onClick={() => toggleTab("device-issue")}
            text="Number of Devices with Issue"
          />
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="overview">
            <PerformanceKPIOverviewTabContent />
          </TabPane>
          <TabPane tabId="device-issue">
            <DeviceWithIssueTabContent />
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
};

export default RegionPerformanceView;
