import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { RegionListPage } from "./pages";

function RegionRouter() {
  return (
    <Switch>
      <Route exact path={`/admin/regions/`} component={RegionListPage} />
      <Redirect to="/admin/regions/" />
    </Switch>
  );
}

export default RegionRouter;
