import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { DeviceInventoryList } from "./pages";
import { CreateDevice as ProvisionDevice } from  "./../../device/pages/CreateDevice";

function DeviceInventoryRoutes() {
  return (
    <Switch>
      <Route exact path={`/admin/inventory/device`} component={DeviceInventoryList} />
      <Route exact path={`/admin/inventory/device/provision`} component={ProvisionDevice} />
      <Redirect to="/admin/inventory/device" />
    </Switch>
  );
}

export default DeviceInventoryRoutes;
