import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from 'reactstrap';

import { Can, DELETE_SIMCARD_INVENTORY, UNASSIGN_SIMCARD, UPDATE_SIMCARD_INVENTORY } from '../../../../config';

const CustomActionButton = ({ row, onDelete, onEdit, onUnassignSIM }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const assetStatus = row.assetStatus;

  const handleOnDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete(row);
    }
  }

  const handleOnEdit = () => {
    if (typeof onEdit === 'function') {
      onEdit(row);
    }
  }

  return (
    <Nav navbar>
      <UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
        <DropdownToggle nav>
          <i className="fas fa-ellipsis-v text-default" />
        </DropdownToggle>
        <DropdownMenu right>
          <Can I="do" this={DELETE_SIMCARD_INVENTORY}>
            {() => (
              <DropdownItem onClick={handleOnDelete}>
                <span className="text-danger"><i className="fa fa-trash text-danger mr-2"></i> Delete</span>
              </DropdownItem>
            )}
          </Can>
          <Can I="do" this={UPDATE_SIMCARD_INVENTORY}>
            {() => (
              <DropdownItem onClick={handleOnEdit}>
                <span className="text-default"><i className="fa fa-edit text-default mr-2"></i>Edit</span>
              </DropdownItem>
            )}
          </Can>
          <Can I="do" this={UNASSIGN_SIMCARD}>
            {() => assetStatus === 'ASSIGNED' && (
              <DropdownItem onClick={() => onUnassignSIM(row)}>
                <span className="text-default"><i className="fa fa-minus-square text-danger mr-2"></i>Unassign</span>
              </DropdownItem>
            )}
          </Can>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
}

export default CustomActionButton;