import { PageContent } from "components";
import customStyles from "components/organisms/ReactDatatable/customStyles";
import moment from "moment";
import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
} from "reactstrap";
import { StringParam, useQueryParams } from "use-query-params";
import OverviewCards from "../components/OverviewCards";
import useDeviceLicenseColumns from "../hooks/useDeviceLicenseColumns";
import useDeviceLicenseDatatable from "../hooks/useDeviceLicenseDatatable";
import { useGetOneLicenseQuery } from "../services/licenseApi";

const DeviceLicensePage = () => {
  const { licenseId } = useParams();
  const { data: license } = useGetOneLicenseQuery(Number(licenseId), {
    skip: !licenseId,
  });

  const [queryParams, setQuery] = useQueryParams({
    filterBy: StringParam
  });

  const buildFilters = useMemo(() => {
    if (!queryParams.filterBy) return {};

    switch (queryParams.filterBy) {
      case "nearlyExpired":
        return {
          gteLicenseEndTs: moment().add(7, 'days').valueOf(),
        };
      case "newLicense":
        return {
          gteLicenseBeginTs: moment().subtract(7, 'days').valueOf(),
        };
      default:
        return {};
    }
  }, [queryParams])

  const onClickWidget = (key) => {
    switch (key) {
      case "nearlyExpired":
      case "newLicense":
        setQuery({ filterBy: key });
        break;
      default:
        setQuery({ filterBy: undefined });
        break;
    }
  };

  const {
    data,
    isLoading,
    totalRows,
    onChangePage,
    onChangeRowsPerPage,
    pageSize,
    onSort,
  } = useDeviceLicenseDatatable({ licenseId, ...buildFilters });
  const columns = useDeviceLicenseColumns();

  const mapDataWithLicense = useMemo(() => {
    return data.map((d) => {
      return {
        ...d,
        licenseNumber: license?.name,
        licenseEndTs: license?.licenseEndTs || null,
        licenseBeginTs: license?.licenseBeginTs || null,
      };
    });
  }, [data, license]);

  return (
    <PageContent title="Subscription License">
      <Card>
        <CardBody>
          <OverviewCards onClickWidget={onClickWidget} />
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <Breadcrumb listClassName="bg-white m-0 p-0">
                <BreadcrumbItem>
                  <Link className="text-lg" to="/admin/license">
                    All Data
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span className="text-lg">List of Device</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
          <DataTable
            keyField="id"
            title="All Data"
            responsive
            persistTableHead
            columns={columns}
            data={mapDataWithLicense}
            striped
            noHeader
            highlightOnHover
            pagination
            paginationServer
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationPerPage={pageSize}
            customStyles={customStyles}
            progressPending={isLoading}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default DeviceLicensePage;
