import { useMemo } from "react";
import { defaultOptions, usePagination } from "./../../../hooks";
import { groupBy } from "lodash";
import { useGetRolesQuery } from "features/user/service/roleApi";

const defaultParams = {
  ids: undefined,
  name: undefined,
};
export function useRoleDatatable(
  params = defaultParams,
  options = defaultOptions
) {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination({
    pageNumber: options?.pageNumber,
    pageSize: options?.pageSize,
    sortFields: options?.sortFields,
    sortOrder: options?.sortOrder,
  });

  const { data: paginatedResult, isLoading } = useGetRolesQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    ...params,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    const mapData =
      paginatedResult?.content &&
      paginatedResult.content.map((d) => {
        const { rolePrivileges } = d;
        return {
          ...d,
          privileges:
            rolePrivileges &&
            groupBy(
              rolePrivileges.map((p) => p.feature),
              "category"
            ),
          features: rolePrivileges.map(({ feature }) => feature),
        };
      });

    return mapData;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}
