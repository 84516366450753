import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Loading } from "./../../../../../components";
import ReactECharts from 'echarts-for-react';

const DeviceQuotaPercentageCard = ({ data, loading }) => {
  const options = useMemo(() => {
    let option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        right: 'right',
        top: 'middle'
      },
      series: [
        {
          left: '-30%',
          name: 'Usage in Percentage',
          type: 'pie',
          radius: '80%',
          data: [
            { value: 0, name: "0%-25%" },
            { value: 0, name: "26%-50%" },
            { value: 0, name: "51%-75%" },
            { value: 0, name: "76%-100%" },
          ],
          label: {
            position: 'inside',
            formatter: '{c}'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    const labels = data.labels || [];
    const series = [];

    if (data && data.datasets) {
      data.datasets.forEach(({ label, data: dataVal }) => {
        const dataValue = [];
        if (dataVal) {
          dataVal.forEach((d, i) => {
            dataValue.push({ value: d, name: labels[i] || null });
          });
        }
        series.push({
          left: '-30%',
          radius: '80%',
          name: label || 'Usage in Percentage',
          type: 'pie',
          data: dataValue,
          label: {
            position: 'inside',
            formatter: '{c}'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        })
      });
      option.series = series;
    }

    return option;
  }, [data]);

  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col>
            <CardTitle tag="h3" className="text-muted">
              ALL DEVICE QUOTA
            </CardTitle>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="chart chart-sm">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Loading />
                </div>
              ) : (
                <div className="w-100 h-100">
                  <ReactECharts option={options} style={{ width: '100%', height: '100%' }} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

DeviceQuotaPercentageCard.defaultProps = {
  data: {
    labels: ["0%-25%", "26%-50%", "51%-75%", "76%-100%"],
    datasets: [
      { data: [0, 0, 0, 0] },
    ],
  },
  loading: false,
};

DeviceQuotaPercentageCard.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.array,
    datasets: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default DeviceQuotaPercentageCard;
