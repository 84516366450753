import { Formik } from 'formik';
import React from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from "reactstrap";

import * as Yup from 'yup';
import { useGetSimcardByGsmNumberMutation } from './../../inventory/simcards';

import { RowItem, CustomLabel, FormikToggleSwitch, TritronikSelect, TritronikInputText, Gap } from './../../../components';

const validationSchema = Yup.object().shape({
    id: Yup.number().required('Please select SIM first.'),
    gsmNumber: Yup.string().required('GSM Number required.'),
    circuitNumber: Yup.string().required('SIA required.'),
    quotaPackage: Yup.string().required('Quota required.'),
    quotaPackageUom: Yup.string().required('Quota UOM required.'),
});

const ModalChangeSIM = ({ isLoading, onSubmit, isOpen, toggle, device, sim, unprovisionGsmNumbersOptions, checkCircuitNumber, quotaOptions, uomOptions }) => {
    const [getSimcardByGsmNumber] = useGetSimcardByGsmNumberMutation();

    const slotNumber = sim?.slotNumber || 0;

    const onGsmNumberChange = async (e, setFieldValue) => {
        setFieldValue('gsmNumber', e.value);

        const simDetail = await getSimcardByGsmNumber(e.value).unwrap();
        if (simDetail) {
            setFieldValue(`id`, simDetail.id || '');
            setFieldValue(`imsi`, simDetail.imsi || '');
            setFieldValue(`circuitNumber`, simDetail.circuitNumber || '');
            setFieldValue(`operator`, simDetail.operator || '');
            setFieldValue(`billingCycle`, simDetail.billingCycle || '');
        }
    }

    const validateCircuitNumber = async (id, circuitNumber) => {
        let error;

        if (circuitNumber !== '') {
            const exists = await checkCircuitNumber({ id, circuitNumber }).unwrap();
            if (exists) {
                error = 'SIA Number already exists.';
            }
        }
        return error;
    }

    const onCancel = (resetForm) => {
        resetForm();
        toggle();
    }

    return (
        <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>
                Change SIM {slotNumber} For
            </ModalHeader>
            <Formik
                initialValues={{
                    id: '',
                    slotNumber: sim?.slotNumber || '',
                    billingCycle: "",
                    ratePlan: "",
                    services: "",
                    servicesApplication: "",
                    quotaPackage: "",
                    quotaPackageUom: "",
                    gsmNumber: "",
                    circuitNumber: "",
                    primary: false
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({ values, errors, touched, dirty, setFieldValue, resetForm, handleSubmit }) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            <ModalBody>
                                <Row className="px-5">
                                    <Col lg="6" sm="12">
                                        <RowItem className="border" boldLabel label="Device Type" value={device?.type} />
                                    </Col>
                                    <Col lg="6" sm="12">
                                        <RowItem className="border" boldLabel label="SN" value={device?.sn} />
                                    </Col>
                                </Row>
                                <hr className='mx-4' />
                                <Row>
                                    <Col className="col-6 px-5 border-right">
                                        <RowItem boldLabel label={`Is Primary`} value={<FormikToggleSwitch checked={values.primary} onChange={() => setFieldValue('primary', !values.primary)} />} />
                                        <RowItem
                                            boldLabel
                                            rightCol="col-8"
                                            label={<CustomLabel
                                                name="GSM Number"
                                                required />} value={(
                                                    <TritronikSelect
                                                        filter
                                                        name={`gsmNumber`}
                                                        value={values.gsmNumber}
                                                        onChange={(e) => onGsmNumberChange(e, setFieldValue)}
                                                        options={unprovisionGsmNumbersOptions}
                                                        placeholder="GSM Number"
                                                        invalid={Boolean(errors.gsmNumber && touched.gsmNumber)}
                                                        error={errors.gsmNumber}
                                                    />
                                                )} />
                                        <Gap height={10} />
                                        <RowItem boldLabel rightCol="col-8" label={<CustomLabel name="IMSI" required />} value={(
                                            <TritronikInputText small name={`imsi`} placeholder="IMSI" readOnly />
                                        )} />
                                        <Gap height={10} />
                                        <RowItem boldLabel rightCol="col-8" label={<CustomLabel name="SIA" required />} value={(
                                            <TritronikInputText small name={`circuitNumber`} placeholder="SIA" validate={(val) => validateCircuitNumber(values.id, val)} />
                                        )} />
                                    </Col>
                                    <Col className="col-6 px-5 border-left">
                                        <Gap height={50} />
                                        <RowItem boldLabel rightCol="col-8" label={<CustomLabel name="Operator" />} value={(
                                            <TritronikInputText small name={`operator`} placeholder="Operator" readOnly />
                                        )} />
                                        <Gap height={10} />
                                        <RowItem boldLabel rightCol="col-8" label={<CustomLabel name="Billing Date" />} value={(
                                            <TritronikInputText small name={`billingCycle`} placeholder="Billing Date" readOnly />
                                        )} />
                                        <Gap height={10} />
                                        <RowItem boldLabel rightCol="col-8" label={<CustomLabel name="Quota" required />} value={(
                                            <div>
                                                <div className="d-flex w-100 justfiy-content-between">
                                                    <TritronikSelect
                                                        name={`quotaPackage`}
                                                        value={values.quotaPackage}
                                                        onChange={(e) => setFieldValue(`quotaPackage`, e.value)}
                                                        options={quotaOptions}
                                                        placeholder="Package"
                                                        invalid={Boolean(errors.quotaPackage && touched.quotaPackage)}
                                                        error={errors.quotaPackage}
                                                    />
                                                    <Gap width={20} />
                                                    <TritronikSelect
                                                        name={`quotaPackageUom`}
                                                        value={values.quotaPackageUom}
                                                        onChange={(e) => setFieldValue(`quotaPackageUom`, e.value)}
                                                        options={uomOptions}
                                                        placeholder="UOM"
                                                        invalid={Boolean(errors.quotaPackageUom && touched.quotaPackageUom)}
                                                        error={errors.quotaPackageUom}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        />
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="btn btn-outline-default px-5 py-1 ml-auto" onClick={() => onCancel(resetForm)}>
                                    Cancel
                                </Button>
                                <Button className="btn btn-default px-5 py-1" type="submit" disabled={isLoading || !dirty}>
                                    Apply
                                    {isLoading && (<Spinner className="ml-2" color="light" size="sm" />)}
                                </Button>
                            </ModalFooter>
                        </form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}

ModalChangeSIM.defaultOptions = {
    isOpen: false,
    toggle: () => { },
}

export default ModalChangeSIM;