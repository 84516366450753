import { createApi } from '@reduxjs/toolkit/query/react';

import { authApi } from './../../auth/service/authApi';
import { baseQuery } from './../../../services/baseApi';

export const areaApi = createApi({
  reducerPath: 'areaApi',
  baseQuery: baseQuery,
  tagTypes: ['Area'],
  endpoints: (builder) => ({
    getAreas: builder.query({
      query: (params) => ({
        url: `/hierarchy/area`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'Area', id })),
            { type: 'Area', id: 'LIST' },
          ]
          : [{ type: 'Area', id: 'LIST' }],
    }),
    getArea: builder.query({
      query: (id) => ({
        url: `/hierarchy/area/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'Area', id }],
    }),
    createArea: builder.mutation({
      query: (body) => ({
        url: `/hierarchy/area`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Area', id: 'LIST' }],
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        queryFulfilled.then(() => {
          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
        });
      },
    }),
    updateArea: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/hierarchy/area/${id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        const patchResult = dispatch(
          areaApi.util.updateQueryData('getArea', id, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled;
          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Area', id }],
    }),
    deleteArea: builder.mutation({
      query(id) {
        return {
          url: `/hierarchy/area/${id}`,
          method: 'DELETE',
        }
      },
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        queryFulfilled.then(() => {
          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
        });
      },
      invalidatesTags: (result, error, id) => [{ type: 'Area', id }],
    }),
    deleteAreaBulk: builder.mutation({
      query(ids) {
        return {
          url: `/hierarchy/area/bulk/${ids}`,
          method: 'DELETE',
        }
      },
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        queryFulfilled.then(() => {
          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
          dispatch(areaApi.util.invalidateTags([{ type: 'Area', id: 'LIST' }]));
        });
      },
    }),
    setAreaToRegion: builder.mutation({
      query({ areaId, regionId }) {
        return {
          url: `/hierarchy/mapping/area/${areaId}/region/${regionId}`,
          method: 'PUT',
        }
      },
      invalidatesTags: (result, error, { areaId }) => [{ type: 'Area', id: areaId }],
    }),
    removeAreaFromRegion: builder.mutation({
      query({ areaId, regionId }) {
        return {
          url: `/hierarchy/mapping/area/${areaId}/region/${regionId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, { areaId }) => [{ type: 'Area', id: areaId }],
    }),
    setAreaToGroup: builder.mutation({
      query({ areaId, groupId }) {
        return {
          url: `/group/${groupId}/area/${areaId}`,
          method: 'PUT',
        }
      },
      invalidatesTags: (result, error, { areaId }) => [{ type: 'Area', id: areaId }],
    }),
  })
});

export const {
  useGetAreaQuery,
  useGetAreasQuery,
  useCreateAreaMutation,
  useUpdateAreaMutation,
  useDeleteAreaMutation,
  useSetAreaToRegionMutation,
  useRemoveAreaFromRegionMutation,
  useSetAreaToGroupMutation,
  useDeleteAreaBulkMutation,
} = areaApi;

