import { Dialog } from 'primereact/dialog';
import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import { useGetAboutPageQuery } from './../../services/referenceApi';
import './AboutPageModal.css';
import { Button } from 'reactstrap';

const AboutPageModal = ({ isVisible, onHide }) => {
  const { data } = useGetAboutPageQuery();

  
  const content = useMemo(() => {
    if (!data) return '';
    
    return data.whatsNew;
  }, [data]);

  const version = useMemo(() => {
    return `Version ${data?.version || '-'}`
  }, [data]);

  const renderFooter = () => (
    <div>
      <Button className="btn btn-default px-5 py-2 mt-3" type="button" onClick={onHide} autoFocus>
        Ok
      </Button>
    </div>
  );

  return (
    <div className="about-page">
      <Dialog header={`Device Management Platform - ${version}`} visible={isVisible} style={{ width: '50vw' }} footer={renderFooter()} onHide={onHide}>
        <ReactMarkdown
          remarkPlugins={[gfm]}
          rehypePlugins={[rehypeRaw]}
          children={content} />
      </Dialog>
    </div>
  )
}

export default AboutPageModal
