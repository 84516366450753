import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { Badge, Button, Col, Row } from 'reactstrap';
import SignalStrengthStatus from '../SignalStrengthStatus';
import { RowItem } from './../../../../components';
import { unknown } from './../../../../utils';
import { Tooltip } from 'primereact/tooltip';
import classNames from 'classnames';

export const DeviceOverviewSimCard = ({
  simCard,
  verifySimCard,
  isVerifying,
}) => {
  const {
    id,
    slotNumber,
    imsi,
    imei,
    signalStrengthIndex,
    signalStrength,
    primary,
    operator,
    txUsageInHuman,
    rxUsageInHuman,
    gsmNumber,
    circuitNumber,
    verified,
    createdByStream
  } = simCard;

  const createdByStreamFlag = useCallback((field) => {
    if (!createdByStream) return null;

    return (
      <span>
        <i className={classNames(`fa fa-exclamation-triangle text-red ml-2`, `created-by-stream-${field}-${id}`)} data-pr-tooltip="Unregistered SIM Card Detected" data-pr-position="right"/>
        <Tooltip target={`.created-by-stream-${field}-${id}`} />
      </span>
    )
  }, [createdByStream, id]);

  const renderGSMNumber = useMemo(() => {
    const simCardVerification = () => {
      switch (simCard.verificationStatus) {
        // case 'Verification In Progress':
        //   return <i className='fas fa-hourglass ml-2 text-light' />;
        // case 'Unverified':
        //   return <i className='fas fa-question-circle text-yellow ml-2' />;
        // case 'Match':
        //   return <i className='fa fa-check-circle text-success ml-2' />;
        // case 'Unmatch':
        //   return <i className='fa fa-times-circle ml-2 text-danger ml-2' />;
        // case 'Verification Failed':
        //   return <i className='fa fa-exclamation-circle text-danger ml-2' />;
        // default:
        //   return <i className='fas fa-question-circle text-yellow ml-2' />;
      }
    };

    if (gsmNumber && verified === false) {
      const contentButton = isVerifying ? (
        <span>Verifying...</span>
      ) : (
        <span>Verify Now</span>
      );

      return (
        <div className='d-flex align-items-center'>
          <Button
            className='mr-2'
            color='primary'
            size='sm'
            onClick={() => verifySimCard(id)}
            hidden
          >
            {contentButton}
          </Button>
          {gsmNumber}
          {simCardVerification()}
          {createdByStreamFlag('gsmNumber')}
        </div>
      );
    } else if (gsmNumber && verified === true) {
      return (
        <div className='d-flex align-items-center'>
          {gsmNumber} 
          {simCardVerification()}
          {createdByStreamFlag('gsmNumber')}
        </div>
      );
    }
  }, [gsmNumber, verified, simCard.verificationStatus, isVerifying, verifySimCard, id, createdByStreamFlag]);

  const renderCircuitNumber = () => {
    const flag = createdByStreamFlag('cid');

    return (
      <span>
        {circuitNumber || unknown}
        {flag}
      </span>
    )
  }

  return (
    <Row key={id}>
      <Col>
        <RowItem
          label={
            <h4>
              SIM {slotNumber || 'Not Available'}{' '}
              {primary ? (
                <Badge className='ml-2' color='primary'>
                  PRIMARY
                </Badge>
              ) : null}
            </h4>
          }
        />
        <RowItem stripped label='SIA' value={renderCircuitNumber()} />
        <RowItem label='IMEI' value={imei || unknown} />
        <RowItem
          stripped
          label='IMSI'
          value={
            simCard.unverifiedDetected === true ? (
              <span>
                {imsi || unknown}
                {/* <i className='fa fa-exclamation-triangle text-red ml-2' /> */}
              </span>
            ) : (
              <span>{imsi || unknown}</span>
            )
          }
        />

        <RowItem label='GSM Number' value={renderGSMNumber} />
        <RowItem
          stripped
          label='Signal'
          value={
            <SignalStrengthStatus
              status={signalStrength}
              signal={signalStrengthIndex}
              type='index'
            />
          }
        />
        {/* <RowItem label='Operator' value={operator || unknown} /> */}
        <RowItem 
          label='Operator' 
          value={
            simCard.unmatchedOperator === true ? (
              <span id='unmatchedOperatorSign'>
                {operator || unknown}
                <i className='fa fa-exclamation-triangle text-red ml-2 custom-target-icon' data-pr-tooltip="Unmatched IMSI & Operator" data-pr-position="right"/>
                <Tooltip target=".custom-target-icon" />
              </span>
              
            ) : (
              <span>{operator || unknown}</span>
            )
          } />
        <RowItem stripped label='Tx Usage' value={txUsageInHuman || '- kB'} />
        <RowItem label='Rx Usage' value={rxUsageInHuman || '- kB'} />
      </Col>
    </Row>
  );
};
