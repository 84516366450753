import React from "react";
import { toast } from "react-toastify";
import {
  Modal
} from "reactstrap";
import { useCreateSimcardMutation } from "..";
import FormSimcard from "./FormSimcard";

const CreateSimcardInventoryModal = ({ isOpen, toggle, simcard }) => {
  const [createSimcard, { isLoading }] = useCreateSimcardMutation();

  const onSubmit = async (device) => {
    createSimcard(device)
      .unwrap()
      .then(() => {
        toast.success(`Device ${device.sn} created.`);
        toggle();
      })
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <FormSimcard onSubmit={onSubmit} onCancel={toggle} isLoading={isLoading} />
    </Modal>
  );
};

export default CreateSimcardInventoryModal;
