import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InputText } from 'primereact/inputtext';

const PrimeSearchInput = forwardRef(({ onFilter, placeholder, size, block, ...props }, ref) => {
  const [searchText, setSearchText] = useState('');

  const handleOnKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      if (onFilter && typeof onFilter === 'function') {
        onFilter(searchText);
      }
    }
  }

  useImperativeHandle(ref, () => ({
    clearFilter() {
      setSearchText('');
    },
    changeValue(value) {
      setSearchText(value);
    }
  }));

  return (
    <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={handleOnKeyDown}
          className={classnames({
            "p-inputtext-sm": size === 'sm',
            "p-inputtext-lg": size === 'lg',
            "p-d-block": block,
          })}
          {...props}
        />
    </span>
  )
});

PrimeSearchInput.propTypes = {
  onFilter: PropTypes.func,
  size: PropTypes.oneOf(['sm','md','lg']),
  placeholder: PropTypes.string,
  block: PropTypes.bool
};

PrimeSearchInput.defaultProps = {
  size: 'md',
  onFilter: () => {},
  placeholder: 'Search',
  block: true,
}

export default PrimeSearchInput
