import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Row,
  Spinner,
  UncontrolledDropdown
} from "reactstrap";
import { ButtonLinkIcon, PrimeDropdown, RowItem } from "../../../../components";

const severityOptions = [
  { value: null, label: "All" },
  { value: "minor", label: "Minor" },
  { value: "major", label: "Major" },
  { value: "critical", label: "Critical" },
];

const statusOptions = [
  { value: null, label: "All" },
  { value: "Open", label: "Open" },
  { value: "Resolved", label: "Resolved" },
  { value: "No Data", label: "No Data" },
];

const FilterAlertHistory = (props) => {
  const { filterSeverity, filterLastState } = props;
  const [changes, setChanges] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const [severity, setSeverity] = useState(filterSeverity);
  const [lastState, setLastState] = useState(filterLastState);

  useEffect(() => {
    setSeverity(filterSeverity);
    setLastState(filterLastState);
  }, [filterSeverity, filterLastState, setSeverity, setLastState]);

  const onFilter = () => {
    if (typeof props.onFilter === "function") {
      props.onFilter({
        severity: severity,
        lastState: lastState
      });
    }
    toggle();
  };

  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={menuOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <ButtonLinkIcon label="Filters" icon="fa-filter" />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 350 }} className="p-3">
            <RowItem
              label="Severity"
              value={
                <PrimeDropdown
                  options={severityOptions}
                  value={severity}
                  onChange={(e) => {
                    setSeverity(e.value);
                    setChanges(true);
                  }}
                />
              }
              rightCol="col-7"
            />
            <RowItem
              label="Status"
              value={
                <PrimeDropdown
                  options={statusOptions}
                  value={lastState}
                  onChange={(e) => {
                    setLastState(e.value);
                    setChanges(true);
                  }}
                />
              }
              rightCol="col-7"
            />

            <Row className="mt-4">
              <Col className="text-center">
                <Button
                  className="m-0"
                  block
                  color="default"
                  size="sm"
                  type="button"
                  onClick={onFilter}
                  disabled={props.loading || !changes}
                >
                  Apply
                  {props.loading && (
                    <Spinner className="ml-2" color="light" size="sm" />
                  )}
                </Button>
                <Button
                  className="m-0 mt-2"
                  block
                  color="secondary"
                  size="sm"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

FilterAlertHistory.propTypes = {
  loading: PropTypes.bool,
  onFilter: PropTypes.func,
  filterSeverity: PropTypes.string,
  filterLastState: PropTypes.string,
};

FilterAlertHistory.defaultProps = {
  loading: false,
  onFilter: () => { },
  filterSeverity: null,
  filterLastState: null,
};

export default FilterAlertHistory;
