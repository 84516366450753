import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button, Col, Form, Modal, Row, Spinner } from "reactstrap";
import { Gap, TritronikInputPassword, CustomLabel } from "./../../index";

const ModalUpdatePassword = ({ isOpen, loading, toggle, onSubmit, minPasswordLength, maxPasswordLength }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
    >
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required('Enter your old password.'),
          newPassword: Yup.string().required('Enter your new password.').min(minPasswordLength, `Password length minimum is ${minPasswordLength} characters!`).max(maxPasswordLength, `Password length maximum is ${maxPasswordLength} characters!`),
          confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Password must match!'),
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h3 className="modal-title">Change Password</h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body py-2">
              <Row>
                <Col><CustomLabel name="Old Password" required /></Col>
                <Col>
                  <TritronikInputPassword
                    name="oldPassword"
                    placeholder="Old Password"
                    small
                    autoComplete="new-password"
                  />
                </Col>
              </Row>
              <Gap height={15} />
              <Row>
                <Col><CustomLabel name="New Password" required /></Col>
                <Col>
                  <TritronikInputPassword
                    name="newPassword"
                    placeholder="New Password"
                    small
                    autoComplete="new-password"
                  />
                </Col>
              </Row>
              <Gap height={15} />
              <Row>
                <Col><CustomLabel name="Confirm Password" required /></Col>
                <Col>
                  <TritronikInputPassword
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    small
                    autoComplete="new-password"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-center">
                  <Button block color="default" type="submit" size="sm" disabled={!dirty}>
                    Apply
                    {loading && <Spinner className="ml-2" color="light" size="sm" />}
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="text-center">
                  <Button
                    block
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ModalUpdatePassword.defaultProps = {
  loading: false,
  minPasswordLength: 6,
  maxPasswordLength: 16
}

export default ModalUpdatePassword;
