import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { DeviceUsageSim } from './DeviceUsageSim';
import { BootstrapDateRangePicker, Loading, TritronikSelect } from '../../../../components';
import { useDeviceUsageHistory } from './../../hooks/useDeviceUsageHistory';
import { useSignalStrengthHistory } from './../../hooks/useSignalStrengthHistory';
import DeviceUsageEchart from './DeviceUsageEchart';
import DeviceSignalTrendEchart from './DeviceSignalTrendEchart';
import DeviceBandwidthUsageChart from './DeviceBandwidthUsageChart';
import { useGetDeviceBandwidthUsageQuery } from './../../service/usageApi';
import DeviceLatencyTrendEchart from './DeviceLatencyTrendEchart';

const timeGroupOptions = [
  { value: "2m", label: "2m" },
  { value: "5m", label: "5m" },
  { value: "10m", label: "10m" },
  { value: "30m", label: "30m" },
  { value: "1h", label: "1h" },
  { value: "3h", label: "3h" },
  { value: "6h", label: "6h" },
  { value: "12h", label: "12h" },
  { value: "1d", label: "1d" },
];

export function DeviceUsage({ device }) {
  const { simCards } = device;
  const [activeNav, setActiveNav] = useState('usage');

  const getImsiParams = (simCards) => {
    if (Array.isArray(simCards) && simCards.length > 0) {
      const validSim = simCards.filter(sim => sim.imsi !== null && sim.imsi !== '');

      return validSim.length > 0 ? validSim.map((sim) => sim.imsi).join(',') : undefined;
    }
    return undefined;
  }

  const [params, setParams] = useState({
    deviceId: device.id,
    sn: device.sn,
    imsi: getImsiParams(simCards),
    startTs: moment().startOf('day').valueOf(),
    endTs: moment().endOf('day').valueOf(),
    timeGroupping: '5m'
  });

  const { data: usageHistory, isLoading: loadingUsageHistory } = useDeviceUsageHistory(simCards, params);
  const { data: signalHistory, isLoading: loadingSignalHistory } = useSignalStrengthHistory(simCards, params);
  const { data: bandwidthUsage, isLoading: loadingBandwidthUsage } = useGetDeviceBandwidthUsageQuery({ params: {
    startTs: params.startTs,
    endTs: params.endTs,
    timeGroupping: params.timeGroupping,
  }, body: params.imsi}, { skip: !(simCards && simCards.length > 0 && params.imsi !== undefined)});

  const toggleNavs = (e, nav) => {
    e.preventDefault();
    setActiveNav(nav);
    // if (nav === 'latency') {
    //   setParams(prevParams => ({
    //     ...prevParams,
    //     timeGroupping : '5m'
    //   }))
    // } else {
    //   setParams(prevParams => ({
    //     ...prevParams,
    //     timeGroupping : '5m'
    //   }))
    // }
  }

  const onDateChange = (start, end, label) => {
    setParams((prevState) => ({
      ...prevState,
      startTs: start.valueOf(),
      endTs: end.valueOf(),
    }));
  };

  const onTimeGroupChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      timeGroupping: e.value
    }));
  };

  const RenderChart = ({ type }) => {
    switch (type) {
      case 'signal':
        return <DeviceSignalTrendEchart data={signalHistory} simCards={simCards} />
      case 'usage':
        return <DeviceUsageEchart data={usageHistory} simCards={simCards} />
      case 'bandwidth':
        return <DeviceBandwidthUsageChart data={bandwidthUsage} simCards={simCards} />
      case 'latency':
        return <DeviceLatencyTrendEchart params={params} />
      default:
        return null;
    }
  }

  return (
    <Row>
      <Col>
        <Row className="mt-4">
          <Col>
            <h2 className="text-dark ml-4">Data Usage</h2>
            <Row className="mt-4 border-bottom pb-4">
              {simCards && _.sortBy(simCards, [(o) => o.slotNumber]).map((simCard, i) => (
                <Col md="6" key={simCard.id} className={classnames("px-5", {
                  "border-right": (i % 2 === 0),
                  "border-left": (i % 2 !== 0),
                })}>
                  <DeviceUsageSim simCard={simCard} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="d-flex my-2 justify-content-between">
              <Nav className="justify-content-start" pills>
                <NavItem>
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 'usage'
                    })}
                    href="#usage"
                    onClick={e => toggleNavs(e, 'usage')}
                  >
                    <span className="d-none d-md-block">Usage Trend</span>
                    <span className="d-md-none">UT</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 'signal'
                    })}
                    data-toggle="tab"
                    href="#signal"
                    onClick={e => toggleNavs(e, 'signal')}
                  >
                    <span className="d-none d-md-block">Signal Trend</span>
                    <span className="d-md-none">ST</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 'bandwidth'
                    })}
                    data-toggle="tab"
                    href="#bandwidth"
                    onClick={e => toggleNavs(e, 'bandwidth')}
                  >
                    <span className="d-none d-md-block">Bandwidth Trend</span>
                    <span className="d-md-none">BWT</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("py-2 px-3", {
                      active: activeNav === 'latency'
                    })}
                    data-toggle="tab"
                    href="#latency"
                    onClick={e => toggleNavs(e, 'latency')}
                  >
                    <span className="d-none d-md-block">Connectivity Performance</span>
                    <span className="d-md-none">PERF</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="col-4 d-flex justify-content-end">
                <TritronikSelect value={params.timeGroupping} options={timeGroupOptions} onChange={onTimeGroupChange} style={{ height: '3rem' }} className="mr-2" />
                <BootstrapDateRangePicker onDateChange={onDateChange} alwaysShowCalendars={false} />
              </div>
            </div>
            {(loadingSignalHistory || loadingUsageHistory || loadingBandwidthUsage)
              ? <div className="d-flex justify-content-center mt-4"><Loading /></div>
              : <RenderChart type={activeNav} />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
