import React from 'react'
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { Loading } from 'components';

const SimCardItem = ({ loading, label, value, onClick, isDanger }) => {
  const clickable = parseInt(value) === 0 ? false : true;
  if (isDanger) {
      return (
        <Col>
            <h2 className="text-danger" onClick={clickable ? onClick : null} style={{ cursor: (onClick && clickable) ? 'pointer' : 'default' }}>{loading ? <Loading /> : value}</h2>
            <span className="text-danger">{label}</span>
        </Col>
      )
  } else {
    return (
        <Col>
            <h2 /* className="text-danger"  */onClick={clickable ? onClick : null} style={{ cursor: (onClick && clickable) ? 'pointer' : 'default' }}>{loading ? <Loading /> : value}</h2>
            <span /* className="text-danger" */>{label}</span>
        </Col>
      )
  }
}

const SIMStatusCard = ({ title, reachQuotaLimit, connectionLost, deactivated, onWidgetClick, loading, activated, totalDevice, goToDeviceList }) => {
    if(title === 'DEVICE'){
        return (
            <Card className="card-stats">
                <CardBody>
                    <CardTitle tag="h3" className="text-muted">{title}</CardTitle>
                    <Row>
                        <SimCardItem loading={loading} label="Total Device" value={totalDevice} onClick={goToDeviceList} />
                        <SimCardItem loading={loading} label="Connection Lost" isDanger value={connectionLost} onClick={() => onWidgetClick('connectionStatus', 'Disconnected')} />
                        {/* <SimCardItem loading={loading} label="Deactivated" value={deactivated} onClick={() => onWidgetClick('simCardStatus', 'Deactivated')} /> */}
                    </Row>
                </CardBody>
            </Card>
        )
    } else {
        return (
            <Card className="card-stats">
                <CardBody>
                    <CardTitle tag="h3" className="text-muted">{title}</CardTitle>
                    <Row>
                        <SimCardItem loading={loading} label="Activated" value={activated} onClick={() => onWidgetClick('simCardStatus', 'Activated')} />
                        <SimCardItem loading={loading} label="Deactivated" isDanger value={deactivated} onClick={() => onWidgetClick('simCardStatus', 'Deactivated')} />
                        <SimCardItem loading={loading} label="Reach Quota Limit" isDanger value={reachQuotaLimit} onClick={() => onWidgetClick('reachQuotaLimit', true)} />
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

SIMStatusCard.propTypes = {
    title: PropTypes.string,
    reachQuotaLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    connectionLost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    deactivated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onWidgetClick: PropTypes.func,
    loading: PropTypes.bool
}

SIMStatusCard.defaultProps = {
    title: 'SIM',
    reachQuotaLimit: 0,
    connectionLost: 0,
    deactivated: 0,
    onWidgetClick: () => {},
    loading: false
}

export default SIMStatusCard;
