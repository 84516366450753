import classNames from "classnames";
import React from "react";

const OverviewStatusItem = ({
  value,
  icon,
  caption,
  hasAlert = false,
  onClickDetail,
  textViewDetails = "See Details",
}) => {
  return (
    <div className={classNames({ "text-danger": hasAlert })}>
      <div className="d-flex align-items-center">
        <span className="font-weight-bold">{value}</span>
        <span className="ml-2">{icon}</span>
      </div>
      <div>
        <span className="mr-2">{caption}</span>
        {/* <div> */}
        {hasAlert ? (
          <button className="btn btn-sm btn-link p-0 m-0" onClick={onClickDetail}>
            {textViewDetails}
          </button>
        ) :
          <button className="btn btn-sm btn-link p-0 m-0" disabled>
          </button>
        }
        {/* </div> */}
      </div>
    </div>
  );
};

export default OverviewStatusItem;
