import { useMemo } from "react";
import { useGetAlertHistoriesQuery } from "../service/alertHistoryApi";
import { usePagination } from "./../../../hooks/usePagination";
import { useAuth } from "./../../auth/hooks";

const mapAlertStatus = (state) => {
  switch (state) {
    case "OK": return "RESOLVED";
    case "ALERTING": return "OPEN";
    case "NO_DATA": return "NO DATA";
    case "Resolved": return "OK";
    case "Open": return "ALERTING";
    case "No Data": return "NO_DATA";
    default: return undefined;
  }
}

export const useAlerts = (params = {}, options = {}) => {
  const { user } = useAuth();

  const { pageNumber, pageSize, sortFields, sortOrder, onChangePage, onChangeRowsPerPage, onSort } = usePagination({ pageNumber: 1, pageSize: 10, sortFields: options?.sortFields, sortOrder: options?.sortOrder });

  const { lastState, ...restParams } = params;
  const { data: paginatedResult, isLoading } = useGetAlertHistoriesQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    alertState: mapAlertStatus(lastState),
    username: user?.username,
    ...restParams,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
};
