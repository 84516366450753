import { useCallback, useMemo } from 'react';
import { useGetRolesQuery } from './../features/user/service/roleApi';

export function useRole() {
  const { data, isLoading } = useGetRolesQuery();

  const options = useMemo(() => {
    const opts = [];

    if (data && data.content && data.content.length > 0) {
      data.content.forEach((opt) => {
        opts.push({ value: opt.id, label: opt.name });
      });
    }

    return opts;
  }, [data]);

  const getRolePrivileges = useCallback((roleId) => {
    const privileges = [];
    if (data && data.content && data.content.length > 0) {
      const roles = data.content;
      const role = roles.find((r) => r.id === roleId);

      if (role.rolePrivileges && role.rolePrivileges.length > 0) {
        role.rolePrivileges.forEach(({ feature }) => {
          privileges.push(feature);
        });
      }
    }
    return privileges;
  }, [data]);

  const getAvailablePrivileges = useCallback((selectedFeatures, options) => {
    return options.filter((opt) => !selectedFeatures.some((feature) => feature.id === opt.value));
  }, []);

  return {
    data,
    isLoading,
    options,
    getRolePrivileges,
    getAvailablePrivileges,
  };
}