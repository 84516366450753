import { createApi } from '@reduxjs/toolkit/query/react';

import { authApi } from './../../auth/service/authApi';
import { baseQuery } from './../../../services/baseApi';

export const regionApi = createApi({
  reducerPath: 'regionApi',
  baseQuery: baseQuery,
  tagTypes: ['Region'],
  endpoints: (builder) => ({
    getRegions: builder.query({
      query: (params) => ({
        url: `/hierarchy/region`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'Region', id })),
            { type: 'Region', id: 'LIST' },
          ]
          : [{ type: 'Region', id: 'LIST' }],
      
    }),
    getRegion: builder.query({
      query: (id) => ({
        url: `/hierarchy/region/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'Region', id }],
    }),
    createRegion: builder.mutation({
      query: (body) => ({
        url: `/hierarchy/region`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Region', id: 'LIST' }],
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        queryFulfilled.then(() => {
          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
        });
      },
    }),
    updateRegion: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/hierarchy/region/${id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, getState, queryFulfilled }) {
        const patchResult = dispatch(
          regionApi.util.updateQueryData('getRegion', id, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled;

          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Region', id }],
    }),
    deleteRegion: builder.mutation({
      query(id) {
        return {
          url: `/hierarchy/region/${id}`,
          method: 'DELETE',
        }
      },
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        queryFulfilled.then(() => {
          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
        });
      },
      invalidatesTags: (result, error, id) => [{ type: 'Region', id }, { type: 'Region', id: 'LIST' }]
    }),
    deleteRegionBulk: builder.mutation({
      query(ids) {
        return {
          url: `/hierarchy/region/bulk/${ids}`,
          method: 'DELETE',
        }
      },
      async onQueryStarted(args, { dispatch, getState, queryFulfilled }) {
        queryFulfilled.then(() => {
          const state = getState();
          dispatch(authApi.endpoints.getProfile.initiate(state.auth.username, { subscribe: false, forceRefetch: true }));
          dispatch(regionApi.util.invalidateTags(['Region']));
        });
      },
    }),
    setRegionToGroup: builder.mutation({
      query({ regionId, groupId }) {
        return {
          url: `/group/${groupId}/region/${regionId}`,
          method: 'PUT',
        }
      },
      invalidatesTags: (result, error, { regionId }) => [{ type: 'Region', id: regionId }],
    }),
    getRegionAreas: builder.mutation({
      query(regionId) {
        return {
          url: `/hierarchy/area`,
          method: 'GET',
          params: { regionId }
        }
      },
    }),
  })
});

export const {
  useGetRegionQuery,
  useGetRegionsQuery,
  useCreateRegionMutation,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
  useSetRegionToGroupMutation,
  useGetRegionAreasMutation,
  useDeleteRegionBulkMutation,
} = regionApi;

