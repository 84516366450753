import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const columnOptions = [
  { className: "text-xs", value: "sn", label: "Serial Number" },
  { className: "text-xs", value: "snHardware", label: "SN Hardware" },
  { className: "text-xs", value: "circuitNumber", label: "Device ID" },
  { className: "text-xs", value: "customer.name", label: "Customer Name" },
  { className: "text-xs", value: "connectionStatus", label: "Connection Status" },
  { className: "text-xs", value: "sim1CircuitNumber", label: "SIM 1 SIA" },
  { className: "text-xs", value: "sim1GsmNumber", label: "SIM 1 GSM Number" },
  { className: "text-xs", value: "sim1Imsi", label: "SIM 1 IMSI" },
  { className: "text-xs", value: "sim1Status", label: "SIM 1 Status" },
  { className: "text-xs", value: "sim1Usage", label: "SIM 1 Usage" },
  { className: "text-xs", value: "sim1Signal", label: "SIM 1 Signal" },
  { className: "text-xs", value: "sim1Operator", label: "SIM 1 Operator" },
  { className: "text-xs", value: "sim2CircuitNumber", label: "SIM 2 SIA" },
  { className: "text-xs", value: "sim2GsmNumber", label: "SIM 2 GSM Number" },
  { className: "text-xs", value: "sim2Imsi", label: "SIM 2 IMSI" },
  { className: "text-xs", value: "sim2Status", label: "SIM 2 Status" },
  { className: "text-xs", value: "sim2Usage", label: "SIM 2 Usage" },
  { className: "text-xs", value: "sim2Signal", label: "SIM 2 Signal" },
  { className: "text-xs", value: "sim2Operator", label: "SIM 2 Operator" },
  { className: "text-xs", value: "area.name", label: "Area" },
  { className: "text-xs", value: "lastDataReceive", label: "Last Update" },
  { className: "text-xs", value: "ipAddress", label: "IP Address" },
  { className: "text-xs", value: "uptimeDurationInHuman", label: "Uptime Duration" },
  { className: "text-xs", value: "location", label: "Address" },
  { className: "text-xs", value: "province", label: "Province" },
  { className: "text-xs", value: "regency", label: "District/City" },
  { className: "text-xs", value: "type", label: "Device Type" },
  { className: "text-xs", value: "groups", label: "Groups" },
  { className: "text-xs", value: "lanPortStatus", label: "LAN Port Status" },
];

export const initialState = {
  columns: [
    "sn",
    "customer.name",
    "connectionStatus",
    "sim1Status",
    "sim1Usage",
    "sim1Signal",
    "sim2Status",
    "sim2Usage",
    "sim2Signal",
    "area.name",
    "lastDataReceive",
  ],
};

export const deviceSlice = createSlice({
  name: "devices",
  initialState: initialState,
  reducers: {
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    resetColumns: (state) => {
      state.columns = initialState.columns;
    }
  },
});

export const { setColumns, resetColumns } = deviceSlice.actions;

export const selectColumnDefs = state => state.devices.columns;

export const deviceReducer = persistReducer({
  key: 'devices',
  storage,
  whitelist: ['columns']
}, deviceSlice.reducer);

export default deviceReducer;