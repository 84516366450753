import React, { useMemo } from 'react'
import DataTable from 'react-data-table-component'
import { Col, Row } from 'reactstrap'
import { StatusBadge } from '../../../../components'

const DeviceTunnelOverview = ({ tunnels }) => {
  const columns = useMemo(() => {

    return [
      {
        name: 'TUNNEL NAME',
        selector: row => row?.name,
      },
      {
        name: 'TYPE',
        selector: row => row?.connectionProtocol,
        cell: ({ connectionProtocol }) => connectionProtocol || '-'
      },
      {
        name: 'TUNNEL STATUS',
        selector: row => row?.name,
        cell: ({ status }) => <StatusBadge label={status} type={status === 'UP' ? 'success' : (status === 'DOWN' ? 'danger' : 'none')} isUppercase />
      },
      {
        name: 'UPLINK DURATION',
        selector: row => row?.linkUpDurationInHuman,
      },
      {
        name: 'DETECTION TYPE',
        selector: row => row?.detectionType,
      },
      {
        name: 'DETECTION IP',
        selector: row => row?.detectionIpAddress,
      },
      // {
      //   name: 'PING STATUS',
      //   selector: row => row?.detectionStatus,
      //   cell: ({ detectionStatus }) => <StatusBadge label={detectionStatus} type={detectionStatus === 'GOOD' ? 'success' : (detectionStatus === 'BAD' ? 'danger' : 'none')} isUppercase />
      // },
      {
        name: 'PING STATUS',
        selector: row => row?.detectionStatus,
        cell: ({ detectionStatus, status }) => status === 'UP' ? <StatusBadge label={detectionStatus} type={detectionStatus === 'GOOD' ? 'success' : (detectionStatus === 'BAD' ? 'danger' : 'none')} isUppercase  /> : '-'
      },
      // {
      //   name: 'LATENCY',
      //   cell: ({ latencyInMs }) => `${latencyInMs} ms`
      // },
      {
        name: 'LATENCY',
        cell: ({ latencyInMs, status }) => status === "UP" ? `${latencyInMs} ms` : '-'
      },
    ]

  }, []);
  return (
    <Row>
      <Col className="px-5 pb-5">
        <h2 className="text-dark mt-3 ml--3">Tunnel Overview</h2>
        <div className="mt-4"></div>
        <DataTable
          className="ml--3"
          data={tunnels}
          columns={columns}
          responsive={true}
          keyField="id"
          defaultSortAsc={false}
          striped
          noHeader
          highlightOnHover
          persistTableHead
          customStyles={{
            head: {},
            headRow: {
              style: {
                backgroundColor: "#F1F3F9"
              }
            },
          }}
          overflowY={true}
        />
      </Col>
    </Row>
  )
}

export default DeviceTunnelOverview
