import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import './PrimeDropdown.scss';

const PrimeDropdown = (props) => {
  return (
    <Dropdown {...props} />
  )
}

export default PrimeDropdown;
