import _ from 'lodash';
import { useMemo } from 'react';
import { useAuth } from './../../../features/auth/hooks';
import { defaultOptions, usePagination } from './../../../hooks';
import { useGetUsersQuery } from './../service/userApi';

const defaultParams = {
  groupIds: undefined,
  regionIds: undefined,
  areaIds: undefined,
  customerIds: undefined,
  status: undefined,
  username: undefined,
  smartSearch: undefined,
};
export function useUserDatatable(params = defaultParams, options = defaultOptions) {
  const { user, isAdmin } = useAuth();

  const { pageNumber, pageSize, sortFields, sortOrder, onChangePage, onChangeRowsPerPage, onSort } = usePagination({ pageNumber: options?.pageNumber, pageSize: options?.pageSize, sortFields: options?.sortFields, sortOrder: options?.sortOrder });

  const { regionIds, areaIds, username, ...restParams } = params;
  const { data: paginatedResult, isLoading } = useGetUsersQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    regionIds: areaIds ? undefined : regionIds,
    areaIds: areaIds,
    username: username ? username : (isAdmin ? undefined : user?.username),
    ...restParams,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    const mapData = paginatedResult?.content && paginatedResult.content.map((d) => {
      const { combinePrivileges } = d;
      return {
        ...d,
        privileges: combinePrivileges && _.groupBy(combinePrivileges.map((p) => p.feature), 'category')
      }
    });

    return mapData;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  }
}