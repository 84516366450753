import classnames from 'classnames';
import { FieldArray } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { CustomLabel, Gap, RowItem, TritronikSelect } from '../../../components';
import { useCustomers } from './../../customers/hooks';
import { ModalAddArea } from './../../area/components';
import { ModalAddRegion } from './../../region/components';
import { useGetRegionAreasMutation } from './../../region/service/regionApi';

const FormEligibleData = ({ formik, regionOptions }) => {
  const [modalRegion, setModalRegion] = useState(false);
  const [modalArea, setModalArea] = useState(false);

  const [getRegionAreas] = useGetRegionAreasMutation();

  const { values, errors, touched } = formik;
  const { regions } = values;

  const { options: customerOptions } = useCustomers({
    regionIds: regions && regions.length > 0 ? regions.map((r) => r.id).join(',') : undefined,
  });

  const handleRegionChange = async (e, actions) => {
    if (e.value === '-1') {
      actions.form.setFieldValue('regions', []);
      actions.form.setFieldValue('selectedRegion', e.value);
    } else {
      const result = await getRegionAreas(e.value).unwrap();

      const region = _.find(regionOptions, { value: e.value });

      if (region) {
        actions.push({
          id: region.value,
          name: region.label,
          description: null,
          areas: result?.content || []
        });
      }
      actions.form.setFieldValue('selectedRegion', '');
    }
  }

  const handleCustomerChange = async (e, actions) => {
    if (e.value === '-1') {
      actions.form.setFieldValue('customers', []);
      actions.form.setFieldValue('selectedCustomer', e.value);
    } else {
      const customer = _.find(customerOptions, { value: e.value });
      if (customer) {
        actions.push({
          id: customer.value,
          name: customer.label,
        });
      }
      actions.form.setFieldValue('selectedCustomer', '');
    }
  }

  const getFilteredRegions = () => {
    return [{ value: '-1', label: 'All Region' }, ...regionOptions].filter((region) => !values.regions.map((r) => r.id).includes(region.value));
  }

  const getFilteredCustomers = () => {
    return [{ value: '-1', label: 'All Customer' }, ...customerOptions].filter((cust) => !values.customers.map((r) => r.id).includes(cust.value));
  }

  const toggleModalRegion = () => {
    setModalRegion(!modalRegion);
  }

  const toggleModalArea = () => {
    setModalArea(!modalArea);
  }

  return (
    <div>
      <h2 className="ml-4">Data</h2>
      <Row>
        <Col className="col-6 px-5 border-right">
          <Gap height={30} />
          <FieldArray name="regions">
            {(regionActions) => (
              <div>
                <div style={{ backgroundColor: "#F7FAFC" }}>
                  <RowItem stripped label={<CustomLabel name="Zone" required />} value={(
                    <TritronikSelect
                      name="zone"
                      placeholder="Add Zone"
                      options={getFilteredRegions()}
                      value={values.selectedRegion}
                      onChange={(e) => {
                        handleRegionChange(e, regionActions);
                      }}
                      invalid={errors.regions && touched.selectedRegion}
                      error={errors.regions}
                    />
                  )} />
                  <Row style={{ backgroundColor: "#F7FAFC" }} className="pb-2">
                    <Col>
                      <span className="btn btn-sm btn-link text-default text-sm font-weight-normal" style={{ marginLeft: -8 }} onClick={toggleModalRegion}>
                        Add New Region <i className="fa fa-plus-circle ml-2"></i>
                      </span>
                      <span className="btn btn-sm btn-link text-default text-sm font-weight-normal" style={{ marginLeft: -8 }} onClick={toggleModalArea}>
                        Add New Area <i className="fa fa-plus-circle ml-2"></i>
                      </span>
                    </Col>
                  </Row>
                </div>
                {values.regions && values.regions.map((region, idx) => (
                  <div key={idx}>
                    <RowItem stripped={idx % 2 !== 0} padding={0} label={(
                      <span>
                        <span className={classnames("btn btn-link mr-2")} onClick={() => regionActions.remove(idx)}><i className="fa fa-times-circle"></i></span>
                        {region.name}
                      </span>
                    )} />
                    <FieldArray name={`regions[${idx}].areas`}>
                      {(areaActions) => (
                        <div>
                          {values.regions[idx].areas && values.regions[idx].areas.map((area, index) => (
                            <RowItem padding={0} key={area.id} offset stripped={index % 2 === 0} label={(
                              <span>
                                <span className={classnames("btn btn-link mr-2")} onClick={() => areaActions.remove(index)}><i className="fa fa-times-circle"></i></span>
                                {area.name}
                              </span>
                            )} />
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </Col>
        <Col className="col-6 px-5 border-left">
          <Gap height={30} />
          <FieldArray name="customers">
            {(customerActions) => (
              <div>
                <div style={{ backgroundColor: "#F7FAFC" }}>
                  <RowItem stripped label={<CustomLabel name="Customer" required />} value={(
                    <TritronikSelect
                      name="customer"
                      placeholder="Add Customer"
                      options={getFilteredCustomers()}
                      value={values.selectedCustomer}
                      onChange={(e) => {
                        handleCustomerChange(e, customerActions);
                      }}
                      disabled={(values.regions.length === 0 && values.selectedRegion !== '-1') || customerOptions.length === 0}
                      invalid={errors.customers && touched.selectedCustomer}
                      error={errors.customers}
                    />
                  )} />
                </div>
                {values.customers && values.customers.map((customer, idx) => (
                  <div key={customer.id}>
                    <RowItem stripped={idx % 2 !== 0} padding={0} label={(
                      <span>
                        <span className={classnames("btn btn-link mr-2")} onClick={() => customerActions.remove(idx)}><i className="fa fa-times-circle"></i></span>
                        {customer.name}
                      </span>
                    )} />
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </Col>
      </Row>
      <ModalAddRegion
        isOpen={modalRegion}
        toggle={toggleModalRegion}
      />
      <ModalAddArea
        isOpen={modalArea}
        toggle={toggleModalArea}
      />
    </div>
  )
}

export default FormEligibleData
