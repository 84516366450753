import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNetworksThunk, selectNetworks, selectNetworkState } from './../networkSlice';

export function useNetworks() {
	const dispatch = useDispatch();

	const { isLoading } = useSelector(selectNetworkState);
	const networkList = useSelector(selectNetworks);

	const fetchNetworksList = useCallback(async () => {
		await dispatch(fetchNetworksThunk());
	}, [dispatch]);

	useEffect(() => {
		fetchNetworksList();
	}, [fetchNetworksList]);

	return {
		data: networkList,
		isLoading,
		refetch: fetchNetworksList,
	}
}