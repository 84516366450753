import classNames from "classnames";
import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

const OverviewStatusCard = ({ title, hasAlert = false, children }) => {
  return (
    <Card>
      <CardHeader
        className={classNames("px-4 py-3 border-0 font-weight-bold", { "text-danger": hasAlert })}
      >
        {title}
        {hasAlert ? (
          <i className="mdi mdi-16px mdi-alert text-danger ml-2"></i>
        ) : null}
      </CardHeader>
      <CardBody className='py-2 h-full'>{children}</CardBody>
    </Card>
  );
};

export default OverviewStatusCard;
