import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import { Loading } from 'components';
import classNames from 'classnames';
import { FormikToggleSwitch } from 'components';

const ModalNotificationPermission = props => {
  const [allowAll, setAllowAll] = useState(props.all);
  const [allowEmail, setAllowEmail] = useState(props.email);
  const [allowTelegram, setAllowTelegram] = useState(props.telegram);
  const [allowSMS, setAllowSMS] = useState(props.sms);

  const dirty = (allowAll !== props.all) || (allowEmail !== props.email) || (allowTelegram !== props.telegram) || (allowSMS !== props.sms)

  const isAllowedAll = allowEmail && allowTelegram && allowSMS
  const isAllowedSome = allowEmail || allowTelegram || allowSMS

  const handleAllAllowedChanges = () => {
    const nextState = !allowAll;
    setAllowAll(nextState);
    setAllowEmail(nextState);
    setAllowTelegram(nextState);
    setAllowSMS(nextState);
  }

  useEffect(() => {
    setAllowAll(isAllowedAll);

  }, [isAllowedAll]);

  const handleOnSubmit = () => {
    if (typeof props.onSubmit === 'function') {
      props.onSubmit({ allowAll, allowEmail, allowTelegram, allowSMS, isAllowedSome });
    }
  }

  return (
    <Modal className="modal-dialog-centered" size="sm" isOpen={props.isOpen} toggle={props.toggle}>
      <ModalBody>
          <Row>
            <Col className="text-center">
              <h2>Notification Permission</h2>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="offset-1">
              <div className="d-flex align-items-center justify-content-start">
                <FormikToggleSwitch checked={allowAll} value={allowAll} onChange={handleAllAllowedChanges} />
                <div className={classNames("ml-2", { "text-success": allowAll, "text-dark": !allowAll })}>
                  {allowAll ? "All Allowed" : isAllowedSome ? "Allowed" : "All Not Allowed"}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="offset-2">
              <div className="d-flex align-items-center justify-content-start">
                <FormikToggleSwitch checked={allowEmail} value={allowEmail} onChange={() => {
                  setAllowEmail(!allowEmail);
                }} />
                <div className={classNames("ml-2", { "text-success": allowEmail, "text-dark": !allowEmail })}>
                  {allowEmail ? "Email Allowed" : "Email Not Allowed"}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="offset-2">
              <div className="d-flex align-items-center justify-content-start">
                <FormikToggleSwitch checked={allowTelegram} value={allowTelegram} onChange={() => {
                  setAllowTelegram(!allowTelegram);
                }} />
                <div className={classNames("ml-2", { "text-success": allowTelegram, "text-dark": !allowTelegram })}>
                  {allowTelegram ? "Telegram Allowed" : "Telegram Not Allowed"}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="offset-2">
              <div className="d-flex align-items-center justify-content-start">
                <FormikToggleSwitch checked={allowSMS} value={allowSMS} onChange={() => {
                  setAllowSMS(!allowSMS);
                }} />
                <div className={classNames("ml-2", { "text-success": allowSMS, "text-dark": !allowSMS })}>
                  {allowSMS ? "SMS Allowed" : "SMS Not Allowed"}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Button
                block
                color="secondary"
                type="button"
                size="sm"
                onClick={props.toggle}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button block color="default" type="submit" size="sm" disabled={props.loading || !dirty} onClick={handleOnSubmit}>
                Apply
                {props.loading && <Loading className="ml-2" />}
              </Button>
            </Col>
          </Row>
      </ModalBody>
    </Modal>
  )
}

ModalNotificationPermission.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  all: PropTypes.bool,
  email: PropTypes.bool,
  telegram: PropTypes.bool,
  sms: PropTypes.bool,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
}

ModalNotificationPermission.defaultProps = {
  all: false,
  email: false,
  telegram: false,
  sms: false,
  loading: false,
}

export default ModalNotificationPermission
