import React from "react";
import { Formik } from 'formik';
import { Card, CardBody, Col, Container, Form, Row, Spinner } from "reactstrap";
import * as Yup from 'yup';
import { InputTextGroup, MyButton } from "./../../../../components";
import './../Login/Login.css';
import { useQueryParam, StringParam } from "use-query-params";
import { useResetPasswordMutation, useValidateRestPasswordTokenQuery } from "../../../auth/service/authApi";
import { FullscreenLoading } from './../../../../components';
import { toast } from "react-toastify";
import { history } from "./../../../../utils";
import { APP_CLIENT_LOGO } from "./../../../../config/config";

const ResetPassword = () => {
  const [token] = useQueryParam('key', StringParam);

  const { isLoading, isError } = useValidateRestPasswordTokenQuery({ key: token }, { skip: !token });

  const [resetPassword, { isLoading: resetingPassword }] = useResetPasswordMutation();

  const onSubmit = async ({ password }) => {
    resetPassword({ newPassword: password, resetPasswordKey: token })
      .then(() => {
        toast.success('Your password has been reset.');

        setTimeout(() => {
          history.push('/auth/login');
        }, 1000);
      })
  }

  if (isError) {
    setTimeout(() => {
      history.push('/auth/forgot-password');
    }, 1000);
  }

  if (isLoading) {
    return <FullscreenLoading />
  }

  return (
    <>
      <div className="header bg-white py-5 py-lg-8">
        <Container>
          <div className="header-body text-center mb-8">
          </div>
          <div className="brand-logo d-sm-none d-md-block">
            {/* <img id="brand-logo" alt="" src={APP_BRAND_LOGO} /> */}
            <h4 className="text-primary">Device Management Platform</h4>
          </div>

        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-3">
                <div className="mb-4 text-center mt-3">
                  <img src={APP_CLIENT_LOGO} alt="" width="175" height="auto" />
                </div>
                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string().required('New password required!').min(6, 'Password length minimum is 6 characters!').max(16, 'Password length maximum is 16 characters!'),
                    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password does not match!').required('Confirm password required.'),
                  })}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <Form role="form" onSubmit={formik.handleSubmit}>
                      <InputTextGroup
                        placeholder="Password"
                        type="password"
                        icon="ni ni-lock-circle-open"
                        invalid={Boolean(formik.errors.password && formik.touched.password)}
                        error={formik.errors.password}
                        {...formik.getFieldProps('password')}
                      />
                      <InputTextGroup
                        placeholder="Confirm Password"
                        type="password"
                        icon="ni ni-lock-circle-open"
                        invalid={Boolean(formik.errors.confirmPassword && formik.touched.confirmPassword)}
                        error={formik.errors.confirmPassword}
                        {...formik.getFieldProps('confirmPassword')}
                      />
                      <div className="text-center mt-5">
                        <MyButton block type="submit">
                          Reset Password
                          {resetingPassword && <Spinner className="ml-2" color="light" size="sm" />}
                        </MyButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResetPassword;
