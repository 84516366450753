import React from "react";
import { Switch, Route } from "react-router-dom";
import {LicenseListPage ,DeviceLicensePage} from "../pages";

export default function LicenseRouter() {
  return (
    <Switch>
      <Route exact path={`/admin/license/`} component={LicenseListPage} />
      <Route path={`/admin/license/:licenseId/devices`} component={DeviceLicensePage} />
    </Switch>
  );
}
