import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import {Chips} from 'primereact/chips';
import "./InputChips.scss"

function InputChips(props) {
  const { className, containerClassName, ...rest } = props;
  return (
    <div className={classNames("InputChips p-fluid w-100", containerClassName)}>
      <Chips {...rest} className={className} />
    </div>
  )
}

InputChips.propTypes = {
  containerClassName: PropTypes.string
}

export default InputChips

