import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { ChartJS, Loading } from './../../../components';

const IntegrationReportContent = ({ loading, pieChartData, barChartData, unitTime }) => {
  return (
    <Row id="integrationReportContent">
      <Col lg="4" sm="12">
        <h3>ALL DEVICES</h3>
        <div>
          <div className="chart">
            {loading && (
              <div className="d-flex justify-content-center align-items-center h-100">
                <Loading size="lg" />
              </div>
            )}
            {!loading && pieChartData && (
              <ChartJS
                type="pie"
                data={pieChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                      usePointStyle: false,
                    },
                  },
                  pieceLabel: {
                    render: "value",
                    fontSize: 16,
                    fontColor: "#fff",
                    fontStyle: "normal",
                  },
                }}
              />
            )}
          </div>
        </div>
      </Col>
      <Col lg="8" sm="12">
        <h3>DEVICE INTEGRATION TREND</h3>
        <div className="chart chart-over-quota">
          {loading && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Loading size="lg" />
            </div>
          )}
          {!loading && barChartData && (
            <ChartJS
              type="bar"
              data={barChartData}
              options={{
                legend: {
                  display: true,
                  position: "top",
                  fullWidth: true,
                  align: "start",
                  labels: {
                    usePointStyle: false,
                    padding: 16,
                  },
                },
                responsive: true,
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                      scaleLabel: {
                        display: true,
                        labelString: 'Date/Time',
                      },
                      type: "time",
                      id: "time",
                      time: {
                        minUnit: "hour",
                        unit: unitTime
                      },
                    },
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        drawOnChartArea: true,
                        lineWidth: 1,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Total Devices',
                      },
                    },
                  ],
                },
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  )
}

IntegrationReportContent.propTypes = {
  pieChartData: PropTypes.object.isRequired,
  barChartData: PropTypes.object.isRequired,
};

export default IntegrationReportContent;
