import { useMemo } from "react";
import { useAuth } from "../../auth/hooks";
import { useGetRegionsQuery } from "../service/regionApi";

const allRegionOption = { value: -1, label: 'All Region' };

export function useRegion(params = {}, withAllOption = true) {
  const { user } = useAuth();
  const { data, isLoading } = useGetRegionsQuery({
    ...params,
    username: user ? user.username : undefined,
  });

  const options = useMemo(() => {
    const opts = [];

    if (user && user.allRegion && withAllOption) {
      opts.push(allRegionOption);
    }

    if (data && data.content) {
      data.content.forEach((d) => {
        opts.push({ value: d.id, label: d.name });
      });
    }

    return opts;
  }, [data, user, withAllOption]);

  const optionsWithCustomerCount = useMemo(() => {
    const opts = [];

    if (user && user.allRegion && withAllOption) {
      opts.push(allRegionOption);
    }

    if (data && data.content) {
      data.content.forEach((d) => {
        opts.push({
          value: d.id,
          label: `${d.name} (${d.hierarchyRegionSummary?.countCustomer || 0} Customer)`,
          countCustomer: d.hierarchyRegionSummary?.countCustomer || 0,
        });
      });
    }

    return opts;
  }, [data, user, withAllOption]);

  return {
    data,
    isLoading,
    options,
    optionsWithCustomerCount,
  };
}