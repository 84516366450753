import React, { useCallback, useMemo } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { StatusBadge } from '../../../../components'
import customStyles from '../DeviceTable/customStyles'
import { history, momentLastUpdate, unknown } from './../../../../utils'
import { useDeviceOpenAlert } from './../../hooks'

const DeviceOpenAlert = ({ device }) => {

  const { data, isLoading } = useDeviceOpenAlert(device.id, {
    sortFields: 'lastUpdated',
    sortOrder: 'desc',
    pageSize: 5
  });

  const renderLink = useCallback(({ lastMessage, id }) => {
    if (!id) return unknown;

    return (
      <a className="text-sm text-default" href="#alerts" onClick={(e) => {
        e.preventDefault();
        history.push({
          pathname: "/admin/alerts",
          search: `?sns=${device.sn}&lastState=Open`,
          state: {
            alertId: id
          }
        })
      }}>{lastMessage}</a>
    )
  }, [device.sn]);

  const columns = useMemo(() => {
    return [
      {
        name: 'SEVERITY',
        selector: row => row?.severity,
        sortable: false,
        cell: ({ severity }) => <StatusBadge label={severity} type={severity === 'MINOR' ? 'success' : (severity === 'MAJOR' ? 'warning' : 'danger')} isUppercase />
      },
      {
        name: 'ALERT',
        selector: row => row?.lastMessage,
        sortable: false,
        grow: 4,
        cell: renderLink
      },
      {
        name: 'DATETIME',
        selector: row => row?.lastUpdated,
        sortable: false,
        cell: ({ lastUpdated }) => momentLastUpdate(lastUpdated)
      },
    ];
  }, [renderLink]);

  return (
    <>
      <Row className="mt-4"><Col><h2 className="text-dark ml-4">Open Alert</h2></Col></Row>
      <Row className="mt-4">
        <Col className="mx-4">
          <DataTable
            data={data}
            columns={columns}
            responsive={true}
            keyField="id"
            defaultSortField="lastUpdated"
            defaultSortAsc={false}
            striped
            noHeader
            highlightOnHover
            persistTableHead
            progressPending={isLoading}
            customStyles={customStyles}
            overflowY={true}
          />
        </Col>
      </Row>
      {data && data.length > 0 && (
        <Row>
          <Col className="px-5 mt-3 mb-5">
            <Link className="float-right text-underline" to={{
              pathname: '/admin/alerts',
              search: `?sns=${device.sn}&lastState=Open`
            }}>See More</Link>
          </Col>
        </Row>
      )}
    </>
  )
}

export default DeviceOpenAlert;
