import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AreaListPage } from "./pages";

function Router() {
  return (
    <Switch>
      <Route exact path={`/admin/areas/`} component={AreaListPage} />
      <Redirect to="/admin/areas/" />
    </Switch>
  );
}

export default Router;
