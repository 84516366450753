export const customStyles = {
  head: {
    style: {
      textAlign: 'center'
    }
  },
  headRow: {
    style: {
      backgroundColor: "#F1F3F9",
    }
  },
  expanderCell: {
    style: {
      flex: '48px 48px',
      minWidth: '0',
    }
  },
  tableWrapper: {
		style: {
			minHeight: '530px'
		},
	},
}
export const customStyles2 = {
  head: {
    style: {
      textAlign: 'center'
    }
  },
  headRow: {
    style: {
      backgroundColor: "#F1F3F9"
    }
  },
  expanderCell: {
    style: {
      flex: '48px 48px',
      minWidth: '0',
    }
  },
  // tableWrapper: {
	// 	style: {
	// 		minHeight: '0'
	// 	},
	// },
}
export const customStyles3 = {
  head: {
    style: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '14px'
    }
  },
  headRow: {
    style: {
      backgroundColor: "#F1F3F9",
    }
  },
  expanderCell: {
    style: {
      flex: '48px 48px',
      minWidth: '0',
    }
  },
}