import classnames from 'classnames';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import {
  Button, Card,
  CardBody,
  Col,
  Row
} from "reactstrap";
import Swal from 'sweetalert2';
import { StringParam, useQueryParams } from 'use-query-params';
import { UserDetail } from '.';
import { useGroups } from '../..';
import { ButtonLinkIcon, CustomCellStatusBadge, CustomExpandableIcon, PageContent, PrimeSearchInput, Tags } from '../../../components';
import { AbilityContext, ADD_NEW_USER, Can, DELETE_USER, UPDATE_USER_GROUP, UPDATE_USER_PROFILE } from '../../../config';
import { useOptions } from '../../../hooks';
import { history, paginationRowsPerPageOptions } from "../../../utils";
import { useArea } from '../../area/hooks';
import { useAuth } from '../../auth/hooks';
import { useCustomers } from '../../customers/hooks';
import { useRegion } from '../../region/hooks';
import { FilterDropdownUser } from '../components';
import ModalSetGroup from '../components/modals/ModalSetGroup';
import ModalSetStatus from '../components/modals/ModalSetStatus';
import { useUserDatatable } from '../hooks/useUserDatatable';
import { useDeleteUserMutation } from '../service/userApi';
import { CommaArrayParam } from './../../../utils/queryParams';
import { CustomActionButton } from './../components/CustomActionButton';
import { customStyles } from "./../../../utils/datatableStyles";

const defaultSortFields = 'username';

function UserList() {
  const searchRef = useRef();
  const { user } = useAuth();
  const [query, setQuery] = useQueryParams({
    groupIds: CommaArrayParam,
    regionIds: CommaArrayParam,
    areaIds: CommaArrayParam,
    customerIds: CommaArrayParam,
    status: StringParam,
    username: StringParam,
    smartSearch: StringParam,
  });

  const {
    data,
    isLoading,
    totalRows,
    pageSize,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  } = useUserDatatable(query, { sortFields: defaultSortFields });

  const [selectedRegionIds, setSelectedRegionIds] = useState(undefined);

  const [selectedRows, setSelectedRows] = useState([]);
  const [modalGroup, setModalGroup] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [title, setTitle] = useState('All User');

  const { options: groupOptions } = useGroups();
  const { options: regionOptions } = useRegion({}, false);
  const { options: areaOptions } = useArea({ regionIds: selectedRegionIds }, false);
  const { options: customerOptions } = useCustomers();

  const { getLabelOptions } = useOptions();

  const [deleteUser] = useDeleteUserMutation();

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.search || '');
    }

    const queries = [];
    Object.keys(query).forEach((key) => {
      queries.push(query[key] ? true : false);
    });

    if (queries.some((q) => q === true)) {
      setTitle('User');
    } else {
      setTitle('All User');
    }
  }, [query]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const onRegionChanges = (regionIds) => {
    setSelectedRegionIds(regionIds);
  }

  const handleDelete = useCallback((row) => {
    Swal.fire({
      title: 'Are you sure want to delete this?',
      text: `User: ${row.username}.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return deleteUser(row.username)
          .unwrap()
          .then(() => {
            toast.success('User successfully removed.');
            return true;
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error.data.error}: ${error.data.message}`)
          });
      },
    });
  }, [deleteUser]);

  const handleBatchDelete = () => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to delete?',
      text: `${selectedRows.map(r => r.username || r.name)}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      reverseButtons: true,
      preConfirm: async () => {
        try {
          for (let i = 0; i < selectedRows.length; i++) {
            await deleteUser(selectedRows[i].username).unwrap();
          }
          setToggleCleared(!toggleCleared);
          toast.success('Delete users success.');
          return true;
        } catch (error) {
          Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  };

  const onSearch = (searchText) => {
    setQuery({ smartSearch: searchText !== "" && searchText !== null ? searchText : undefined });
  }

  const onRemoveFilter = useCallback((key = null, value = null) => {
    if (!key) return;

    if (value === null) {
      setQuery({ [key]: undefined });
    } else {
      setQuery((q) => {
        const filterQ = q[key]?.filter(v => v !== value);
        return {
          [key]: filterQ && filterQ.length > 0 ? filterQ : undefined
        }
      });
    }

  }, [setQuery]);

  const onResetFilter = useCallback(() => {
    setQuery({
      smartSearch: undefined,
      status: undefined,
      username: undefined,
      groupIds: undefined,
      regionIds: undefined,
      areaIds: undefined,
      customerIds: undefined,
    });
  }, [setQuery]);

  const onFilter = (filter) => {
    if (filter) {
      const { status, groupIds, regionIds, areaIds, customerIds } = filter;

      setQuery({
        status: status || undefined,
        groupIds: groupIds && groupIds.length > 0 ? groupIds : undefined,
        regionIds: regionIds && regionIds.length > 0 ? regionIds : undefined,
        areaIds: areaIds && areaIds.length > 0 ? areaIds : undefined,
        customerIds: customerIds && customerIds.length > 0 ? customerIds : undefined,
      });
    }
  }

  const getOptionsLabel = useCallback((key, value) => {
    switch (key) {
      case 'customerIds': return getLabelOptions(customerOptions, value);
      case 'groupIds': return getLabelOptions(groupOptions, value);
      case 'regionIds': return getLabelOptions(regionOptions, value);
      case 'areaIds': return getLabelOptions(areaOptions, value);
      default: return null;
    }
  }, [getLabelOptions, customerOptions, groupOptions, regionOptions, areaOptions]);

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            tags.push({
              key: key,
              value: v,
              label: getOptionsLabel(key, v),
              onRemove: () => onRemoveFilter(key, v)
            })
          })
        } else {
          tags.push({
            key: key,
            value: value,
            label: value,
            onRemove: () => onRemoveFilter(key)
          })
        }
      }
    });

    return tags;
  }, [query, getOptionsLabel, onRemoveFilter]);

  const rowDisabledCriteria = row => row.username === user.username;

  const ability = useContext(AbilityContext);

  const checkPermission = useCallback(() => {
    if (ability.can('do', UPDATE_USER_GROUP)) {
      return true;
    }
    if (ability.can('do', UPDATE_USER_PROFILE)) {
      return true;
    }
    if (ability.can('do', DELETE_USER)) {
      return true;
    }
    return false;
  }, [ability]);

  const columns = useMemo(() => {
    return [
      {
        name: 'GROUP',
        sortable: false,
        left: true,
        hide: "md",
        cell: ({ groups }) => groups ? groups.map((g) => g.name).join(', ') : '-',
        omit: true,
      },
      {
        name: 'USER ID',
        selector: row => row?.username,
        sortable: true,
        sortField: 'username',
        left: true,
        hide: "md",
      },
      {
        name: 'NAME',
        selector: row => row?.name,
        sortable: true,
        sortField: 'name',
        left: true,
        grow: 2,
      },
      {
        name: 'EMAIL',
        selector: row => row?.email,
        sortable: true,
        sortField: 'email',
        left: true,
        grow: 2,
        hide: "md",
      },
      {
        name: 'PHONE',
        selector: row => row?.phoneNumber,
        sortable: true,
        sortField: 'phoneNumber',
        left: true,
        grow: 2,
        hide: "md",
      },

      {
        name: 'LEVEL',
        sortable: false,
        center: true,
        hide: "md",
        cell: ({ roleNames }) => roleNames && roleNames.length > 0 ? roleNames.join(', ') : '-',
      },
      {
        name: 'STATUS',
        selector: row => row?.status,
        sortable: true,
        sortField: 'status',
        center: true,
        hide: "md",
        cell: (row) => <CustomCellStatusBadge row={row} name="status" successFlag="Activated" dangerFlag="Deactivated" />
      },
      {
        name: 'ACTIVE DATE',
        selector: row => row?.activeDate,
        sortable: true,
        sortField: 'activeDate',
        center: true,
        hide: "md",
        cell: (row) => {
          if (moment(row.activeDate).isValid()) {
            return moment(row.activeDate).format('DD/MM/YYYY');
          } else {
            return 'Inactive'
          }
        }
      },
      {
        name: 'ACTION',
        cell: (row) => (
          <CustomActionButton 
            row={row}
            onDeleteClick={() => handleDelete(row)}
            groupOptions={groupOptions}
            authUser={user}
          />
        ),
        allowOverflow: true,
        button: true,
        omit: !checkPermission()
      },
    ]
  }, [handleDelete, checkPermission, groupOptions, user]);

  return (
    <PageContent title="All User">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <h2 className="text-dark">{title}</h2>
            </Col>
            <Col xs="12" md="4" className="d-flex justify-content-lg-end align-items-center">
              <PrimeSearchInput
                ref={searchRef}
                className="p-inputtext-sm p-d-block"
                onFilter={onSearch}
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: 'top' }}
              />
            </Col>
            <Col xs="12" md="2" className="d-flex justify-content-lg-end align-items-center">
              <FilterDropdownUser
                filteredGroupIds={query.groupIds}
                filteredRegionIds={query.regionIds}
                filteredAreaIds={query.areaIds}
                filteredCustomerIds={query.customerIds}
                filteredStatus={query.status}
                onFilter={onFilter}
                loading={isLoading}
                onRegionChanges={onRegionChanges}
                groupOptions={groupOptions}
                regionOptions={regionOptions}
                areaOptions={areaOptions}
                customerOptions={customerOptions}
              />
              <Can I="do" this={ADD_NEW_USER}>
                {() => <ButtonLinkIcon label="Add New" onClick={() => history.push('/admin/users/new')} />}
              </Can>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {<Tags tags={filterTags} onReset={onResetFilter} />}
            </Col>
          </Row>
          <div className={classnames("justify-content-between align-items-center", {
            "d-none": selectedRows?.length === 0,
            "d-flex": selectedRows?.length > 0
          })}>
            <div>
              <Can I="do" this={UPDATE_USER_GROUP}>
                {() => (
                  <Button key="set-group" className="btn-icon btn-3" color="default" type="button" size="sm" onClick={() => setModalGroup(!modalGroup)}>
                    <span className="btn-inner--text">Set Group</span>
                  </Button>
                )}
              </Can>
              <Can I="do" this={UPDATE_USER_PROFILE}>
                {() => (
                  <Button key="set-status" className="btn-icon btn-3" color="default" type="button" size="sm" onClick={() => setModalStatus(!modalStatus)}>
                    <span className="btn-inner--text">Set Status</span>
                  </Button>
                )}
              </Can>
              <Can I="do" this={DELETE_USER}>
                {() => (
                  <Button key="delete" className="btn-icon btn-3" color="danger" type="button" size="sm" onClick={handleBatchDelete}>
                    <span className="btn-inner--text">Delete</span>
                  </Button>
                )}
              </Can>
            </div>
            <div className="col-6 text-right pt-3">
              <p>{selectedRows?.length} {selectedRows?.length > 1 ? 'users' : 'user'} selected.</p>
            </div>
          </div>
          <DataTable
            title={title}
            data={data}
            columns={columns}
            defaultSortField={defaultSortFields}
            keyField="username"
            onSelectedRowsChange={handleRowSelected}
            striped
            noHeader
            highlightOnHover
            selectableRows
            clearSelectedRows={toggleCleared}
            selectableRowDisabled={rowDisabledCriteria}
            pagination
            paginationServer
            customStyles={customStyles}
            expandableRows
            expandableRowsComponent={UserDetail}
            expandableIcon={{
              collapsed: <CustomExpandableIcon type="collapsed" />,
              expanded: <CustomExpandableIcon type="expanded" />,
            }}
            persistTableHead
            progressPending={isLoading}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            paginationPerPage={pageSize}
            onChangePage={onChangePage}
            sortServer
            onSort={onSort}
          />
          <ModalSetGroup 
            title="Set Group"
            isOpen={modalGroup}
            toggle={() => setModalGroup(!modalGroup)}
            selectedRows={selectedRows}
            groupOptions={groupOptions}
            clearSelectedRows={() => setToggleCleared(!toggleCleared)}
          />
          <ModalSetStatus
            title="Set Status"
            isOpen={modalStatus}
            toggle={() => setModalStatus(!modalStatus)}
            selectedRows={selectedRows}
            clearSelectedRows={() => setToggleCleared(!toggleCleared)}
            />
        </CardBody>
      </Card>
    </PageContent>
  )
}

export default UserList
