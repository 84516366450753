import { useMemo } from "react";
import { useGetCustomersQuery } from "../service/customerApi";
import { defaultOptions, usePagination } from "./../../../hooks";
import { useAuth } from "./../../auth/hooks";


const defaultParams = {
  ids: undefined,
  groupIds: undefined,
  regionIds: undefined,
  areaIds: undefined,
  smartSearch: undefined,
};

export const useCustomerDatatable = (params = defaultParams, options = defaultOptions) => {
  const { user, isAdmin } = useAuth();

  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort
  } = usePagination({ pageNumber: 1, pageSize: 10, sortFields: options.sortFields, sortOrder: options.sortOrder });


  const { regionIds, areaIds, ...restParams } = params;
  const { data: paginatedResult, isLoading } = useGetCustomersQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    username: isAdmin ? undefined : user?.username,
    includeHierarchySummary: true,
    regionIds: areaIds ? null : regionIds,
    areaIds: areaIds,
    ...restParams,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
};
