import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./../../../../services/baseApi";

export const routerApi = createApi({
  reducerPath: "routerApi",
  baseQuery: baseQuery,
  tagTypes: ["Router", "RouterInterfaceUnit", "RouterInterfaceUnitJob"],
  endpoints: (builder) => ({
    getRouter: builder.query({
      query: () => ({
        url: "/Router/",
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "Router",
                id,
              })),
              { type: "Router", id: "LIST" },
            ]
          : [{ type: "Router", id: "LIST" }],
    }),
    getRouterbyId: builder.query({
      query: (id) => ({
        url: `/Router/${id}`,
        params: {id:id}
      })
    }),
    createRouter: builder.mutation({
      query: (body) => ({
        url: "/Router/",
        method: "POST",
        body,
      }),
      invalidatesTags:[{ type: "Router", id: "LIST"}]
    }),
    updateRouter: builder.mutation({
      query: ({id, body}) => ({
        url: `/Router/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags:[{ type: "Router", id: "LIST"}]
    }),
    deleteRouter: builder.mutation({
      query: (id) => ({
        url: `/Router/${id}`,
        method: "DELETE",
      }),
      invalidatesTags:[{ type: "Router", id: "LIST"}]
    }),
    getRouterInterface: builder.query({
      query: (id) => ({
        url: `/RouterInterface/`,
        params: id
      })
    }),
    getRouterInterfaceUnit: builder.query({
      query: (id) => ({
        url: `/RouterInterfaceUnit/`,
        params: id
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "RouterInterfaceUnit",
                id,
              })),
              { type: "RouterInterfaceUnit", id: "LIST" },
            ]
          : [{ type: "RouterInterfaceUnit", id: "LIST" }],
    }),
    getRouterInterfacebyId: builder.query({
      query: (id) => ({
        url: `/RouterInterface/${id}`,
        params: {id:id}
      })
    }),
    updateRouterInterfaceUnit: builder.mutation({
      query: ({routerInterfaceUnitId, body}) => ({
        url: `/RouterInterfaceUnit/${routerInterfaceUnitId}`,
        params: {id:routerInterfaceUnitId},
        body,
        method: "PUT"
      }),
      // invalidatesTags:[{ type: "RouterInterfaceUnit", id: "LIST"}]
    }),
    getJobPackage: builder.query({
      query: (routerInterfaceUnitId) => ({
        url: `/RouterInterfaceUnit/${routerInterfaceUnitId}/jobs/policy`,
        params: {id:routerInterfaceUnitId},
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "RouterInterfaceUnitJob",
                id,
              })),
              { type: "RouterInterfaceUnitJob", id: "LIST" },
            ]
          : [{ type: "RouterInterfaceUnitJob", id: "LIST" }],
    }),
    createJobPackage: builder.mutation({
      query: ({routerInterfaceUnitId, body}) => ({
        url: `/RouterInterfaceUnit/${routerInterfaceUnitId}/jobs/policy`,
        method: "POST",
        params: {id:routerInterfaceUnitId},
        body
      }),
       invalidatesTags:[{ type: "RouterInterfaceUnitJob", id: "LIST"}]
    }),
    updateJobPackage: builder.mutation({
      query: ({routerInterfaceUnitId, jobId, body}) => ({
        url: `/RouterInterfaceUnit/${routerInterfaceUnitId}/jobs/policy/${jobId}`,
        method: "PUT",
        params: {id:routerInterfaceUnitId, jobId: jobId},
        body
      }),
       invalidatesTags:[{ type: "RouterInterfaceUnitJob", id: "LIST"}]
    }),
  }),
});

export const {
  useGetRouterQuery,
  useCreateRouterMutation,
  useUpdateRouterMutation,
  useDeleteRouterMutation,
  useGetRouterInterfaceQuery,
  useGetRouterInterfaceUnitQuery,
  useGetRouterbyIdQuery,
  useGetRouterInterfacebyIdQuery,
  useUpdateRouterInterfaceUnitMutation,
  useCreateJobPackageMutation,
  useGetJobPackageQuery,
  useUpdateJobPackageMutation,
} = routerApi;
