import EChartsReact from "echarts-for-react";
import { useGetNumberDevicesSnQuery } from "features/dashboard/service/dashboardApi";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatNumber, history } from "utils";
import { usePerformanceDashboard } from "../../context";
import Swal from "sweetalert2";

const CombinationChart = ({ data = [], isLoading = false, unit = "", minInterval = false, clickable = false, stacked = false, type = undefined, threshold = 0 }) => {
  const [params, setParams] = useState(null);
  const { data: sns, isFetching, isError } = useGetNumberDevicesSnQuery({ params, type }, { skip: !params });
  const { selectedRegion } = usePerformanceDashboard();

  useEffect(() => {
    if (isFetching) {
      Swal.fire({
        title: 'Please Wait',
        text: "We're redirecting you to the Devices page",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      });
    } else {
      Swal.close();
    }

    if (isError) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch data, please try again',
        allowOutsideClick: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Try again",
      }).then((result) => {
        if (result.isConfirmed) {
          let newParam = params
          setParams(null)
          setParams(newParam)
        } else {
          setParams(null)
        }
      });
    }
  }, [isFetching, isError, params]);

  const options = useMemo(() => {
    const dataSeries = data && data.datasets ? data.datasets : [];
    const labels = data && data.labels ? data.labels : [];

    const totalValues = labels.map((label, index) => {
      return dataSeries.reduce((sum, dataset) => {
        return sum + (dataset.data[index] || 0);
      }, 0);
    });
    const maxTotalValue = Math.max(...totalValues);

    return {
      legend: {
        bottom: 0,
        type: 'scroll',
      },
      grid: {
        left: 100,
        right: 50,
        top: 10,
        bottom: 60
      },
      dataZoom: threshold && selectedRegion !== 'All Region' ? [
        { 
            show: true,
            yAxisIndex: 0,
            startValue: Math.floor(threshold - (threshold / 5)),
            endValue: Math.ceil(threshold + (threshold / 5)),
            filterMode: 'none',
            showDataShadow: false,
        }
      ] : false,
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: `{value} ${unit}`,
          },
          minInterval: 5,
          max: function (value) {
            if (stacked) {
              return Math.ceil(maxTotalValue / 5) * 5 + 5;
            } else {
              if (unit === '%') {
                return selectedRegion === 'All Region' ? null : 100
              } else {
                if (value.max < threshold) {
                return threshold + (threshold/5)} else {
                 return null
                }
              }
            }
          }
        },
      ],
      xAxis: [
        {
          type: "category",
          axisLabel: {
            hideOverlap: true,
            align: 'center'
          },
          interval: "auto",
        },
      ],
      tooltip: {},
      series: dataSeries.map((dataset) => {
        return {
          type: "bar",
          name: dataset.label,
          stack: stacked ? 'total' : false,
          label: {
            show: stacked,
            fontSize: '11px'
          },
          data: dataset.data.map((d, i) => {
            const label = moment(labels[i]);
            return {
              name: label.format('DD/MM/YYYY'),
              value: [label.format('DD MMM'), d],
            };
          }),
          tooltip: {
            valueFormatter: (value) => value ? formatNumber(value) : value,
          },
          markPoint: stacked ? {
            data: labels.map((label, i) => {
              if (totalValues[i] === 0) return null;
              return {
                coord: [i, totalValues[i]],
                value: totalValues[i],
                label: {
                  show: true,
                  formatter: `${formatNumber(totalValues[i])}`,
                },
                tooltip: {
                  formatter: `TOTAL: ${formatNumber(totalValues[i])}`,
                },
              };
            }).filter(point => point !== null),
            symbol: 'pin',
            symbolSize: 40,
            itemStyle: {
              color: '#2367AC'
            },
            emphasis: {
              itemStyle: {
                opacity: 1
              }
            },
            silent: false,
          } : false,
          markLine: threshold ? {
            silent: true,
            data: [
                { yAxis: threshold, name: 'Threshold' }
            ],
            lineStyle: {
                color: '#F5365C'
            },
            label: {
              formatter: `${threshold} ${unit}`,
              color: '#F5365C'
            }
        } : false
        };
      }),
    };
  }, [data, stacked, unit, threshold, selectedRegion]);

  const formatEpoch = (dateStr) => {
    const [day, month, year] = dateStr.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return date.getTime();
  };

  const onSearchDevice = (key, value) => {
    return history.push({
      pathname: "/admin/devices",
      search: `?${key}=${value}`,
    })
  }

  const onClickChart = useCallback((params) => {
    if (params.componentType === 'markPoint') {
      const { coord } = params.data;
      if (coord) {
        const [index] = coord;
        const dateStr = moment(data.labels[index]).format('DD/MM/YYYY');
        const paramMap = {
          date: formatEpoch(dateStr),
          region: selectedRegion === 'All Region' ? undefined : selectedRegion
        };
        setParams(paramMap);
      }
    } else if (params.data && params.seriesName) {
      const paramMap = {
        date: formatEpoch(params.data.name),
        region: selectedRegion === 'All Region' ? params.seriesName : undefined,
        area: selectedRegion === 'All Region' ? undefined : params.seriesName
      };
      setParams(paramMap);
    }
  }, [data.labels, selectedRegion]);

  useEffect(() => {
    if (sns) {
      onSearchDevice('smartSearch', sns);
    }
  }, [sns]);

  const onEvents = useMemo(() => ({
    click: clickable ? onClickChart : undefined,
  }), [clickable, onClickChart]);

  return (
    ((!data || !data.datasets || data.datasets.length === 0) && !isLoading) ? (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%'}}>No data available</div>
      ) : (
      <EChartsReact
        notMerge={true}
        option={options}
        showLoading={isLoading}
        style={{ height: '100%' }}
        onEvents={onEvents}
      />
    )
  );
};

export default CombinationChart;
