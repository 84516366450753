import { defaultApi } from "services/baseApi";

const licenseApiWithTags = defaultApi.enhanceEndpoints({
  addTagTypes: ["License", "Device", "SummaryLicense"],
});

export const licenseApi = licenseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getListLicense: builder.query({
      query: (params) => ({
        url: "/license/",
        method: "GET",
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "License", id })),
              { type: "License", id: "LIST" },
            ]
          : [{ type: "License", id: "LIST" }],
    }),
    getOneLicense: builder.query({
      query: (id) => ({
        url: `/license/${id}`,
        method: "GET",
      }),
      providesTags: (res, err, id) => [{ type: "License", id }],
    }),
    createLicense: builder.mutation({
      query: (body) => ({
        url: "/license/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["License"],
    }),
    updateLicense: builder.mutation({
      query: (body) => ({
        url: `/license/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (res, err, { id }) => [{ type: "License", id }],
    }),
    deleteLicense: builder.mutation({
      query: (id) => ({
        url: `/license/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (res, err, id) => [{ type: "License", id }],
    }),
    getDeviceLicense: builder.query({
      query: (params) => ({
        url: '/device',
        method: 'GET',
        params
      })
    }),
    getLicenseSummary: builder.query({
      query: (params) => ({
        url: '/license/summary',
        method: 'GET',
        params
      }),
      providesTags: ['SummaryLicense']
    }),
  }),
});

export const {
  useGetListLicenseQuery,
  useGetOneLicenseQuery,
  useCreateLicenseMutation,
  useUpdateLicenseMutation,
  useDeleteLicenseMutation,
  useGetDeviceLicenseQuery,
  useGetLicenseSummaryQuery,
} = licenseApi;
