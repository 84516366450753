import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { AlertOverview } from './AlertOverview';
import { AlertConfigurationForm } from './AlertConfigurationForm';
import { ButtonLinkIcon } from '../../../components';
import { Can, CONFIGURE_ALERT_RULE } from '../../../config';

export function AlertTableDetail({ data }) {
  const [editing, setEditing] = useState(false);

  const toggleEditing = () => {
    setEditing(!editing);
  }
  return (
    <div className="px-5">
      <Row>
        <Col className="d-flex justify-content-between align-items-center mt-4">
          <h2 className="ml-3">Alert Setting</h2>
          <Can I="do" this={CONFIGURE_ALERT_RULE}>
            {() => !editing && (<ButtonLinkIcon label="Edit" icon="fa-edit" onClick={toggleEditing} />)}
          </Can>
        </Col>
      </Row>
      {!editing && (
        <AlertOverview alert={data} />
      )}
      {editing && (
        <AlertConfigurationForm alert={data} toggleEditing={toggleEditing} />
      )}
    </div>
  )
}
