import React from 'react';
import {
  Button, Modal
} from "reactstrap";

const ResetPasswordEmailSentModal = ({
  isOpen,
  toggle,
  onContinue,
  email
}) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-body mx-5">
        <div className="text-center mb-3">
          <h1>Check Your Email</h1>
        </div>
        <p className="text-center">
          We've sent instructions on how to change your
          password to the following email address:
        </p>
        <p className="text-center">
          {email}
        </p>
        <div className="text-center my-4">
          <Button
            className="btn-block btn-default"
            data-dismiss="modal"
            type="button"
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ResetPasswordEmailSentModal
