import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { RowItem } from './../../../components';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';
import { useCallback } from 'react';
import { unknown } from 'utils';

const fieldLabelMap = {
	id: 'Network ID',
	name: 'Name',
	ipAssignmentPools: 'IP Assignment Pools',
	routes: 'Routes',
	ipRangeEnd: 'IP Range End',
	ipRangeStart: 'IP Range Start',
	via: 'Gateway',
	target: 'Target',
};

const NetworkOverview = ({ network }) => {
	const [collapsible, setCollapsible] = useState({
		ipAssignmentPools: false,
		routes: false,
	});

	const onCollapsibleStateChange = useCallback((field) => {
		setCollapsible((state) => ({
			...state,
			[field]: !Boolean(state[field]),
		}))
	}, []);

	const renderCollapsible = useCallback((
		collapsibleKey,
		rowProps = {},
		value,
		children,
		key
	) => {
		return (
			<div key={key}>
				<RowItem
					{...rowProps}
					value={
						<span>
							{value}
							<button
								type='button'
								onClick={() => onCollapsibleStateChange(collapsibleKey)}
								className='btn btn-sm btn-link bg-transparent ml-2'
							>
								<i
									className={classnames('fa text-default', {
										'fa-chevron-down': Boolean(collapsible[collapsibleKey]),
										'fa-chevron-up': !Boolean(collapsible[collapsibleKey]),
									})}
								></i>
							</button>
						</span>
					}
				/>
				<Collapse isOpen={!Boolean(collapsible[collapsibleKey])}>
					{children}
				</Collapse>
			</div>
		)
	}, [collapsible, onCollapsibleStateChange]);

	const leftColumn = useMemo(() => {
		const selectedFields = pick(network, ['id', 'name', 'ipAssignmentPools']);

		return Object.entries(selectedFields).map(([key, value], idx) => {
			if (key === 'ipAssignmentPools') {

				const children = Array.isArray(value) && value.length > 0 && value.map((assignedPool, i) => {
					return renderCollapsible(
						`${key}-${i}`,
						{
							label: `IP Assignment Pool - ${i + 1}`,
							leftCol: 'offset-1'
						},
						null,
						Object.entries(assignedPool).map(([keyField, value], j) => {
							return (
								<RowItem
									key={`${key}-${keyField}-${j}`}
									leftCol={'offset-2'}
									stripped={j % 2 === 0}
									label={fieldLabelMap[keyField]}
									value={value || unknown}
								/>
							)
						}),
						`${key}-${idx}-${i}`
					)
				})
				return renderCollapsible(
					key,
					{
						label: fieldLabelMap[key],
						stripped: idx % 2 === 0,
					},
					`${selectedFields[key].length} Assigned Pools`,
					children,
					`${key}-${idx}`
				)
			}
			return (
				<RowItem
					key={key}
					stripped={idx % 2 === 0}
					label={fieldLabelMap[key]}
					value={value || unknown}
				/>
			)
		})
	}, [network, renderCollapsible]);

	const rightColumn = useMemo(() => {
		const selectedFields = pick(network, ['routes']);

		return Object.entries(selectedFields).map(([key, value], idx) => {
			if (key === 'routes') {

				const children = Array.isArray(value) && value.length > 0 && value.map((route, i) => {
					return renderCollapsible(
						`${key}-${i}`,
						{
							label: `Route - ${i + 1}`,
							leftCol: 'offset-1'
						},
						null,
						Object.entries(route).map(([keyField, value], j) => {
							return (
								<RowItem
									key={`${key}-${keyField}-${j}`}
									leftCol={'offset-2'}
									stripped={j % 2 === 0}
									label={fieldLabelMap[keyField]}
									value={value || unknown}
								/>
							)
						}),
						`${key}-${idx}-${i}`,
					)
				})
				return renderCollapsible(
					key,
					{
						label: fieldLabelMap[key],
						stripped: idx % 2 === 0,
					},
					`${selectedFields[key].length} Routes`,
					children,
					`${key}-${idx}`
				)
			}
			return (
				<RowItem
					key={key}
					stripped={idx % 2 === 0}
					label={fieldLabelMap[key]}
					value={value || unknown}
				/>
			)
		})
	}, [network, renderCollapsible]);

	return (
		<div>
			<h2 className='text-dark ml-4'>Network Overview</h2>
			<div className='row mt-4'>
				<div className='col px-5'>
					{leftColumn}
				</div>
				<div className='col px-5'>
					{rightColumn}
				</div>
			</div>
		</div>
	)
}

NetworkOverview.propTypes = {
	network: PropTypes.object.isRequired,
}

export default NetworkOverview