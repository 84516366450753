import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  tagTypes: ['User', 'Profile'],
  endpoints: (builder) => ({
    authenticate: builder.mutation({
      query: ({ username, password }) => ({
        url: `/authentication/login`,
        method: 'POST',
        body: {
          username,
          password
        }
      }),
    }),
    logout: builder.mutation({
      query: (token) => ({
        url: `/authentication/logout/${token}`,
        method: 'POST',
        body: {}
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ username, ...body }) => ({
        url: `/user/password/${username}`,
        method: 'PUT',
        body
      }),
    }),
    getProfile: builder.query({
      query: (username) => ({
        url: `/user/${username}`
      }),
      providesTags: (result, error, id) => ['Profile'],
    }),
    requestResetPassword: builder.mutation({
      query: (body) => ({
        url: `/reset-password/request`,
        method: 'POST',
        body: body
      })
    }),
    validateRestPasswordToken: builder.query({
      query: (params) => ({
        url: `/reset-password/validate`,
        params: params
      })
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: `/reset-password/new`,
        method: 'POST',
        body: body
      })
    }),
    uploadProfilePicture: builder.mutation({
      query: ({ username, body }) => ({
        url: '/user-profile-picture/upload',
        method: 'POST',
        body,
        params: { username }
      }),
      onQueryStarted({ username }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(authApi.endpoints.getProfile.initiate(username, { subscribe: false, forceRefetch: true }));
        })
      }
    })
  })
});

export const {
  useAuthenticateMutation,
  useLogoutMutation,
  useUpdatePasswordMutation,
  useGetProfileQuery,
  useRequestResetPasswordMutation,
  useValidateRestPasswordTokenQuery,
  useResetPasswordMutation,
  useUploadProfilePictureMutation,
} = authApi;

