import React from "react";
import { toast } from "react-toastify";
import {
  Modal
} from "reactstrap";
import { useCreateDeviceMutation } from "../services/deviceInventoryApi";
import FormDevice from "./FormDevice";

const CreateDeviceInventoryModal = ({ isOpen, toggle }) => {
  const [createDevice, { isLoading }] = useCreateDeviceMutation();

  const onSubmit = async (device) => {
    createDevice(device)
      .unwrap()
      .then(() => {
        toast.success(`Device ${device.sn} created.`);
        toggle();
      })
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <FormDevice onSubmit={onSubmit} onCancel={toggle} isLoading={isLoading} />
    </Modal>
  );
};

export default CreateDeviceInventoryModal;
