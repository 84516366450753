import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  Modal,
  Row
} from "reactstrap";
import * as Yup from 'yup';
import {
  Gap,
  Loading,
  TritronikInputText
} from "../../../components";
import { useCreateCustomerMutation } from "../service/customerApi";


const ModalAddCustomer = ({ isOpen, toggle }) => {
  const [createCustomer, { isLoading }] = useCreateCustomerMutation();

  const onSubmit = async (customer) => {
    createCustomer(customer)
      .unwrap()
      .then(() => {
        toast.success(`Customer ${customer.name} created.`);
        toggle();
      })
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <Formik
        initialValues={{
          id: 0,
          name: "",
          businessId: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Customer name is required."),
          businessId: Yup.string().required(
            "Customer business id is required."
          ),
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h3 className="modal-title">Add New Customer</h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body py-2">
              <TritronikInputText
                label="Customer Business ID"
                name="businessId"
                placeholder="Customer Business ID"
                small
                required
              />
              <Gap height={15} />
              <TritronikInputText
                label="Customer Name"
                name="name"
                placeholder="Customer Name"
                small
                required
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button block color="default" type="submit" size="sm" disabled={isLoading || !dirty}>
                    Create
                    {isLoading && <Loading className="ml-2" />}
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="text-center">
                  <Button
                    block
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalAddCustomer;
