import { BootstrapDateRangePicker, PrimeDropdown } from 'components';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Button, Col, Modal, Row } from 'reactstrap'
import { customStyles3 } from 'utils/datatableStyles';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';

const defaultParamsState = {
    startTime: moment().startOf("day"),
    endTime: moment().endOf("day"),
  };

const DeviceAvailabilityDetailModal = ({isSnModalOpen, toggleSnModal, snList = [], sn = '3258562385'}) => {
    const [hourlyDisplay, setHourlyDisplay] = useState(false)
    const [selectedSn, setSelectedSn] = useState(sn)
    useEffect(() => {
        setSelectedSn(sn);
    }, [sn]);
    

    const transformSn = snList.map(sn => ({ value: sn, label: sn }))

    const handleClick = (time) => {
        setHourlyDisplay(true)
    }
    
    let columns = [
        {
            name: 'Date',
            selector: row => row.time,
            cell: ({time}) => time ? <strong style={{color: '#2065AC', cursor: 'pointer'}} onClick={() => handleClick(time)}>{time}</strong> : '-',   
            omit : hourlyDisplay
        },
        {
            name: 'Time',
            selector: row => row.timeHours, 
            omit : !hourlyDisplay      
        },
        {
            name: 'Uptime',
            selector: row => row.uptime,
            omit : hourlyDisplay
        },
        {
            name: 'Availability',
            selector: row => row.availability,
            omit : hourlyDisplay
        },
        {
            name: 'Status',
            selector: row => row.status,
            omit : !hourlyDisplay
        },
    ];
    
    const data = [
        {
            time: '01/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:00:00 - 00:05:00',
            uptime: '24H',
            availability: '100%',
            status: 'UP'
        },
        {
            time: '02/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:05:00 - 00:10:00',
            uptime: '24H',
            availability: '100%',
            status: 'DOWN'
        },
        {
            time: '03/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:10:00 - 00:15:00',
            uptime: '24H',
            availability: '100%',
            status: 'UP'
        },
        {
            time: '04/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:15:00 - 00:20:00',
            uptime: '24H',
            availability: '100%',
            status: 'DOWN'
        },
        {
            time: '05/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:20:00 - 00:25:00',
            uptime: '12H',
            availability: '50%',
            status: 'UP'
        },
        {
            time: '06/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:25:00 - 00:30:00',
            uptime: '24H',
            availability: '100%',
            status: 'UP'
        },
        {
            time: '07/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:30:00 - 00:35:00',
            uptime: '24H',
            availability: '100%',
            status: 'UP'
        },
        {
            time: '08/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:35:00 - 00:40:00',
            uptime: '24H',
            availability: '100%',
            status: 'DOWN'
        },
        {
            time: '09/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:40:00 - 00:45:00',
            uptime: '24H',
            availability: '100%',
            status: 'UP'
        },
        {
            time: '10/08/2024',
            timeHours: '01/08/2024 05/08/2024 00:45:00 - 00:50:00',
            uptime: '24H',
            availability: '100%',
            status: 'DOWN'
        },
    ];    

    const paginationComponentOptions = {
        noRowsPerPage: true
    }

    const onChangeSn = (e) => {
        setSelectedSn(e.value)
    }

    const [achievedOnly, setAchievedOnly] = useState(false)
    const [timeGroup, setTimeGroup] = useState('5')

    const [params, setParams] = useState(defaultParamsState);

    const onDateChange = (start, end, label) => {
        setParams({
          startTime: start,
          endTime: end,
        });
        console.log({params});
      };

      const timeGroupOpts = [
        { label: '3m', value: '3' },
        { label: '5m', value: '5' },
        { label: '15m', value: '15' },
        { label: '30m', value: '30' },
        { label: '60m', value: '60' },
      ]

      const onChangeTimeGroup = (params) => {
        setTimeGroup(params.value)
      }

    const renderModalHeader = () => {
        if (!hourlyDisplay) {
            return (
                <Row className="mb-3 d-flex justify-content-between align-items-center">
                    <Col className="d-flex justify-content-between align-items-center">
                        <span style={{textWrap: 'noWrap', marginRight: '10px'}}>Showing data for</span>
                        <BootstrapDateRangePicker onDateChange={onDateChange}/>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <InputSwitch style={{ transform: 'scale(0.75)' }} checked={achievedOnly} onChange={(e) => setAchievedOnly(e.value)} />
                        <span>Show only not achieved</span>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row className="mb-3 d-flex justify-content-between align-items-center">
                    <Col md='5' className="d-flex justify-content-between align-items-center">
                        <Button color="link" onClick={() => setHourlyDisplay(false)} className="d-flex align-items-center p-0">
                            <i className="fas fa-chevron-left"></i>
                            <span>Date list</span>
                        </Button>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                        <InputSwitch 
                            style={{ transform: 'scale(0.75)', marginRight: '0.5rem' }} 
                            checked={achievedOnly} 
                            onChange={(e) => setAchievedOnly(e.value)} 
                        />
                        <span style={{ marginRight: '1rem' }}>Show only down status</span>
                        <PrimeDropdown 
                            className="col-lg-3 col-sm-3" 
                            style={{ height: '45px' }} 
                            options={timeGroupOpts} 
                            value={timeGroup} 
                            onChange={onChangeTimeGroup} 
                        />
                    </Col>
                </Row>
            )
        }
    }

  return (
    <Modal className="modal-dialog-centered" size="lg" isOpen={isSnModalOpen} toggle={toggleSnModal}>
        <div className="modal-header d-flex align-items-center">
          <h3 className="modal-title">Device’ Availability Details</h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleSnModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body py-2">
            <Row className="mb-3">
                <Col><PrimeDropdown options={transformSn} value={selectedSn} onChange={onChangeSn}/></Col>
            </Row>
            {renderModalHeader()}
            <DataTable
                title=""
                // keyField='id'
                data={data}
                columns={columns}
                // defaultSortField="customerName"
                striped
                noHeader
                highlightOnHover
                persistTableHead
                // progressPending={loading}
                pagination
                paginationPerPage={10}
                // paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                customStyles={customStyles3}
                paginationComponentOptions={paginationComponentOptions}
            />
          <Row>
          </Row>
        </div>
        <div className="modal-footer pt-0">
          <Button className="btn btn-default px-5 py-1 ml-auto w-100" disabled>
            Export
          </Button>
        </div>
      </Modal>
  )
}

export default DeviceAvailabilityDetailModal