import { TritronikCheckbox, TritronikInputText } from "components";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from "reactstrap";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
});

const AddRoleModal = ({
  isOpen = false,
  toggleModal,
  featuresGroup = [],
  onSubmit,
  isLoading = false,
}) => {
  const _onSubmit = ({ name, description, featuresGroup }) => {
    // transform form object into designated request
    const featureIds = [];

    featuresGroup.forEach(({ features }) => {
      if (features && features.length > 0) {
        features
          .filter((v) => v.checked) // only checked features that will be send
          .forEach(({ id }) => {
            featureIds.push(id);
          });
      }
    });

    if (typeof onSubmit === "function") {
      onSubmit({ name, description, featureIds });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="modal-dialog-centered"
      size="lg"
    >
      <Formik
        initialValues={{
          name: "",
          description: "",
          featuresGroup,
        }}
        onSubmit={_onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Role
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <TritronikInputText
                size="sm"
                name="name"
                label="Role Name"
                required
                className="mb-2"
              />
              <TritronikInputText
                size="sm"
                name="description"
                label="Description"
              />

              <div className="mt-4">
                <h3>Role Privileges</h3>
                <Row
                  style={{ width: "100%", height: "50vh", overflowY: "scroll" }}
                >
                  <FieldArray
                    name="featuresGroup"
                    render={() => (
                      <>
                        {values && values.featuresGroup.length > 0
                          ? values.featuresGroup.map(
                              ({ category, features: featureList }, index) => (
                                <Col
                                  key={`role-category-${index}`}
                                  sm="12"
                                  md="6"
                                  className="mb-2"
                                >
                                  <div className="mb-2">
                                    <h4>{category}</h4>
                                    <div
                                      style={{
                                        height: 200,
                                        overflowY: "scroll",
                                      }}
                                      className="border p-2 rounded"
                                    >
                                      <FieldArray
                                        name={`featuresGroup.${index}.features`}
                                        render={() => (
                                          <>
                                            {featureList &&
                                            featureList.length > 0
                                              ? featureList.map(
                                                  (
                                                    { name, id, checked },
                                                    idx
                                                  ) => (
                                                    <div
                                                      key={`role-id-${id}`}
                                                      className="text-sm"
                                                    >
                                                      <TritronikCheckbox
                                                        name={`featuresGroup.${index}.features.${idx}.checked`}
                                                        label={name}
                                                        checked={checked}
                                                      />
                                                    </div>
                                                  )
                                                )
                                              : null}
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              )
                            )
                          : null}
                      </>
                    )}
                  />
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
                size="sm"
              >
                Close
              </Button>
              <Button
                color="primary"
                type="submit"
                size="sm"
                disabled={isLoading}
              >
                Create
                {isLoading && (
                  <Spinner className="ml-2" color="light" size="sm" />
                )}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddRoleModal;
