import React from "react";
import { toast } from "react-toastify";
import {
  Modal
} from "reactstrap";
import { useUpdateDeviceMutation } from "../services/deviceInventoryApi";
import FormDevice from "./FormDevice";

const CreateDeviceInventoryModal = ({ isOpen, toggle, device }) => {
  const [updateDevice, { isLoading }] = useUpdateDeviceMutation();

  const onSubmit = async (body) => {
    updateDevice({ id: device.id, body })
      .unwrap()
      .then(() => {
        toast.success(`Device ${device.sn} updated.`);
        toggle();
      })
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <FormDevice device={device} onSubmit={onSubmit} onCancel={toggle} isLoading={isLoading} />
    </Modal>
  );
};

export default CreateDeviceInventoryModal;
