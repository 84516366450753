import { useMemo } from "react";
import { useGetCustomersQuery } from "../features/customers/service/customerApi";

export function useCustomerOptions(params = {}) {
  const { data, isLoading } = useGetCustomersQuery();

  const customerOptions = useMemo(() => {
    if (!data) return [];

    return data.content.map((d) => {
      return {
        value: d.id,
        label: d.name,
      };
    });
  }, [data]);

  return {
    customerOptions,
    isLoading,
  };
}
