import { useState, useMemo } from "react";
import { useAuth } from "./../../auth/hooks";
import { defaultOptions, usePagination } from "./../../../hooks";
import { useGetAreasQuery } from "../service/areaApi";

export function useAreaDatatable(defaultParams = {}, options = defaultOptions) {
  const { user, isAdmin } = useAuth();
  const [params, setParams] = useState(defaultParams);

  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort
  } = usePagination({
    pageNumber: 1,
    pageSize: 10,
    sortFields: options.sortFields,
    sortOrder: options.sortOrder
  });

  const { data: paginatedResult, isLoading, isFetching, refetch } = useGetAreasQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    username: isAdmin ? undefined : user?.username,
    includeHierarchySummary: true,
    ...params,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    isFetching,
    pageSize,
    pageNumber,
    totalRows,
    params,
    setParams,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
    refetch,
  };

}