import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';

const FullscreenLoading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s"/>
    </div>
  )
}

export default FullscreenLoading;
