import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { ChartJS, Loading, SLACard } from "./../../../components";

const SLATunnelReportContent = ({ loading, pieChartData, panelData }) => {
  const { UPTIME, DOWNTIME } = panelData;
  let data = pieChartData ? { ...pieChartData } : null;
  const updateLabels = (data) => {
    if (data) {
      const updatedLabels = data.labels.map((label, index) => {
          const value = data.datasets[0].data[index];
          return `${label === '>= 99.5%' ? 'Achieved with' : 'Not Achieved with'} ${label} (${value} device${value > 1 ? 's' : ''})`;
      });
      data.labels = updatedLabels;
    }
  };

  if (data) {
    updateLabels(data); // Update the labels in the copied data object
  }
  return (
    <Row>
      <Col className="col-lg-4 col-sm-12">
        <div className="chart mb-2" id="slaTunnelReportContent">
          {loading && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Loading size="lg" />
            </div>
          )}
          {!loading && data && (
            <ChartJS
              type="pie"
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    usePointStyle: false,
                    onClick: () => {}
                  },
                },
                tooltips: {
                  callbacks: {
                    label: function(tooltipItem, data) {
                      return `${data.labels[tooltipItem.index]}`;
                    }
                  }
                },
                pieceLabel: {
                  render: "percentage",
                  fontSize: 16,
                  fontColor: "#fff",
                  fontStyle: "normal",
                },
              }}
            />
          )}
        </div>
      </Col>
      <Col className="col-lg-8 col-sm-12">
        {loading && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Loading size="lg" />
          </div>
        )}
        {!loading && (
          <Card className="mb-4">
            <CardBody className="py-2">
              <CardTitle tag="h4" className="mb-2">
                {!loading ? 'UPTIME' : "Loading..."}
              </CardTitle>
              <Row>
                {!loading && UPTIME &&
                  UPTIME.map((ut, i) => {
                    const { label, value, unit, deviation } = ut;
                    return (
                      <Col lg="4" sm="6" key={`sla-tunnel-uptime-panel-${i}`}>
                        <SLACard
                          title={label}
                          value={value ? (value === 'NaN' ? '0' : (typeof value === 'number') ? value.toFixed(2) : value) : '0'}
                          unitValue={unit}
                          status={deviation !== null ? deviation?.status : undefined}
                          statusText={deviation !== null ? `${deviation?.value}${deviation?.unit} than ${deviation?.comparison}` : undefined}
                        />
                      </Col>
                    );
                  })}
                {!loading && typeof UPTIME === "undefined" && (
                  <>
                    <Col lg="4" sm="6">
                      <SLACard title="Minimum" value={0} unitValue="H" />
                    </Col>
                    <Col lg="4" sm="6">
                      <SLACard title="Maximum" value={0} unitValue="H" />
                    </Col>
                    <Col lg="4" sm="6">
                      <SLACard title="Average" value={0} unitValue="H" />
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
            <CardBody className="py-2">
              <CardTitle tag="h4" className="mb-2">
                {!loading ? 'DOWNTIME' : ""}
              </CardTitle>
              <Row>
                {!loading && DOWNTIME &&
                  DOWNTIME.map((ut, i) => {
                    const { label, value, unit, deviation } = ut;
                    return (
                      <Col lg="4" sm="6" key={`sla-tunnel-downtime-panel-${i}`}>
                        <SLACard
                          title={label}
                          value={value ? (value === 'NaN' ? '0' : (typeof value === 'number') ? value.toFixed(2) : value) : '0'}
                          unitValue={unit}
                          status={deviation !== null ? deviation?.status : undefined}
                          statusText={deviation !== null ? `${deviation?.value}${deviation?.unit} than ${deviation?.comparison}` : undefined}
                        />
                      </Col>
                    );
                  })}
                {!loading && typeof DOWNTIME === "undefined" && (
                  <>
                    <Col lg="4" sm="6">
                      <SLACard title="Minimum" value={0} unitValue="H" />
                    </Col>
                    <Col lg="4" sm="6">
                      <SLACard title="Maximum" value={0} unitValue="H" />
                    </Col>
                    <Col lg="4" sm="6">
                      <SLACard title="Average" value={0} unitValue="H" />
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default SLATunnelReportContent;

