import { useAuth } from 'features/auth';
import React from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import { useCreateTokenMutation } from '../services/generateTokenApi';
import moment from 'moment';
import { Formik } from 'formik';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { RowItem } from 'components';
import Swal from 'sweetalert2';

const FormGenerate = () => {
  const { user } = useAuth();
  const [createToken] = useCreateTokenMutation();
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState('');
  const toggle = () => setModal(!modal);

  const handleCreateToken = async ({ username, expired }) => {
    const { data } = await createToken({
      username: user.username,
      expired: moment(expired).valueOf(),
    });
    setToken(data.token);
    toggle();
  };

  //swal when token copie to clipboard
  const handleCopySwal = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Token copied to clipboard',
      showConfirmButton: false,
      timer: 2500,
    });
  };

  return (
    <Formik
      initialValues={{
        username: user.username,
        expired: moment().add(1, 'month').format('YYYY-MM-DD'),
      }}
      onSubmit={handleCreateToken}
    >
      {({ values, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md='6' className='mb-3 mt-3' sm='12'>
              <RowItem label='User'>
                <Col md='8' sm='12'>
                  <Input
                    type='text'
                    name='username'
                    value={values.username}
                    onChange={handleChange}
                    disabled
                  />
                </Col>
              </RowItem>

              <RowItem label='Expired Date'>
                <Col md='8' sm='12'>
                  <Input
                    type='date'
                    name='expired'
                    id='expired'
                    placeholder='Expired'
                    value={values.expired}
                    onChange={handleChange}
                  />
                </Col>
              </RowItem>
            </Col>

            <Col md='12'>
              <RowItem>
                <Button color='secondary' type='reset'>
                  Clear
                </Button>

                <Button
                  type='submit'
                  color='primary'
                  onClick={handleSubmit}
                  disabled={values.expired === ''}
                >
                  Generate
                </Button>
              </RowItem>
            </Col>
          </Row>

          <Row className='mb-2 d-flex align-items-center'>
            <Col xs='12' md='6'>
              <Modal isOpen={modal} toggle={toggle} size='lg' centered>
                <ModalBody className='text-center' style={{ fontSize: '20px' }}>
                  <p>API Token : {token}</p>
                  <p>
                    API Token valid until :
                    {moment(values.expired).format('DD-MM-YYYY')}
                  </p>
                  <p>
                    Keep your token Secure and Store it Safely, it only showed
                    once and can be used by other user.
                  </p>
                </ModalBody>
                <ModalFooter>
                  <CopyToClipboard text={token} onCopy={handleCopySwal}>
                    <Button type='button' color='primary' onClick={toggle}>
                      Copy
                    </Button>
                  </CopyToClipboard>
                  <Button type='button' color='secondary' onClick={toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default FormGenerate;
