import React, { useMemo, useState } from "react";
import { Col, Form, Row, Button } from "reactstrap";
import { Gap, RowItem, TritronikSelect } from "../../../../components";
import { useFormik } from "formik";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { useGetJobPackagesQuery } from "features/inventory/onu/service";
import { useGetListPolicyPackageQuery } from "features/package-policy/service/packagePolicyApi";
import Swal from "sweetalert2";
import {
  useCreateJobPackageMutation,
  useUpdateJobPackageMutation,
} from "features/inventory/onu/service";
import { customStyles2 } from "utils/datatableStyles";
import { TabView, TabPanel } from "primereact/tabview";
import DataTable from "react-data-table-component";

const RegisteredOnuSchedule = (props) => {
  const { onuId } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const { data: jobPackages } = useGetJobPackagesQuery(onuId, {
    skip: !onuId,
    pollingInterval: 60000,
  });
  const { data: policyListPackage } = useGetListPolicyPackageQuery();
  const [createJobPackage] = useCreateJobPackageMutation();
  const [updateJobPackage] = useUpdateJobPackageMutation();

  const policyListOptions = useMemo(() => {
    if (!policyListPackage) return [];

    return policyListPackage.map((item) => {
      return { label: item.packageName, value: item, data: item };
    });
  }, [policyListPackage]);

  const date = new Date();
  const timeStamp = date.getTime();

  const formik = useFormik({
    initialValues: {
      selectedPackage: "",
      validityPeriod: {
        beginTs: timeStamp,
        endTs: timeStamp,
      },
    },

    onSubmit: (value) => {
      const body = {
        beginTs: value.validityPeriod.beginTs,
        demandPackageId: value.selectedPackage.id,
        endTs: value.validityPeriod.endTs,
        jobStatus: "SCHEDULED",
      };

      Swal.fire({
        text: "Are You Sure want to Apply Configuration?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          createJobPackage({ onuId, body })
            .unwrap()
            .then(() =>
              Swal.fire(
                "Success!",
                "Your configuration has been added.",
                "success"
              )
            )
            .catch(() =>
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              })
            );
        }
      });
    },
  });

  const onCallbackChange = (s, e) => {
    formik.setFieldValue("validityPeriod", {
      beginTs: s._d.getTime(),
      endTs: e._d.getTime(),
    });
  };

  const onCancelled = (content) => {
    const jobId = content.id;
    const body = {
      beginTs: content.beginTs,
      defaultPackageId: content.defaultPackageId,
      demandPackageId: content.demandPackageId,
      endTs: content.endTs,
      id: content.id,
      jobStatus: "CANCELLED",
      notes: content.notes,
      startedTs: content.startedTs,
      terminatedTs: content.terminatedTs,
      updateModified: content.updateModified,
    };

    Swal.fire({
      text: "Are You Sure Want to Cancel This Schedule?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        updateJobPackage({ onuId, jobId, body })
          .unwrap()
          .then(() =>
            Swal.fire(
              "Success!",
              "Your configuration has been cancelled.",
              "success"
            )
          )
          .catch(() =>
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            })
          );
      }
    });
  };

  const columnsScheduled = [
    {
      name: "PACKAGENAME",
      center: true,
      selector: (row) => `${row.demandPackageInfo?.packageName}`,
    },
    {
      name: "DOWNSTREAM",
      center: true,
      selector: (row) => `${row.demandPackageInfo?.policyDownstream.name}`,
    },
    {
      name: "UPSTREAM",
      center: true,
      selector: (row) => `${row.demandPackageInfo?.policyUpstream.name}`,
    },
    {
      name: "VALIDITY PERIOD",
      center: true,
      selector: (row) =>
        `${new Date(row.beginTs).toLocaleString("en-GB")} - ${new Date(
          row.endTs
        ).toLocaleString("en-GB")}`,
    },
    {
      name: "ACTION",
      button: true,
      cell: (content) =>
        content.jobStatus === "RUNNING" ? (
          <div>{content.jobStatus}</div>
        ) : (
          <div>
            <Button color="link">
              <i
                className="fas fa-trash"
                onClick={() => onCancelled(content)}
              ></i>
            </Button>
          </div>
        ),
    },
  ];

  const columnsHistory = [
    {
      name: "PACKAGENAME",
      center: true,
      selector: (row) => `${row.demandPackageInfo?.packageName}`,
    },
    {
      name: "DOWNSTREAM",
      center: true,
      selector: (row) => `${row.demandPackageInfo?.policyDownstream.name}`,
    },
    {
      name: "UPSTREAM",
      center: true,
      selector: (row) => `${row.demandPackageInfo?.policyUpstream.name}`,
    },
    {
      name: "VALIDITY PERIOD",
      center: true,
      selector: (row) =>
        `${new Date(row.beginTs).toLocaleString("en-GB")} - ${new Date(
          row.endTs
        ).toLocaleString("en-GB")}`,
    },
    {
      name: "STATUS",
      button: true,
      cell: ({ jobStatus }) => <div>{jobStatus}</div>,
    },
  ];

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mt-4">
          <Col className="border-right border-bottom px-5 pb-5">
            <h2 className="text-dark ml--3">Package Schedule</h2>
            <Gap height={10} />
            <RowItem
              stripped
              label="PackageName"
              value={
                <TritronikSelect
                  value={formik.values.selectedPackage}
                  options={policyListOptions}
                  name="selectedPackage"
                  placeholder="Select Package"
                  onChange={formik.handleChange}
                />
              }
            />
            <RowItem
              label="Downstream"
              offset
              value={formik.values.selectedPackage?.policyDownstream?.name}
            />
            <RowItem
              label="Upstream"
              stripped
              offset
              value={formik.values.selectedPackage?.policyUpstream?.name}
            />
          </Col>
          <Col className="border-left border-bottom px-5 pb-5">
            <Gap height={50} />
            <RowItem
              label="Validity Period"
              stripped
              value={
                <DateRangePicker
                  initialSettings={{
                    timePicker: true,
                    startDate: moment().startOf("hour").toDate(),
                    endDate: moment().startOf("hour").add(32, "hour").toDate(),
                    locale: {
                      format: "DD-MM-YYYY HH:mm",
                    },
                    minDate: new Date(),
                    timePicker24Hour: true,
                    timePickerIncrement: 5,
                    showDropdowns: true,
                  }}
                  onCallback={onCallbackChange}
                >
                  <input
                    type="text"
                    placeholder="Set Schedule"
                    className="form-control"
                    name="validityPeriod"
                    autoComplete="off"
                    style={{ height: "30px" }}
                    required
                  />
                </DateRangePicker>
              }
            />
            <Gap height={10} />
            <Row className="d-flex justify-content-end">
              <Button
                outline
                color="primary"
                type="reset"
                onClick={formik.resetForm}
              >
                Clear
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={formik.values.selectedPackage === "" ? true : false}
              >
                Add
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col className="border-left px-5 pb-5">
          <h2 className="text-dark mt-4">Package on Demand List</h2>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Scheduled">
              <Row>
                <DataTable
                  data={jobPackages?.content
                    .filter((schedule) => {
                      return (
                        schedule.jobStatus === "SCHEDULED" ||
                        schedule.jobStatus === "RUNNING" ||
                        schedule.jobStatus === "SUBMITTED"
                      );
                    })
                    .sort((a, b) => {
                      return a.beginTs - b.beginTs;
                    })}
                  columns={columnsScheduled}
                  striped
                  keyField="id"
                  persistTableHead
                  highlightOnHover
                  noHeader
                  customStyles={customStyles2}
                />
              </Row>
            </TabPanel>
            <TabPanel header="History">
              <Row>
                <DataTable
                  data={jobPackages?.content
                    .filter((schedule) => {
                      return (
                        schedule.jobStatus === "EXPIRED" ||
                        schedule.jobStatus === "CANCELLED"
                      );
                    })
                    .sort((a, b) => {
                      return a.beginTs - b.beginTs;
                    })}
                  columns={columnsHistory}
                  striped
                  keyField="id"
                  persistTableHead
                  highlightOnHover
                  noHeader
                  pagination={true}
                  customStyles={customStyles2}
                />
              </Row>
            </TabPanel>
          </TabView>
        </Col>
      </Row>
    </>
  );
};

export default RegisteredOnuSchedule;
