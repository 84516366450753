import {
  RowItem,
  TritronikCheckbox,
  TritronikInputText,
} from "./../../../../components";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { Tooltip } from "primereact/tooltip";
import { Button, Form, Spinner } from "reactstrap";
import { CONFIGURE_DEVICE } from "../../../../config";
import { useCan } from "hooks/useCan";

const FormRateLimit = ({ initialValues, onSubmit, isLoading }) => {
  const { rateLimitDonwloadInKb, rateLimitUploadInKb } = initialValues;
  const hasPermission = useCan();

  const canConfigure = hasPermission(CONFIGURE_DEVICE);

  const CustomLabel = ({ label, tooltip }) => {
    return (
      <>
        <span>
          {label}{" "}
          <i
            className="fa fa-info-circle text-xs fa-1x custom-target-icon"
            data-pr-tooltip={tooltip}
            data-pr-position="right"
          ></i>
        </span>
        <Tooltip target=".custom-target-icon" />
      </>
    );
  };

  const onUnlimitedChanges = (e, setFieldValue) => {
    if (e.checked) {
      setFieldValue("rateLimitDownloadInKb", -1);
      setFieldValue("rateLimitUploadInKb", -1);
    } else {
      setFieldValue("rateLimitDownloadInKb", 256);
      setFieldValue("rateLimitUploadInKb", 256);
    }
  };

  const customValidate = (values) => {
    const errors = {};
    if (
      values.rateLimitDownloadInKb !== -1 &&
      values.rateLimitDownloadInKb === 0
    ) {
      errors.rateLimitDownloadInKb = "Invalid value.";
    }
    if (values.rateLimitUploadInKb !== -1 && values.rateLimitUploadInKb === 0) {
      errors.rateLimitUploadInKb = "Invalid value.";
    }

    return errors;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        rateLimitDownloadInKb: rateLimitDonwloadInKb || "",
        rateLimitUploadInKb: rateLimitUploadInKb || "",
        unlimited:
          rateLimitDonwloadInKb === -1 || rateLimitUploadInKb === -1
            ? true
            : false,
      }}
      validationSchema={Yup.object({
        rateLimitDownloadInKb: Yup.number().required(
          "Download limit required."
        ),
        rateLimitUploadInKb: Yup.number().required("Upload limit required."),
      })}
      onSubmit={onSubmit}
      validate={customValidate}
    >
      {({ values, handleSubmit, setFieldValue, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <RowItem
            rightCol="col-2"
            offset
            label={
              <CustomLabel
                label="Download Limit (in kb/s)"
                tooltip="-1 is equal to unlimited"
              />
            }
            value={
              canConfigure ? (
                <TritronikInputText
                  name="rateLimitDownloadInKb"
                  small
                  readOnly={values.unlimited}
                />
              ) : (
                <span>{values.rateLimitDownloadInKb}</span>
              )
            }
          />
          <RowItem
            rightCol="col-2"
            offset
            label={
              <CustomLabel
                label="Upload Limit (in kb/s)"
                tooltip="-1 is equal to unlimited"
              />
            }
            value={
              canConfigure ? (
                <TritronikInputText
                  type="number"
                  name="rateLimitUploadInKb"
                  small
                  readOnly={values.unlimited}
                />
              ) : (
                <span className="text-dark">{values.rateLimitUploadInKb}</span>
              )
            }
          />
          <div className="d-flex justify-content-end">
              <TritronikCheckbox
                type="number"
                name="unlimited"
                checked={values.unlimited}
                label="Unlimited"
                onChange={(e) => onUnlimitedChanges(e, setFieldValue)}
                disabled={!canConfigure}
              />
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              className="btn btn-default px-5 py-1"
              type="submit"
              disabled={isLoading || !dirty || !canConfigure}
            >
              Apply
              {isLoading && (
                <Spinner className="ml-2" color="light" size="sm" />
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormRateLimit;
