import React, { useEffect, useState } from "react";

import HighPingRTTCard from "features/dashboard/components/molecules/HighPingRTTCard";
import OverQuotaLimitCard from "features/dashboard/components/molecules/OverQuotaLimitCard";
import PingFailureRateCard from "features/dashboard/components/molecules/PingFailureRateCard";
import PingPacketLossCard from "features/dashboard/components/molecules/PingPacketLossCard";
import {
  Col,
  Nav,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import PageToolbar from "../../PageToolbar";
import PerformanceKPIOverviewTabContent from "./performance-tabs/PerformanceKPIOverviewTabContent";
import DeviceWithIssueTabContent from "./performance-tabs/DeviceWithIssueTabContent";
import CustomNavItem from "../CustomNavItem";
import { PageContent } from "components";

const AllRegionPerformanceView = () => {
  // Initialize state from sessionStorage or default to 'overview'
  const [activeTab, setActiveTab] = useState(sessionStorage.getItem('performanceTab') || 'overview');

  useEffect(() => {
    // Clear sessionStorage on page reload
    const handleUnload = () => {
      sessionStorage.removeItem('performanceTab');
    };
    
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      sessionStorage.setItem('performanceTab', tab);
    }
  };

  return (
    <PageContent title="Performance Dasboard">
        <div className="header header-dark pb-2">
            <Row>
              <Col>
                <PageToolbar />
              </Col>
            </Row>

            <h3 className="text-secondary mb-3">Issues Summary</h3>

            <Row>
              <Col md="6" sm="12">
                <PingFailureRateCard />
              </Col>
              <Col md="6" sm="12">
                <PingPacketLossCard />
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12">
                <HighPingRTTCard />
              </Col>
              <Col md="6" sm="12">
                <OverQuotaLimitCard />
              </Col>
            </Row>
        </div>
        <h3 className="mb-3">Performance Chart</h3>
        <Nav tabs>
          <CustomNavItem
            active={activeTab === "overview"}
            onClick={() => toggle("overview")}
            text="Performance KPI Overview"
          />
          <CustomNavItem
            active={activeTab === "device-issue"}
            onClick={() => toggle("device-issue")}
            text="Number of Devices with Issue"
          />
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="overview">
            <PerformanceKPIOverviewTabContent />
          </TabPane>
          <TabPane tabId="device-issue">
            <DeviceWithIssueTabContent />
          </TabPane>
        </TabContent>
    </PageContent>
  );
};

export default AllRegionPerformanceView;
