import React from 'react'
import { Formik } from 'formik'
import { Button, Col, Form, Row } from 'reactstrap'
import * as Yup from 'yup';

import { CustomLabel, Loading, RowItem, TritronikInputText, Gap, TritronikSelect } from '../../../../components'
import { useSIMOperator } from './../../../../hooks';
import { billingCycleOptions } from './../../../../utils';
import { useIsGsmNumberExistsMutation } from 'features/device/service/deviceApi';

const FormSimcard = ({ onSubmit, isLoading, onCancel, simcard }) => {
    const { options: operatorOptions } = useSIMOperator();
    const billingDateOptions = billingCycleOptions();
    const [checkGsmNumber] = useIsGsmNumberExistsMutation();

    const validateGsmNumber = async (gsmNumber) => {
        let error;
    
        if (gsmNumber !== '') {
          const exists = await checkGsmNumber({ id: simcard ? simcard.id : 0, gsmNumber }).unwrap();
          if (exists) {
            error = 'GSM Number already exists.';
          }
        }
        return error;
      }

    return (
        <Formik
            initialValues={{
                id: simcard?.id || 0,
                imsi: simcard?.imsi || "",
                gsmNumber: simcard?.gsmNumber || "",
                operator: simcard?.operator || "",
                billingCycle: simcard?.billingCycle || "1",
                inventoryId: simcard?.inventoryId || "",
                circuitNumber: simcard?.circuitNumber || "",
            }}
            validationSchema={Yup.object().shape({
                gsmNumber: Yup.string().required("GSM number required."),
                operator: Yup.string().required("Operator required."),
                circuitNumber: Yup.string().required("SIA required."),
            })}
            validateOnBlur={true}
            onSubmit={onSubmit}
        >
            {({ values, dirty, errors, touched, setFieldValue, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="modal-header">
                        <h3 className="modal-title">{simcard ? 'Edit SIM' : 'Add New SIM' }</h3>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={onCancel}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body py-2">
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="GSM Number" required />}
                            value={
                                <TritronikInputText
                                    small
                                    name="gsmNumber"
                                    placeholder="GSM Number"
                                    validate={validateGsmNumber} 
                                />
                            }
                        />
                        <Gap height={15} />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="Operator" required />}
                            value={
                                <TritronikSelect
                                    name="operator"
                                    placeholder="Operator"
                                    options={operatorOptions}
                                    value={values.operator}
                                    onChange={(e) => setFieldValue("operator", e.value)}
                                    invalid={errors.operator}
                                    error={errors.operator}
                                    editable
                                />
                            }
                        />
						{simcard && (
							<>
								<Gap height={15} />
								<RowItem
									rightCol='col-8'
									label={<CustomLabel name="IMSI" />}
									value={
										<TritronikInputText
											small
											name="imsi"
											placeholder="IMSI"
											readOnly={true}
										/>
									}
								/>
							</>
						)}
                        <Gap height={15} />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="Billing Date" />}
                            value={
                                <TritronikSelect
                                    name="billingCycle"
                                    placeholder="Billing Date"
                                    options={billingDateOptions}
                                    value={values.billingCycle}
                                    onChange={(e) => setFieldValue("billingCycle", e.value)}
                                    invalid={errors.billingCycle}
                                    error={errors.billingCycle}
                                />
                            }
                        />
                        <Gap height={15} />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="Inventory ID" />}
                            value={
                                <TritronikInputText
                                    small
                                    name="inventoryId"
                                    placeholder="Inventory ID"
                                />
                            }
                        />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="SIA" required/>}
                            value={
                                <TritronikInputText
                                    small
                                    name="circuitNumber"
                                    placeholder="SIA"
                                />
                            }
                        />
                        <Row className="mt-4">
                            <Col className="text-right">
                                <Button
                                    color="secondary"
                                    type="button"
                                    size="sm"
                                    onClick={onCancel}
                                    className="px-5 py-1"
                                >
                                    Cancel
                                </Button>
                                <Button className="px-5 py-1" color="default" type="submit" size="sm" disabled={isLoading || !dirty}>
                                    Save
                                    {isLoading && <Loading className="ml-2" />}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default FormSimcard
