import { useMemo } from "react";
import { useAvailableUpdateQuery, useFirmwareInfoQuery } from "../service/deviceApi";

export function useFirmware(deviceId) {
    const { data: updateInfo } = useAvailableUpdateQuery(deviceId);

    const { data: firmwareInfo } = useFirmwareInfoQuery(deviceId);

    const updateAvailable = useMemo(() => {
        if (updateInfo && updateInfo.value === true) {
            return true;
        }

        return false;
    }, [updateInfo]);

    return {
        updateAvailable,
        firmwareInfo,
    }
}