import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PageContent } from '../../../components';
import { FormGenerate } from '../components';

const GenerateTokenPage = () => {
  const title = 'Generate Token';

  return (
    <PageContent title='API Token'>
      <Card>
        <CardBody>
          <Row className='mb-2 d-flex align-items-center'>
            <Col className='d-flex align-items-center' xs='12' md='6'>
              <h2 className='text-dark'>{title}</h2>
            </Col>
            <Col
              xs='12'
              md='6'
              className='d-flex justify-content-end align-items-center'
            ></Col>
          </Row>
          <Row className='mb-2 d-flex align-items-center'>
            <Col md='12' sm='12'>
              <FormGenerate />
            </Col>
            <Col></Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default GenerateTokenPage;
