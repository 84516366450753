import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateNetworkThunk } from "../networkSlice";

export function useUpdateNetwork() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const updateNetwork = useCallback(async (id, data) => {
		setIsLoading(true);
		await dispatch(updateNetworkThunk({ id, ...data }));
		setIsLoading(false);
	}, [dispatch]);

	return {
		updateNetwork,
		isLoading
	}
}