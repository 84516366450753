import { PageContent } from "components";
import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { history } from "utils";
import { customStyles } from "utils/datatableStyles";
import { useGetRouterInterfaceQuery } from "../service";

const RouterInterface = () => {
  const path = window.location.href;
  const arrId = path.split("/").splice(-2);
  const ipAddress = arrId[0]
  const id = arrId[1]

  const { data: routerInterfaces, isLoading } = useGetRouterInterfaceQuery({
    routerId: id,
  });

  const columns = [
    {
      name: "INTERFACE NAME",
      center: true,
      hide: "md",
      selector: (row) => row?.interfaceName,
    },
    {
      name: "PHYSICAL NAME",
      center: true,
      hide: "md",
      selector: (row) => row?.physicalName,
    },
    {
      name: "ADMIN STATUS",
      left: true,
      hide: "md",
      cell: (row) =>
        row?.adminStatus === "up" ? (
          <div>
            <i style={{color: "limegreen", marginRight: "10px"}} className="mdi mdi-arrow-up mr-2"></i>
            {row.adminStatus.toUpperCase()}
          </div>
        ) : (
          <div>
            <i style={{color: "red", marginRight: "10px"}} className="mdi mdi-arrow-up mr-2"></i>
            {row.adminStatus.toUpperCase()}
          </div>
        ),
    },
    {
      name: "LINKSTATUS",
      left: true,
      hide: "md",
      cell: (row) =>
        row?.linkStatus === "up" ? (
          <div>
            <i style={{color: "limegreen", marginRight: "10px"}} className="mdi mdi-arrow-up mr-2"></i>
            {row.linkStatus.toUpperCase()}
          </div>
        ) : (
          <div>
            <i style={{color: "red", marginRight: "10px"}} className="mdi mdi-arrow-down mr-2"></i>
            {row.linkStatus.toUpperCase()}
          </div>
        ),
    },
    {
      name: "ACTION",
      button: true,
      allowOverflow: true,
      hide: "md",
      cell: (row) => (
        <Button
          color="link"
          onClick={() =>
            history.push(
              `/admin/router-list/router-interface/${ipAddress}/${row.routerId}/${row.interfaceName.split('/').join('%2F')}/${row.id}`
            )
          }
        >
          <i className="fa fa-search"></i>
        </Button>
      ),
    },
  ];

  return (
    <PageContent title="Router Interface">
      <Card>
        <CardBody>
          <Row className="mb-3 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <Breadcrumb listClassName="bg-white p-0 m-0">
                <BreadcrumbItem>
                  <Link to="/admin/router-list" className="text-lg">
                   All Router
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-lg">
                {ipAddress}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-lg-end align-items-center"
            >
              <Button size="sm" color="primary">
                <Link
                  to="/admin/router-list"
                  style={{ color: "white" }}
                >
                  <i className="mdi mdi-arrow-left mr-2"></i>
                  Back
                </Link>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={routerInterfaces?.content}
                columns={columns}
                progressPending={isLoading}
                persistTableHead
                highlightOnHover
                noHeader
                striped
                pagination
                customStyles={customStyles}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default RouterInterface;
