import { useMemo } from "react";
import { useGetGroupsQuery } from "../service/groupApi";

export function useGroups(params = {}) {
  const { data, isLoading } = useGetGroupsQuery({
    ...params
  });

  const options = useMemo(() => {
    const opts = [];

    if (data && data.content) {
      data.content.forEach((d) => {
        opts.push({ value: d.id, label: d.name });
      });
    }

    return opts;
  }, [data]);

  return {
    data,
    isLoading,
    options,
  };
}