import React from 'react';
import AllRegionPerformanceView from "./components/PerformanceDashboard/AllRegionPerformanceView";
import { usePerformanceDashboard } from "./context";
import RegionPerformanceView from './components/PerformanceDashboard/RegionPerformanceView';

const PerformanceDashboard = () => {
  const { selectedRegion } = usePerformanceDashboard();
  
  if (selectedRegion === 'All Region') {
    return <AllRegionPerformanceView />
  }

  return <RegionPerformanceView />
};

export default PerformanceDashboard;
