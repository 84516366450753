import { useState } from "react";

export const defaultOptions = {
  pageNumber: 1,
  pageSize: 10,
  sortFields: undefined,
  sortOrder: undefined,
}

export function usePagination(options = defaultOptions) {
  const [pageNumber, setPageNumber] = useState(options.pageNumber ?? defaultOptions.pageNumber);
  const [pageSize, setPageSize] = useState(options.pageSize ?? defaultOptions.pageSize);
  const [sortFields, setSortFields] = useState(options.sortFields ?? defaultOptions.sortFields);
  const [sortOrder, setSortOrder] = useState(options.sortOrder ?? defaultOptions.sortOrder);

  const onChangeRowsPerPage = (currentRowsPerPage) => {
    setPageSize(currentRowsPerPage);
  };

  const onChangePage = async (page) => {
    setPageNumber(page);
  };

  const onSort = ({ sortField }, sortDirection) => {
    setSortFields(sortField);
    setSortOrder(sortDirection);
  };

  return {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  }
}