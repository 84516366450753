import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { confirmAction } from "../../../utils/swal";
import { useSetNotificationSubscriptionByUsernameMutation } from "../service/notificationApi";

export const useNotificationSubscription = () => {
  const [updateNotificationSubscriptionByUsername] = useSetNotificationSubscriptionByUsernameMutation();

  const changeNotificationSubscriptionByUsername = async (username, subscribe) => {
    const action = subscribe ? 'Allow' : 'Disallow';
    return confirmAction({
      text: `${action} All Notification`,
      preConfirm: async () => {
        return updateNotificationSubscriptionByUsername({ username, subscribe }).unwrap().then(() => {
          toast.success(`Notification Subscriptions Updated.`);
          return true;
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  return { changeNotificationSubscriptionByUsername };
}