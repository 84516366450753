import { DELETE_PACKAGE_POLICY } from "config";
import { UPDATE_PACKAGE_POLICY } from "config";
import { Can } from "config";
import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";

const PackagePolicyActionButton = ({ row, onDelete, onEdit }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={menuOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <i className="fas fa-ellipsis-v text-default" />
        </DropdownToggle>
        <DropdownMenu right>
          <Can I="do" this={DELETE_PACKAGE_POLICY}>
            {() => (
              <DropdownItem onClick={() => onDelete(row)}>
                <span className="text-danger">
                  <i className="fa fa-trash text-danger mr-2"></i> Delete
                </span>
              </DropdownItem>
            )}
          </Can>
          <Can I="do" this={UPDATE_PACKAGE_POLICY}>
            {() => (
              <DropdownItem onClick={() => onEdit(row)}>
                <span className="text-default">
                  <i className="fa fa-edit text-default mr-2"></i> Edit
                </span>
              </DropdownItem>
            )}
          </Can>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default PackagePolicyActionButton;
