import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const MyCheckbox = ({ id, label, alternative, ...props }) => {
  return (
    <>
      <div
        className={classnames("custom-control custom-checkbox", {
          "custom-control-alternative": alternative,
        })}
      >
        <input
          className="custom-control-input"
          id={id}
          type="checkbox"
          {...props}
        />
        {label && (
          <label className="custom-control-label" htmlFor={id}>
            <span
              className={classnames({
                "text-muted": alternative,
              })}
            >
              {label}
            </span>
          </label>
        )}
      </div>
    </>
  );
};

MyCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  alternative: PropTypes.bool,
};

MyCheckbox.defaultProps = {
  id: Math.random().toString(),
  alternative: false,
}

export default MyCheckbox;
