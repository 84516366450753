import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL, API_BASE_URL_USAGE } from "./../config";
import { Mutex } from "async-mutex";
import qs from 'query-string';
import { resetStateAction } from "store/actions";
import { authSlice } from "features/auth/reducers/authSlice";

const mutex = new Mutex();

export const createBaseQuery = (baseUrl) => {
  return fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("token", `${token}`);
      }

      return headers;
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const baseQueryBase = createBaseQuery(API_BASE_URL);

export const baseQueryUsageBase = createBaseQuery(API_BASE_URL_USAGE);

export const baseQuery = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQueryBase(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        api.dispatch(authSlice.actions.logout());
        api.dispatch(resetStateAction());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQueryBase(args, api, extraOptions);
    }
  }

  return result;
};

export const baseQueryUsage = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQueryUsageBase(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        api.dispatch(authSlice.actions.logout());
        api.dispatch(resetStateAction());
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQueryUsageBase(args, api, extraOptions);
    }
  }

  return result;
};

export const defaultApi = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  endpoints: () => ({}),
});

export const usageApi = createApi({
  baseQuery: baseQueryUsage,
  endpoints: () => ({}),
});
