import React from "react";
import { Formik } from "formik";
import { RowItem } from "components";
import { CustomLabel } from "components";
import { TritronikInputText, Loading } from "components";
import { Button, Col, Form, Row } from "reactstrap";

const FormPolicy = ({onCancel, onSubmit, isLoading, policy}) => {
  return (
    <Formik
      initialValues={{
        // id: policy?.id || "",
        name: policy?.name || "",
        cir: policy?.cir || 0,
        cbs: policy?.cbs || 0,
        pir: policy?.pir || 0,
        pbs: policy?.pbs || 0,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h3 className="modal-title">{policy? "Edit Policy" : "Add New Policy"}</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={onCancel}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body py-2">
            {/* <RowItem
              rightCol="col-8"
              label={<CustomLabel name="ID" />}
              value={<TritronikInputText small name="id" placeholder="ID" disabled={policy? true : false}/>}
            /> */}
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="Name" />}
              value={
                <TritronikInputText small name="name" placeholder="Name" />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="CIR" />}
              value={
                <TritronikInputText
                  small
                  name="cir"
                  placeholder="CIR"
                  type="number"
                />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="CBS" />}
              value={
                <TritronikInputText
                  small
                  name="cbs"
                  placeholder="CBS"
                  type="number"
                />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="PIR" />}
              value={
                <TritronikInputText
                  small
                  name="pir"
                  placeholder="PIR"
                  type="number"
                />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="PBS" />}
              value={
                <TritronikInputText
                  small
                  name="pbs"
                  placeholder="PBS"
                  type="number"
                />
              }
            />
            <Row className="my-4">
              <Col className="text-right">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={onCancel}
                  className="px-5 py-1"
                >
                  Cancel
                </Button>
                <Button
                  className="px-5 py-1"
                  color="default"
                  type="submit"
                  size="sm"
                  disabled={isLoading}
                >
                  Save
                  {isLoading && <Loading className="ml-2" />}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormPolicy;
