import React from "react";
import moment from "moment";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { unknown } from "utils";

export default function useDeviceLicenseColumns() {
  return useMemo(() => {
    const textBodyTemplate = (value) => {
      return value || unknown;
    };

    const dateTemplate = (data, field, format = "DD/MM/YYYY") => {
      if (!data[field]) return unknown;

      return moment(data[field]).format(format);
    };

    const linkTemplate = (data, field) => {
      if (!data[field]) return unknown;

      return (
        <Link
          to={{
            pathname: `/admin/devices`,
            search: `?sn=${data[field]}`
          }}
        >
          {data[field]}
        </Link>
      );
    };

    return [
      {
        name: "DEVICE SERIAL NUMBER",
        selector: (row) => row?.sn,
        sortable: true,
        sortField: "sn",
        cell: (data) => linkTemplate(data, "sn"),
      },
      {
        name: "LICENSE NUMBER",
        selector: (row) => row?.licenseNumber,
        sortable: true,
        sortField: "licenseNumber",
        cell: ({ licenseNumber }) => textBodyTemplate(licenseNumber),
      },
      {
        name: "DEVICE TYPE",
        selector: (row) => row?.type,
        sortable: true,
        sortField: "type",
        cell: ({ type }) => textBodyTemplate(type),
      },
      {
        name: "CUSTOMER",
        selector: (row) => row?.customerName,
        sortable: true,
        sortField: "customerName",
        cell: ({ customerName }) => textBodyTemplate(customerName),
      },
      {
        name: "ASSIGNED DATE",
        selector: (row) => row?.assignedLicenseTs,
        sortable: true,
        sortField: "assignedLicenseTs",
        cell: (data) => dateTemplate(data, "assignedLicenseTs"),
      },
      {
        name: "EXPIRY DATE",
        selector: (row) => row?.licenseEndTs,
        sortable: true,
        sortField: "licenseEndTs",
        cell: (data) => dateTemplate(data, "licenseEndTs"),
      },
      {
        name: "STATUS",
        selector: (row) => row?.status,
        sortable: true,
        sortField: "status",
        cell: ({ status }) => textBodyTemplate(status),
      },
    ];
  }, []);
}
