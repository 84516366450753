import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from 'reactstrap';

import { Can, DELETE_DEVICE_INVENTORY, PROVISION_DEVICE, UNPROVISION_DEVICE, UPDATE_DEVICE_INVENTORY } from '../../../../config';
import { history } from "./../../../../utils"

const CustomActionButton = ({ row, onDelete, onEdit, onUnprovisionDevice }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const assetStatus = row.assetStatus;

  const handleOnDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete(row);
    }
  }

  const handleOnEdit = () => {
    if (typeof onEdit === 'function') {
      onEdit(row);
    }
  }

  const onProvisionClick = () => {
    history.push({
      pathname: '/admin/inventory/device/provision',
      state: {
        device: row
      }
    })
  }

  return (
    <Nav navbar>
      <UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
        <DropdownToggle nav>
          <i className="fas fa-ellipsis-v text-default" />
        </DropdownToggle>
        <DropdownMenu right>
          <Can I="do" this={DELETE_DEVICE_INVENTORY}>
            {() => (
              <DropdownItem onClick={handleOnDelete}>
                <span className="text-danger"><i className="fa fa-trash text-danger mr-2"></i> Delete</span>
              </DropdownItem>
            )}
          </Can>
          <Can I="do" this={UPDATE_DEVICE_INVENTORY}>
            {() => (
              <DropdownItem onClick={handleOnEdit}>
                <span className="text-default"><i className="fa fa-edit text-default mr-2"></i>Edit</span>
              </DropdownItem>
            )}
          </Can>
          <Can I="do" this={PROVISION_DEVICE}>
            {() => assetStatus === 'UNPROVISION' && (
              <DropdownItem onClick={onProvisionClick}>
                <span className="text-success"><i className="fa fa-plus-square text-success mr-2"></i>Provision</span>
              </DropdownItem>
            )}
          </Can>
          <Can I="do" this={UNPROVISION_DEVICE}>
            {() => assetStatus === 'PROVISION' && (
              <DropdownItem onClick={() => onUnprovisionDevice(row)}>
                <span className="text-default"><i className="fa fa-minus-square text-danger mr-2"></i>Unprovision</span>
              </DropdownItem>
            )}
          </Can>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
}

export default CustomActionButton;