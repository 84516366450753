import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import * as Yup from "yup";
import {
  Gap,
  Loading,
  TritronikInputText
} from "../../../components";
import { useUpdateCustomerMutation } from "../service/customerApi";


const ModalEditCustomer = ({ isOpen, toggle, customer }) => {

  const [updateCustomer, { isLoading }] = useUpdateCustomerMutation();

  const onSubmit = async (customer) => {
    updateCustomer(customer)
      .unwrap()
      .then(() => {
        toast.success(`Customer ${customer.name} updated.`);
        toggle();
      });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <Formik
        initialValues={{
          id: customer.id,
          name: customer.name,
          businessId: customer.businessId,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Customer name is required."),
          businessId: Yup.string().required(
            "Customer business id is required."
          ),
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h3 className="modal-title">Update Customer</h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body py-2">
              <TritronikInputText
                label="Customer Business ID"
                name="businessId"
                placeholder="Customer Business ID"
                small
                required
                className="text-dark"
              />
              <Gap height={15} />
              <TritronikInputText
                label="Customer Name"
                name="name"
                placeholder="Customer Name"
                small
                required
                className="text-dark"
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button block color="default" type="submit" size="sm" disabled={isLoading || !dirty}>
                    Update
                    {isLoading && <Loading className="ml-2" />}
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="text-center">
                  <Button
                    block
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalEditCustomer;
