import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { RowItem, Gap, TogglePasswordLabel } from '../../../components'

const FormConfirmation = ({ formik, groupOptions }) => {
  const { values } = formik;

  const renderGroupNames = () => {
    return groupOptions.filter((g) => values.groupIds.includes(g.value)).map((group => group.label)).join(', ');
  }
  return (
    <div>
      <h2 className="ml-4">Confirmation</h2>
      <Gap height={30} />
      <Row className="px-3">
        <Col>
          <Card>
            <CardBody>
              <h4 style={{ marginLeft: -14}}>Profile</h4>
              <Gap height={10} />
              <RowItem stripped label="Name" value={values.name} />
              <RowItem label="Email" value={values.email} />
              <RowItem stripped label="Phone Number" value={values.phoneNumber} />
              <RowItem label="Telegram Number" value={values.telegramNumber} />
              <RowItem stripped label="Username" value={values.username} />
              <RowItem label="Password" value={<TogglePasswordLabel value={values.password} />} />
              <RowItem stripped label="Group" value={renderGroupNames()} valueRight />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <h4 style={{ marginLeft: -14}}>Privilege</h4>
              <Gap height={10} />
              {values.privileges && values.privileges.map((priv, idx) => (
                <RowItem key={priv.id} stripped={idx % 2 === 0} label={priv.name} />
              ))}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <h4 style={{ marginLeft: -14}}>Eligible Data</h4>
              <Gap height={10} />
              <h5 style={{ marginLeft: -14}}>Eligible Zone</h5>
              <Gap height={10} />
              {values.selectedRegion && values.selectedRegion === '-1' && (
                <RowItem stripped label="All Region" />
              )}
              {values.regions && values.regions.map((region, idx) => (
                <div key={region.id}>
                  <RowItem stripped={idx % 2 === 0} label={(
                    <span>
                      <span><i className="fa fa-circle mr-2 text-default"></i></span>
                      {region.name}
                    </span>
                  )} />
                  {region.areas && region.areas.map((area, index) => (
                    <RowItem offset key={area.id} stripped={index % 2 !== 0} label={(
                      <span>
                        <span><i className="fa fa-dot-circle mr-2 text-default"></i></span>
                        {area.name}
                      </span>
                    )} />
                  ))}
                </div>
              ))}
              <Gap height={20} />
              <h5 style={{ marginLeft: -14}}>Eligible Customer</h5>
              <Gap height={10} />
              {values.selectedCustomer && values.selectedCustomer === '-1' && (
                <RowItem stripped label="All Customer" />
              )}
              {values.customers && values.customers.map((cust, idx) => (
                <RowItem key={cust.id} stripped={idx % 2 === 0} label={cust.name} />
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default FormConfirmation
