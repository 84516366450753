import React from "react";
import { Badge, Col, Row } from "reactstrap";
import SignalStrengthStatus from "../SignalStrengthStatus";
import { RowItem, TritronikInputText } from "./../../../../components";
import { unknown } from "./../../../../utils";
import { Tooltip } from "primereact/tooltip";

export const DeviceOverviewSimCardConfiguration = ({ simCard, formIndex }) => {
  const {
    id,
    slotNumber,
    imsi,
    imei,
    signalStrengthIndex,
    signalStrength,
    primary,
    operator,
    txUsageInHuman,
    rxUsageInHuman,
  } = simCard;

  return (
    <Row key={id}>
      <Col>
        <RowItem
          label={
            <h4>
              SIM {slotNumber || "Not Available"}{" "}
              {primary ? (
                <Badge className="ml-2" color="primary">
                  PRIMARY
                </Badge>
              ) : null}
            </h4>
          }
        />
        <RowItem stripped label="SIA" value={(
          <TritronikInputText name={`simCards.${formIndex}.circuitNumber`} small />
        )} />
        <RowItem label="IMEI" value={imei || unknown} />
        <RowItem
          stripped
          label="IMSI"
          value={
            simCard.unverifiedDetected === true ? (
              <span>{imsi || unknown}</span>
            ) : (
              <span>{imsi || unknown}</span>
            )
          }
        />

        <RowItem label="GSM Number" value={(
          <TritronikInputText name={`simCards.${formIndex}.gsmNumber`} small />
        )} />
        <RowItem
          stripped
          label="Signal"
          value={
            <SignalStrengthStatus
              status={signalStrength}
              signal={signalStrengthIndex}
              type="index"
            />
          }
        />
        <RowItem
          label="Operator"
          value={
            simCard.unmatchedOperator === true ? (
              <span id="unmatchedOperatorSign">
                {operator || unknown}
                <i
                  className="fa fa-exclamation-triangle text-red ml-2 custom-target-icon"
                  data-pr-tooltip="Unmatched IMSI & Operator"
                  data-pr-position="right"
                />
                <Tooltip target=".custom-target-icon" />
              </span>
            ) : (
              <span>{operator || unknown}</span>
            )
          }
        />
        <RowItem stripped label="Tx Usage" value={txUsageInHuman || "- kB"} />
        <RowItem label="Rx Usage" value={rxUsageInHuman || "- kB"} />
      </Col>
    </Row>
  );
};
