import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  DeviceQuotaPercentageCard,
  SIMStatusCard,
  Widget01,
} from "../components";
import { DeviceUsageOverQuotaCard } from "../components/molecules/DeviceUsageOverQuotaCard";
import { useDashboardData } from "../hooks";
import { BootstrapDateRangePicker } from "./../../../components";
import { history, getTimeGroupping } from "./../../../utils";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import moment from "moment";
import MissingCIDAlert from "../components/MissingCIDAlert";
import OpenAlert from "../components/OpenAlert";

const Dashboard = () => {
  const [query, setQuery] = useQueryParams({
    startTs: NumberParam,
    endTs: NumberParam,
    timeGroupping: StringParam,
  });

  useEffect(() => {
    setQuery({
      startTs: moment().startOf("day").valueOf(),
      endTs: moment().endOf("day").valueOf(),
      timeGroupping: "1h",
    });
  }, [setQuery]);

  const {
    widget,
    isLoadingSummary,
    isLoadingOQC,
    isLoadingOQP,
    deviceUsagePercentageData,
    dataOverQuotaByCustomer
  } = useDashboardData({
    startTs: query.startTs || moment().startOf("day").valueOf(),
    endTs: query.endTs || moment().endOf("day").valueOf(),
    timeGroupping: query.timeGroupping || '1h'
  });

  const onDateChange = (start, end, label) => {
    setQuery({
      startTs: start.valueOf(),
      endTs: end.valueOf(),
      timeGroupping: getTimeGroupping(start, end),
    });
  };

  const goToDeviceList = () => {
    history.push("/admin/devices");
  };
  
  const goToCustomerList = () => {
    return history.push("/admin/customers");
  };

  const onWidgetClick = (key, value) => {
    return history.push({
      pathname: "/admin/devices",
      search: `?${key}=${value}`,
    });
  };

  const goToRegionList = () => {
    history.push("/admin/regions");
  };

  return (
    <>
      <div className="header header-dark bg-default">
        <Container fluid>
          <div className="header-body">
            <Row className="d-flex justify-content-between align-items-center py-4">
              <Col>
                <h1 className="fullcalendar-title h1 text-white d-inline-block mb-0">
                  Device Dashboard
                </h1>
              </Col>
              <div className="col-2">
                <BootstrapDateRangePicker onDateChange={onDateChange} />
              </div>
            </Row>
            <MissingCIDAlert />
            <Row>
              <Col md="4" sm="12">
                <DeviceQuotaPercentageCard
                  loading={isLoadingOQP}
                  data={deviceUsagePercentageData}
                />
              </Col>
              <Col md="8" sm="12">
                <Row>
                  <Col>
                    <Widget01
                      title="Customers"
                      value={widget.countCustomer}
                      loading={isLoadingSummary}
                      onClick={goToCustomerList}
                    />
                  </Col>
                  <Col>
                    <Widget01
                      title="Regional"
                      value={widget.countRegion}
                      loading={isLoadingSummary}
                      onClick={goToRegionList}
                    />
                  </Col>
                  <Col xs='6'>
                    <SIMStatusCard
                      title="DEVICE"
                      connectionLost={widget.countConnectionLostSimCard}
                      totalDevice={widget.countDevice}
                      loading={isLoadingSummary}
                      onWidgetClick={onWidgetClick}
                      goToDeviceList={goToDeviceList}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SIMStatusCard
                      title="SIM STATUS"
                      activated={widget.countActivatedSimCard}
                      deactivated={widget.countDeactivatedSimCard}
                      reachQuotaLimit={widget.countOffLimitSimCard}
                      loading={isLoadingSummary}
                      onWidgetClick={onWidgetClick}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container fluid>
        <Row className="my-4">
          <Col md="6" sm="12">
            <DeviceUsageOverQuotaCard
              loading={isLoadingOQC}
              data={dataOverQuotaByCustomer}
            />
          </Col>
          <Col md="6" sm="12">
            <OpenAlert />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
