import React from 'react';
import { Field } from 'formik';
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import classNames from 'classnames';

const TritronikInputText = ({ label, name, small, required, block, validate, ...props }) => (
  <Field name={name} validate={validate}>
    {({ field, form: { touched, errors }, meta }) => (
      <FormGroup className={classNames('mb-0', { 'w-100': block })}>
        {label && <Label className="control-label">{label}{required ? <span className="text-danger ml-1">*</span> : null}</Label>}
        <Input {...field} {...props} invalid={Boolean(meta.touched && meta.error)} bsSize={small ? "sm" : "md" } />
        {meta.touched && meta.error && <FormFeedback>{meta.error}</FormFeedback>}
      </FormGroup>
      )}
  </Field>
);

TritronikInputText.defaultProps = {
  block: true
}

export default TritronikInputText;
