import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
	Card,
	CardBody,
	Col,
	Row
} from "reactstrap";
import Swal from "sweetalert2";

import { ButtonLinkIcon, CustomExpandableIcon, PageContent } from '../../../components';
import { paginationRowsPerPageOptions } from "../../../utils";
import { customStyles } from "./../../../utils/datatableStyles";

import { useNetworks } from '../hooks/useNetworks';
import { Chip } from 'primereact/chip';
import moment from 'moment';
import NetworkTableDetail from '../components/NetworkTableDetail';
import ModalFormNetwork from '../components/modals/ModalFormNetwork';
import { useCreateNetwork } from '../hooks/useCreateNetwork';
import { useUpdateNetwork } from '../hooks/useUpdateNetwork';
import { useCallback } from 'react';
import CustomActionButton from '../components/CustomActionButton';
import { useDeleteNetwork } from '../hooks/useDeleteNetwork';

const ListNetworkPage = () => {
	const { data, isLoading } = useNetworks();
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [updateModalOpen, setUpdateModalOpen] = useState(false);
	const [selectedNetwork, setSelectedNetwork] = useState(null);

	const { createNetwork, isLoading: isCreatingNetwork } = useCreateNetwork();
	const { updateNetwork, isLoading: isUpdatingNetwork } = useUpdateNetwork();
	const { deleteNetwork, isLoading: isDeletingNetwork } = useDeleteNetwork();

	const title = 'All Networks';

	const onAddNetworkClick = () => {
		setCreateModalOpen(true);
	}

	const onSubmitCreateNetwork = async (data) => {
		await createNetwork(data);
		setCreateModalOpen(false);
	}

	const onSubmitUpdateNetwork = async ({ id, ...data }) => {
		await updateNetwork(id, data);
		setSelectedNetwork(null);
		setUpdateModalOpen(false);
	}

	const onEdit = useCallback((network) => {
		setSelectedNetwork(network);
		setUpdateModalOpen(true);
	}, []);

	const ExpandedComponent = (props) => {
		return (
			<NetworkTableDetail
				onEdit={onEdit}
				{...props}
			/>
		)
	}

	const onSubmitUpdateAction = useCallback(async (id, data) => {
		Swal.fire({
			icon: "question",
			title: `Update network ${id}?`,
			showLoaderOnConfirm: true,
			showCancelButton: true,
			confirmButtonText: "Yes",
			reverseButtons: true,
			preConfirm: async () => {
				try {
					await updateNetwork(id, data);
					return true;
				} catch (error) {
					Swal.showValidationMessage(`${error?.error}: ${error?.message}`);
					return false;
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	}, [updateNetwork]);

	const onDeleteNetwork = useCallback(async (id) => {
		Swal.fire({
			icon: "question",
			title: `Delete network ${id}?`,
			showLoaderOnConfirm: true,
			showCancelButton: true,
			confirmButtonText: "Yes",
			reverseButtons: true,
			preConfirm: async () => {
				try {
					await deleteNetwork(id)
					return true;
				} catch (error) {
					Swal.showValidationMessage(`${error?.error}: ${error?.message}`);
					return false;
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	}, [deleteNetwork]);

	const columns = useMemo(() => {
		const renderTags = (tags) => {
			if (!tags) return '-';

			return tags && tags.map((tag) => (
				<Chip label={tag} />
			))
		}

		return [
			{
				name: 'ID',
				sortable: true,
				sortField: 'id',
				left: true,
				selector: row => row?.id,
			},
			{
				name: 'NAME',
				selector: row => row?.name,
				sortable: true,
				sortField: 'name',
				left: true,
			},
			{
				name: 'TAGS',
				selector: row => row?.tags,
				sortable: false,
				left: true,
				cell: ({ tags }) => renderTags(tags)
			},
			{
				name: 'REGISTERED MEMBERS',
				selector: row => row?.members,
				sortable: false,
				center: true,
				cell: ({ members }) => {
					return members ? Object.entries(members).length : '-';
				}
			},
			{
				name: 'ACCESS CONTROL',
				selector: row => row?.private,
				sortable: true,
				sortField: 'private',
				center: true,
				cell: (row) => row.private ? 'Private' : 'Public'
			},
			{
				name: 'CREATION TIME',
				selector: row => row?.creationTime,
				sortable: true,
				sortField: 'creationTime',
				center: true,
				hide: "md",
				cell: (row) => {
					if (moment(row.creationTime).isValid()) {
						return moment(row.creationTime).format('DD/MM/YYYY');
					} else {
						return '-'
					}
				}
			},
			{
				width: '40px',
				cell: (row) => (
					<CustomActionButton
						row={row}
						onDelete={onDeleteNetwork}
						isDeleting={isDeletingNetwork}
						onUpdate={onSubmitUpdateAction}
						isUpdating={isUpdatingNetwork}
					/>
				),
				allowOverflow: true,
				button: true,
			},
		]
	}, [onSubmitUpdateAction, isUpdatingNetwork, onDeleteNetwork, isDeletingNetwork]);

	return (
		<PageContent title="Network">
			<Card>
				<CardBody>
					<Row className="mb-2 d-flex align-items-center">
						<Col className="d-flex align-items-center" xs="12" md="6">
							<h2 className="text-dark">{title}</h2>
						</Col>
						<Col xs="12" md="6" className="d-flex justify-content-end align-items-center">
							<ButtonLinkIcon
								label="Add New"
								onClick={onAddNetworkClick} />
						</Col>
					</Row>
					<DataTable
						title={title}
						data={data}
						columns={columns}
						defaultSortField={'id'}
						keyField="id"
						striped
						noHeader
						highlightOnHover
						customStyles={customStyles}
						expandableRows
						expandableRowsComponent={ExpandedComponent}
						expandableIcon={{
							collapsed: <CustomExpandableIcon type="collapsed" />,
							expanded: <CustomExpandableIcon type="expanded" />,
						}}
						persistTableHead
						progressPending={isLoading}
						pagination
						paginationRowsPerPageOptions={paginationRowsPerPageOptions}
					/>
				</CardBody>
			</Card>
			<ModalFormNetwork
				isOpen={createModalOpen}
				toggle={() => setCreateModalOpen(!createModalOpen)}
				title='Create Network'
				isLoading={isCreatingNetwork}
				onSubmit={onSubmitCreateNetwork}
			/>
			<ModalFormNetwork
				isOpen={updateModalOpen}
				toggle={() => setUpdateModalOpen(!updateModalOpen)}
				title='Update Network'
				submitLabel='Update'
				isLoading={isUpdatingNetwork}
				onSubmit={onSubmitUpdateNetwork}
				network={selectedNetwork}
			/>
		</PageContent>
	)
}

export default ListNetworkPage;