import { Gap, RowItem, TritronikSelect } from "components";
import { useGetRouterPolicyListQuery } from "features/router/routerPolicy/service/routerPolicyApi";
import { useFormik } from "formik";
import moment from "moment";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DataTable from "react-data-table-component";
import { Button, Col, Form, Row } from "reactstrap";
import Swal from "sweetalert2";
import { customStyles2 } from "utils/datatableStyles";
import { useCreateJobPackageMutation, useGetJobPackageQuery, useUpdateJobPackageMutation } from "../service";

const RouterInterfaceUnitSchedule = ({ data }) => {
  const routerInterfaceUnitId = data.id;
  const { data: routerPolicyList } = useGetRouterPolicyListQuery();

  const [createSchedule] = useCreateJobPackageMutation();
  const [cancelSchedule] = useUpdateJobPackageMutation();
  const { data: jobs } = useGetJobPackageQuery(routerInterfaceUnitId, {
    skip: !routerInterfaceUnitId,
    pollingInterval: 60000,
  });

  const [activeIndex, setActiveIndex] = useState(0);

  const date = new Date();
  const timeStamp = date.getTime();

  const formik = useFormik({
    initialValues: {
      policyDownstreamId: data.policyDownstreamId,
      policyUpstreamId: data.policyUpstreamId,
      validityPeriod: {
        beginTs: timeStamp,
        endTs: timeStamp,
      },
    },
    onSubmit: (value) => {
      const body = {
        beginTs: value.validityPeriod.beginTs,
        defaultPolicyDownstreamId: data.policyDownstreamId,
        defaultPolicyUpstreamId: data.policyUpstreamId,
        demandPolicyDownstreamId: value.policyDownstreamId,
        demandPolicyUpstreamId: value.policyUpstreamId,
        endTs: value.validityPeriod.endTs,
        jobStatus: "SCHEDULED",
        routerInterfaceUnitId: routerInterfaceUnitId,
        // id:,
        // notes:,
        // startedTs:,
        // terminatedTs:
      };
      Swal.fire({
        text: "Are You Sure want to Apply Configuration?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          createSchedule({ routerInterfaceUnitId, body })
            .unwrap()
            .then(() =>
              Swal.fire(
                "Success!",
                "Your configuration has been added.",
                "success"
              )
            )
            .catch(() =>
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              })
            );
        }
      });
    },
  });

  const onCallbackChange = (s, e) => {
    formik.setFieldValue("validityPeriod", {
      beginTs: s._d.getTime(),
      endTs: e._d.getTime(),
    });
  };

  const onCancelled = (content) => {
    const jobId = content.id;

    const body = {
        beginTs: content.beginTs,
        defaultPolicyDownstreamId: content.defaultPolicyDownstreamId,
        defaultPolicyUpstreamId: content.defaultPolicyUpstreamId,
        demandPolicyDownstreamId: content.demandPolicyDownstreamId,
        demandPolicyUpstreamId: content.demandPolicyUpstreamId,
        endTs: content.endTs,
        jobStatus: "CANCELLED",
        routerInterfaceUnitId: content.routerInterfaceUnitId,
        id: content.id,
        notes:content.notes,
        startedTs: content.startedTs,
        terminatedTs: content.terminatedTs
      };
    
    Swal.fire({
      text: "Are You Sure Want to Cancel This Schedule?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log({id: jobId, body: body})
        cancelSchedule({ routerInterfaceUnitId, jobId, body })
          .unwrap()
          .then(() =>
            Swal.fire(
              "Success!",
              "Your configuration has been cancelled.",
              "success"
            )
          )
          .catch(() =>
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            })
          );
      }
    });
  };

  const option = routerPolicyList?.map((policy) => {
    return { label: policy.policyName, value: policy.id };
  });

  const columnsScheduled = [
    {
      name: "DOWNSTREAM",
      center: true,
      selector: (row) => `${row.demandPolicyDownstreamId} Kbps`,
    },
    {
      name: "UPSTREAM",
      center: true,
      selector: (row) => `${row.demandPolicyUpstreamId} Kbps`,
    },
    {
      name: "VALIDITY PERIOD",
      center: true,
      selector: (row) =>
        `${new Date(row.beginTs).toLocaleString("en-GB")} - ${new Date(
          row.endTs
        ).toLocaleString("en-GB")}`,
    },
    {
      name: "ACTION",
      button: true,
      cell: (content) =>
        content.jobStatus === "RUNNING" ? (
          <div>{content.jobStatus}</div>
        ) : (
          <div>
            <Button color="link">
              <i
                className="fas fa-trash"
                onClick={() => onCancelled(content)}
              ></i>
            </Button>
          </div>
        ),
    },
  ];

  const columnsHistory = [
    {
      name: "DOWNSTREAM",
      center: true,
      selector: (row) => `${row.demandPolicyDownstreamId} Kbps`,
    },
    {
      name: "UPSTREAM",
      center: true,
      selector: (row) => `${row.demandPolicyUpstreamId} Kbps`,
    },
    {
      name: "VALIDITY PERIOD",
      center: true,
      selector: (row) =>
        `${new Date(row.beginTs).toLocaleString("en-GB")} - ${new Date(
          row.endTs
        ).toLocaleString("en-GB")}`,
    },
    {
      name: "STATUS",
      button: true,
      cell: ({ jobStatus }) => <div>{jobStatus}</div>,
    },
  ];

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mt-2">
          <Col className="border-right border-bottom px-5 pb-5">
            <h2 className="text-dark mt-4">Policy Configuration Schedule</h2>
            <Gap height={10} />
            <RowItem
              stripped
              label="Downstream"
              value={
                <TritronikSelect
                  value={formik.values.policyDownstreamId}
                  options={option}
                  placeholder={"Select Policy"}
                  name="policyDownstreamId"
                  onChange={formik.handleChange}
                />
              }
            />
            <RowItem
              label="Upstream"
              value={
                <TritronikSelect
                  value={formik.values.policyUpstreamId}
                  options={option}
                  placeholder={"Select Policy"}
                  name="policyUpstreamId"
                  onChange={formik.handleChange}
                />
              }
            />
          </Col>
          <Col className="border-left border-bottom px-5 pb-5">
            <Gap height={70} />
            <RowItem
              label="Validity Period"
              stripped
              value={
                <DateRangePicker
                  initialSettings={{
                    timePicker: true,
                    startDate: moment().startOf("hour").toDate(),
                    endDate: moment().startOf("hour").add(32, "hour").toDate(),
                    locale: {
                      format: "DD-MM-YYYY HH:mm",
                    },
                    minDate: new Date(),
                    timePicker24Hour: true,
                    timePickerIncrement: 5,
                    showDropdowns: true,
                  }}
                  onCallback={onCallbackChange}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="validityPeriod"
                    autoComplete="off"
                    style={{ height: "30px" }}
                    required
                  />
                </DateRangePicker>
              }
            />
            <Gap height={10} />
            <Row className="d-flex justify-content-end">
              <Button
                outline
                color="primary"
                type="reset"
                onClick={formik.resetForm}
              >
                Clear
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={
                  formik.values.policyDownstreamId === "" ||
                  formik.values.policyUpstreamId === ""
                }
              >
                Add
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col className="border-left px-5 pb-5">
          <h2 className="text-dark mt-4">Policy Configuration Schedule List</h2>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Scheduled">
              <Row>
                <DataTable
                  data={jobs?.content
                    .filter((schedule) => {
                      return (
                        schedule.jobStatus === "SCHEDULED" ||
                        schedule.jobStatus === "RUNNING" ||
                        schedule.jobStatus === "SUBMITTED"
                      );
                    })
                    .sort((a, b) => {
                      return a.beginTs - b.beginTs;
                    })}
                  columns={columnsScheduled}
                  striped
                  keyField="id"
                  persistTableHead
                  highlightOnHover
                  noHeader
                  customStyles={customStyles2}
                />
              </Row>
            </TabPanel>
            <TabPanel header="History">
              <Row>
                <DataTable
                  data={jobs?.content
                    .filter((schedule) => {
                      return (
                        schedule.jobStatus === "EXPIRED" ||
                        schedule.jobStatus === "CANCELLED"
                      );
                    })
                    .sort((a, b) => {
                      return a.beginTs - b.beginTs;
                    })}
                  columns={columnsHistory}
                  striped
                  keyField="id"
                  persistTableHead
                  highlightOnHover
                  noHeader
                  pagination={true}
                  customStyles={customStyles2}
                />
              </Row>
            </TabPanel>
          </TabView>
        </Col>
      </Row>
    </>
  );
};

export default RouterInterfaceUnitSchedule;
