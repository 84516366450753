import { useMemo } from 'react';
import { useGetReferencesQuery } from '../services/referenceApi';

export function useAlertType() {
  const { data, isLoading } = useGetReferencesQuery('alert-type');

  const options = useMemo(() => {
    const opts = [];

    if (data) {
      data.forEach((opt) => {
        const { key, value, ...rest } = opt;
        opts.push({ value: key, label: value, ...rest });
      });
    }

    return opts;
  }, [data]);

  return {
    data,
    isLoading,
    options,
  };
}