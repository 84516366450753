import { Loading } from "components";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useSetDeviceAreaMutation } from "../service/deviceApi";
import { TritronikSelect } from "./../../../components";


export function ModalSetArea({ isOpen, toggle, selectedRows, areaOptions, clearSelectedRows }) {
  const [loading, setLoading] = useState(false);

  const [setDeviceArea] = useSetDeviceAreaMutation();

  const {
    handleSubmit,
    isSubmitting,
    setFieldValue,
    values,
    errors,
    touched,
    dirty,
    resetForm
  } = useFormik({
    initialValues: {
      areaId: "",
    },
    validationSchema: Yup.object().shape({
      areaId: Yup.string().required("Select area first."),
    }),
    onSubmit: async ({ areaId }, {resetForm }) => {
      Swal.fire({
        icon: "question",
        title: "Are you sure want to update this?",
        text: `Device area`,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        reverseButtons: true,
        preConfirm: async () => {
          setLoading(true);
          try {
            for (let i=0; i < selectedRows.length; i++) {
              await setDeviceArea({ id: selectedRows[i].id, areaId }).unwrap();
            }
            setLoading(false);
            toast.success("Set area of devices success.");
            clearSelectedRows();
            resetForm();
            toggle();
          } finally {
            setLoading(false);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={!loading || !isSubmitting}
    >
      <Form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h3 className="modal-title" id="modalSetGroup">
            Set Area
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body py-2">
          <Row>
            <Col>
              <TritronikSelect
                filter
                options={areaOptions}
                value={values.areaId}
                onChange={(e) => setFieldValue("areaId", e.value)}
                error={errors.areaId}
                invalid={Boolean(errors.areaId && touched.areaId)}
                placeholder="Select Area"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                block
                color="default"
                type="submit"
                size="sm"
                disabled={loading || isSubmitting || !dirty}
              >
                Apply
                {(isSubmitting || loading) && <Loading className="ml-2" />}
              </Button>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className="text-center">
              <Button
                block
                color="secondary"
                type="button"
                size="sm"
                onClick={() => {
                  resetForm();
                  toggle();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}
