import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { deleteMemberThunk } from "../networkMemberSlice";

export function useDeleteMember() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const deleteMember = useCallback(async (nwid, id) => {
		setIsLoading(true);
		await dispatch(deleteMemberThunk({ nwid, id }));
		setIsLoading(false);
	}, [dispatch]);

	return {
		deleteMember,
		isLoading
	}
}