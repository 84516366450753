import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { PageContent, PrimeSearchInput, ReactDatatable, Tags, Gap } from '../../../components'
import { useUserActivityDatatable } from '../hooks'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { customStyles } from "./../../../utils/datatableStyles";
import 'bootstrap-daterangepicker/daterangepicker.css';

const dateRangeOptions = {
  ranges: {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'Previous Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Year': [moment().startOf('year'), moment().endOf('year')],
    'Previous Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
  },
  "startDate": moment(),
  "endDate": moment(),
  "drops": "bottom",
  "cancelClass": "btn-secondary",
  "alwaysShowCalendars": true
}

const UserActivityPage = () => {
  const searchRef = useRef();
  const [query, setQuery] = useQueryParams({ 
    search: StringParam,
    activityDateBegin: NumberParam,
    activityDateEnd: NumberParam,
  });

  const [filterDateLabel, setFilterDateLabel] = useState('Today');


  const { params, ...dt } = useUserActivityDatatable(query);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.search || '');
    }
  }, [query]);

  useEffect(() => {
    setQuery({
      activityDateBegin: moment().startOf("day").valueOf(),
      activityDateEnd: moment().endOf("day").valueOf(),
    });
  }, [setQuery]);

  const onSearch = (searchText) => {
    setQuery({ search: searchText !== "" && searchText !== null ? searchText : undefined });
  };

  const onRemoveFilter = useCallback((key = null, value = null) => {
    if (!key) return;

    if (value === null) {
      setQuery({ [key]: undefined });
    } else {
      setQuery((q) => {
        const filterQ = q[key]?.filter(v => v !== value);
        return {
          [key]: filterQ && filterQ.length > 0 ? filterQ : undefined
        }
      });
    }

  }, [setQuery]);

  const onResetFilter = () => {
    setQuery({ search: undefined });
  };

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined && !['activityDateBegin','activityDateEnd'].includes(key)) {
        tags.push({
          key: key,
          value: value,
          label: value,
          onRemove: () => onRemoveFilter(key)
        })
      }
    });

    return tags;
  }, [query, onRemoveFilter]);

  const onFilterDateChanges = (start, end, label) => {
    setQuery({
      activityDateBegin: start.valueOf(),
      activityDateEnd: end.valueOf(),
    });
    setFilterDateLabel(label);
  }

  return (
    <PageContent title="User Activity Log">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col>
              <h2 className="text-dark">User Activity Log</h2>
            </Col>
            <Col className="d-flex justify-content-lg-end justify-content-between  align-items-center">
              <DateRangePicker
                initialSettings={dateRangeOptions}
                onCallback={onFilterDateChanges}
              >
                <button className="btn btn-icon btn-white text-default" type="button">
                  <span className="btn-inner--icon">
                    <i className="fa fa-calendar" />
                  </span>
                  {filterDateLabel && <span className="btn-inner--text">{filterDateLabel}</span>}
                </button>
              </DateRangePicker>
              <Gap width={10} />
              <PrimeSearchInput
                ref={searchRef}
                block
                onFilter={onSearch}
                size="sm"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Tags tags={filterTags} onReset={onResetFilter} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ReactDatatable
                data={dt.data}
                columns={dt.columns}
                defaultSortField={dt.sortFields}
                defaultSortAsc={true}
                progressPending={dt.isLoading}
                pagination
                paginationServer
                onChangePage={dt.onChangePage}
                paginationPerPage={dt.pageSize}
                paginationTotalRows={dt.totalRows}
                onChangeRowsPerPage={dt.onChangeRowsPerPage}
                sortServer
                onSort={dt.onSort}
                customStyles={customStyles}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  )
}

export default UserActivityPage
