import React from 'react';
import PropTypes from 'prop-types';

import { DataTable } from "primereact/datatable";

function TritronikDataTable(props) {
  return (
    <DataTable
      value={props.value}
      className="p-datatable-striped"
      rowHover={props.rowHover}
      paginator={props.paginator}
      paginatorLeft
      paginatorTemplate={props.paginatorTemplate}
      currentPageReportTemplate={props.currentPageReportTemplate}
      rows={props.rows}
      rowsPerPageOptions={props.rowsPerPageOptions}
      resizableColumns={props.resizableColumns}
      columnResizeMode={props.columnResizeMode}
      {...props}
    >
      {props.children}
    </DataTable>
  )
}

TritronikDataTable.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  resizableColumns: PropTypes.bool,
  columnResizeMode: PropTypes.string,
  rowHover: PropTypes.bool,
  paginator: PropTypes.bool,
  rows: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  currentPageReportTemplate: PropTypes.string,
  paginatorTemplate: PropTypes.string,
};

TritronikDataTable.defaultProps = {
  resizableColumns: true,
  columnResizeMode: "fit",
  rows: 10,
  rowsPerPageOptions: [10, 20, 50],
  currentPageReportTemplate: "{first}-{last} of {totalRecords}",
  paginatorTemplate: "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink ",
  paginator: true,
  rowHover: true,
};

export default TritronikDataTable;
