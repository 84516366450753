import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import history from "utils/history";
import {
  FormConfirmation,
  FormDeviceProfile,
  FormSIMProfile,
} from "../components";
import {
  useCreateDeviceMutation,
  useIsCircuitNumberExistsMutation,
  useIsGatewayIdExistsMutation,
  useIsSimcardCircuitNumberExistMutation,
  useSetDeviceAreaMutation,
  useSetQuotaLimitSimCardMutation,
} from "../service/deviceApi";
import { PageContent, PrimeSteps } from "./../../../components";
import { useArea } from "./../../area/hooks";
import { useCustomers } from "./../../customers/hooks";
import { useGroups } from "./../../group/hooks";
import { deviceValidationSteps } from "./../validationSchema";
import { useQuotaUom, useQuotaPackage } from "./../../../hooks";
import { useRegion } from "./../../region/hooks";
import { useSimcardGsmNumbers } from "./../../inventory/simcards/hooks/useSimcardGsmNumbers";
import { useGatewayNodeRef } from "../hooks/useGatewayNodeRef";

const formSteps = [
  { label: "Device Profile" },
  { label: "SIM Profile" },
  { label: "Confirmation" },
];

export function CreateDevice() {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidation = deviceValidationSteps[activeStep];
  const isLastStep = activeStep === formSteps.length - 1;

  const [selectedRegion, setSelectedRegion] = useState(undefined);
  const { options: groupOptions } = useGroups();
  const { options: regionOptions } = useRegion({}, false);
  const { options: areaOptions } = useArea({ regionId: selectedRegion }, false);
  const { options: customerOptions } = useCustomers();
  const { options: uomOptions } = useQuotaUom();
  const { options: quotaOptions } = useQuotaPackage();
  const { gatewayNodeOptions } = useGatewayNodeRef();

  const { options: unprovisionGsmNumbersOptions } = useSimcardGsmNumbers();

  const [createDevice] = useCreateDeviceMutation();
  const [setDeviceArea] = useSetDeviceAreaMutation();
  const [setSimCardQuotaLimit] = useSetQuotaLimitSimCardMutation();
  const [checkCircuitNumberDevice] = useIsCircuitNumberExistsMutation();
  const [isSimcardCircuitNumberExist] =
    useIsSimcardCircuitNumberExistMutation();
  const [isGatewayIdExists] = useIsGatewayIdExistsMutation();

  const location = useLocation();

  const deviceState = location.state.device;

  const nextStep = () => {
    if (!isLastStep) {
      setActiveStep(activeStep + 1);
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const onRegionChanges = (regionId) => {
    setSelectedRegion(regionId ? regionId : undefined);
  };

  const validateDeviceCircuitNumber = async (id, circuitNumber) => {
    let error = null;
    if (circuitNumber !== "") {
      const exists = await checkCircuitNumberDevice({
        id,
        circuitNumber,
      }).unwrap();
      if (exists) {
        error = "Device ID already exists.";
      }
    }
    return error;
  };
  const validateSimcardCircuitNumber = async (id, circuitNumber) => {
    let error;
    if (circuitNumber !== "") {
      const exists = await isSimcardCircuitNumberExist({
        id,
        circuitNumber,
      }).unwrap();
      if (exists) {
        error = "SIA already exists.";
      }
    }
    return error;
  };

  const validateGatewayId = async (id, gatewayId) => {
    let error;
    if (gatewayId !== "") {
      const exists = await isGatewayIdExists({ id, gatewayId }).unwrap();
      if (exists) {
        error = "Gateway ID already exists.";
      }
    }
    return error;
  };

  const validateQuota = (quota) => {
    const isNumeric = (value) => {
      return /^[0-9]+$/.test(value)
    }
    let error
    if (!isNumeric(quota)) {
      error = "Input Must Be Numeric"
    }
    return error
  }

  const renderFormStep = (step, formik) => {
    switch (step) {
      case 0:
        return (
          <FormDeviceProfile
            formik={formik}
            onRegionChanges={onRegionChanges}
            regionOptions={regionOptions}
            areaOptions={areaOptions}
            groupOptions={groupOptions}
            customerOptions={customerOptions}
            gatewayNodeOptions={gatewayNodeOptions}
            validateDeviceCircuitNumber={validateDeviceCircuitNumber}
            validateGatewayId={validateGatewayId}
          />
        );
      case 1:
        return (
          <FormSIMProfile
            formik={formik}
            unprovisionGsmNumbersOptions={unprovisionGsmNumbersOptions}
            quotaUomOptions={uomOptions}
            quotaPackageOptions={quotaOptions}
            validateSimcardCircuitNumber={validateSimcardCircuitNumber}
            validateQuota={validateQuota}
          />
        );
      default:
        return <FormConfirmation formik={formik} />;
    }
  };

  const onSubmit = async (values, options) => {
    setLoading(true);
    const {
      customerName,
      areaId,
      areaName,
      groupNames,
      confirmPassword,
      simCards: cards,
      circuitNumberExist,
      ...device
    } = values;

    try {
      const simCardsQuota = [];
      const simCards =
        cards &&
        cards.map((c) => {
          const { quotaPackage, quotaPackageUom, ...simCard } = c;
          simCardsQuota.push({
            quotaPackage,
            quotaPackageUom,
            slotNumber: simCard.slotNumber,
          });
          return simCard;
        });

      if (cards.length === 1) {
        options.setFieldValue(`simCards[0].primary`, true);
      }

      const newDevice = await createDevice({
        body: { ...device, simCards },
        params: {
          updateGroup: true,
          updateCustomer: true,
          updateSimCard: true,
        },
      }).unwrap();

      await setDeviceArea({ id: newDevice.id, areaId }).unwrap();

      if (simCardsQuota && simCardsQuota.length > 0) {
        for (let i = 0; i < simCardsQuota.length; i++) {
          const sc = simCardsQuota[i];
          await setSimCardQuotaLimit({
            id: newDevice.id,
            slotNumber: sc.slotNumber,
            limit: sc.quotaPackage,
            uom: sc.quotaPackageUom,
          }).unwrap();
        }
      }

      toast.success(`Device provisioned.`);
      history.push("/admin/inventory/device");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContent title="Devices">
      <Card className="mb-5">
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="mb-2">
              <Breadcrumb listClassName="bg-white p-0">
                <BreadcrumbItem>
                  <Link
                    to={"/admin/inventory/device"}
                    className="text-lg text-default font-weight-bold"
                  >
                    Device Inventory
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-lg">
                  Provision
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
          <Row>
            <Col>
              <Formik
                initialValues={{
                  id: deviceState.id || 0,
                  macAddress: "",
                  sn: deviceState?.sn || "",
                  type: deviceState?.type || "",
                  ipAddress: "",
                  circuitNumber: "",
                  location: "",
                  username: "admin",
                  password: "admin",
                  confirmPassword: "admin",
                  areaId: "",
                  areaName: "",
                  regionId: "",
                  regionName: "",
                  customerId: "",
                  customerName: "",
                  groupIds: [],
                  groupNames: "",
                  simCards: [
                    {
                      id: 0,
                      imsi: "",
                      circuitNumber: "",
                      gsmNumber: "",
                      imei: "",
                      operator: "",
                      ipAddress: "",
                      slotNumber: 1,
                      primary: true,
                      billingCycle: "",
                      quotaPackage: "2",
                      quotaPackageUom: "GB",
                    },
                  ],
                  circuitNumberExist: false,
                  gateways: [
                    {
                      id: 0,
                      gatewayId: "",
                      peerId: "",
                      peerIp: "",
                    },
                  ],
                }}
                validationSchema={currentValidation}
                onSubmit={async (values, formik) => {
                  if (isLastStep) {
                    onSubmit(values, formik);
                  } else {
                    formik.setTouched({});
                    formik.setSubmitting(false);
                    nextStep();
                  }
                }}
              >
                {(actions) => (
                  <Form onSubmit={actions.handleSubmit}>
                    <Row className="mt-5">
                      <Col>
                        <PrimeSteps
                          model={formSteps}
                          activeIndex={activeStep}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col className="px-lg-5">
                        {renderFormStep(activeStep, actions)}
                      </Col>
                    </Row>
                    <div className="d-flex mt-5 px-lg-5 justify-content-end">
                      <Link
                        to={"/admin/inventory/device"}
                        className="btn btn-outline-default px-5 py-1 ml-auto"
                      >
                        Cancel
                      </Link>
                      {activeStep > 0 && (
                        <Button
                          type="button"
                          onClick={prevStep}
                          className="btn btn-default px-5 py-1"
                        >
                          Previous
                        </Button>
                      )}
                      <Button
                        className="btn btn-default px-5 py-1"
                        type="submit"
                        disabled={loading}
                      >
                        {isLastStep ? "Apply" : "Next"}
                        {loading && (
                          <Spinner className="ml-2" color="light" size="sm" />
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
}
