import React from 'react'
import { Formik } from 'formik'
import { Button, Col, Form, Row } from 'reactstrap'
import * as Yup from 'yup';
import { useDeviceType } from "./../../../../hooks";

import { Loading, Gap, TritronikSelect, TritronikInputText, RowItem, CustomLabel } from '../../../../components'
import { useIsSerialNumberExistsMutation, useIsSnHardwareExistsMutation } from '../services';

const FormDevice = ({ onSubmit, isLoading, onCancel, device }) => {
    const { options: deviceTypeOptions } = useDeviceType();
    const [checkSerialNumber] = useIsSerialNumberExistsMutation();
    const [checkSnHardware] = useIsSnHardwareExistsMutation();
    const validateSerialNumber = async (id, sn) => {
        let error;
        if (sn !== '') {
            const exists = await checkSerialNumber({ id, sn }).unwrap();
            if (exists) {
                error = 'Serial Number already exists.';
            }
        }
        return error;
    }
    const validateSnHardware = async (id, snHardware) => {
        let error;
        if (snHardware !== '') {
            const exists = await checkSnHardware({ id, snHardware }).unwrap();
            if (exists) {
                error = 'SN Hardware already exists.';
            }
        }
        return error;
    }

    return (
        <Formik
            initialValues={{
                id: device?.id || 0,
                sn: device?.sn || '',
                snHardware: device?.snHardware || '',
                type: device?.type || '',
                name: device?.name || '',
                inventoryId: device?.inventoryId || '',
            }}
            validationSchema={Yup.object().shape({
                sn: Yup.string().required("Serial number required."),
                type: Yup.string().required("Device type required."),
            })}
            onSubmit={onSubmit}
        >
            {({ values, dirty, errors, touched, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="modal-header">
                        <h3 className="modal-title">{device ? 'Edit Device' : 'Add New Device'}</h3>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={onCancel}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body py-2">
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="Serial Number" required />}
                            value={
                                <TritronikInputText
                                    small
                                    name="sn"
                                    placeholder="Serial Number"
                                    validate={val => validateSerialNumber(values.id, val)}
                                />
                            }
                        />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="SN Hardware" />}
                            value={
                                <TritronikInputText
                                    small
                                    name="snHardware"
                                    placeholder="SN Hardware"
                                    validate={val => validateSnHardware(values.id, val)}
                                />
                            }
                        />
                        <Gap height={15} />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="Device Type" required />}
                            value={
                                <TritronikSelect
                                    name="type"
                                    placeholder="Device Type"
                                    options={deviceTypeOptions}
                                    value={values.type}
                                    onChange={(e) => setFieldValue("type", e.value)}
                                    invalid={errors.type && touched.type}
                                    error={errors.type}
                                />
                            }
                        />
                        <Gap height={15} />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="Inventory ID" />}
                            value={
                                <TritronikInputText
                                    small
                                    name="inventoryId"
                                    placeholder="Inventory ID"
                                />
                            }
                        />
                        <Gap height={15} />
                        <RowItem
                            rightCol='col-8'
                            label={<CustomLabel name="Name" />}
                            value={
                                <TritronikInputText
                                    small
                                    name="name"
                                    placeholder="Name"
                                />
                            }
                        />
                        <Row className="my-4">
                            <Col className="text-right">
                                <Button
                                    color="secondary"
                                    type="button"
                                    size="sm"
                                    onClick={onCancel}
                                    className="px-5 py-1"
                                >
                                    Cancel
                                </Button>
                                <Button className="px-5 py-1" color="default" type="submit" size="sm" disabled={isLoading || !dirty}>
                                    Save
                                    {isLoading && <Loading className="ml-2" />}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default FormDevice
