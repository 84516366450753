import React from "react";
import PropTypes from "prop-types";
import { Pie, Bar, Line, Doughnut, Radar } from "react-chartjs-2";
import Chart from "chart.js";
import "chart.piecelabel.js";

import { chartOptions, parseOptions } from "./../../../variables/charts";

const ChartJS = ({ data, options, type, ...props }) => {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());

    switch (type) {
      case "bar":
        Chart.Legend.prototype.afterFit = function () {
          this.height = this.height + 50;
        };
        break;
      default:
        break;
    }

    // Chart.plugins.register({
    //   afterDraw: function (chart) {
    //     if (chart.data && chart.data.datasets[0] && chart.data.datasets[0].data.every((item) => item === 0)) {
    //       let ctx = chart.chart.ctx;
    //       let width = chart.chart.width;
    //       let height = chart.chart.height;

    //       chart.clear();
    //       ctx.save();
    //       ctx.textAlign = "center";
    //       ctx.textBaseline = "middle";
    //       ctx.fillText("No data to display", width / 2, height / 2);
    //       ctx.restore();
    //     }
    //   },
    // });
  }
  switch (type) {
    case "pie":
      return (
        <Pie key={Math.random()} data={data} options={options} {...props} />
      );
    case "doughnut":
      return (
        <Doughnut
          key={Math.random()}
          data={data}
          options={options}
          {...props}
        />
      );
    case "line":
      return (
        <Line key={Math.random()} data={data} options={options} {...props} />
      );
    case "radar":
      return (
        <Radar key={Math.random()} data={data} options={options} {...props} />
      );
    default:
      return (
        <Bar key={Math.random()} data={data} options={options} {...props} />
      );
  }
};

ChartJS.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  type: PropTypes.oneOf(["pie", "doughnut", "bar", "line", "radar"]),
};

ChartJS.defaultProps = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "top",
    },
  },
};

export default ChartJS;
