import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../../services/baseApi';

export const simcardInventoryApi = createApi({
    reducerPath: 'simcardInventoryApi',
    baseQuery: baseQuery,
    tagTypes: ['SimCard', 'SimCardImsis', 'Unprov_GSMNumbers'],
    endpoints: (builder) => ({
        getSimcards: builder.query({
            query: (params) => ({
                url: `/sim-card/asset`,
                params: params
            }),
            providesTags: (result) =>
                result?.content
                    ? [
                        ...result.content.map(({ id }) => ({ type: 'SimCard', id })),
                        { type: 'SimCard', id: 'LIST' },
                    ]
                    : [{ type: 'SimCard', id: 'LIST' }],
        }),
        getSimcard: builder.query({
            query: (id) => ({
                url: `/sim-card/${id}`
            }),
            providesTags: (result, error, id) => [{ type: 'SimCard', id }],
        }),
        createSimcard: builder.mutation({
            query: (body) => ({
                url: `/sim-card/asset`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'SimCard', id: 'LIST' }, { type: 'SimCardImsis' }],
        }),
        updateSimcard: builder.mutation({ // TODO:: update endpoint
            query: ({ id, params, ...body }) => ({
                url: `/sim-card/${id}`,
                method: 'PUT',
                body: body,
                params
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    simcardInventoryApi.util.updateQueryData('getSimcard', id, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'SimCard', id }, { type: 'SimCardImsis' }],
        }),
        deleteSimcard: builder.mutation({
            query(id) {
                return {
                    url: `/sim-card/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'SimCard', id }, { type: 'SimCardImsis' }]
        }),
        getSimcardImsis: builder.query({
            query: (params) => ({
                url: `/sim-card/imsis`,
                params: params,
            }),
            providesTags: ['SimCardImsis'],
        }),
        getSimcardByIMSI: builder.mutation({
            query: (imsi) => ({
                url: `/sim-card/imsi/${imsi}`,
                method: 'GET'
            })
        }),
        unAssignSimcard: builder.mutation({
            query: (id) => ({
                url: `/sim-card/unprovision/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: (result, error, id) => [{ type: 'SimCard', id }, { type: 'SimCardImsis' }]
        }),
        getSimcardByGsmNumber: builder.mutation({
            query: (gsmNumber) => ({
                url: `/sim-card/gsm-number/${gsmNumber}`,
                method: 'GET'
            })
        }),
        getSimcardGsmNumbers: builder.query({
            query: (params) => ({
                url: `/sim-card/gsm-numbers/unprovision`,
                params: params,
            }),
            providesTags: ['Unprov_GSMNumbers'],
        }),
        getSimCardByCircuitNumber: builder.mutation({
            query: (circuitNumber) => ({
                url: `/sim-card/circuitNumber/${circuitNumber}`,
            }),
        }),
    })
});

export const {
    useGetSimcardsQuery,
    useGetSimcardQuery,
    useCreateSimcardMutation,
    useUpdateSimcardMutation,
    useDeleteSimcardMutation,
    useGetSimcardImsisQuery,
    useGetSimcardByIMSIMutation,
    useUnAssignSimcardMutation,
    useGetSimcardByGsmNumberMutation,
    useGetSimcardGsmNumbersQuery,
    useGetSimCardByCircuitNumberMutation,
} = simcardInventoryApi;

