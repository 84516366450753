import React from "react";
import { Row, Col } from "reactstrap";
import BlendedFailureChart from "../../performance-charts/BlendedFailureChart";
import MaxPingRTTChart from "../../performance-charts/MaxPingRTTChart";
import AveragePingRTTChart from "../../performance-charts/AveragePingRTTChart";
import BlendedPingPacketLossChart from "../../performance-charts/BlendedPingPacketLossChart";
import MaxPingPacketLossChart from "../../performance-charts/MaxPingPacketLossChart";
import UndeliveredDataChart from "../../performance-charts/UndeliveredDataChart";

const PerformanceKPIOverviewTabContent = () => {
  return (
    <Row className="mt-4">
      <Col md="12">
        <BlendedFailureChart />
      </Col>
      <Col md="12">
        <MaxPingRTTChart />
      </Col>
      <Col md="12">
        <AveragePingRTTChart />
      </Col>
      <Col md="12">
        <BlendedPingPacketLossChart />
      </Col>
      <Col md="12">
        <MaxPingPacketLossChart />
      </Col>
      <Col md="12">
        <UndeliveredDataChart />
      </Col>
    </Row>
  );
};

export default PerformanceKPIOverviewTabContent;
