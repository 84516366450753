import React, { useMemo, useRef, useState } from 'react'
import { Row, Col, Button, Spinner } from 'reactstrap'
import { unknown } from 'utils';
import { RowItem, TritronikInputText } from './../../../../../components';
import { Formik, Form } from 'formik';
import { updateProfileValidation } from '../validationSchema';
import { defaultUserProfile } from './../../../../../assets';

const EditProfile = ({ user, isLoading, onCancel, onSubmitProfile }) => {
  const { name, email, username, phoneNumber, telegramNumber, userProfilePicture } = user;

  
  const avatar = useMemo(() => userProfilePicture?.actualLink || defaultUserProfile, [userProfilePicture])
  
  const imageRef = useRef(null);
  const [preview, setPreview] = useState(avatar);

  const handleImageChange = (e, setFieldValue) => {
    if (e.target.files.length) {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        setPreview(reader.result);
        setFieldValue('avatar', file);
      }

      reader.readAsDataURL(file);
    }
  };

  const openImage = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  }

  const onLoadImageError = (event) => {
    event.target.src = defaultUserProfile;
    event.onerror = null
  }

  return (
    <Formik
      initialValues={{
        name: name,
        email: email,
        username: username,
        phoneNumber: phoneNumber,
        telegramNumber: telegramNumber,
        avatar: '',
      }}
      validationSchema={updateProfileValidation}
      onSubmit={onSubmitProfile}
    >
      {({ dirty, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="2" xs="12" className="d-flex justify-content-center">
              <div className="profilepic">
                <label htmlFor="upload-picture">
                  <input type="file" accept="image/*" id="upload-picture" className="d-none" ref={imageRef} onChange={(e) => handleImageChange(e, setFieldValue)} />
                  <img alt="avatar" className="profilepic__image" src={preview} width="150" height="150" onError={onLoadImageError} />
                </label>
                <div className="profilepic__content" onClick={openImage}>
                  <span className="profilepic__icon"><i className="fas fa-camera"></i></span>
                  <span className="profilepic__text">Change Picture</span>
                </div>
              </div>
              <div className="profilepic__hint_content">
                <span className="profilepic__hint_text">Click image to change</span>
              </div>
            </Col>
            <Col md="10" xs="12">
              <Row>
                <Col md="6" xs="12" className="px-5 border-right">
                  <RowItem label="Name" value={(
                    <TritronikInputText name="name" placeholder="Full Name" small />
                  )} stripped />
                  <RowItem label="Email" value={(
                    <TritronikInputText name="email" placeholder="Email" small />
                  )} />
                  <RowItem label="Phone Number" value={(
                    <TritronikInputText name="phoneNumber" placeholder="Phone Number" small />
                  )} stripped />
                  <RowItem label="Telegram Conversation ID" value={(
                    <TritronikInputText name="telegramNumber" placeholder="Telegram Conversation ID" small />
                  )} />
                </Col>
                <Col md="6" xs="12" className="px-5 border-left">
                  <RowItem label="Username" value={user?.username || unknown} stripped />
                  <RowItem label="Password" value="*********" />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-end">
                  <Button className="btn btn-outline-default px-5 py-1 ml-auto" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button className="btn btn-default px-5 py-1" type="submit" disabled={isLoading || !dirty}>
                    Apply
                    {isLoading && <Spinner className="ml-2" color="light" size="sm" />}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default EditProfile
