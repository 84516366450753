import React from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'

const SubscriptionTable = props => {
  const { dt } = props;
  return (
    <DataTable
      noHeader
      noTableHead
      striped
      data={dt.data}
      columns={dt.columns}
      sortServer
      onSort={dt.onSort}
      progressPending={dt.isLoading}
      pagination
      paginationServer
      paginationPerPage={dt.pageSize}
      onChangePage={dt.onChangePage}
      paginationTotalRows={dt.totalRows}
      onChangeRowsPerPage={dt.onChangeRowsPerPage}
      paginationRowsPerPageOptions={[5,10,20,30,50]}
      customStyles={{
        rows: {
          stripedStyle: {
            backgroundColor: '#F7FAFC'
          },
          style: {
            '&:not(:last-of-type)': {
              borderBottomWidth: '0px',
            },
          }
        },
        pagination: {
          style: {
            borderTopWidth: '0px',
          }
        },
        headRow: {
          style: {
            borderBottomWidth: '0px',
          },
        },
      }}
    />
  )
}

SubscriptionTable.propTypes = {
  dt: PropTypes.object.isRequired
}

export default SubscriptionTable
