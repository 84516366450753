import React from 'react';
import { Field } from 'formik';
import { Checkbox } from 'primereact/checkbox';

const TritronikCheckbox = ({ label, name, checked, onChange, disabled }) => {
  const handleOnChange = (e, setFieldValue) => {
    setFieldValue(name, e.checked);
    if (onChange) {
      onChange(e);
    }
  }
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue }, meta }) => (
        <div className="d-flex align-items-center">
          <Checkbox name={name} onChange={e => handleOnChange(e, setFieldValue)} checked={checked} disabled={disabled}></Checkbox>
          {label && <label className="p-checkbox-label ml-2 pt-2">{label}</label>}
        </div>
      )}
    </Field>
  )
};

export default TritronikCheckbox;
