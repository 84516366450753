import classnames from 'classnames';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, DropdownMenu, DropdownToggle, Form, Nav, Row, Spinner, UncontrolledDropdown } from 'reactstrap';
import { FormikToggleSwitch, Gap, PrimeMultiSelect } from '../../../components';
import { CHANGE_SIM_STATUS, CONFIG_DEVICE_GROUP, DELETE_USER } from '../../../config';
import { Can } from '../../../config/Can';
import { useUpdateUserMutation } from '../service/userApi';


const ActionItem = ({ label, value }) => {
  return (
    <div className="row d-flex justify-content-between align-items-center border-bottom mx-2 py-2">
      <div className="col-4 p-0">
        <span className="text-sm text-default font-weight-bold">{label}</span>
      </div>
      <div className="col-8 p-0 d-flex justify-content-end">
        {value}
      </div>
    </div>
  )
}

export const CustomActionButton = ({ row, onDeleteClick, groupOptions, authUser }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);
  
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const authUsername = authUser.username;

  const isMyself = row?.username === authUsername;

  const { values, handleSubmit, setFieldValue, handleReset, dirty } = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: row.username,
      groupIds: row.groupIds || [],
      status: row.status || 'deactivated'
    },
    onSubmit: (values) => {
      updateUser({ body: values, params: { updateGroup: true }})
        .unwrap()
        .then(() => {
          toast.success(`User ${row.username} updated.`);
        });
      
    }
  });

  return (
    <div>
      <Nav navbar>
        <UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
          <DropdownToggle nav>
            <i className="fas fa-ellipsis-v text-default" />
          </DropdownToggle>
          <DropdownMenu right>
            <div style={{ width: 350 }} className="p-2">
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <ActionItem
                  label="Change Status"
                  value={(
                    <div className="d-flex align-items-center">
                      <span className={classnames("mr-2", {
                        "text-danger": _.toLower(values.status) === 'deactivated',
                        "text-success": _.toLower(values.status) === 'activated',
                      })}>
                        {_.startCase(values.status)}
                      </span>
                      <Can I="do" this={CHANGE_SIM_STATUS} passThrough>
                        {(allowed) => (
                          <FormikToggleSwitch 
                            checked={_.toLower(values.status) === 'activated'} 
                            value={values.status} 
                            onChange={(e) => setFieldValue('status', e.target.checked ? 'activated' : 'deactivated')} 
                            disabled={!allowed || isMyself}
                          />
                        )}
                      </Can>
                    </div>
                  )}
                />
                <Gap height={10} />
                <ActionItem
                  label="Group"
                  value={(
                    <Can I="do" this={CONFIG_DEVICE_GROUP} passThrough>
                      {(allowed) => (
                        <PrimeMultiSelect
                          filter
                          options={groupOptions}
                          value={values.groupIds}
                          onChange={(e) => {
                            setFieldValue('groupIds', e.value);
                          }}
                          disabled={!allowed}
                        />
                      )}
                    </Can>
                  )}
                />
                <Gap height={10} />
                <Row className="mx-2 mt-2">
                  <Col className="text-center p-0">
                    <Button className="m-0" block color="default" size="sm" type="submit" disabled={isLoading || !dirty}>
                      Apply
                      {isLoading && <Spinner className="ml-2" color="light" size="sm" />}
                    </Button>
                    <Button className="m-0 mt-2" block color="secondary" size="sm" onClick={() => setMenuOpen(false)}>Cancel</Button>
                    <Can I="do" this={DELETE_USER}>
                      {() => (
                        <Button className="m-0 mt-2" block color="danger" size="sm" onClick={onDeleteClick} disabled={row.username === authUsername}>
                          Delete User
                        </Button>
                      )}
                    </Can>
                  </Col>
                </Row>
              </Form>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </div>
  );
}
