import React from 'react';
import { Row, Col } from 'reactstrap';
import { RowItem, TritronikInputText, Gap, TritronikMultiSelect, CustomLabel, TritronikInputPassword } from '../../../components';

const FormProfile = ({ groupOptions }) => {
  return (
    <div>
      <h2 className="ml-4">User Profile</h2>
      <Row>
        <Col className="col-6 px-5 border-right">
          <Gap height={30} />
          <RowItem label={<CustomLabel name="Name" required />} value={(
            <TritronikInputText small name="name" placeholder="Full Name" />
          )} />
          <Gap height={20} />
          <RowItem label={<CustomLabel name="Email" required />} value={(
            <TritronikInputText small name="email" type="email" placeholder="Email" />
          )} />
          <Gap height={20} />
          <RowItem label={<CustomLabel name="Phone Number" required />} value={(
            <TritronikInputText small name="phoneNumber" placeholder="Phone Number" />
          )} />
          <Gap height={20} />
          <RowItem label="Telegram Number" value={(
            <TritronikInputText small name="telegramNumber" placeholder="Telegram Number" />
          )} />
        </Col>
        <Col className="col-6 px-5 border-left">
          <Gap height={30} />
          <RowItem label={<CustomLabel name="Username" required />} value={(
            <TritronikInputText small name="username" placeholder="Username" />
          )} />
          <Gap height={20} />
          <RowItem label={<CustomLabel name="Password" required />} value={(
            <TritronikInputPassword small name="password" placeholder="Password" />
          )} />
          <Gap height={20} />
          <RowItem label={<CustomLabel name="Confirm Password" required />} value={(
            <TritronikInputPassword small name="confirmPassword" placeholder="Confirm Password" />
          )} />
          <Gap height={20} />
          <RowItem label="Group" value={(
            <TritronikMultiSelect name="groupIds" placeholder="Group" options={groupOptions} />
          )} />
        </Col>
      </Row>
    </div>
  )
}

export default FormProfile;
