import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { useGetOpenAlertSummaryQuery } from "../service/dashboardApi";
import { history, momentLastUpdate, unknown } from "utils";
// import { StatusBadge } from "components";

const OpenAlert = () => {
  const { data, isLoading, isFetching } = useGetOpenAlertSummaryQuery({
    state: 'ALERTING'
  });

  const columns = useMemo(() => {
    const renderLink = (data) => {
      if (!data) return unknown;

      const onClick = (e) => {
        e.preventDefault();
        history.push({ pathname: "/admin/alerts", search: `?name=${data}&lastState=Open` });
      };

      return (
        <a className="text-sm text-default" href="#device" onClick={onClick}>
          {data}
        </a>
      );
    };

    return [
      {
        id: 'type',
        name: "ALERT TYPE",
        selector: (row) => row?.type,
        sortable: true,
        sortField: "type",
        cell: ({ type }) => renderLink(type),
        grow: 2
      },
      {
        id: 'openAlertCount',
        name: "ALERT COUNT",
        selector: (row) => row?.openAlertCount,
        sortable: true,
        sortField: "openAlertCount",
        center: true,
      },
      // {
      //   id: 'severity',
      //   name: "SEVERITY",
      //   selector: (row) => row?.severity,
      //   sortable: true,
      //   sortField: "severity",
      //   cell: ({ severity }) => (
      //     <StatusBadge
      //       label={severity}
      //       type={
      //         severity === "MINOR"
      //           ? "success"
      //           : severity === "MAJOR"
      //           ? "warning"
      //           : "danger"
      //       }
      //       isUppercase
      //     />
      //   ),
      // },
      {
        id: 'lastUpdated',
        name: "DATE TIME",
        selector: (row) => row?.lastUpdated,
        sortable: true,
        sortField: "lastUpdated",
        cell: ({ lastUpdated }) => momentLastUpdate(lastUpdated),
      },
    ];
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <h3 className="text-muted card-title">OPEN ALERT</h3>
        </CardTitle>
        <DataTable
          data={data}
          columns={columns}
          keyField="type"
          defaultSortAsc={false}
          defaultSortFieldId="lastUpdated"
          noHeader
          highlightOnHover
          pagination
          paginationTotalRows={data ? data.length : 0}
          progressPending={isLoading || isFetching}
          progressComponent={<Spinner />}
        />
      </CardBody>
    </Card>
  );
};

export default OpenAlert;
