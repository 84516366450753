import { defaultUserProfile } from "assets";
import classnames from "classnames";
import _ from 'lodash';
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import AboutPageModal from "./../../../features/about/AboutPageModal";
import { useAuth } from './../../../features/auth/hooks';

const AdminNavbar = (props) => {
  const [aboutPageVisible, setAboutPageVisible] = useState(false);

  const { user, logout } = useAuth();

  const onAboutPageClick = (e) => {
    e.preventDefault();
    setAboutPageVisible(true);
  }

  const onAboutPageHide = () => {
    setAboutPageVisible(false);
  }

  const onLoadImageError = (event) => {
    event.target.src = defaultUserProfile;
    event.onerror = null
  }

  const avatar = useMemo(() => user?.userProfilePicture?.thumbnailLink || defaultUserProfile, [user]);

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand",
          { "navbar-dark bg-default": props.theme === "dark" },
          { "navbar-light bg-secondary": props.theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: props.sidenavOpen },
                    { "sidenav-toggler-dark": props.theme === "dark" }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="mdi mdi-help-circle-outline" style={{ fontSize: 20 }} />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    href="#pablo"
                    onClick={onAboutPageClick}
                  >
                    <i className="mdi mdi-help-circle" style={{ fontSize: 20 }} />
                    <span>About</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt={(user && user.name) || '...'} src={avatar} onError={onLoadImageError} />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {user && (user.name || _.upperFirst(user.username))}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="mdi mdi-account" style={{ fontSize: 20 }} />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <i className="mdi mdi-logout" style={{ fontSize: 20 }} />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <AboutPageModal isVisible={aboutPageVisible} onHide={onAboutPageHide} />
    </>
  );
};
AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
