import { createAsyncThunk, createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import networkApi from './services/networkApi';

export const fetchNetworkMemberThunk = createAsyncThunk(
	'network/fetchNetworkMembers',
	async (networkId) => {
		const response = await networkApi.getNetworkMembers(networkId);
		const memberIds = Object.keys(response);
		const promiseArray = memberIds.map((memberId) => networkApi.getNetworkMemberDetail(networkId, memberId));

		const getPeerDetail = async (memberId) => {
			try {
				const peer = await networkApi.getPeerDetail(memberId);
				return {
					memberId,
					peer
				}
			} catch (error) {
				return {
					memberId,
					peer: null
				};
			}
		}
		const members = await Promise.all(promiseArray)
			.then(async (values) => {
				const peerDetail = {};
				const getPeerDetails = values.map((member) => getPeerDetail(member.id));
				for await (const networkMember of getPeerDetails) {
					const { memberId, peer } = networkMember;
					peerDetail[memberId] = peer;
				}

				return values.map((member) => {
					return {
						...member,
						peer: peerDetail[member.id]
					}
				})
			})
		return members;
	}
)

export const updateMemberThunk = createAsyncThunk(
	'network/updateMember',
	async ({ nwid, id, ...data }, { dispatch }) => {
		const member = await networkApi.updateMember(nwid, id, data);

		dispatch(updateMember(member));
		return member;
	}
)


export const deleteMemberThunk = createAsyncThunk(
	'network/deleteMember',
	async ({ nwid, id }, { dispatch }) => {
		await networkApi.deleteMember(nwid, id);
		dispatch(removeMember(id));
	}
)

const networkMemberAdapter = createEntityAdapter();

export const networkMemberSlice = createSlice({
	name: 'networkMember',
	initialState: networkMemberAdapter.getInitialState({
		isLoading: false,
		error: null
	}),
	reducers: {
		addMember: networkMemberAdapter.addOne,
		removeMember: networkMemberAdapter.removeOne,
		updateMember: networkMemberAdapter.upsertOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNetworkMemberThunk.pending, (state) => {
				state.isLoading = true
			})
			.addCase(fetchNetworkMemberThunk.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				networkMemberAdapter.upsertMany(state, payload);
			})
			.addCase(fetchNetworkMemberThunk.rejected, (state, { payload }) => {
				state.isLoading = false
				state.error = payload
			})
	}
});

export default networkMemberSlice.reducer;

export const selectNetworkMemberState = (state) => state.networkMember;

export const {
	selectAll: selectMembers,
	selectById: selectMemberById,
} = networkMemberAdapter.getSelectors(state => state.networkMember);

export const { addMember, updateMember, removeMember } = networkMemberSlice.actions;

export const selectMembersByNetworkId = createSelector([
		selectMembers,
		(_, networkId) => networkId
	],
	(members, networkId) => {
		return members.filter(member => member.nwid === networkId)
	}
)