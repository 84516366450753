import { ButtonLinkIcon, TritronikCheckbox } from "components";
import React, { useMemo, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { Formik, Form, FieldArray } from "formik";
import { useUpdateRoleFeatures } from "../hooks/useUpdateRoleFeatures";
import { Can } from "config";
import { UPDATE_ROLE } from "config";

const RoleDetailExpandable = ({ data, featuresGroup = [] }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { id, features } = data;
  const { handleUpdate, isLoading: isUpdating } = useUpdateRoleFeatures();

  const toggleEdit = () => {
    setIsEditing((current) => !current);
  };

  const onSubmit = async (values) => {
    // transform form object into designated request
    const roleId = values.id;
    const featureIds = [];

    values.featuresGroup.forEach(({ features }) => {
      if (features && features.length > 0) {
        features
          .filter((v) => v.checked) // only checked features that will be send
          .forEach(({ id }) => {
            featureIds.push(id);
          });
      }
    });

    await handleUpdate(roleId, featureIds);
  };

  const initialValues = useMemo(() => {
    const featureIds = features.map((feat) => feat.id);

    const modifiedFeaturesGroup = featuresGroup.map((group) => {
      return {
        category: group.category,
        features: group.features.map((feature) => {
          return {
            ...feature,
            checked: featureIds.includes(feature.id),
          };
        }),
      };
    });

    return {
      id,
      featuresGroup: modifiedFeaturesGroup,
    };
  }, [id, features, featuresGroup]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, isValid, dirty, handleSubmit, resetForm }) => (
        <Form onSubmit={handleSubmit}>
          <div className="px-3 py-2">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h3>Privileges</h3>
              <div className="d-flex align-items-center justify-contend-end">
                {!isEditing && (
                  <>
                    <Can I="do" this={UPDATE_ROLE}>
                      {() => (
                        <ButtonLinkIcon
                          label="Edit"
                          icon="fa-edit"
                          onClick={toggleEdit}
                        />
                      )}
                    </Can>
                  </>
                )}
                {isEditing && (
                  <>
                    <Button
                      className="btn btn-outline-default btn-sm ml-auto"
                      onClick={() => {
                        resetForm();
                        toggleEdit();
                      }}
                    >
                      Cancel
                    </Button>
                    <Can I="do" this={UPDATE_ROLE}>
                      {() => (
                        <Button
                          className="btn btn-default btn-sm"
                          type="submit"
                          disabled={isUpdating || !dirty || !isValid}
                        >
                          Save
                          {isUpdating && (
                            <Spinner className="ml-2" color="light" size="sm" />
                          )}
                        </Button>
                      )}
                    </Can>
                  </>
                )}
              </div>
            </div>
            <div>
              <Row>
                <FieldArray
                  name="featuresGroup"
                  render={() => (
                    <>
                      {values && values.featuresGroup.length > 0
                        ? values.featuresGroup.map(
                            ({ category, features: featureList }, index) => (
                              <Col
                                key={`role-category-${index}`}
                                sm="12"
                                md="4"
                                lg="3"
                                className="mb-2"
                              >
                                <div className="mb-2">
                                  <h4>{category}</h4>
                                  <div
                                    style={{ height: 200, overflowY: "scroll" }}
                                    className="border p-2 rounded"
                                  >
                                    <FieldArray
                                      name={`featuresGroup.${index}.features`}
                                      render={() => (
                                        <>
                                          {featureList && featureList.length > 0
                                            ? featureList.map(
                                                (
                                                  { name, id, checked },
                                                  idx
                                                ) => (
                                                  <div
                                                    key={`role-id-${id}`}
                                                    className="text-sm"
                                                  >
                                                    <TritronikCheckbox
                                                      name={`featuresGroup.${index}.features.${idx}.checked`}
                                                      label={name}
                                                      checked={checked}
                                                      disabled={!isEditing}
                                                    />
                                                  </div>
                                                )
                                              )
                                            : null}
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </Col>
                            )
                          )
                        : null}
                    </>
                  )}
                />
              </Row>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              {isEditing && (
                <>
                  <Button
                    className="btn btn-outline-default btn-sm ml-auto"
                    onClick={() => {
                      resetForm();
                      toggleEdit();
                    }}
                  >
                    Cancel
                  </Button>
                  <Can I="do" this={UPDATE_ROLE}>
                    {() => (
                      <Button
                        className="btn btn-default btn-sm"
                        type="submit"
                        disabled={isUpdating || !dirty || !isValid}
                      >
                        Save
                        {isUpdating && (
                          <Spinner className="ml-2" color="light" size="sm" />
                        )}
                      </Button>
                    )}
                  </Can>
                </>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RoleDetailExpandable;
