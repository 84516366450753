import Swal from 'sweetalert2'

export const showAlert = (text = '', title = 'Info', icon = 'info') => Swal.fire({
  title,
  text,
  icon,
});

export const showDialog = ({title = 'Are you sure?', text = "You won't be able to revert this!", icon = 'warning', confirmButtonText = 'Yes', ...rest}) => Swal.fire({
  title,
  text,
  icon,
  showCancelButton: true,
  confirmButtonColor: '#2065AC',
  cancelButtonColor: '#d33',
  confirmButtonText,
  reverseButtons: true,
  ...rest
});

export const confirmAction = ({
  icon = "question",
  title = "Are you sure want to do this?",
  text = 'You won\'t be able to revert this!',
  confirmButtonText = 'Yes',
  ...restOptions
}) => {
  return Swal.fire({
    icon: icon,
    title: title,
    text: text,
    showLoaderOnConfirm: true,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    reverseButtons: true,
    ...restOptions
  });
}

export default Swal;