import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import './PrimeMultiSelect.scss';

const PrimeMultiSelect = (props) => {
  return (
    <MultiSelect {...props} />
  )
}

export default PrimeMultiSelect
