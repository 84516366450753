import { useMemo } from 'react';
import { useGetFeaturesQuery } from './../features/user/service/featureApi';

export function useFeatures() {
  const { data, isLoading } = useGetFeaturesQuery();

  const options = useMemo(() => {
    const opts = [];

    if (data && data.content && data.content.length > 0) {
      data.content.forEach((opt) => {
        opts.push({ value: opt.id, label: opt.name });
      });
    }

    return opts;
  }, [data]);

  return {
    data,
    isLoading,
    options,
  };
}