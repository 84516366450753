import React, { useState, useCallback } from "react";
import { PageContent } from "components";
import { Card, CardBody, Row, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "utils/datatableStyles";
import {
  useGetPolicyQuery,
  useDeletePolicyMutation,
} from "../service/policyApi";
import { ButtonLinkIcon } from "components";
import AddPolicyModal from "../components/AddPolicyModal";
import PolicyActionButton from "../components/PolicyActionButton";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import EditPolicyModal from "../components/EditPolicyModal";
import { Can } from "config";
import { CREATE_BANDWIDTH_POLICY } from "config";
import { useCan } from "hooks/useCan";
import { UPDATE_BANDWIDTH_POLICY } from "config";
import { DELETE_BANDWIDTH_POLICY } from "config";

const PolicyList = () => {
  const [title] = useState("Bandwidth Policy");
  const [modalCreate, setModalCreate] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [modalEdit, setModalEdit] = useState(false);

  const { data: policy, isLoading } = useGetPolicyQuery();
  const [deletePolicy] = useDeletePolicyMutation();
  const can = useCan();

  const canUpdate = can(UPDATE_BANDWIDTH_POLICY);
  const canDelete = can(DELETE_BANDWIDTH_POLICY);

  const onAddPolicyClick = () => {
    setModalCreate(true);
  };

  const toggleModalCreate = () => {
    setModalCreate(!modalCreate);
  };

  const toggleModalEdit = () => {
    setModalEdit(!modalEdit);
  };

  const onDeletePolicy = useCallback(
    (policy) => {
      Swal.fire({
        title: "Are you sure want to delete this?",
        text: `Policy: ${policy.name}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          return deletePolicy(policy.id)
            .unwrap()
            .then(() => {
              toast.success("Policy deleted.");
              return true;
            });
        },
      });
    },
    [deletePolicy]
  );

  const onEditPolicy = useCallback(
    (policy) => {
      setSelectedPolicy(policy);
      setModalEdit(true);
    },
    [setModalEdit, setSelectedPolicy]
  );

  const columns = [
    // {
    //   name: "ID",
    //   center: true,
    //   hide: "md",
    //   selector: (row) => row?.id,
    // },
    {
      name: "NAME",
      center: true,
      hide: "md",
      selector: (row) => row?.name,
    },
    {
      name: "CIR",
      center: true,
      hide: "md",
      selector: (row) => row?.cir,
    },
    {
      name: "CBS",
      center: true,
      hide: "md",
      selector: (row) => row?.cbs,
    },
    {
      name: "PIR",
      center: true,
      hide: "md",
      selector: (row) => row?.pir,
    },
    {
      name: "PBS",
      center: true,
      hide: "md",
      selector: (row) => row?.pbs,
    },
    {
      name: "",
      button: true,
      allowOverflow: true,
      hide: "md",
      omit: !canUpdate && !canDelete,
      cell: (row) => (
        <PolicyActionButton
          row={row}
          onDelete={onDeletePolicy}
          onEdit={onEditPolicy}
        />
      ),
    },
  ];

  return (
    <PageContent title="Bandwidth Policy">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <h2 className="text-dark">{title}</h2>
            </Col>
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-lg-end align-items-center"
            >
              <Can I="do" this={CREATE_BANDWIDTH_POLICY}>
                {() => (
                  <ButtonLinkIcon label="Add New" onClick={onAddPolicyClick} />
                )}
              </Can>
            </Col>
          </Row>
          <DataTable
            title={title}
            columns={columns}
            data={policy?.content}
            striped
            noHeader
            highlightOnHover
            pagination
            persistTableHead
            customStyles={customStyles}
            progressPending={isLoading}
          />
        </CardBody>
      </Card>
      <AddPolicyModal isOpen={modalCreate} toggle={toggleModalCreate} />
      <EditPolicyModal
        policy={selectedPolicy}
        isOpen={modalEdit}
        toggle={toggleModalEdit}
      />
    </PageContent>
  );
};

export default PolicyList;
