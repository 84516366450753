import { useMemo } from "react";
import { useSelector } from "react-redux";
import { deviceApi } from "../service/deviceApi";

export function useUserIMSI() {
  const { username } = useSelector(state => state.auth);

  const { data } = deviceApi.endpoints.getUserImsis.useQueryState(username);

  const imsis = useMemo(() => {
    if (!data) return undefined;

    return data && data.join(',');
  }, [data]);

  return imsis;
}