import React from 'react';
import { Form, Formik, FieldArray } from 'formik';
import { ButtonLinkIcon, CustomLabel, FormikToggleSwitch, RowItem, TritronikInputText } from './../../../components';
import { Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { addNetworkValidationSchema } from '../validationSchema';

const FormNetwork = ({
	network,
	onSubmit,
	isLoading,
	onCancel,
	submitLabel
}) => {

	const initialValues = {
		id: network?.id,
		name: network?.name || '',
		private: network?.private || false,
		ipAssignmentPools: network?.ipAssignmentPools || [{ ipRangeStart: '', ipRangeEnd: '' }],
		routes: network?.routes || [{ target: '', via: '' }],
	}

	const _handleSubmit = (values) => {
		if (typeof onSubmit === 'function') {
			onSubmit(values);
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={_handleSubmit}
			validationSchema={addNetworkValidationSchema}
		>
			{({ values, handleSubmit, setFieldValue }) => (
				<Form onSubmit={handleSubmit}>
					<div className='row'>
						<div className='col'>
							<RowItem
								rightCol="col-12 col-md-6"
								label={<CustomLabel name="Network Name" required />}
								value={
									<TritronikInputText
										small
										name="name"
										placeholder="Network Name"
									/>
								}
							/>
						</div>
						<div className='col'>
							<RowItem
								rightCol="col-12 col-md-6"
								label={<CustomLabel name="Access Control" />}
								value={
									<div className='d-flex justify-content-end align-items-center'>
										<FormikToggleSwitch
											label='Private'
											name='private'
											checked={values.private}
											onChange={(e) => {
												setFieldValue('private', e.target.checked)
											}}
										/>
										<span className='ml-2'>Private</span>
									</div>
								}
							/>
						</div>
					</div>
					<hr className='my-2' />
					<FieldArray name='ipAssignmentPools'>
						{(arrHelper) => (
							<>
								<div className='d-flex justify-content-end align-items-center'>
									<ButtonLinkIcon label='Add IP Assignment Pool' onClick={() => {
										arrHelper.push({
											ipRangeStart: '',
											ipRangeEnd: ''
										})
									}} />
								</div>
								{values.ipAssignmentPools && values.ipAssignmentPools.map((ipAssigned, idxIp) => (
									<div key={`ipAssignmentPools-${idxIp}`} className='row'>
										<div className='col'>
											<RowItem
												rightCol="col-12 col-lg-6"
												label={<CustomLabel name="IP Range Start" required />}
												value={
													<TritronikInputText
														small
														name={`ipAssignmentPools.${idxIp}.ipRangeStart`}
														placeholder="IP Range Start"
													/>
												}
											/>
										</div>
										<div className='col'>
											<RowItem
												rightCol="col-12 col-lg-6"
												label={<CustomLabel name="IP Range End" required />}
												value={
													<TritronikInputText
														small
														name={`ipAssignmentPools.${idxIp}.ipRangeEnd`}
														placeholder="IP Range End"
													/>
												}
											/>
										</div>
										<div className='col-1 d-flex justify-content-end align-items-center'>
											<Button className="btn btn-danger btn-sm" type="button" onClick={() => arrHelper.remove(idxIp)}>
												<i className="fa fa-trash"></i>
											</Button>
										</div>
									</div>
								))}
							</>
						)}
					</FieldArray>
					<hr className='my-2' />
					<FieldArray name='routes'>
						{(arrHelper) => (
							<>
								<div className='d-flex justify-content-end align-items-center'>
									<ButtonLinkIcon label='Add Route' onClick={() => {
										arrHelper.push({
											target: '',
											via: ''
										})
									}} />
								</div>
								{values.routes && values.routes.map((route, idxRoute) => (
									<div key={`routes-${idxRoute}`} className='row'>
										<div className='col'>
											<RowItem
												rightCol="col-12 col-lg-6"
												label={<CustomLabel name="Target" required />}
												value={
													<TritronikInputText
														small
														name={`routes.${idxRoute}.target`}
														placeholder="Target"
													/>
												}
											/>
										</div>
										<div className='col'>
											<RowItem
												rightCol="col-12 col-lg-6"
												label={<CustomLabel name="Gateway" required />}
												value={
													<TritronikInputText
														small
														name={`routes.${idxRoute}.via`}
														placeholder="Gateway"
													/>
												}
											/>
										</div>
										<div className='col-1 d-flex justify-content-end align-items-center'>
											<Button className="btn btn-danger btn-sm" type="button" onClick={() => arrHelper.remove(idxRoute)}>
												<i className="fa fa-trash"></i>
											</Button>
										</div>
									</div>
								))}
							</>
						)}
					</FieldArray>
					<div className="d-flex mt-5 justify-content-end">
						<Button type="button" onClick={onCancel} className="btn btn-outline-default px-5 py-1">
							Cancel
						</Button>
						<Button className="btn btn-default px-5 py-1" type="submit" disabled={isLoading}>
							{submitLabel || 'Create'}
							{isLoading && <Spinner className="ml-2" color="light" size="sm" />}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}


FormNetwork.propTypes = {
	network: PropTypes.object,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	isLoading: PropTypes.bool,
	submitLabel: PropTypes.string,
}
export default FormNetwork;