import React, { useState, useCallback } from "react";
import { PageContent, ButtonLinkIcon } from "components";
import { Card, CardBody, Row, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "utils/datatableStyles";
import {
  useDeleteByIdMutation,
  useGetPolicyPackageQuery,
} from "../service/packagePolicyApi";
import PackagePolicyActionButton from "../components/PackagePolicyActionButton";
import EditPackagePolicyModal from "../components/EditPackagePolicyModal";
import AddPackagePolicyModal from "../components/AddPackagePolicyModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useCan } from "hooks/useCan";
import { UPDATE_PACKAGE_POLICY } from "config";
import { DELETE_PACKAGE_POLICY } from "config";
import { Can } from "config";
import { CREATE_PACKAGE_POLICY } from "config";

const PackagePolicyList = () => {
  const [title] = useState("Package Policy");
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const { data: policyPackage, isLoading } = useGetPolicyPackageQuery();
  const [deleteById] = useDeleteByIdMutation();
  const can = useCan();

  const canUpdate = can(UPDATE_PACKAGE_POLICY);
  const canDelete = can(DELETE_PACKAGE_POLICY);

  const toggleModalEdit = () => {
    setModalEdit(!modalEdit);
  };

  const toggleModalCreate = () => {
    setModalCreate(!modalCreate);
  };

  const onAddPackagePolicyClick = () => {
    setModalCreate(true);
  };

  const onEditPolicy = useCallback(
    (policyPackage) => {
      setSelectedPolicy(policyPackage);
      setModalEdit(true);
    },
    [setModalEdit, setSelectedPolicy]
  );

  const onDeletePackagePolicy = useCallback(
    (policy) => {
      Swal.fire({
        title: "Are you sure want to delete this?",
        text: `Policy: ${policy.packageName}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          return deleteById(policy.id)
            .unwrap()
            .then(() => {
              toast.success("Package Policy deleted.");
              return true;
            });
        },
      });
    },
    [deleteById]
  );

  const columns = [
    {
      name: "NAME",
      center: true,
      hide: "md",
      selector: (row) => row?.packageName,
    },
    {
      name: "UPSTREAM",
      center: true,
      hide: "md",
      selector: (row) => row.policyUpstream?.name,
    },
    {
      name: "DOWNSTREAM",
      center: true,
      hide: "md",
      selector: (row) => row.policyDownstream?.name,
    },
    {
      name: "",
      button: true,
      allowOverflow: true,
      hide: "md",
      omit: !canUpdate && !canDelete,
      cell: (row) => (
        <PackagePolicyActionButton
          row={row}
          onDelete={onDeletePackagePolicy}
          onEdit={onEditPolicy}
        />
      ),
    },
  ];

  return (
    <PageContent title="Package Policy">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <h2 className="text-dark">{title}</h2>
            </Col>
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-lg-end align-items-center"
            >
              <Can I="do" this={CREATE_PACKAGE_POLICY}>
                {() => (
                  <ButtonLinkIcon
                    label="Add New"
                    onClick={onAddPackagePolicyClick}
                  />
                )}
              </Can>
            </Col>
          </Row>
          <DataTable
            title={title}
            columns={columns}
            data={policyPackage?.content}
            striped
            noHeader
            highlightOnHover
            pagination
            persistTableHead
            customStyles={customStyles}
            progressPending={isLoading}
          />
        </CardBody>
      </Card>
      <AddPackagePolicyModal isOpen={modalCreate} toggle={toggleModalCreate} />
      <EditPackagePolicyModal
        policy={selectedPolicy}
        isOpen={modalEdit}
        toggle={toggleModalEdit}
      />
    </PageContent>
  );
};

export default PackagePolicyList;
