import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './../../../services/baseApi';

export const generateTokenApi = createApi({
  reducerPath: 'generateTokenApi',
  baseQuery: baseQuery,
  tagTypes: ['Token'],
  endpoints: (builder) => ({
    getToken: builder.query({
      query: (params) => ({
        url: '/api-user/generate',
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: 'Token', id })),
              { type: 'Token', id: 'LIST' },
            ]
          : [{ type: 'Token', id: 'LIST' }],
    }),
    createToken: builder.mutation({
      query: (params) => ({
        url: '/api-user/generate',
        method: 'POST',
        body: {},
        params,
      }),
    }),
  }),
});
export const { useGetTokenQuery, useCreateTokenMutation } = generateTokenApi;
