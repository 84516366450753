import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './../../../features/auth/hooks';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useAuth();
  return (
    <Route {...rest} render={(props) => (
      token 
        ? <Component {...rest} {...props} /> 
        : <Redirect to="/auth/login" />
    )} />
  )
}

export default PrivateRoute;