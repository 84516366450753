import React, { useState, useRef } from 'react'
import { useExports } from './../hooks/useExports';
import { CSVLink } from "react-csv";
import classnames from 'classnames';

const DownloadCSVButton = ({ params }) => {
    const { fetchData, isLoading, fileNameCSV, headers } = useExports();
    const [csvData, setCsvData] = useState([]);
    const excelRef = useRef(null);

    const getDeviceData = () => {
        fetchData(params).then((data) => {
            setCsvData(data);
        }).then(() => {
            if (excelRef.current) {
                excelRef.current.link.click();
            }
        });
    }

    return (
        <>
            <button className="font-weight-normal btn btn-link text-default btn-md p-2" onClick={getDeviceData} title="Export to CSV">
                <i className={classnames({ "fa fa-sync fa-spin": isLoading, "fa fa-file-csv": !isLoading })}></i>
            </button>
            <CSVLink
                ref={excelRef}
                data={csvData}
                headers={headers}
                filename={`${fileNameCSV}.csv`}
                className="d-none">
                Export CSV
            </CSVLink>
        </>
    )
}

export default DownloadCSVButton;