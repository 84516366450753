import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";

import { Can, DELETE_ROLE } from "../../../config";

const RoleListAction = ({ row, onDelete }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const handleOnDelete = () => {
    if (typeof onDelete === "function") {
      onDelete(row);
    }
  };

  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={menuOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <i className="fas fa-ellipsis-v text-default" />
        </DropdownToggle>
        <DropdownMenu right>
          <Can I="do" this={DELETE_ROLE}>
            {() => (
              <DropdownItem onClick={handleOnDelete}>
                <span className="text-danger">
                  <i className="fa fa-trash text-danger mr-2"></i> Delete
                </span>
              </DropdownItem>
            )}
          </Can>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default RoleListAction;
