import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TogglePasswordLabel = ({ value }) => {
  const [show, setShow] = useState(false);
  return (
    <span>
      {show ? value : "********"}
      <span
        className={classnames("btn btn-sm btn-link ml-2", {
          "fa fa-eye": show,
          "fa fa-eye-slash": !show,
        })}
        onClick={() => setShow(!show)}
      ></span>
    </span>
  );
};

TogglePasswordLabel.propTypes = {
  value: PropTypes.string.isRequired,
};

export default TogglePasswordLabel;
