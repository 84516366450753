import React, { useState } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ButtonLinkIcon, NavItemContent } from '../../../components';
import NetworkOverview from './NetworkOverview';
import MemberTabView from './MemberTabView';
import PropTypes from 'prop-types';

const NetworkTableDetail = ({ data, onEdit }) => {
	const [activeTab, setActiveTab] = useState('1');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	return (
		<div>
			<Nav tabs className="row">
				<NavItem className="col">
					<NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>
						<NavItemContent title="Overview" icon="fa fa-info-circle" active={activeTab === '1'} />
					</NavLink>
				</NavItem>
				<NavItem className="col">
					<NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>
						<NavItemContent title="Member" icon="fa fa-sliders-h" active={activeTab === '2'} />
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<div className='my-5'>
						<div className='d-flex justify-content-end align-items-center'>
							<ButtonLinkIcon label='Edit' icon='fa-edit' onClick={() => onEdit(data)} />
						</div>
						<NetworkOverview network={data} />
					</div>
				</TabPane>
				<TabPane tabId="2">
					<div className='my-5'>
						<MemberTabView network={data} />
					</div>
				</TabPane>
			</TabContent>
		</div>
	)
}

NetworkTableDetail.propTypes = {
	data: PropTypes.any,
	onEdit: PropTypes.func,
}
export default NetworkTableDetail;