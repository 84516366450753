import { StatusBadge } from 'components';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { unknown } from 'utils';
import { RowItem } from '../../../../components';

export function AlertOverview({alert}) {
  const {
    type,
    name,
    description,
    threshold,
    channelInCollection,
    status,
    severity,
    additionalRecipients,
    allRegion,
    idsOfRegion,
    allArea,
    idsOfArea,
    idsOfCustomer,
    regions,
    areas,
    hasThreshold,
    thresholdUnit,
    hasTimeRange,
    timeRange,
    customers,
  } = alert;
  const applyByRegion = allRegion || idsOfRegion.length > 0;
  const applyByArea = allArea || idsOfArea.length > 0;
  const applyByCustomer = idsOfCustomer.length > 0;
  return (
    <Row className="mt-4 mb-5">
      <Col className="px-5 border-right">
        <RowItem stripped label="Alert Type" value={type || unknown} valueRight />
        <RowItem label="Alert Name" value={name || unknown} valueRight />
        <RowItem stripped label="Description" value={description || unknown} valueRight />
        {hasThreshold && (
          <RowItem label="Threshold" value={(threshold || unknown) + ` ${thresholdUnit}`} />
        )}
        {hasTimeRange && (
          <RowItem stripped label="Time Range" value={(timeRange || unknown)} />
        )}
        <RowItem stripped={hasThreshold && !hasTimeRange} label="Severity" value={<StatusBadge label={severity} type={severity === 'MINOR' ? 'success' : (severity === 'MAJOR' ? 'warning' : 'danger')} isUppercase />} />
        <RowItem stripped={!hasThreshold || hasTimeRange} label="Status" value={<StatusBadge label={status || unknown} type={status === 'Activated' ? 'success' : (status === 'Deactivated' ? 'danger' : 'none')} />} />
      </Col>
      <Col className="px-5 border-left">
        <RowItem stripped label="Apply To" value={applyByRegion ? 'By Region' : applyByArea ? 'By Area' : applyByCustomer ? 'By Customer' : '' } />
        {applyByRegion && (
          <RowItem valueRight label="By Region" value={allRegion ? 'All Region' : regions && regions.length > 0 ? regions.map(r => r.name).join(', ') : '-'} />
        )}
        {applyByArea && (
          <RowItem valueRight label="By Area" value={allArea ? 'All Area' : areas && areas.length > 0 ? areas.map(r => r.name).join(', ') : '-' } />
        )}
        {applyByCustomer && (
          <RowItem valueRight label="By Customer" value={customers && customers.length > 0 ? customers.map(r => r.name).join(', ') : '-' } />
        )}
        
        <RowItem valueRight stripped label="Alert Channel" value={channelInCollection.join(', ')} />
        <RowItem valueRight label="Additional Recipients" value={(additionalRecipients && additionalRecipients.length > 0) ? additionalRecipients.join(', ') : '-'} />
      </Col>
    </Row>
  )
}
