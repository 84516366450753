import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import networkApi from './services/networkApi';

export const fetchNetworksThunk = createAsyncThunk(
	'network/fetchNetworks',
	async () => {
		const response = await networkApi.getNetworks();
		if (response.status !== 200) {
			throw new Error('Failed to fetch networks.');
		}

		const networkIds = await response.json();
		const promiseArray = networkIds.map((networkId) => networkApi.getNetworkDetail(networkId).then((res) => res.json()));


		const getNetworkMember = async (networkId) => {
			try {
				const members = await networkApi.getNetworkMembers(networkId);
				return {
					networkId,
					members
				}
			} catch (error) {
				return {
					[networkId]: null
				};
			}
		}
		const networks = await Promise.all(promiseArray)
			.then(async (values) => {
				const networkMembers = {};
				const getNetworkMembers = values.map((network) => getNetworkMember(network.id));
				for await (const networkMember of getNetworkMembers) {
					const { networkId, members } = networkMember;
					networkMembers[networkId] = members;
				}

				return values.map((network) => {
					return {
						...network,
						members: networkMembers[network.id]
					}
				})
			})
		return networks;
	}
)

export const createNetworkThunk = createAsyncThunk(
	'network/createNetwork',
	async (args) => {
		const network = await networkApi.createNetwork(args);
		return network;
	}
)
export const updateNetworkThunk = createAsyncThunk(
	'network/updateNetwork',
	async ({ id, ...data }, { dispatch }) => {
		const network = await networkApi.updateNetwork(id, data);

		dispatch(updateNetwork(network));
		return network;
	}
)

export const deleteNetworkThunk = createAsyncThunk(
	'network/deleteNetwork',
	async (id, { dispatch }) => {
		await networkApi.deleteNetwork(id);
		dispatch(removeNetwork(id));
	}
)

const networkAdapter = createEntityAdapter();

export const networkSlice = createSlice({
	name: 'network',
	initialState: networkAdapter.getInitialState({
		isLoading: false,
		error: null
	}),
	reducers: {
		addNetwork: networkAdapter.addOne,
		updateNetwork: networkAdapter.upsertOne,
		removeNetwork: networkAdapter.removeOne
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNetworksThunk.pending, (state) => {
				state.isLoading = true
			})
			.addCase(fetchNetworksThunk.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				networkAdapter.setAll(state, payload);
			})
			.addCase(fetchNetworksThunk.rejected, (state, { payload }) => {
				state.isLoading = false
				state.error = payload
			})
			.addCase(createNetworkThunk.pending, (state) => {
				state.isLoading = true
				state.error = null
			})
			.addCase(createNetworkThunk.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.error = null
				networkAdapter.addOne(state, payload);
			})
			.addCase(createNetworkThunk.rejected, (state, { payload }) => {
				state.isLoading = false
				state.error = payload
			})

	}
});

export default networkSlice.reducer;

export const selectNetworkState = (state) => state.network;

export const { addNetwork, updateNetwork, removeNetwork } = networkSlice.actions;

export const {
	selectAll: selectNetworks,
	selectById: selecNetworkById,
} = networkAdapter.getSelectors(state => state.network);