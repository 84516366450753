import { useMemo } from 'react';
import { useGetSimcardGsmNumbersQuery } from './../services';

export function useSimcardGsmNumbers(params, allOptions = false) {
    const { data, isLoading } = useGetSimcardGsmNumbersQuery(params);

    const options = useMemo(() => {
        const opts = [];

        if (allOptions) {
            opts.push({ value: '', label: 'All' });
        }

        if (data && data.length > 0) {
            data.forEach(d => {
                if (d !== '' && d !== null) {
                    opts.push({ value: d, label: d });
                }
            });
        }

        return opts;
    }, [data, allOptions]);

    return {
        options,
        isLoading
    }
}