import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const BatchActions = props => {
  if (props.selectedRows === 0) {
    return null;
  }

  return (
    <div className={classnames("row d-flex justify-content-between align-items-center", props.className)}>
      <div className="col-xs-12 col-md-6">
        {props.children}
      </div>
      <div className="col-xs-12 col-md-6 d-flex justify-content-end">
        <span>
          {`${props.selectedRows} ${props.selectedRows > 1 ? props.pluralName : props.singularName} selected.`}
        </span>
      </div>
    </div>
  )
}

BatchActions.propTypes = {
  show: PropTypes.bool,
  selectedRows: PropTypes.number,
  singularName: PropTypes.string,
  pluralName: PropTypes.string,
}

BatchActions.defaultProps = {
  show: false,
  selectedRows: 0,
  singularName: 'row',
  pluralName: 'rows'
}

export default BatchActions
