import { useDeleteRoleMutation } from "features/user/service/roleApi";
import { useCallback } from "react";
import { toast } from "react-toastify";
import Swal, { confirmAction } from "utils/swal";

export function useDeleteRole() {
  const [remove, result] = useDeleteRoleMutation();

  const handleDelete = useCallback(
    async (role) => {
      confirmAction({
        title: `Delete role ${role.name}?`,
        text: `Associated users will be revoked from this role.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        confirmButtonColor: "#d33",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          try {
            return remove(role.id)
              .unwrap()
              .then(() => {
                toast.success("Role deleted.");
                return true;
              });
          } catch (error) {
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    [remove]
  );

  return {
    remove,
    handleDelete,
    ...result,
  };
}
