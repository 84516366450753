import React from "react";
import classnames from "classnames";

const NavItemContent = ({ title, icon = "fas fa-puzzle-piece", active }) => (
  <div className="text-center">
    <div className="text-center">
      <i
        className={classnames(icon, {
          "text-primary": active,
          "text-gray": !active,
        })}
      ></i>
    </div>
    <span
      className={classnames({ "text-primary": active, "text-gray": !active })}
    >
      {title}
    </span>
  </div>
);
export default NavItemContent;
