import { Gap, RowItem, TritronikSelect } from "components";
import { useGetRouterPolicyListQuery } from "features/router/routerPolicy/service/routerPolicyApi";
import { useFormik } from "formik";
import React from "react";
import { Form, Col, Row, Button } from "reactstrap";
import Swal from "sweetalert2";
import { useUpdateRouterInterfaceUnitMutation } from "../service";

const RouterInterfaceUnitConfig = ({data}) => {
  const { data: routerPolicyList } = useGetRouterPolicyListQuery();
  const [updateRouterInterfaceUnit] = useUpdateRouterInterfaceUnitMutation()

  const routerInterfaceUnitId = data.id

  const formik = useFormik({
    initialValues: {
      policyDownstreamId: data.policyDownstreamId,
      policyUpstreamId: data.policyUpstreamId,
    },
    onSubmit: (value) => {
      const body = {
        adminStatus: data.adminStatus,
        id: data.id,
        linkStatus: data.linkStatus,
        logicalInterface: data.logicalInterface,
        logicalName: data.logicalName,
        proto: data.proto,
        routerInterfaceId: data.routerInterfaceId,
        policyDownstreamId: value.policyDownstreamId,
        policyUpstreamId: value.policyUpstreamId,
      };
      Swal.fire({
        text: "Are You Sure want to Apply Configuration?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
            updateRouterInterfaceUnit({ routerInterfaceUnitId, body })
              .unwrap()
              .then(() =>
                Swal.fire(
                  "Success!",
                  "Your configuration has been added.",
                  "success"
                )
              )
              .catch(() =>
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                })
              );
        }
      });
    },
  });

  const option = routerPolicyList?.map((policy) => {
    return { label: policy.policyName, value: policy.id}
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="mt-2">
          <Col className="border-right border-bottom px-5 pb-5" md="6">
            <h2 className="text-dark mt-4">Policy</h2>
            <Gap height={10} />
            <RowItem
              stripped
              label="Downstream"
              value={
                <TritronikSelect
                  value={formik.values.policyDownstreamId}
                  placeholder={"Select Policy"}
                  options={option}
                  name="policyDownstreamId"
                  onChange={formik.handleChange}
                />
              }
            />
            <RowItem
              label="Upstream"
              value={
                <TritronikSelect
                  value={formik.values.policyUpstreamId}
                  placeholder={"Select Policy"}
                  options={option}
                  name="policyUpstreamId"
                  onChange={formik.handleChange}
                />
              }
            />
            <Row className="d-flex justify-content-end px-3">
              {/* <Button
                outline
                color="primary"
                type="reset"
                onClick={formik.resetForm}
              >
                Clear
              </Button> */}
              <Button color="primary" type="submit" disabled={formik.values.policyDownstreamId === '' || formik.values.policyUpstreamId === ''}>
                Apply
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RouterInterfaceUnitConfig;
