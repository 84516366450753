import { useMemo } from "react";
import { usePagination } from "./../../../hooks/usePagination";
import { useAuth } from "./../../auth/hooks";
import { useGetAlertConfigurationsQuery } from './../service/alertConfigurationApi';


const defaultOptions = {
  pageNumber: 1,
  pageSize: 10,
  sortFields: undefined,
  sortOrder: undefined,
}

export const useAlertDatatable = (params = {}, options = defaultOptions) => {
  const { user } = useAuth();
  const { pageNumber, pageSize, sortFields, sortOrder, onChangePage, onChangeRowsPerPage, onSort } = usePagination({ pageNumber: options?.pageNumber, pageSize: options?.pageSize, sortFields: options?.sortFields, sortOrder: options?.sortOrder });

  const { data: paginatedResult, isLoading } = useGetAlertConfigurationsQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    username: user ? user.username : undefined,
    ...params,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
};
