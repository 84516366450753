import React, { useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap-daterangepicker/daterangepicker.css';

const dateRangeOptions = {
    ranges: {
        'Last 7 Days': [moment().subtract(6, 'day'), moment()],
        'Last 14 Days': [moment().subtract(13, 'day'), moment()],
    },
    "locale": {
        "format": "MM/DD/YYYY",
        "separator": " - ",
        "applyLabel": "Apply",
        "cancelLabel": "Cancel",
        "fromLabel": "From",
        "toLabel": "To",
        "customRangeLabel": "Custom",
        "weekLabel": "W",
        "daysOfWeek": [
            "Su",
            "Mo",
            "Tu",
            "We",
            "Th",
            "Fr",
            "Sa"
        ],
        "monthNames": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ],
        "firstDay": 1
    },
    "alwaysShowCalendars": true,
    "startDate": moment().subtract(6, 'day'),
    "endDate": moment(),
    "drops": "bottom",
    "cancelClass": "btn-secondary"
};

const BootstrapDateRangePicker2 = ({ onDateChange, alwaysShowCalendars = true }) => {
  const [label, setLabel] = useState('Last 7 Days');
  const [show, setShow] = useState(false);

  const onCallback = (start, end, label) => {
    setLabel(label);
    if (typeof onDateChange === 'function') {
      onDateChange(start, end, label);
    }
  }
  
  return (
    <DateRangePicker
      initialSettings={{...dateRangeOptions, alwaysShowCalendars: alwaysShowCalendars }}
      onShow={() => setShow(true)}
      onHide={() => setShow(false)}
      onCallback={onCallback}
    >
      <button className="btn btn-icon btn-white btn-block text-default" type="button">
        <span className="btn-inner--icon">
          <i className="far fa-clock" />
        </span>
        <span className="btn-inner--text">{label}</span>
        <span className="btn-inner--icon">
          <i className={`fa fa-chevron-${show ? 'up' : 'down'}`} />
        </span>
      </button>
    </DateRangePicker>
  )
}


export default BootstrapDateRangePicker2;