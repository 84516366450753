import classnames from "classnames";
import { Formik } from "formik";
import { useProvinces, useRegencies } from "./../../../../hooks";
import _ from "lodash";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Collapse, Form, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import {
  CustomLabel,
  ModalChangePassword,
  RowItem,
  StatusBadge,
  TritronikInputText,
  TritronikMultiSelect,
  TritronikSelect,
} from "./../../../../components";
import {
  useRemoveDeviceAreaMutation,
  useSetDeviceAreaMutation,
  useUpdateDeviceMutation,
  useUpdateDeviceSimCardsMutation,
} from "./../../service/deviceApi";
import SimCardOverview from "./SimCardOverview";
import { useArea } from "../../../area/hooks";
import { useRegion } from "../../../region/hooks";
import { unknown } from "utils";
import { momentLastUpdate } from "utils";
import ModalAddGateway from "./ModalAddGateway";
import { updateDeviceOverviewSchema } from "./../../validationSchema";

const DeviceConfigurationForm = ({
  device,
  toggleEditing,
  getLanPortStatus,
  groupOptions,
  customerOptions,
  deviceTypeOptions,
}) => {
  const {
    sn,
    type,
    macAddress,
    ipDevice,
    username,
    password,
    location,
    groupIds,
    area,
    customerId,
    lanPorts,
    simCards,
    circuitNumber,
    province,
    regency,
    region,
    gateways,
    uptimeDurationInHuman,
    updateModified,
    snHardware,
    arpIpAddresses,
  } = device;

  const [loading, setLoading] = useState(false);
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const [collapsible, setCollapsible] = useState({
    credentials: false,
    arpIpAddresses: false,
    lanPorts: false,
    gateways: true,
    ...gateways.reduce(
      (obj, item) =>
        Object.assign(obj, { [`gateway-${item.gatewayId}`]: true }),
      {}
    ),
  });

  const [provinceId, setProvinceId] = useState(undefined);
  const [showModalEditGateway, setShowModalEditGateway] = useState(false);

  const [selectedRegionId, setSelectedRegionId] = useState(
    region?.id || undefined
  );

  const { options: provinceOptions } = useProvinces();
  const { options: regencyOptions } = useRegencies({ provinceIds: provinceId });
  const { options: regionOptions } = useRegion({}, false);
  const { options: areaOptions } = useArea(
    { regionId: selectedRegionId },
    false
  );

  const [updateDevice] = useUpdateDeviceMutation();
  const [setDeviceToArea] = useSetDeviceAreaMutation();
  const [removeDeviceFromArea] = useRemoveDeviceAreaMutation();
  const [updateDeviceSimCards] = useUpdateDeviceSimCardsMutation();

  const onSubmit = (values) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to update this?",
      text: `Device: ${sn}, Type: ${type}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        setLoading(true);
        try {
          await updateDevice({
            id: device.id,
            body: {
              circuitNumber: values.circuitNumber,
              snHardware: values.snHardware,
              sn: values.sn,
              type: values.type,
              gateways: values.gateways,
              username: values.username,
              password: values.password,
              groupIds: values.groupIds,
              customerId: values.customerId,
              province: values.province,
              regency: values.regency,
              location: values.location,
            },
            params: {
              updateGroup: true,
              updateCustomer: true,
            },
          }).unwrap();

          if (values.areaId !== "") {
            await setDeviceToArea({
              id: device.id,
              areaId: values.areaId,
            }).unwrap();
          } else {
            // area not set and current area is exists, then unset from area
            if (device.area !== null) {
              await removeDeviceFromArea({
                id: device.id,
                areaId: device.area.id,
              }).unwrap();
            }
          }

          // update simCards
          // transform data into desired object
          const { simCards } = values;
          const simCardsData = simCards && simCards.length > 0 ? simCards.map((sim) => ({
            id: sim.id,
            cid: sim.circuitNumber,
            gsmNumber: sim.gsmNumber,
          })) : [];

          if (simCardsData && simCardsData.length > 0) {
            await updateDeviceSimCards({ deviceId: device.id, data: simCardsData}).unwrap();
          }

          toast.success("Device configuration updated.");
          toggleEditing();
        } catch (error) {
          Swal.showValidationMessage(
            `${error?.data?.error}: ${error?.data?.message}`
          );
        } finally {
          setLoading(false);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const onCollapsibleStateChange = (field) => {
    setCollapsible((state) => ({
      ...state,
      [field]: !Boolean(state[field]),
    }));
  };

  const onChangePasswordSubmit = ({ newPassword }, setFieldValue) => {
    setFieldValue("password", newPassword);
    setModalChangePassword(!modalChangePassword);
  };

  const onProvinceChange = (value, setFieldValue) => {
    setFieldValue("province", value);
    const province = provinceOptions.find((p) => p.label === value);
    if (province) {
      setProvinceId(province.id);
    }
  };

  const onRegionChanges = (e, setFieldValue) => {
    setFieldValue("regionId", e.value);
    setFieldValue("areaId", "");
    setSelectedRegionId(e.value);
  };

  const openModalGateway = () => {
    setShowModalEditGateway(true);
  };

  const toggleModalGateway = () => {
    setShowModalEditGateway((show) => !show);
  };

  return (
    <Row>
      <Col>
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: device.id || 0,
            circuitNumber: circuitNumber || "",
            macAddress: macAddress || "",
            sn: sn || "",
            type: type || "",
            snHardware: snHardware || "",
            ipDevice: ipDevice || "",
            location: location || "",
            username: username || "",
            password: password || "",
            groupIds: groupIds || [],
            areaId: area?.id || "",
            regionId: region?.id || "",
            customerId: customerId,
            province: province || "",
            regency: regency || "",
            simCards: _.sortBy(simCards, [(o) => o.slotNumber]),
            gateways: _.sortBy(gateways, [(o) => o.id]) || [],
          }}
          onSubmit={onSubmit}
          validationSchema={updateDeviceOverviewSchema}
        >
          {({
            values,
            isValid,
            setFieldValue,
            handleSubmit,
            dirty,
            validateForm,
            resetForm,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col className="border-right px-5 pb-5">
                  <h2 className="text-dark mt-3 ml--3">Device Configuration</h2>
                  <Row className="mt-4">
                    <Col>
                      <RowItem
                        className="bg-default"
                        label={
                          <span className="text-white">Last Modified</span>
                        }
                        value={
                          <span className="text-white">
                            {momentLastUpdate(updateModified)}
                          </span>
                        }
                      />
                      <RowItem
                        label="Uptime Duration"
                        value={uptimeDurationInHuman || unknown}
                      />
                      <RowItem
                        rightCol="col-8"
                        stripped
                        label="Device ID"
                        value={
                          <TritronikInputText name="circuitNumber" small />
                        }
                      />
                      <RowItem
                        rightCol="col-8"
                        label="SN Hardware"
                        value={<TritronikInputText name="snHardware" small />}
                      />
                      <RowItem
                        stripped
                        rightCol="col-8"
                        label={<CustomLabel name="Serial Number" required />}
                        value={<TritronikInputText name="sn" small readOnly />}
                      />
                      <RowItem
                        rightCol="col-8"
                        label={<CustomLabel name="Device Type" required />}
                        value={
                          <TritronikSelect
                            name="type"
                            value={values.type}
                            onChange={(e) => setFieldValue("type", e.value)}
                            options={deviceTypeOptions}
                          />
                        }
                      />
                      <RowItem
                        rightCol="col-8"
                        stripped
                        label="IP Address"
                        value={ipDevice}
                      />
                      <RowItem
                        rightCol="col-8"
                        label="LAN Port Status"
                        value={
                          <span>
                            {getLanPortStatus()}
                            <button
                              id="togglerLanPortStatus"
                              type="button"
                              onClick={() =>
                                onCollapsibleStateChange("lanPorts")
                              }
                              className="btn btn-sm btn-link bg-transparent ml-2"
                            >
                              <i
                                className={classnames("fa text-default", {
                                  "fa-chevron-down": !Boolean(
                                    collapsible["lanPorts"]
                                  ),
                                  "fa-chevron-up": Boolean(
                                    collapsible["lanPorts"]
                                  ),
                                })}
                              ></i>
                            </button>
                          </span>
                        }
                      />
                      <Collapse isOpen={Boolean(collapsible["lanPorts"])}>
                        {lanPorts &&
                          lanPorts.map((lanPort, idx) => (
                            <RowItem
                              stripped={idx % 2 === 0}
                              offset
                              key={`lan-port-${lanPort.id}`}
                              label={`LAN Port ${lanPort.portNumber}`}
                              value={
                                <StatusBadge
                                  label={
                                    lanPort.status === "up"
                                      ? "Connected"
                                      : "Disconnected"
                                  }
                                  type={
                                    lanPort.status === "up"
                                      ? "success"
                                      : "danger"
                                  }
                                />
                              }
                            />
                          ))}
                      </Collapse>

                      <RowItem
                        stripped
                        label="ARP IP Addresses"
                        value={
                          <span>
                            {`${arpIpAddresses.length || 0} Connected`}
                            <button
                              id="togglerARPIPAddress"
                              type="button"
                              onClick={() =>
                                onCollapsibleStateChange("arpIpAddresses")
                              }
                              className="btn btn-sm btn-link bg-transparent ml-2"
                            >
                              <i
                                className={classnames("fa text-default", {
                                  "fa-chevron-down": !Boolean(
                                    collapsible["arpIpAddresses"]
                                  ),
                                  "fa-chevron-up": Boolean(
                                    collapsible["arpIpAddresses"]
                                  ),
                                })}
                              ></i>
                            </button>
                          </span>
                        }
                      />
                      <Collapse isOpen={Boolean(collapsible["arpIpAddresses"])}>
                        {arpIpAddresses &&
                          arpIpAddresses.map((ipAddr, i) => (
                            <RowItem
                              stripped={i % 2 === 1}
                              offset
                              key={`lan-port-${ipAddr}`}
                              label={`IP Address`}
                              value={ipAddr}
                            />
                          ))}
                      </Collapse>

                      <RowItem
                        label="Gateway ID"
                        value={
                          <span>
                            {`${values.gateways.length || 0} ${
                              values.gateways.length > 1
                                ? "Gateways"
                                : "Gateway"
                            }`}
                            <button
                              type="button"
                              onClick={openModalGateway}
                              className="btn btn-sm btn-link bg-transparent ml-2"
                            >
                              <i
                                className={classnames("fa fa-cog text-default")}
                              ></i>
                            </button>
                          </span>
                        }
                      />
                      {errors.gateways && (
                        <span className="text-danger">
                          Gateway ID is required.
                        </span>
                      )}
                      <ModalAddGateway
                        isOpen={showModalEditGateway}
                        toggle={toggleModalGateway}
                        validateForm={validateForm}
                        resetForm={resetForm}
                        isValid={isValid}
                        errors={errors}
                      />

                      <RowItem
                        stripped
                        label="Device Credential"
                        value={
                          <button
                            id="togglerCredential"
                            type="button"
                            onClick={() =>
                              onCollapsibleStateChange("credentials")
                            }
                            className="btn btn-sm btn-link bg-transparent ml-2"
                          >
                            <i
                              className={classnames("fa text-default", {
                                "fa-chevron-down": !Boolean(
                                  collapsible["credentials"]
                                ),
                                "fa-chevron-up": Boolean(
                                  collapsible["credentials"]
                                ),
                              })}
                            ></i>
                          </button>
                        }
                      />
                      <Collapse isOpen={Boolean(collapsible["credentials"])}>
                        <RowItem
                          label="Username"
                          offset
                          value={<TritronikInputText name="username" small />}
                        />
                        <RowItem
                          stripped
                          label="Password"
                          offset
                          value={
                            <Button
                              color="default"
                              size="sm"
                              type="button"
                              onClick={() =>
                                setModalChangePassword(!modalChangePassword)
                              }
                            >
                              Update Password
                            </Button>
                          }
                        />
                        <ModalChangePassword
                          isOpen={modalChangePassword}
                          currentPassword={password}
                          toggle={() =>
                            setModalChangePassword(!modalChangePassword)
                          }
                          onSubmit={(values) =>
                            onChangePasswordSubmit(values, setFieldValue)
                          }
                        />
                      </Collapse>

                      <RowItem
                        rightCol="col-8"
                        label="Group"
                        value={
                          <TritronikMultiSelect
                            filter
                            name="groupIds"
                            options={groupOptions}
                            placeholder="Select Group"
                          />
                        }
                      />

                      <RowItem
                        rightCol="col-8"
                        stripped
                        label="Customer"
                        value={
                          <TritronikSelect
                            filter
                            name="customerId"
                            value={values.customerId}
                            onChange={(e) =>
                              setFieldValue("customerId", e.value)
                            }
                            options={customerOptions}
                          />
                        }
                      />

                      <RowItem
                        rightCol="col-8"
                        label={<CustomLabel name="Region" required />}
                        value={
                          <TritronikSelect
                            filter
                            name="regionId"
                            value={values.regionId}
                            onChange={(e) => onRegionChanges(e, setFieldValue)}
                            options={regionOptions}
                            placeholder="Select Region"
                          />
                        }
                      />

                      <RowItem
                        rightCol="col-8"
                        stripped
                        label={<CustomLabel name="Area" required />}
                        value={
                          <TritronikSelect
                            filter
                            name="areaId"
                            value={values.areaId}
                            onChange={(e) => setFieldValue("areaId", e.value)}
                            options={[
                              { value: "", label: "Select Area" },
                              ...areaOptions,
                            ]}
                            placeholder="Select Area"
                          />
                        }
                      />

                      <RowItem
                        rightCol="col-8"
                        label="Province"
                        value={
                          <TritronikSelect
                            filter
                            name="province"
                            value={values.province}
                            onChange={(e) =>
                              onProvinceChange(e.value, setFieldValue)
                            }
                            options={provinceOptions}
                            placeholder="Select Province"
                          />
                        }
                      />

                      <RowItem
                        rightCol="col-8"
                        stripped
                        label="District/City"
                        value={
                          <TritronikSelect
                            filter
                            name="regency"
                            value={values.regency}
                            onChange={(e) => setFieldValue("regency", e.value)}
                            options={regencyOptions}
                            placeholder="Select Regency"
                          />
                        }
                      />

                      <RowItem
                        rightCol="col-8"
                        label={<CustomLabel name="Address" required />}
                        value={<TritronikInputText name="location" small />}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="border-left px-5 pb-5">
                  <SimCardOverview
                    deviceId={device.id}
                    simCards={device.simCards}
                    isEditing={false}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end mb-5">
                <Button
                  className="btn btn-outline-default px-5 py-1 ml-auto"
                  onClick={toggleEditing}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-default px-5 py-1"
                  type="submit"
                  disabled={loading || !dirty}
                >
                  Apply
                  {loading && (
                    <Spinner className="ml-2" color="light" size="sm" />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

export default DeviceConfigurationForm;
