import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQuery,
  tagTypes: ['Notification', 'SubscribedNotification'],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params) => ({
        url: `/notification/history`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'Notification', id })),
            { type: 'Notification', id: 'LIST' },
          ]
          : [{ type: 'Notification', id: 'LIST' }],
    }),
    getSubscribedNotifications: builder.query({
      query: (params) => ({
        url: `/notification/subscription`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'SubscribedNotification', id })),
            { type: 'SubscribedNotification', id: 'LIST' },
          ]
          : [{ type: 'SubscribedNotification', id: 'LIST' }],
    }),
    subscribeNotification: builder.mutation({
      query: (username) => ({
        url: `/notification/subscription/${username}`,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'SubscribedNotification', id: 'LIST' }],
    }),
    changeSubscriptionNotification: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/notification/subscription/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: 'SubscribedNotification', id: 'LIST' }],
    }),
    setNotificationSubscriptionByUsername: builder.mutation({
      query: ({ username, subscribe, body = {} }) => ({
        url: `/notification/subscription/${username}/subscribe-all/${subscribe}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: 'SubscribedNotification', id: 'LIST' }],
    }),
    
  })
});

export const {
  useGetNotificationsQuery,
  useGetSubscribedNotificationsQuery,
  useSubscribeNotificationMutation,
  useChangeSubscriptionNotificationMutation,
  useSetNotificationSubscriptionByUsernameMutation,
} = notificationApi;

