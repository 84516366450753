import moment from 'moment';

export const unixToDate = (time) => moment(time).isValid() ? moment(time).format('DD MMM YYYY') : time;

export const getTimeGroupping = (start, end) => {
  if (moment.isMoment(start) && moment.isMoment(end)) {
    const days = end.diff(start, "days");
    if (days <= 1) {
      return "1h";
    } else {
      return "1d";
    }
  }
  return "1h";
};

export const getTimeUnit = (start, end) => {
  if (moment.isMoment(start) && moment.isMoment(end)) {
    const days = end.diff(start, "days");
    if (days <= 1) {
      return "hour";
    } else if (days > 1 && days <= 31) {
      return "day";
    } else {
      return 'month'
    }
  }
  return "hour";
};

export const momentLastUpdate = (date, format = 'DD/MM/YYYY HH:mm:ss', limit = 24) => {
  const today = moment();
  const d = moment(date);
  if (today.diff(d, 'hours') > limit) {
    return d.format(format)
  }
  return d.fromNow();
}