import classnames from "classnames";
import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Nav,
  Row,
  Spinner,
  UncontrolledDropdown
} from "reactstrap";
import Swal from "sweetalert2";
import { onKeyDown } from "utils";
import * as Yup from 'yup';
import { ButtonLinkIcon, Gap, PrimeMultiSelect, TritronikSelect } from "../../../components";
import {
  CHANGE_SIM_QUOTA,
  CHANGE_SIM_STATUS,
  CONFIG_DEVICE_GROUP,
  CONFIG_PRIMARY_SIM,
  UNPROVISION_DEVICE,
  UPDATE_DEVICE_AREA
} from "../../../config";
import { Can } from "../../../config/Can";
import { ModalAddGroup } from "../../group";
import {
  useRemoveDeviceAreaMutation,
  useSetDeviceAreaMutation,
  useSetPrimarySimCardMutation,
  useSetQuotaLimitSimCardMutation,
  useSetSimCardStatusMutation,
  useUpdateDeviceMutation
} from "../service/deviceApi";


const FilterItem = ({ label, value }) => {
  return (
    <div className="row d-flex justify-content-between align-items-center mx-2 py-2">
      <div className="col-4 p-0">
        <h4 className="text-sm text-dark">{label}</h4>
      </div>
      <div className="col-8 p-0">{value}</div>
    </div>
  );
};

const CustomActionButton = ({ row, onDeleteClick, groupOptions, uomOptions, areaOptions }) => {
  const {
    primarySimCardSlotNumber,
    groupIds,
    simCards,
    area,
    sn,
    type
  } = row;

  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);
  const [loading, setLoading] = useState(false);
  const [modalAddGroup, setModalAddGroup] = useState(false);

  const [updateDevice] = useUpdateDeviceMutation();
  const [setDeviceToArea] = useSetDeviceAreaMutation();
  const [removeDeviceFromArea] = useRemoveDeviceAreaMutation();
  const [setPrimarySimCard] = useSetPrimarySimCardMutation();
  const [setSimCardQuotaLimit] = useSetQuotaLimitSimCardMutation();
  const [setSimCardStatus] = useSetSimCardStatusMutation(); 

  const onSubmit = (values, { resetForm }) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to update this?",
      text: `Device: ${sn}, Type: ${type}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        const {
          primarySim,
          simSlotNumber,
          quotaLimit,
          quotaUom,
          simStatus,
          groupIds,
          areaId,
        } = values;
        try {
          setLoading(true);
          // set new area and unset current area if any
          if (areaId !== '') {
            await setDeviceToArea({ id: row.id, areaId }).unwrap();
          } else {
            // area not set and current area is exists, then unset from area
            if (area !== null) {
              await removeDeviceFromArea({ id: row.id, areaId: area.id }).unwrap();
            }
          }

          // sim cards
          if (simCards && simCards.length > 0) {
            if (primarySim !== '' && primarySim !== null) {
              await setPrimarySimCard({ id: row.id, slotNumber: primarySim }).unwrap();
            }

            // check if sim available to update
            const slotAvailable = simCards && simCards.find((simCard) => simCard.slotNumber === parseInt(simSlotNumber))
            
            if (simSlotNumber !== '' && simSlotNumber !== null && slotAvailable) {
              // set simcard quota limit
              await setSimCardQuotaLimit({
                id: row.id,
                slotNumber: simSlotNumber,
                limit: quotaLimit,
                uom: quotaUom
              }).unwrap();

              // set simcard status
              const action = simStatus ? "Activate" : "Deactivate";
              await setSimCardStatus({
                deviceId: row.id,
                action: action,
                simSlotNumber: simSlotNumber
              }).unwrap();
            }
          }
          // update device
          await updateDevice({ id: row.id, body: { groupIds }, params: { updateGroup: true }}).unwrap();

          toast.success("Update device configuration success.");
          resetForm();
          setLoading(false);
          return true;
        } catch (error) {
          toast.error(`${error?.data?.error}: ${error?.data?.message}`);
          setLoading(false);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const simCardOptions = useMemo(() => {
    const opts = [{ value: "", label: "None" }];

    if (simCards && simCards.length > 0) {
      simCards.forEach((sc) => {
        opts.push({ value: sc.slotNumber, label: `SIM ${sc.slotNumber}` });
      });
    }

    return opts;
  }, [simCards]);

  const getSimCardBySlotNumber = (slotNumber) => {
    return simCards && simCards.find((sc) => sc.slotNumber === slotNumber);
  }

  const getSimCardQuotaLimit = (slotNumber) => {
    const sc = getSimCardBySlotNumber(slotNumber);
    if (sc && sc.quotaLimitInHuman) {
      return sc.quotaLimitInHuman.split(" ").slice(0, 1).join("");
    }
    return 0;
  }
  const getSimCardQuotaUom = (slotNumber) => {
    const sc = getSimCardBySlotNumber(slotNumber);
    if (sc && sc.quotaLimitInHuman) {
      return sc.quotaLimitInHuman.split(" ").slice(-1).join("");
    }
    return "";
  }
  const getSimCardStatus = (slotNumber) => {
    const sc = getSimCardBySlotNumber(slotNumber);
    return sc && sc.status === "Activated" ? true : false;
  }

  const {
    values,
    isSubmitting,
    dirty,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupIds: groupIds || [],
      primarySim: primarySimCardSlotNumber || "",
      simSlotNumber: primarySimCardSlotNumber || "",
      quotaLimit: getSimCardQuotaLimit(primarySimCardSlotNumber),
      quotaUom: getSimCardQuotaUom(primarySimCardSlotNumber),
      simStatus: getSimCardStatus(primarySimCardSlotNumber),
      areaId: area !== null ? area.id : "",
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      groupIds: Yup.array().nullable(),
      areaId: Yup.number().nullable(),
      primarySim: Yup.string().required('Select simcard to update primary simcard.'),
      simSlotNumber: Yup.string().required('Select simcard to update quota limit.'),
      quotaLimit: Yup.number().required('Please provide quota limit.'),
      quotaUom: Yup.string().required('Please provide quota unit.')
    })
  });

  const handleGroupSelectChange = (e) => {
    setFieldValue("groupIds", e.value);
  };

  const handleSimStatusChange = (e) => {
    setFieldValue("simStatus", e.target.checked);
  };

  const handleSelectSimQuota = (e) => {
    setFieldValue("simSlotNumber", e.value);

    setFieldValue("quotaLimit", getSimCardQuotaLimit(e.value));
    setFieldValue("quotaUom", getSimCardQuotaUom(e.value));
    setFieldValue("simStatus", getSimCardStatus(e.value));
  };

  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={menuOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <i className="fas fa-ellipsis-v text-default" />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ width: 400 }} className="p-2">
            <Form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
              <FilterItem
                label="Group"
                value={
                  <Can I="do" this={CONFIG_DEVICE_GROUP} passThrough>
                    {(allowed) => (
                      <PrimeMultiSelect
                        filter
                        onChange={handleGroupSelectChange}
                        value={values.groupIds}
                        options={groupOptions}
                        disabled={!allowed}
                        placeholder="Select Group"
                      />
                    )}
                  </Can>
                }
              />
              <ButtonLinkIcon
                label="Add New Group" 
                icon="fa-plus-circle" 
                className="btn-sm btn-link py-0 px-2" 
                fontWeight="normal" 
                onClick={() => setModalAddGroup(!modalAddGroup)}
              />
              <hr className="my-1 mx-2" />
              <FilterItem
                label="Area"
                value={
                  <Can I="do" this={UPDATE_DEVICE_AREA} passThrough>
                    {(allowed) => (
                      <TritronikSelect
                        options={[
                          { value: "", label: "Select Area" },
                          ...areaOptions,
                        ]}
                        {...getFieldProps("areaId")}
                        disabled={!allowed}
                      />
                    )}
                  </Can>
                }
              />
              <hr className="my-1 mx-2" />
              <FilterItem
                label="Primary SIM"
                value={
                  <Can I="do" this={CONFIG_PRIMARY_SIM} passThrough>
                    {(allowed) => (
                      <TritronikSelect
                        {...getFieldProps("primarySim")}
                        options={simCardOptions}
                        disabled={!allowed}
                        invalid={touched.primarySim && errors.primarySim}
                        error={errors.primarySim}
                      />
                    )}
                  </Can>
                }
              />
              <hr className="my-1 mx-2" />
              {simCards && simCards.length > 0 && (
                <>
                  <Row className="mt-2 mx-2">
                    <Col className="col-7 p-0">
                      <Can I="do" this={CHANGE_SIM_STATUS}>
                        {() => (
                          <TritronikSelect
                            {...getFieldProps("simSlotNumber")}
                            options={simCardOptions}
                            name="simSlotNumber"
                            value={values.simSlotNumber}
                            onChange={handleSelectSimQuota}
                            invalid={touched.simSlotNumber && errors.simSlotNumber}
                            error={errors.simSlotNumber}
                          />
                        )}
                      </Can>
                    </Col>
                  </Row>
                  <Gap height={10} />
                  <Row className="mx-2">
                    <Col className="p-0">
                      <h4 className="text-sm text-dark">Quota</h4>
                      <div className="d-flex justify-content-between">
                        <Can I="do" this={CHANGE_SIM_QUOTA} passThrough>
                          {(allowed) => (
                            <FormGroup className="mb-0" style={{ width: 100 }}>
                              <Input 
                                {...getFieldProps("quotaLimit")} 
                                invalid={Boolean(touched.quotaLimit && errors.quotaLimit)} 
                                bsSize="sm" 
                                disabled={!allowed} 
                              />
                              {touched.quotaLimit && errors.quotaLimit && <FormFeedback>{errors.quotaLimit}</FormFeedback>}
                            </FormGroup>
                          )}
                        </Can>
                        <Can I="do" this={CHANGE_SIM_QUOTA} passThrough>
                          {(allowed) => (
                            <TritronikSelect
                              {...getFieldProps("quotaUom")}
                              style={{ width: 100 }}
                              options={uomOptions}
                              disabled={!allowed}
                            />
                          )}
                        </Can>
                      </div>
                    </Col>
                    <Col className="col-5">
                      <h4 className="text-sm text-dark">SIM Status</h4>
                      <Row>
                        <Col className="pr-0 pt-1">
                          <Can I="do" this={CHANGE_SIM_STATUS} passThrough>
                            {(allowed) => (
                              <label className="custom-toggle">
                                <input
                                  type="checkbox"
                                  checked={values.simStatus}
                                  value={values.simStatus}
                                  onChange={handleSimStatusChange}
                                  disabled={!allowed}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                            )}
                          </Can>
                        </Col>
                        <Col className="px-0 pt-1">
                          <span
                            className={classnames("text-sm", {
                              "text-success": values.simStatus,
                              "text-light": !values.simStatus,
                            })}
                          >
                            {values.simStatus ? "Activate" : "Deactivate"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              <Gap height={20} />
              <Row className="mx-2">
                <Col className="text-center p-0">
                  <Button
                    className="m-0"
                    block
                    color="default"
                    size="sm"
                    type="submit"
                    disabled={!dirty || isSubmitting || loading}
                  >
                    Apply
                    {loading && (
                      <Spinner className="ml-2" color="light" size="sm" />
                    )}
                  </Button>
                  <Button
                    className="m-0 mt-2"
                    block
                    color="secondary"
                    size="sm"
                    onClick={() => setMenuOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Can I="do" this={UNPROVISION_DEVICE}>
                    {() => (
                      <Button
                        className="m-0 mt-2"
                        block
                        color="danger"
                        size="sm"
                        onClick={onDeleteClick}
                      >
                        Unprovision Device
                      </Button>
                    )}
                  </Can>
                </Col>
              </Row>
            </Form>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
      <ModalAddGroup isOpen={modalAddGroup} toggle={() => setModalAddGroup(!modalAddGroup)} />
    </Nav>
  );
};

export default CustomActionButton;