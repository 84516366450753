import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import { MultiSelect } from "primereact/multiselect";
import "./ConfigureTable.scss";
import { ButtonLinkIcon } from "./../../../components";
import { columnOptions, initialState } from './../reducers/deviceSlice';

const ConfigureTable = ({
  isOpen,
  toggle,
  loading,
  onFilter,
  defaultColumns,
  onResetDefault
}) => {
  const [selectedColumns, setSelectedColumns] = React.useState(defaultColumns);
  const [changes, setChanges] = useState(false);

  const handleSelectedColumns = (e) => {
    setSelectedColumns(e.value);
    setChanges(true);
  };

  const onFilterClick = () => {
    onFilter(selectedColumns);
    setChanges(false);
    toggle();
  };

  const _onResetDefault = () => {
    if (typeof onResetDefault === 'function') {
      onResetDefault();
    }
    setSelectedColumns(initialState.columns);
  }

  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={isOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <ButtonLinkIcon className='ml-2' icon="fa-cogs" />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 350 }} className="p-2">
            <Row className="text-sm mt-3">
              <div className="col-3 d-flex align-items-center">Columns</div>
              <div className="col-1 d-flex align-items-center px-0 mx-0">:</div>
              <div className="col multiselect-column">
                <MultiSelect
                  filter
                  options={columnOptions}
                  value={selectedColumns}
                  onChange={handleSelectedColumns}
                  style={{ fontSize: 12 }}
                  placeholder="Filter Column"
                />
              </div>
            </Row>
            <Row className="mt-4">
              <Col className="text-center">
                <Button
                  className="m-0"
                  block
                  color="default"
                  size="sm"
                  type="button"
                  onClick={onFilterClick}
                  disabled={!changes}
                >
                  Apply
                  {loading === "pending" && (
                    <Spinner className="ml-2" color="light" size="sm" />
                  )}
                </Button>
                <Button
                  className="m-0 mt-2"
                  block
                  color="secondary"
                  size="sm"
                  onClick={toggle}
                >
                  Cancel
                </Button>
                <Button
                  className="m-0 mt-2"
                  block
                  color="secondary"
                  size="sm"
                  onClick={_onResetDefault}
                >
                  Reset Default
                </Button>
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default ConfigureTable;
