
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { UserActivityPage } from "./pages";


function UserActivityRoute() {
  return (
    <Switch>
      <Route exact path={`/admin/user-activity/`} component={UserActivityPage} />
      <Redirect to="/admin/user-activity/" />
    </Switch>
  );
}

export default UserActivityRoute;
