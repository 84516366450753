import React from 'react'
import {
  Col,
  Row
} from "reactstrap";
import { defaultUserProfile } from './../../../../../assets';
import ProfileOverviewContent from "./../ProfileOverviewContent";

const PreviewProfile = ({ user, isUpdatingPassword, updateUserPassword }) => {

  const onLoadImageError = (event) => {
    event.target.src = defaultUserProfile;
    event.onerror = null
  }

  const avatar = user?.userProfilePicture?.actualLink || defaultUserProfile;
  return (
    <Row>
      <Col md="2" xs="12" className="d-flex justify-content-center">
        <div className="profilepic_preview">
          <img alt="..." className="profilepic_preview__image" src={avatar} width="150" height="150" onError={onLoadImageError} />
        </div>
      </Col>
      <Col md="10" xs="12">
        <ProfileOverviewContent user={user} loading={isUpdatingPassword} onUpdatePassword={updateUserPassword} />
      </Col>
    </Row>
  )
}

export default PreviewProfile
