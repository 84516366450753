import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Breadcrumb, BreadcrumbItem, Button, Card,
  CardBody, Col, Container, Row, Spinner
} from "reactstrap";
import { DefaultHeader, PrimeSteps } from '../../../components';
import history from '../../../utils/history';
import { FormConfirmation, FormEligibleData, FormPrivilege, FormProfile } from '../components';
import { useSetFeatureToRoleMutation } from '../service/featureApi';
import { useCreateUserMutation, useSetUserAreasMutation, useSetUserCustomersMutation, useSetUserRegionsMutation, useSetUserRoleMutation } from '../service/userApi';
import { useRole } from './../../../hooks';
import { useSubscribeAlertMutation } from './../../alert/service/alertConfigurationApi';
import { useGroups } from './../../group/hooks';
import { useSubscribeNotificationMutation } from './../../notification/service/notificationApi';
import { useRegion } from './../../region/hooks';
import { useCreateRoleMutation } from './../service/roleApi';
import { validationSteps } from './UserValidationSchema';

const formSteps = [
  { label: 'Profile' },
  { label: 'Privilege' },
  { label: 'Data' },
  { label: 'Confirmation' },
];

function UserAdd() {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSteps[activeStep];
  const isLastStep = activeStep === formSteps.length - 1;

  const { options: groupOptions } = useGroups();
  const { options: roleOptions, getRolePrivileges, getAvailablePrivileges } = useRole();
  const { optionsWithCustomerCount: regionOptionsWithCustomer } = useRegion({ includeHierarchySummary: true }, false);

  const [createUser] = useCreateUserMutation();
  const [createRole] = useCreateRoleMutation();
  const [setUserRole] = useSetUserRoleMutation();
  const [setFeatureToRole] = useSetFeatureToRoleMutation();
  const [setUserRegion] = useSetUserRegionsMutation();
  const [setUserArea] = useSetUserAreasMutation();
  const [setCustomer] = useSetUserCustomersMutation();
  const [subscribeNotification] = useSubscribeNotificationMutation();
  const [subscribeAlert] = useSubscribeAlertMutation();


  const nextStep = () => {
    if (!isLastStep) {
      setActiveStep(activeStep + 1);
    }
  }

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }

  const renderFormStep = (step, actions) => {
    switch (step) {
      case 0: return <FormProfile groupOptions={groupOptions} />;
      case 1: return (
        <FormPrivilege
          formik={actions}
          roleOptions={roleOptions}
          getRolePrivileges={getRolePrivileges}
          getAvailablePrivileges={getAvailablePrivileges}
        />
      );
      case 2: return <FormEligibleData formik={actions} regionOptions={regionOptionsWithCustomer} />;
      default: return <FormConfirmation formik={actions} groupOptions={groupOptions} />;
    }
  }

  const onSubmit = async (values) => {
    const { role, roleNames, copyRoleId, newRole, newRoleName, copyRole, privileges, regions, customers, selectedCustomer, selectedRegion, ...data } = values;
    setLoading(true);

    try {
      // create a user
      const user = await createUser({ body: data, params:{ updateGroup: true } }).unwrap();

      // role assignment
      if (newRole === true) {
        await createRole({ name: newRoleName }).unwrap().then(async ({ id }) => {
          // assign features to new role
          for (let i = 0; i < privileges.length; i++) {
            const feature = privileges[i];
            await setFeatureToRole({ featureId: feature.id, roleId: id }).unwrap();
          }
          // set user role
          await setUserRole({ roleId: id, username: user.username });
        });
      } else {
        // set user role
        await setUserRole({ roleId: role, username: user.username });
      }

      // set user eligible region and area (zones)
      if (selectedRegion === '-1') { // is All Region Selected
        await setUserRegion({ regionIds: '-1', username: user.username }).unwrap();
      } else {
        if (regions && regions.length > 0) {
          const userRegions = [];
          const userAreas = [];
          regions.forEach(region => {
            if (region.areas && region.areas.length > 0) {
              region.areas.forEach((area) => {
                userAreas.push(area.id);
              });
            } else {
              userRegions.push(region.id);
            }
          });

          if (userRegions && userRegions.length > 0) {
            await setUserRegion({ username: user.username, regionIds: userRegions.join(',') }).unwrap();
          }

          if (userAreas && userAreas.length > 0) {
            await setUserArea({ username: user.username, areaIds: userAreas.join(',') }).unwrap();
          }
        }
      }

      // set user eligible customer
      if (selectedCustomer === '-1') {
        await setCustomer({ username: user.username, customerIds: '-1' }).unwrap();
      } else {
        if (customers && customers.length > 0) {
          const customerIds = customers.map((cust) => cust.id).join(',');
          await setCustomer({ username: user.username, customerIds: customerIds }).unwrap();
        }
      }

      // Notification Subscription
      await subscribeNotification(user.username).unwrap();

      // Alert Subscription
      await subscribeAlert(user.username).unwrap();

      toast.success(`User ${user.username} created.`);
      history.replace("/admin/users");
    } catch (error) {
      toast.error(`${error?.data?.error}: ${error?.data?.message}`);
    }
    setLoading(false);
  }

  const _handleSubmit = (values, actions) => {
    if (isLastStep) {
      onSubmit(values);
    } else {
      nextStep();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  return (
    <>
      <DefaultHeader title="User" />
      <Container className="mt--8" fluid>
        <Card className="mb-5">
          <CardBody>
            <div className="d-flex justify-content-between">
              <Breadcrumb listClassName="bg-white p-0">
                <BreadcrumbItem>
                  <Link to="/admin/users" className="text-lg">All User</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-lg">Add New User</BreadcrumbItem>
              </Breadcrumb>
            </div>
            <div className="row">
              <div className="col">
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    phoneNumber: '',
                    telegramNumber: '',
                    role: '',
                    username: '',
                    password: '',
                    confirmPassword: '',
                    status: 'activated',
                    activeDate: Date.now(),
                    groupIds: [],
                    roleNames: [],
                    newRole: false,
                    copyRole: false,
                    copyRoleId: '',
                    newRoleName: '',
                    privileges: [],
                    regions: [],
                    customers: [],
                    selectedRegion: '',
                    selectedCustomer: '',
                  }}
                  validationSchema={currentValidationSchema}
                  onSubmit={_handleSubmit}
                >
                  {(actions) => (
                    <Form onSubmit={actions.handleSubmit}>
                      <Row className="mt-5">
                        <Col>
                          <PrimeSteps model={formSteps} activeIndex={activeStep} />
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="px-5">
                          {renderFormStep(activeStep, actions)}
                        </Col>
                      </Row>
                      <div className="row mt-5 px-5">
                        <div className="col text-right px-5">
                          <Link to="/admin/users" className="btn btn-outline-default px-5 py-1 ml-auto">Cancel</Link>
                          {activeStep > 0 && (
                            <Button type="button" onClick={prevStep} className="btn btn-default px-5 py-1">
                              Back
                            </Button>
                          )}
                          <Button className="btn btn-default px-5 py-1" type="submit" disabled={loading}>
                            {isLastStep ? 'Apply' : 'Next'}
                            {loading && <Spinner className="ml-2" color="light" size="sm" />}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default UserAdd;
