import { useFormik } from 'formik';
import html2canvas from "html2canvas";
import pdfConverter from "jspdf";
import 'jspdf-autotable';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, UncontrolledDropdown } from 'reactstrap';
import Swal from "sweetalert2";
import FilterReport from "../components/FilterReport";
import ReportTable from '../components/ReportTable';
import { useReportFilterReferences, useReportOptions, useReportTemplates } from "../hooks";
import { useReportData } from '../hooks/useReportData';
import { useCreateReportTemplateMutation, useDeleteReportTemplateMutation, useUpdateReportTemplateMutation } from "../service/reportApi";
import { BootstrapDateRangePicker, ButtonLinkIcon, Gap, PageContent, PrimeDropdown, } from './../../../components';
import { Can, DOWNLOAD_USAGE_REPORT } from './../../../config';
import { getTimeUnit, unknown } from "./../../../utils";
import { ConfigureReportColumns, IntegrationReportContent, ModalSaveAsName, SignalReportContent, SLAReportContent, UsageReportContent } from './../components';
import { useAuth } from './../../auth/hooks';
import SLATunnelReportContent from '../components/SLATunnelReportContent';
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";
import ReportRightSideBar from '../components/ReportRightSideBar';


const Report = () => {
  const { username } = useAuth()
  const {
    reportTypeOptions,
    mapReportTypeName,
    defaultFilters,
    mapReportTypeVisibleColumns,
    mapReportTypeColumnOptions,
    availabilityOptions,
    availabilityOptionsSla,
    mapObjectFiltersToReportFilters,
    mapReportFiltersToObjectFilters,
    mapColumnsToReportFields,
    mapReportFieldsToColumns,
  } = useReportOptions();
  // report data
  const {
    data,
    params,
    filters,
    reportType,
    reportTemplate,
    loadingReportIntegration,
    loadingReportSignal,
    loadingReportUsage,
    loadingReportSLA,
    loadingReportSLATunnel,
    fetchingReportSLA,
    fecthingReportSLATunnel,
    fetchingReportIntegration,
    fetchingReportSignal,
    fetchingReportUsage,
    setParams,
    setFilters,
    setReportType,
    setReportTemplate
  } = useReportData();
  // modal save as state
  const [modalSaveAs, setModalSaveAs] = useState(false);
  const toggleSaveAs = () => setModalSaveAs(!modalSaveAs);
  // selected template object
  const [selectedTemplate, setSelectedTemplate] = useState({
    id: 0,
    name: '',
    reportFilters: mapObjectFiltersToReportFilters(defaultFilters),
    reportFields: mapColumnsToReportFields(mapReportTypeVisibleColumns[reportType]),
    type: reportType,
  });
  // visible columns
  const [visibleColumns, setVisibleColumns] = useState(mapReportTypeVisibleColumns[reportType]);
  // column options report
  const [columnOptions, setColumnOptions] = useState(mapReportTypeColumnOptions[reportType]);
  // Report title
  const [title, setTitle] = useState('All Devices');
  // Unit Time Chart
  const [unitTime, setUnitTime] = useState('hour');

  const { filterOptions } = useReportFilterReferences(reportType);

  const { customerOptions, areaOptions, deviceTypeOptions, imsiOptions, serialNumberOptions, operatorOptions, locationOptions, integrationStatusOptions } = filterOptions;

  const { templates, templateOptions } = useReportTemplates(reportType);

  const [showCustomerThreshold, setShowCustomerThreshold] = useState(false)

  const getTemplateById = (id) => {
    return _.find(templates, { 'id': parseInt(id) });
  }

  const { areaIds, customerIds, imsi, location, serialNumber, deviceType, operator, availability, integrationStatus } = filters;

  const resetFilters = () => {
    setFilters({
      customerIds: [],
      deviceType: [],
      serialNumber: [],
      operator: [],
      imsi: [],
      location: [],
      areaIds: [],
      availability: null,
      integrationStatus: [],
    });
  }

  const resetSelectedTemplate = (currentReportType) => {
    setReportTemplate('');
    setSelectedTemplate({
      id: 0,
      name: '',
      reportFilters: mapObjectFiltersToReportFilters(defaultFilters),
      reportFields: mapColumnsToReportFields(mapReportTypeVisibleColumns[currentReportType]),
      type: currentReportType
    });
  }

  // Mutation
  const [createTemplate, { isLoading: creatingTemplate }] = useCreateReportTemplateMutation();

  const [updateTemplate, { isLoading: updatingTemplate }] = useUpdateReportTemplateMutation();

  const [deleteTemplate] = useDeleteReportTemplateMutation();

  const onCreateTemplate = (data) => {
    createTemplate(data)
      .unwrap()
      .then((newTemplate) => {
        setSelectedTemplate(newTemplate);
        const { reportFields, reportFilters } = newTemplate;
        setVisibleColumns(mapReportFieldsToColumns(reportFields));
        setFilters(mapReportFiltersToObjectFilters(reportFilters));
        toast.success('Report template created.');
      });
  }

  const onUpdateTemplate = (data) => {
    updateTemplate(data)
      .unwrap()
      .then((updatedTemplate) => {
        setSelectedTemplate(updatedTemplate);
        const { reportFields, reportFilters } = updatedTemplate;
        setVisibleColumns(mapReportFieldsToColumns(reportFields));
        setFilters(mapReportFiltersToObjectFilters(reportFilters));
        toast.success('Report template updated.');
      });
  }

  const handleDeleleteTemplate = () => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to delete?",
      text: `Report Template: ${formik.values.name}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        return deleteTemplate(formik.values.id)
          .unwrap()
          .then(() => {
            resetSelectedTemplate();
            resetFilters();
            toast.success("Report template deleted.");
            setTitle('All Devices');
            return true;
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  const prepareDataTemplate = (values) => {
    return {
      ...values,
      ownerId: username,
      ownerType: 'USER'
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedTemplate,
    onSubmit: async (values) => {
      const actionType = (reportTemplate !== null && reportTemplate !== '') ? 'update' : 'create';
      if (actionType === 'update') {
        await onUpdateTemplate(prepareDataTemplate(values));
      } else {
        await onCreateTemplate(prepareDataTemplate(values));
      }
    }
  });

  // function to handle report type changes
  const handleReportTypeChange = (e) => {
    // change report type
    setReportType(e.value);
    // change columns options based on type
    setColumnOptions(mapReportTypeColumnOptions[e.value]);
    // change visible columns options based on type
    setVisibleColumns(mapReportTypeVisibleColumns[e.value]);
    // reset selected template
    resetSelectedTemplate(e.value);
    // reset filters
    resetFilters();
    // reset title
    setTitle('All Devices');
  }

  const handleSubmitModalSaveAs = (name) => {
    formik.setFieldValue('name', name);
    formik.handleSubmit();
    toggleSaveAs();
  }

  const handleSaveTemplate = (e) => {
    e.preventDefault()
    if (reportTemplate !== null && reportTemplate !== '') {
      formik.handleSubmit()
    } else {
      setModalSaveAs(true)
    }
  }

  const onDateChange = (start, end, label) => {
    setParams({
      startTime: start,
      endTime: end,
    });
    setUnitTime(getTimeUnit(start, end));
  };

  const handleReportTemplateChange = (e) => {
    setReportTemplate(e.value);
    const template = getTemplateById(parseInt(e.value));
    if (template) {
      const { reportFields, reportFilters } = template;

      setSelectedTemplate(template);
      setVisibleColumns(mapReportFieldsToColumns(reportFields));
      setFilters(() => mapReportFiltersToObjectFilters(reportFilters));
    } else {
      setVisibleColumns(mapReportTypeVisibleColumns[reportType]);
      resetSelectedTemplate(reportType);
      resetFilters();
    }
  }

  const onSubmitConfigurationColumns = (columns) => {
    setVisibleColumns(columns);
    formik.setFieldValue('reportFields', mapColumnsToReportFields(columns));
  }

  const onSubmitReportFilter = (filter) => {
    setFilters(filter);
    formik.setFieldValue('reportFilters', mapObjectFiltersToReportFilters(filter));
    // change title based on customer
    if (filter.customerIds && filter.customerIds.length > 0) {
      if (filter.customerIds.length === customerOptions.length) {
        setTitle('All Customer');
      } else {
        const customerNames = customerOptions && customerOptions.filter(co => filter.customerIds.includes(co.value)).map(c => c.label);
        if (customerNames && customerNames.length > 0) {
          setTitle(`Customer: ${customerNames.join(', ')}`);
        }
      }
    } else {
      setTitle('All Devices');
    }
  }

  const renderReportType = useCallback(() => {    
    switch (reportType) {
      case 'sla':
        return <SLAReportContent loading={loadingReportSLA || fetchingReportSLA} pieChartData={data?.sla?.pieChart} lineChartData={data?.sla?.lineChart} panelData={data?.sla?.panel} tableData={data?.sla?.table} dateRange={params} filters={filters}/>;
      case 'slaTunnel':
        return <SLATunnelReportContent loading={loadingReportSLATunnel || fecthingReportSLATunnel} pieChartData={data?.slaTunnel?.pieChart} panelData={data?.slaTunnel?.panel} />;
      case 'signal':
        return <SignalReportContent loading={loadingReportSignal || fetchingReportSignal} pieChartData={data?.signal?.pieChart} panelData={data?.signal?.panel} />;
      case 'integration':
        return <IntegrationReportContent loading={loadingReportIntegration || fetchingReportIntegration} pieChartData={data?.integration?.pieChart} barChartData={data?.integration?.barChart} />;
      default:
        return (
          <UsageReportContent
            loading={loadingReportUsage || fetchingReportUsage}
            dataOverQuotaDevice={data?.usage?.overQuotaUsagePercentage}
            dataOverQuotaUsageCustomer={data?.usage?.overQuotaUsageCustomer}
            unitTime={unitTime}
          />
        )
    }
  }, [data, reportType, loadingReportSLA, fetchingReportSLA, params, filters, loadingReportSLATunnel, fecthingReportSLATunnel, loadingReportSignal, fetchingReportSignal, loadingReportIntegration, fetchingReportIntegration, loadingReportUsage, fetchingReportUsage, unitTime]);

  const mapDataTableForPDF = (data) => {
    const visibleColumnsOptions = mapReportTypeColumnOptions[reportType].filter(vc => visibleColumns.includes(vc.value));

    const head = [visibleColumnsOptions?.map(vco => vco.label)];

    const getSimCardInfo = (simCards, field, slotNumber) => {
      if (simCards && simCards.length === 0) {
        return unknown;
      }
      const simCard = _.find(simCards, { slotNumber: slotNumber });

      if (!simCard) {
        return unknown;
      }

      return simCard[field]
    }

    const renderData = (column, data) => {
      if (column === 'strTime' || column === 'integrationDate') {
        if (reportType === 'sla') {
          return moment(data[column]).format('DD/MM/YYYY');
        } else {
          return moment(data[column]).format('DD/MM/YYYY HH:mm:ss');
        }
      } else if (column === 'sia') {
        if (reportType === 'sla') {
          return `${data['sia']}`;
        }
      } else if (column === 'totalUptime') {
        if (reportType === 'sla') {
          return `${data['totalUptimeFormatted']}`;
        } else {
          return `${data[column]}${data['uptimeUnit']}`;
        }
      } else if (column === 'totalDowntime') {
        if (reportType === 'sla') {
          return `${data['totalDowntimeFormatted']}`;
        } else {
          return `${data[column]}${data['downtimeUnit']}`;
        }
      } else if (column === 'sla') {
        return (typeof data[column] === 'number') ? `${(data[column] * 100).toFixed(2)}%` : `${(data[column] * 100)}%`;
      } else if (column === 'customer.name') {
        return data.customer?.name || unknown
      } else if (column === 'sim1Imsi') {
        return getSimCardInfo(data.simCards, 'imsi', 1);
      } else if (column === 'sim2Imsi') {
        return getSimCardInfo(data.simCards, 'imsi', 2);
      } else if (column === 'sim1Imei') {
        return getSimCardInfo(data.simCards, 'imei', 1);
      } else if (column === 'sim2Imei') {
        return getSimCardInfo(data.simCards, 'imei', 2);
      } else if (column === 'sim1Operator') {
        return getSimCardInfo(data.simCards, 'operator', 1);
      } else if (column === 'sim2Operator') {
        return getSimCardInfo(data.simCards, 'operator', 2);
      } else {
        return data[column];
      }
    }
    const body = data && data.map((d) => {
      return visibleColumnsOptions && visibleColumnsOptions.map((vco) => renderData(vco.value, d));
    });
    return { head, body };
  }

  const exportToPDF = (e) => {
    const btn = e.target;
    btn.disabled = true;

    let input;

    switch (reportType) {
      case 'sla':
        input = document.getElementById("slaReportContent");
        break;
      case 'slaTunnel':
        input = document.getElementById("slaTunnelReportContent");
        break;
      case 'signal':
        input = document.getElementById("signalReportContent");
        break;
      case 'integration':
        input = document.getElementById("integrationReportContent");
        break;
      default:
        input = document.getElementById("usageReportContent");
        break;
    }

    html2canvas(input).then(canvas => {
      const img = canvas.toDataURL("image/jpeg");
      const pdf = new pdfConverter("l", "pt", "letter");

      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      // Pie Chart Sizing
      let imgWidth = canvas.offsetWidth;
      let imgHeight = canvas.offsetHeight;
      const ratio = (width / imgWidth);

      // imgWidth = (imgWidth * ratio) - 128;
			// imgHeight = (imgHeight * ratio) - 128;
      // Scale the image dimensions
      imgWidth *= ratio;
      imgHeight *= ratio;

      // Adjust the dimensions for the margin
      const margin = 128;
      imgWidth -= margin;
      imgHeight -= margin;

      pdf.setFont("GothamBook");

      let textDate = '';

      if (params.endTime.diff(params.startTime, 'days') <= 1) {
        textDate = `${params.startTime.format('DD MMM YYYY HH:mm:ss')} - ${params.endTime.format('DD MMM YYYY HH:mm:ss')}`;
      } else {
        textDate = `${params.startTime.format('DD MMM YYYY')} - ${params.endTime.format('DD MMM YYYY')}`;
      }

      const reportTitle = (reportTemplate !== null && reportTemplate !== '') ? selectedTemplate?.name : mapReportTypeName[reportType];
      // Page Title
      pdf.setFontSize(20)
      pdf.text(`${reportTitle} (${textDate})`, 40, 40)
      // Page Subtitle
      pdf.setFontSize(16)
      pdf.text(`${title}`, 40, 80)

      let marginCard = 20, cardWidth = 120, cardHeight = 75, cardBorderRadius = 5;
      let xPosCard = 350, yPosCard = 160;
      if (reportType === 'sla') {
        pdf.addImage(img, "jpeg", 40, 90, width - 40 * 2 , height * 0.45);
        // if (typeof data?.sla?.panel?.UPTIME !== 'undefined' && data.sla.panel.UPTIME.length > 0) {
        //   // Text 
        //   pdf.setFontSize(16)
        //   pdf.text('Uptime', xPosCard, yPosCard - marginCard);

        //   data.sla.panel.UPTIME.forEach(({ label, value, unit }, i) => {
        //     // Card Status
        //     const x = xPosCard + (i * (marginCard + cardWidth));
        //     pdf.setDrawColor(0);
        //     pdf.setFillColor(224, 224, 224);
        //     pdf.roundedRect(x, yPosCard, cardWidth, cardHeight, cardBorderRadius, cardBorderRadius, 'FD')
        //     // Text Label
        //     pdf.setFontSize(14);
        //     pdf.text(`${label}`, (x + 10), (yPosCard + 25));
        //     // Text Value
        //     pdf.setFontSize(16);
        //     pdf.text(`${value !== null && value !== 'NaN' ? (typeof value === 'number' ? value.toFixed(0) : value) : '0'} ${unit}`, (x + 10), (yPosCard + 50));
        //     // End Card
        //   });
        // }
      }

      if (reportType === 'slaTunnel') {
        pdf.addImage(img, "jpeg", 0, 100, imgWidth, imgHeight);
        if (typeof data?.slaTunnel?.panel?.UPTIME !== 'undefined' && data.slaTunnel.panel.UPTIME.length > 0) {
          // Text 
          pdf.setFontSize(16)
          pdf.text('Uptime', xPosCard, yPosCard - marginCard);

          data.slaTunnel.panel.UPTIME.forEach(({ label, value, unit }, i) => {
            // Card Status
            const x = xPosCard + (i * (marginCard + cardWidth));
            pdf.setDrawColor(0);
            pdf.setFillColor(224, 224, 224);
            pdf.roundedRect(x, yPosCard, cardWidth, cardHeight, cardBorderRadius, cardBorderRadius, 'FD')
            // Text Label
            pdf.setFontSize(14);
            pdf.text(`${label}`, (x + 10), (yPosCard + 25));
            // Text Value
            pdf.setFontSize(16);
            pdf.text(`${value !== null && value !== 'NaN' ? (typeof value === 'number' ? value.toFixed(0) : value) : '0'} ${unit}`, (x + 10), (yPosCard + 50));
            // End Card
          });

          yPosCard += cardHeight + 25;

          pdf.setFontSize(16)
          pdf.text('Downtime', xPosCard, yPosCard);

          yPosCard += marginCard;

          data.slaTunnel.panel.DOWNTIME.forEach(({ label, value, unit }, i) => {
            // Card Status
            const x = xPosCard + (i * (marginCard + cardWidth));
            pdf.setDrawColor(0);
            pdf.setFillColor(224, 224, 224);
            pdf.roundedRect(x, yPosCard, cardWidth, cardHeight, cardBorderRadius, cardBorderRadius, 'FD')
            // Text Label
            pdf.setFontSize(14);
            pdf.text(`${label}`, (x + 10), (yPosCard + 25));
            // Text Value
            pdf.setFontSize(16);
            pdf.text(`${value !== null && value !== 'NaN' ? (typeof value === 'number' ? value.toFixed(0) : value) : '0'} ${unit}`, (x + 10), (yPosCard + 50));
            // End Card
          });
        }
      }

      if (reportType === 'signal') {
        pdf.addImage(img, "jpeg", 0, 100, imgWidth, imgHeight);

        if (typeof data?.signal?.panel !== 'undefined' && data.signal.panel.length > 0) {
          data.signal.panel.forEach(({ label, value, unit }, i) => {
            // Card Status
            const cardPerRow = 3;
            const reset = i > (cardPerRow - 1) ? true : false;
            const x = xPosCard + ((reset ? (i - cardPerRow) : i) * (marginCard + cardWidth));
            const y = reset ? ((yPosCard - 35) + (marginCard + cardHeight)) : (yPosCard - 35);
            pdf.setDrawColor(0);
            pdf.setFillColor(224, 224, 224);
            pdf.roundedRect(x, y, cardWidth, cardHeight, cardBorderRadius, cardBorderRadius, 'FD')
            // Text Label
            pdf.setFontSize(14);
            pdf.text(`${label}`, (x + 10), (y + 25));
            // Text Value
            pdf.setFontSize(16);
            pdf.text(`${value !== null && value !== 'NaN' ? value : '0'} ${unit}`, (x + 10), (y + 50));
            // End Card
          });
        }
      }

      if (reportType === 'usage') {
        pdf.addImage(img, "jpeg", 40, 100, width * 0.83, height * 0.38);
      }

      if (reportType === 'integration') {
        pdf.addImage(img, "jpeg", 40, 100, width * 0.9, height * 0.3);
      }

      // Text 
      pdf.setFontSize(16)
      // pdf.addPage()
      // pdf.text('Data Table', 40, 40);
      pdf.text('Data Table', 40, yPosCard + 220);
      // Table
      const { head, body } = mapDataTableForPDF(data[reportType]?.table);
      
      pdf.autoTable({
        // startY: 80,
        startY: yPosCard + 230,
        head: head,
        body: body
      });

      pdf.save(`${reportTitle}_${textDate}.pdf`);
      canvas = null
      btn.disabled = false;
    });
  }

  const exportToXls = () => {
    const reportTitle = (reportTemplate !== null && reportTemplate !== '') ? selectedTemplate?.name : mapReportTypeName[reportType];
    let textDate = '';
    if (params.endTime.diff(params.startTime, 'days') <= 1) {
      textDate = `${params.startTime.format('DD MMM YYYY HH:mm:ss')} - ${params.endTime.format('DD MMM YYYY HH:mm:ss')}`;
    } else {
      textDate = `${params.startTime.format('DD MMM YYYY')} - ${params.endTime.format('DD MMM YYYY')}`;
    }
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const { head, body } = mapDataTableForPDF(data[reportType]?.table);
    
    // Create a worksheet and add the headers first
    const ws = XLSX.utils.aoa_to_sheet([head[0], ...body]);
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(excelData, reportTitle + `_${textDate}` + fileExtension);
  };

  const hideThresholdConfig = () => {
    setShowCustomerThreshold(false);
  }
  

  return (
    <PageContent title="Report">
      <ReportRightSideBar visible={showCustomerThreshold} onHide={hideThresholdConfig}/>
      <Card>
        <CardBody>
          <Row className="d-flex justify-content-between">
            <Col lg="4" sm="4" xs="12">
              <div className="d-flex">
                <PrimeDropdown options={reportTypeOptions} value={reportType} onChange={handleReportTypeChange} />
                <Gap width={20} />
                <PrimeDropdown options={[{ value: '', label: 'New Template' }, ...templateOptions]} value={reportTemplate} onChange={handleReportTemplateChange} />
              </div>
            </Col>
            <Col lg="4" sm="4" xs="12" className="d-flex justify-content-end">
              <div className="d-flex">
                {(reportTemplate !== null && reportTemplate !== '') && (
                  <Button type="button" className="btn btn-danger px-5 py-1" onClick={handleDeleleteTemplate}>
                    Delete
                  </Button>
                )}
                <Button type="button" onClick={handleSaveTemplate} className="btn btn-default px-5 py-1" disabled={!formik.dirty || creatingTemplate || updatingTemplate}>
                  {(reportTemplate !== null && reportTemplate !== '') ? 'Save' : 'Save As'}
                  {(creatingTemplate || updatingTemplate) && <Spinner className="ml-2" color="light" size="sm" />}
                </Button>
              </div>
              <ModalSaveAsName isOpen={modalSaveAs} toggle={toggleSaveAs} onSubmit={handleSubmitModalSaveAs} />
            </Col>
          </Row>
          <Row className="mt-4 d-flex justify-content-between align-items-center">
            <Col md="3">
              <h2>{mapReportTypeName[reportType]}</h2>
            </Col>
            <Col className="d-flex justify-content-end">
              <Can I="do" this={DOWNLOAD_USAGE_REPORT}>
                {() => (
                  loadingReportIntegration ||
                  loadingReportSignal ||
                  loadingReportUsage ||
                  loadingReportSLA ||
                  fetchingReportSLA ||
                  fecthingReportSLATunnel ||
                  fetchingReportIntegration ||
                  fetchingReportSignal ||
                  fetchingReportUsage ||
                  loadingReportSLATunnel ? 
                  <ButtonLinkIcon label="Export" icon="fa-download" disabled/>
                  :
                  <UncontrolledDropdown nav direction="down">
                    <DropdownToggle nav>
                      <ButtonLinkIcon label="Export" icon="fa-download" />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={exportToPDF}>Export as Pdf</DropdownItem>
                      <DropdownItem onClick={exportToXls}>Export as Excel</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </Can>
              <Gap width={10} />
              <FilterReport
                filteredCustomerIds={customerIds}
                filteredAreaIds={areaIds}
                filteredIMSI={imsi}
                filteredOperator={operator}
                filteredLocation={location}
                filteredSerialNumber={serialNumber}
                filteredDeviceType={deviceType}
                filteredAvailability={availability}
                filteredIntegrationStatus={integrationStatus}
                onFilter={onSubmitReportFilter}
                reportType={reportType}
                customerOptions={customerOptions}
                areaOptions={areaOptions}
                deviceTypeOptions={deviceTypeOptions}
                imsiOptions={imsiOptions}
                serialNumberOptions={serialNumberOptions}
                operatorOptions={operatorOptions}
                locationOptions={locationOptions}
                availabilityOptions={reportType === 'sla' ? availabilityOptionsSla : availabilityOptions}
                integrationStatusOptions={integrationStatusOptions}
              />
              <Gap width={10} />
              <ButtonLinkIcon label="Configure Threshold" icon="fa-cogs" onClick={() => setShowCustomerThreshold(true)} hidden={reportType !== 'sla'}/>
              <Gap width={10} />
              <div style={{ width: '15rem' }}>
                <BootstrapDateRangePicker onDateChange={onDateChange} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>{title}</h2>
            </Col>
          </Row>
          <Gap height={20} />
          {renderReportType()}
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <h4 className='text-muted mb-0' style={{fontSize: '16px'}}>Details</h4>
            </Col>
            <Col className="d-flex justify-content-end">
              <ConfigureReportColumns onSubmit={onSubmitConfigurationColumns} columnOptions={columnOptions} visibleColumns={visibleColumns} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ReportTable
                loading={loadingReportIntegration || loadingReportSLA || loadingReportSignal || loadingReportUsage || fecthingReportSLATunnel || fetchingReportSLA || fetchingReportIntegration || fetchingReportSignal || fetchingReportUsage}
                data={data[reportType]?.table}
                visibleColumns={visibleColumns}
                params={params}
                keyField={reportType === 'integration' ? 'sequenceId' : 'generatedId'}
                reportType={reportType}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  )
}

export default Report
