import React from 'react';
import PropTypes from 'prop-types';
import { ProgressSpinner } from "primereact/progressspinner";

const Loading = ({ size, ...props}) => {

  const getStyle = () => {
    switch (size) {
      case "sm":
        return {
          width: '24px',
          height: '24px',
        }
      case "md":
        return {
          width: '32px',
          height: '32px',
        }
      case "lg":
        return {
          width: '48px',
          height: '48px',
        }
      default:
        return {};
    }
  }
  return (
    <ProgressSpinner
      style={getStyle()}
      {...props}
    />
  )
};

Loading.propTypes = {
  size: PropTypes.string,
};

Loading.defaultProps = {
  size: "sm",
  strokeWidth: "8"
};

export default Loading;
