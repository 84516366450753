import classnames from "classnames";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Collapse, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { ButtonLinkIcon, ModalChangePassword, RowItem, TritronikInputText } from "../../../components";
import { Can, UPDATE_USER_PROFILE } from "../../../config";
import { FormEditEligibleUser, UserOverview } from '../components';
import { useUpdateUserMutation } from "../service/userApi";
import { editProfileValidation } from './UserValidationSchema';


const mapEligibleZones = (zones) => {
  return zones && zones.map(({ region, areas }) => {
    return {
      ...region,
      areas
    }
  })
}

const UserProfileOverview = ({ data }) => {
  const { name, email, phoneNumber, telegramNumber, username } = data;
  return (
    <Row className="mt-2 mb-5">
      <Col className="px-4 border-right">
        <h3>General Information</h3>
        <RowItem stripped label="Name" value={name} />
        <RowItem label="Email" value={email} />
        <RowItem stripped label="Phone Number" value={phoneNumber} />
        <RowItem label="Telegram Number" value={telegramNumber} />
      </Col>
      <Col className="px-4 border-left">
        <h3>Login Information</h3>
        <RowItem stripped label="Username" value={username} />
        <RowItem label="Password" value={"********"} />
      </Col>
    </Row>
  )
}

const UserProfileConfiguration = ({ data, toggleEditing }) => {
  const [modalChangePassword, setModalChangePassword] = useState(false);

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const onSubmit = async (values) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to update this?",
      text: `User: ${data.username}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        return updateUser({ body: values, params: { updateGroup: true } })
          .unwrap()
          .then(() => {
            toast.success(`User updated.`);
            toggleEditing();
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        telegramNumber: data.telegramNumber,
        username: data.username,
        // password: data.password,
      }}
      validationSchema={editProfileValidation}
      onSubmit={onSubmit}
    >
      {({ values, dirty, setFieldValue }) => (
        <Form>
          <Row className="mt-2 mb-5">
            <Col className="px-4 border-right">
              <h3>General Information</h3>
              <RowItem label="Name" value={(
                <TritronikInputText small name="name" />
              )} />
              <RowItem label="Email" value={(
                <TritronikInputText small name="email" />
              )} />
              <RowItem label="Phone Number" value={(
                <TritronikInputText small name="phoneNumber" />
              )} />
              <RowItem label="Telegram Number" value={(
                <TritronikInputText small name="telegramNumber" />
              )} />
            </Col>
            <Col className="px-4 border-left">
              <h3>Login Information</h3>
              <RowItem label="Username" value={(
                <TritronikInputText small name="username" readOnly />
              )} />
              <RowItem label="Password" value="******" />
            </Col>
          </Row>
          <Row className="mb-5">
            <div className="col text-right">
              <Button className="btn btn-outline-default px-5 py-1 ml-auto" onClick={toggleEditing}>
                Cancel
              </Button>
              <Button className="btn btn-default px-5 py-1" type="submit" disabled={isLoading || !dirty}>
                Apply
                {isLoading && <Spinner className="ml-2" color="light" size="sm" />}
              </Button>
            </div>
          </Row>
          <ModalChangePassword
            isOpen={modalChangePassword}
            currentPassword={values.password}
            minPasswordLength={6}
            maxPasswordLength={16}
            toggle={() => setModalChangePassword(!modalChangePassword)}
            onSubmit={({ newPassword }) => {
              setFieldValue('password', newPassword);
              setModalChangePassword(!modalChangePassword);
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

const UserProfileSection = ({ data }) => {
  const [showProfile, setShowProfile] = useState(true);


  const [editingProfile, setEditingProfile] = useState(false);

  const toggleEditingProfile = () => {
    setEditingProfile(!editingProfile);
  }

  return (
    <div>
      <Row className="border-top">
        <Col className="d-flex justify-content-between align-items-center mt-2">
          <h2>
            Profile
            <i className={classnames("btn btn-link fa", {
              "fa-chevron-down": !showProfile,
              "fa-chevron-up": showProfile,
            })}
              onClick={() => setShowProfile(!showProfile)}
            ></i>
          </h2>
          <Can I="do" this={UPDATE_USER_PROFILE}>
            {() => (
              <ButtonLinkIcon label="Edit" icon="fa-edit" onClick={toggleEditingProfile} />
            )}
          </Can>
        </Col>
      </Row>
      <Collapse isOpen={showProfile}>
        {!editingProfile && <UserProfileOverview data={data} />}
        {editingProfile && <UserProfileConfiguration data={data} toggleEditing={toggleEditingProfile} />}
      </Collapse>
    </div>
  )
}

const UserDetail = ({ data }) => {
  const regionAreas = mapEligibleZones(data.eligibleZones);
  const [showPrivilege, setShowPrivilege] = useState(true);

  const [editing, setEditing] = useState(false);

  const toggleEditing = () => {
    setEditing(!editing);
  }

  return (
    <div className="px-5">
      <Row>
        <Col className="d-flex justify-content-between align-items-center mt-2">
          <h2>
            Privilege
            <i className={classnames("btn btn-link fa", {
              "fa-chevron-down": !showPrivilege,
              "fa-chevron-up": showPrivilege,
            })}
              onClick={() => setShowPrivilege(!showPrivilege)}
            ></i>
          </h2>
          {!editing && (
            <Can I="do" this={UPDATE_USER_PROFILE}>
              {() => (
                <ButtonLinkIcon label="Edit" icon="fa-edit" onClick={toggleEditing} />
              )}
            </Can>
          )}
        </Col>
      </Row>
      <Collapse isOpen={showPrivilege}>
        {!editing && (
          <UserOverview data={data} regionAreas={regionAreas} />
        )}
        {editing && (
          <FormEditEligibleUser data={data} regionAreas={regionAreas} toggleEditing={toggleEditing} />
        )}
      </Collapse>
      <UserProfileSection data={data} />
    </div>
  );
};

export default UserDetail;
