import { useCreateRoleMutation } from "features/user/service/roleApi";
import { useCallback } from "react";

export function useCreateRole() {
  const [create, result] = useCreateRoleMutation();

  const handleCreate = useCallback(
    async (data) => {
      const role = await create(data).unwrap();

      return role;
    },
    [create]
  );

  return {
    create,
    handleCreate,
    ...result,
  };
}
