import React from 'react';
import classnames from 'classnames';
import { Badge } from 'reactstrap';
import { ucFirst, lowercase } from './../../../utils';

export const CustomCellStatusBadge = ({ row, name, successFlag = 'success', warningFlag = 'warning', dangerFlag = 'danger', defaultFlag='gray' }) => {
  return (
    <Badge color="" className="badge-dot mr-4">
      <i className={classnames({
        "bg-success": lowercase(row[name]) === lowercase(successFlag),
        "bg-yellow": lowercase(row[name]) === lowercase(warningFlag),
        "bg-danger": lowercase(row[name]) === lowercase(dangerFlag),
        "bg-gray": lowercase(row[name]) === lowercase(defaultFlag),
      })} />
      <span>{ucFirst(row[name])}</span>
    </Badge>
  )
}
