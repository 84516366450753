import { CustomExpandableIcon, PageContent } from "components";
import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "reactstrap";
import { customStyles } from "utils/datatableStyles";
import RouterInterfaceUnitDetails from "../components/RouterInterfaceUnitDetails";
import { useGetRouterInterfaceUnitQuery } from "../service";

const RouterInterfaceUnit = () => {
  const path = window.location.href;
  const arrId = path.split("/").splice(-4);
  const ipAddress = arrId[0];
  const routerId = arrId[1];
  const interfaceName = arrId[2].split('%2F').join('/');
  const routerInterfaceId = arrId[3];
  const linkToRouterInterface = `/admin/router-list/router-interface/${ipAddress}/${routerId}`;
  const { data: routerInterfaceUnits, isLoading } =
    useGetRouterInterfaceUnitQuery({ routerInterfaceId: routerInterfaceId });

  const columns = [
    {
      name: "LOGICAL INTERFACE",
      center: true,
      hide: "md",
      selector: (row) => row?.logicalInterface,
    },
    {
      name: "LOGICAL NAME",
      center: true,
      hide: "md",
      selector: (row) => row?.logicalName,
    },
    {
      name: "ADMIN STATUS",
      left: true,
      hide: "md",
      cell: (row) =>
        row?.adminStatus === "up" ? (
          <div>
            <i
              style={{ color: "limegreen", marginRight: "10px" }}
              className="mdi mdi-arrow-up mr-2"
            ></i>
            {row.adminStatus.toUpperCase()}
          </div>
        ) : (
          <div>
            <i
              style={{ color: "red", marginRight: "10px" }}
              className="mdi mdi-arrow-up mr-2"
            ></i>
            {row.adminStatus.toUpperCase()}
          </div>
        ),
    },
    {
      name: "LINK STATUS",
      left: true,
      hide: "md",
      cell: (row) =>
        row?.linkStatus === "up" ? (
          <div>
            <i
              style={{ color: "limegreen", marginRight: "10px" }}
              className="mdi mdi-arrow-up mr-2"
            ></i>
            {row.linkStatus.toUpperCase()}
          </div>
        ) : (
          <div>
            <i
              style={{ color: "red", marginRight: "10px" }}
              className="mdi mdi-arrow-up mr-2"
            ></i>
            {row.linkStatus.toUpperCase()}
          </div>
        ),
    },
  ];
  const ExpandedComponent = (props) => {
    return <RouterInterfaceUnitDetails {...props} />;
  };

  return (
    <PageContent title="Router Interface Unit">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <Breadcrumb listClassName="bg-white p-0 m-0">
                <BreadcrumbItem>
                  <Link to="/admin/router-list" className="text-lg">
                    All Router
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-lg">
                  <Link to={linkToRouterInterface} className="text-lg">
                    {ipAddress}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-lg">
                  {interfaceName}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-lg-end align-items-center"
            >
              <Button size="sm" color="primary">
                <Link to={linkToRouterInterface} style={{ color: "white" }}>
                  <i className="mdi mdi-arrow-left mr-2"></i>
                  Back
                </Link>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={routerInterfaceUnits?.content}
                columns={columns}
                progressPending={isLoading}
                persistTableHead
                highlightOnHover
                noHeader
                striped
                pagination
                customStyles={customStyles}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                expandableIcon={{
                  collapsed: <CustomExpandableIcon type="collapsed" />,
                  expanded: <CustomExpandableIcon type="expanded" />,
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default RouterInterfaceUnit;
