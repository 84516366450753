import React from "react";
import classnames from "classnames";
import { Badge } from "reactstrap";
import _ from 'lodash';

const StatusBadge = ({ label, type = "success", isUppercase }) => {
  return (
    <Badge color="" className="badge-dot">
      <i
        className={classnames({
          "bg-success": type === "success",
          "bg-yellow": type === "warning",
          "bg-danger": type === "danger" || type === "error",
          "bg-light": type === "none",
        })}
      />
      <span>{isUppercase ? _.toUpper(label) : _.upperFirst(label)}</span>
    </Badge>
  );
};

export default StatusBadge;
