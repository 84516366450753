import React from 'react'
import { Row, Col } from 'reactstrap';

const AggregateTable = ({ data, columns }) => {
    return (
        <Row className="mt-4">
            <Col>
                <table className='table table-sm table-bordered'>
                    <thead>
                        <tr>
                            {columns && columns.map((c,j) => (
                                <th key={`column-${j}`} scope='col'>{c}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((agg, i) => (
                            <tr key={`aggregate-${i}`}>
                                {agg.name && <th scope='row'>{agg.name}</th>}
                                {agg.min && <td>{agg.min}</td>}
                                {agg.max && <td>{agg.max}</td>}
                                {agg.avg && <td>{agg.avg}</td>}
                                {agg.total && <td>{agg.total}</td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Col>
        </Row>
    )
}

export default AggregateTable;