import { Formik } from "formik";
import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import * as Yup from "yup";

import {
  RowItem,
  CustomLabel,
  TritronikInputText,
  Gap,
  TritronikSelect,
} from "./../../../components";
import {
  deviceApi,
  useIsSimcardCircuitNumberExistMutation,
} from "../service/deviceApi";
import { useUpdateSimcardMutation as useUpdateSimcardInventoryMutation } from "./../../inventory/simcards/services/simcardInventoryApi";
import Swal from "utils/swal";
import { useDispatch } from "react-redux";
import { useQuotaUom } from "hooks";

const validationSchema = Yup.object().shape({
  gsmNumber: Yup.string().required("GSM Number required."),
  circuitNumber: Yup.string().required("SIA required."),
  quotaPackage: Yup.number().required("Quota Package required."),
  quotaPackageUom: Yup.string().required("Quota Package UOM required."),
});


const ModalAddCIDSimcard = NiceModal.create(
  ({
    id = "-",
    customerName = "-",
    location = "-",
    sn = "-",
    simCard,
    type = "-",
    region = "-",
    area = "-",
  }) => {
    const modal = useModal();
    const { options: uomOptions } = useQuotaUom();
    const dispatch = useDispatch();
    const [checkCircuitNumber] = useIsSimcardCircuitNumberExistMutation();
    const [updateSimInventory, { isLoading }] =
      useUpdateSimcardInventoryMutation();

    const validateCircuitNumber = async (circuitNumber) => {
      let error;

      if (circuitNumber !== "" && simCard && simCard.id) {
        const exists = await checkCircuitNumber({
          id: simCard.id,
          circuitNumber,
        }).unwrap();
        if (exists) {
          error = "SIA already exists.";
        }
      }
      return error;
    };

    const validateGsmNumber = (gsmNumber) => {
      const numericRegex = /^[0-9]+$/;
      const isNumeric = numericRegex.test(gsmNumber);
      let error;

      if (!isNumeric) {
        error = "Numeric Character Only";
      }
      return error;
    };

    const onCancel = () => {
      modal.hide();
    };

    const onSubmit = async (values, { resetForm }) => {
      Swal.fire({
        icon: "question",
        title: "Update SIM Card information?",
        text: `Slot Number: ${simCard.slotNumber}, Operator: ${simCard.operator}.`,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        reverseButtons: true,
        preConfirm: async () => {
          const { circuitNumber, gsmNumber, quotaPackage, quotaPackageUom } = values;
          try {
            if (simCard && simCard.id) {
              await updateSimInventory({
                id: simCard.id,
                params: {
                  updatePrimary: false,
                  updateQuotaLimit: true,
                },
                circuitNumber,
                gsmNumber,
                quotaLimitValue: quotaPackage,
                quotaLimitUom: quotaPackageUom
              }).unwrap();
              resetForm();

              dispatch(deviceApi.util.invalidateTags(["Device"]));
              modal.remove();
              return true;
            }

            return false;
          } catch (error) {
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    };

    return (
      <Modal
        size="lg"
        isOpen={modal.visible}
        toggle={modal.hide}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={modal.hide}>Add SIA</ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            circuitNumber: simCard?.circuitNumber ?? "",
            gsmNumber: simCard?.gsmNumber ?? "",
            quotaPackageUom: simCard.quotaPackageUom ?? "GB",
            quotaPackage: simCard.quotaPackage ?? "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ dirty, handleSubmit, setFieldValue, values }) => (
            <>
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <Row className="px-5">
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="Device SN"
                        value={sn}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="Device ID"
                        value={id}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="IMSI"
                        value={simCard.imsi}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="Operator"
                        value={simCard.operator}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="Location"
                        value={location}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="Customer"
                        value={customerName}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="Region"
                        value={region}
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <RowItem
                        className="border h-100"
                        boldLabel
                        label="Area"
                        value={area}
                      />
                    </Col>
                  </Row>
                  <hr className="mx-4" />
                  <Row>
                    <Col className="col-12 px-5 border-right">
                      <RowItem
                        boldLabel
                        rightCol="col-8"
                        label={<CustomLabel name="SIA" required />}
                        value={
                          <TritronikInputText
                            small
                            name={`circuitNumber`}
                            placeholder="SIA"
                            validate={(val) => validateCircuitNumber(val)}
                          />
                        }
                      />
                      <RowItem
                        boldLabel
                        rightCol="col-8"
                        label={<CustomLabel name="GSM Number" required />}
                        value={
                          <TritronikInputText
                            small
                            name={`gsmNumber`}
                            placeholder="GSM Number"
                            validate={(val) => validateGsmNumber(val)}
                          />
                        }
                      />
                      <RowItem
                        boldLabel
                        rightCol="col-8"
                        label={<CustomLabel name="Quota" required />}
                        value={
                          <div className="d-flex w-100 justfiy-content-between">
                            <TritronikInputText
                              small
                              name={`quotaPackage`}
                              placeholder="Quota"
                              validate={(val) => validateGsmNumber(val)}
                              // readOnly={!canConfigure}
                            />
                            <Gap width={20} />
                            <TritronikSelect
                              name={`quotaPackageUom`}
                              value={values.quotaPackageUom}
                              onChange={(e) =>
                                setFieldValue(
                                  `quotaPackageUom`,
                                  e.value
                                )
                              }
                              options={uomOptions}
                              placeholder="UOM"
                              // disabled={!canConfigure}
                            />
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="btn btn-outline-default px-5 py-1 ml-auto"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-default px-5 py-1"
                    type="submit"
                    disabled={isLoading || !dirty}
                  >
                    Apply
                    {isLoading && (
                      <Spinner className="ml-2" color="light" size="sm" />
                    )}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </Formik>
      </Modal>
    );
  }
);

export default ModalAddCIDSimcard;
