import React from "react";
import PropTypes from "prop-types";
import { startCase, upperCase } from "lodash";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Loading } from "components";

const Widget01 = ({ title, value, label, loading, onClick }) => {
  const clickable = parseInt(value) === 0 ? false : true;
  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col>
            <CardTitle tag="h3" className="text-muted">
              {upperCase(title)}
            </CardTitle>
          </Col>
        </Row>
        <h2
          onClick={clickable ? onClick : null}
          style={{ cursor: clickable && onClick ? "pointer" : "default" }}
          className="text-dark"
        >
          {loading ? <Loading /> : value}
        </h2>
        <span>{startCase(label)}</span>
      </CardBody>
    </Card>
  );
};

Widget01.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  loading: PropTypes.bool,
};

Widget01.defaultProps = {
  title: "Title",
  value: 0,
  label: "Total",
  onClick: () => {},
  loading: false,
};

export default Widget01;
