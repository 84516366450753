import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNetworkMemberThunk, selectMembersByNetworkId } from "../networkMemberSlice";

export function useNetworkMembers(networkId) {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const members = useSelector(state => selectMembersByNetworkId(state, networkId));

	const fetchMembers = useCallback(async () => {
		setIsLoading(true);
		await dispatch(fetchNetworkMemberThunk(networkId));
		setIsLoading(false);
	}, [dispatch, networkId]);

	useEffect(() => {
		fetchMembers();
	}, [fetchMembers]);

	return {
		data: members,
		isLoading,
		refetch: fetchMembers,
	}
}