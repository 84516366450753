import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { StringParam, useQueryParams } from 'use-query-params'
import DataTable from "react-data-table-component";

// import { Can } from './../../../../config'
import { CreateDeviceInventoryModal, CustomActionButton, EditDeviceInventoryModal } from '../components'
import { useDeviceInventoryDatatables } from './../hooks'
import { PageContent, PrimeSearchInput, ButtonLinkIcon, CustomCellStatusBadge, Tags } from './../../../../components'
import { momentLastUpdate, paginationRowsPerPageOptions } from './../../../../utils';
import { customStyles } from './../../../../utils/datatableStyles';
import { useDeleteDeviceMutation } from '../services/deviceInventoryApi';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import FilterDeviceInventory from '../components/FilterDeviceInventory';
import { useDeviceType } from './../../../../hooks';
import { useUnprovisionDeviceMutation } from './../services'

const DeviceInventoryList = () => {
    const searchRef = useRef();
    const [modalCreate, setModalCreate] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);

    const [deleteDevice] = useDeleteDeviceMutation();
    const [unprovisionDevice] = useUnprovisionDeviceMutation();

    const { options: deviceTypeOptions } = useDeviceType([{ value: null, label: 'All' }]);

    const [query, setQuery] = useQueryParams({
        smartSearch: StringParam,
        sn: StringParam,
        assetStatus: StringParam,
        type: StringParam,
        name: StringParam,
    });

    const {
        data,
        isLoading,
        totalRows,
        pageSize,
        isFetching,
        onChangePage,
        onChangeRowsPerPage,
        onSort,
        onSelectedRowsChange,
    } = useDeviceInventoryDatatables(query, { sortFields: 'creationTime', sortOrder: 'desc' });

    const onEditDevice = useCallback((device) => {
        setSelectedDevice(device);
        setModalEdit(true);
    }, [setModalEdit, setSelectedDevice]);

    const onDeleteDevice = useCallback((device) => {
        Swal.fire({
            title: "Are you sure want to delete this?",
            text: `Device: ${device.sn}?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes!",
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: async () => {
                return deleteDevice(device.id)
                    .unwrap()
                    .then(() => {
                        toast.success("Device deleted.");
                        return true;
                    });
            },
        });
    }, [deleteDevice]);

    const onUnprovisionDevice = useCallback((device) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure you want to unprovision this device?",
            text: `Device: ${device.sn}, Type: ${device.type}.`,
            showLoaderOnConfirm: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            reverseButtons: true,
            preConfirm: async () => {
              return unprovisionDevice(device.id)
                .unwrap()
                .then(() => {
                  toast.success("Device has been unprovisioned.");
                  return true;
                })
                .catch((error) => {
                  Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
                  return false;
                });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          });
    }, [unprovisionDevice]);

    const columns = useMemo(() => {

        const textTemplate = (data, field) => {
            return data[field] || '-';
        }

        return [
            {
                id: 'sn',
                name: 'SERIAL NUMBER',
                selector: row => row.sn,
                sortable: true,
                sortField: 'sn',
                cell: row => textTemplate(row, 'sn')
            },
            {
                id: 'snHardware',
                name: 'SN HARDWARE',
                selector: row => row.snHardware,
                sortable: true,
                sortField: 'snHardware',
                cell: row => textTemplate(row, 'snHardware')
            },
            {
                id: 'type',
                name: 'TYPE',
                selector: row => row.type,
                sortable: true,
                sortField: 'type',
                cell: row => textTemplate(row, 'type')
            },
            {
                id: 'inventoryId',
                name: 'INVENTORY ID',
                selector: row => row.inventoryId,
                sortable: true,
                sortField: 'inventoryId',
                cell: row => textTemplate(row, 'inventoryId')
            },
            {
                id: 'registerDate',
                name: 'REGISTER DATE',
                selector: row => row.creationTime,
                cell: row => momentLastUpdate(row.creationTime, 'DD MMMM YYYY'),
                sortable: true,
                sortField: 'creationTime'
            },
            {
                id: 'name',
                name: 'NAME',
                selector: row => row.name,
                sortable: true,
                sortField: 'name',
                cell: row => textTemplate(row, 'name')
            },
            {
                id: 'assetStatus',
                name: 'STATUS',
                selector: row => row.assetStatus,
                cell: row => (
                    <CustomCellStatusBadge
                        row={row}
                        name='assetStatus'
                        successFlag="PROVISION"
                        defaultFlag="UNPROVISION"
                    />
                ),
                sortable: true,
                sortField: 'assetStatus'
            },
            {
                name: '',
                width: '40px',
                allowOverflow: true,
                button: true,
                cell: (row) => (
                    <CustomActionButton
                        row={row}
                        onEdit={onEditDevice}
                        onDelete={onDeleteDevice}
                        onUnprovisionDevice={onUnprovisionDevice}
                    />
                ),
            },
        ]
    }, [onEditDevice, onDeleteDevice, onUnprovisionDevice]);

    const onSearch = (searchText) => {
        setQuery({ smartSearch: searchText !== "" && searchText !== null ? searchText : undefined });
    }

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.changeValue(query.smartSearch || '');
        }
    }, [query]);

    const onCreateDeviceClick = () => {
        setModalCreate(true);
    }

    const toggleModalCreate = () => {
        setModalCreate(!modalCreate);
    }

    const toggleModalEdit = () => {
        setModalEdit(!modalEdit);
    }

    const onRemoveFilter = useCallback((key = null) => {
        if (key) {
            setQuery({ [key]: undefined });
        }
    }, [setQuery]);

    const onResetFilter = useCallback(() => {
        setQuery({
            smartSearch: undefined,
            sn: undefined,
            assetStatus: undefined,
            name: undefined,
            type: undefined,
        });
    }, [setQuery]);

    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
    }

    const filterTags = useMemo(() => {
        const tags = [];

        Object.keys(query).forEach((key) => {
            const value = query[key];
            if (value !== undefined) {
                tags.push({
                    key: key,
                    value: value,
                    label: value,
                    onRemove: () => onRemoveFilter(key)
                })
            }
        });

        return tags;
    }, [query, onRemoveFilter]);

    const onFilterDevice = (values) => {
        if (values) {
            setQuery({
                assetStatus: values.assetStatus || undefined,
                type: values.type || undefined,
            });
        }
    }

    return (
        <PageContent title="Devices Inventory">
            <Card>
                <CardBody>
                    <Row className="mb-2">
                        <Col xs="12" md="6">
                            <h2 className="text-dark">All Devices</h2>
                        </Col>
                        <Col xs="12" md="6" className="d-flex justify-content-between justify-content-lg-end align-items-center">
                            <PrimeSearchInput
                                ref={searchRef}
                                block
                                onFilter={onSearch}
                                size="sm"
                                tooltip="Press 'Enter' to search."
                                tooltipOptions={{ position: "top" }}
                            />

                            <FilterDeviceInventory
                                isLoading={isFetching || isLoading}
                                onFilter={onFilterDevice}
                                deviceTypeOptions={deviceTypeOptions}
                                filters={query}
                                isOpen={filterOpen}
                                toggle={toggleFilter}
                            />
                            <ButtonLinkIcon label="Add New" onClick={onCreateDeviceClick} />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Tags tags={filterTags} onReset={onResetFilter} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable
                                data={data}
                                columns={columns}
                                progressPending={isLoading}
                                keyField='id'
                                defaultSortAsc={false}
                                defaultSortFieldId={'registerDate'}
                                persistTableHead
                                highlightOnHover
                                noHeader
                                striped
                                onSelectedRowsChange={onSelectedRowsChange}
                                pagination={true}
                                paginationServer={true}
                                paginationTotalRows={totalRows}
                                paginationPerPage={pageSize}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                sortServer={true}
                                onSort={onSort}
                                customStyles={customStyles}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <CreateDeviceInventoryModal isOpen={modalCreate} toggle={toggleModalCreate} />
            <EditDeviceInventoryModal device={selectedDevice} isOpen={modalEdit} toggle={toggleModalEdit} />
        </PageContent>
    )
}

export default DeviceInventoryList
