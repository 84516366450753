import { useLocation } from "react-router-dom";

export function generateRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const dynamicColors = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};


export const ucFirst = (text) => {
  if (!text) return;
  const toLower = text.toLowerCase();
  return toLower.charAt(0).toUpperCase() + toLower.slice(1);
}

export const uppercase = (text) => {
  return text && text.toUpperCase();
}

export const lowercase = (text) => {
  return text && text.toLowerCase();
}

export const unknown = '-';

export function formatBytes(bytes, decimals = 2) {
  if (typeof bytes === 'string') {
    bytes = parseInt(bytes);
  }
  if (bytes === 0) return '0 Bytes';

  // TODO: Make agreement to use 1024 or 1000 as conversion rate on bytes
  const k = 1000; // Default 1024
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatBits(bytes, decimals = 2) {
  if (typeof bytes === 'string') {
    bytes = parseInt(bytes);
  }
  if (bytes === 0) return '0 kbps';
  
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['kbps', 'mbps', 'gbps', 'tbps', 'pbps', 'ebps', 'zbps', 'ybps'];

  const i = Math.floor(bytes / k);

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getUomFromBytes(bytes) {
  if (bytes === 0) return 'Bytes';
  const formatted = formatBytes(bytes);
  return formatted.split(' ').slice(-1).join('');
}

export const onKeyDown = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export const datasetSimCard = {
  1: {
    type: 'line',
    label: 'SIM 1 Usage',
    fill: true,
    lineTension: 0.1,
    backgroundColor: 'rgba(91, 204, 114, 0.3)',
    borderColor: '#5BCC72',
    hoverBackgroundColor: '#5BCC72',
    hoverBorderColor: '#5BCC72',
    borderWidth: 2
  },
  2: {
    type: 'line',
    label: 'SIM 2 Usage',
    fill: true,
    lineTension: 0.1,
    backgroundColor: 'rgba(247, 188, 69, 0.3) ',
    borderColor: '#F7BB45 ',
    hoverBackgroundColor: '#F7BB45 ',
    hoverBorderColor: '#F7BB45 ',
    borderWidth: 2
  },
}

export function useQuery(){
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return { query, location };
}

export const paginationRowsPerPageOptions = [10, 20, 50, 100];

export const billingCycleOptions = () => {
  let options = [];
  for (let i = 1; i <= 31; i++) {
    options.push({ value: `${i}`, label: i });
  }
  return options;
};

export function formatNumber(number, options, locales = 'en-US') {
  const formatter = new Intl.NumberFormat(locales, options);

  return formatter.format(number);
}