import { RowItem } from "components";
import React from "react";
import { Col, Row } from "reactstrap";
import { useGetRouterbyIdQuery, useGetRouterInterfacebyIdQuery } from "../service";

const InterfaceUnitOverviewTab = ({ data }) => {
  const path = window.location.href;
  const arrId = path.split("/").splice(-4);
  const routerId = arrId[1];
  const routerInterfaceId = arrId[3];
  const { data: router } = useGetRouterbyIdQuery(routerId);
  const { data: routerInterface } = useGetRouterInterfacebyIdQuery(routerInterfaceId);
  
  return (
    <Row>
      <Col className="border-right px-5 pb-5">
        <div>
          <Row className="mt-4">
            <Col>
              <RowItem
                label="Router Name"
                value={router?.device}
              />
              <RowItem
                label="OS"
                value={router?.os}
                stripped
              />
              <RowItem label="Hostname" value={router?.hostname} />
              <RowItem label="IP Address" value={router?.ipAddress} stripped />
              <RowItem label="Interface Name" value={routerInterface?.interfaceName} />
              <RowItem label="Physical Name" value={routerInterface?.physicalName} stripped />
            </Col>
          </Row>
        </div>
      </Col>
      <Col className="border-right px-5 pb-5">
        <div>
          <Row className="mt-4">
            <Col>
              <RowItem
                label="Logical Interface"
                value={data.logicalInterface}
              />
              <RowItem
                label="Logical Name"
                value={data.logicalName}
                stripped
              />
              <RowItem label="Admin Status" value={data.adminStatus} />
              <RowItem label="Link Status" value={data.linkStatus} stripped />
              {/* <RowItem label="Policy Downstream" value={data.policyDownstreamId} />
              <RowItem label="Policy Upstream" value={data.policyUpstreamId} stripped /> */}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default InterfaceUnitOverviewTab;
