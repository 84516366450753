import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./../../../services/baseApi";

export const policyApi = createApi({
  reducerPath: "policyApi",
  baseQuery: baseQuery,
  tagTypes: ["Policy"],
  endpoints: (builder) => ({
    getPolicy: builder.query({
      query: () => ({
        url: "/policy/",
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "Policy",
                id,
              })),
              { type: "Policy", id: "LIST" },
            ]
          : [{ type: "Policy", id: "LIST" }],
    }),
    createPolicy: builder.mutation({
      query: (body) => ({
        url: "/policy/",
        method: "POST",
        body,
      }),
      invalidatesTags:[{ type: "Policy", id: "LIST" }],
    }),
    deletePolicy: builder.mutation({
      query: (id) => ({
        url: `/policy/${id}`,
        method: "DELETE",
      }),
      invalidatesTags:[{ type: "Policy", id: "LIST" }],
    }),
    updatePolicy: builder.mutation({
      query: ({id, body}) => ({
        url: `/policy/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags:[{ type: "Policy", id: "LIST" }],
    }),
  }),
});

export const {
    useGetPolicyQuery,
    useCreatePolicyMutation,
    useDeletePolicyMutation,
    useUpdatePolicyMutation,
} = policyApi