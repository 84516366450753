import { useMemo } from 'react';
import { useGetReferencesQuery } from '../services/referenceApi';

export function useServiceApplications() {
  const { data, isLoading } = useGetReferencesQuery('service-application');

  const options = useMemo(() => {
    const opts = [];

    if (data) {
      data.forEach((opt) => {
        opts.push({ value: opt.key, label: opt.value });
      });
    }

    return opts;
  }, [data]);

  return {
    data,
    isLoading,
    options,
  };
}