import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Row, Col } from "reactstrap";
import { momentLastUpdate } from "utils";
import { paginationRowsPerPageOptions } from "utils";
import { useGetAlertHistoryDetailsQuery } from './../service/alertHistoryApi';
import _ from "lodash";

import { RowItem, Loading } from "./../../../components";

const CurrentAlertTableDetail = ({ data }) => {
  const { data: detail, isLoading } = useGetAlertHistoryDetailsQuery(data.id);

  const alertMessage = useMemo(() => {
    if (detail && detail.detailInfo) {
      const messageDetail = Object.keys(detail.detailInfo).map((key, i) => {
        const label = _.startCase(_.toLower(key));
        return <RowItem stripped={i % 2 === 0} key={`detail-info-${i}`} label={label} value={detail.detailInfo[key]} />
      });
      return messageDetail;
    }

    return <p>Alert Message Empty</p>;
  }, [detail]);

  const recipients = useMemo(() => {
    if (detail && detail.alertNotificationDetails) {
      return detail.alertNotificationDetails
    }

    return [];
  }, [detail]);

  const columns = useMemo(() => {
    return [
      {
        name: 'RECIPIENT',
        selector: row => row?.recipient,
        sortable: false,
      },
      {
        name: 'STATUS',
        selector: row => row?.status,
        sortable: false,
      },
      {
        name: 'DATETIME',
        selector: row => row?.timeMs,
        sortable: false,
        cell: ({ timeMs }) => momentLastUpdate(timeMs)
      },
    ]
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <Loading />
      </div>
    )
  }

  return (
    <Row>
      <Col className="px-5">
        <Row className="my-3">
          <Col className="px-5">
            <h2>Alert Details</h2>
          </Col>
        </Row>
        <Row>
          <Col className="px-5" lg="6" sm="12">
            <h2 className="text-dark">Alert Message</h2>
            {alertMessage}
          </Col>
        </Row>
        <hr className="px-5" />
        <Row className="mb-5">
          <Col className="px-5" >
            <h2 className="text-dark">Alert Delivery Status</h2>
            <DataTable
              data={recipients}
              columns={columns}
              striped
              noHeader
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, ...paginationRowsPerPageOptions]}
              noDataComponent="There are no alert delivery status to display"
              customStyles={{
                head: {},
                headRow: {
                  style: {
                    backgroundColor: "#F1F3F9"
                  }
                },
                expanderCell: {
                  style: {
                    flex: '48px 48px'
                  }
                }
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CurrentAlertTableDetail;
