import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./../../../services/baseApi";

export const featureApi = createApi({
  reducerPath: "featureApi",
  baseQuery: baseQuery,
  tagTypes: ["Feature"],
  endpoints: (builder) => ({
    getFeatures: builder.query({
      query: (params) => ({
        url: `/privilege/features`,
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "Feature", id })),
              { type: "Feature", id: "LIST" },
            ]
          : [{ type: "Feature", id: "LIST" }],
    }),
    getFeature: builder.query({
      query: (id) => ({ url: `/privilege/feature/${id}` }),
      providesTags: (result, error, id) => [{ type: "Feature", id }],
    }),
    createFeature: builder.mutation({
      query: (body) => ({
        url: `/privilege/feature`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Feature", id: "LIST" }],
    }),
    updateFeature: builder.mutation({
      query: (body) => ({
        url: `/privilege/feature/${body.id}`,
        method: "PUT",
        body,
      }),
      onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          featureApi.util.updateQueryData("getFeature", id, (draft) => {
            Object.assign(draft, patch);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Feature", id }],
    }),
    deleteFeature: builder.mutation({
      query(id) {
        return {
          url: `/privilege/feature/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: "Feature", id },
        { type: "Feature", id: "LIST" },
      ],
    }),
    setFeatureToRole: builder.mutation({
      query({ featureId, roleId }) {
        return {
          url: `/privilege/feature/${featureId}/role/${roleId}/`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { featureId }) => [
        { type: "Feature", id: featureId },
      ],
    }),
    getFeaturesGroupCategory: builder.query({
      query: (params) => ({
        url: "/privilege/feature/group-by-category",
        params,
      }),
    }),
  }),
});

export const {
  useGetFeatureQuery,
  useGetFeaturesQuery,
  useCreateFeatureMutation,
  useUpdateFeatureMutation,
  useDeleteFeatureMutation,
  useSetFeatureToRoleMutation,
  useGetFeaturesGroupCategoryQuery,
} = featureApi;
