import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Dropdown, DropdownMenu, DropdownToggle, Nav, Row, Spinner } from 'reactstrap';
import { ButtonLinkIcon, PrimeDropdown, RowItem } from '../../../../components';

const assetStatusOptions = [
    { value: null, label: 'All' },
    { value: 'ASSIGNED', label: 'Assigned' },
    { value: 'UNASSIGNED', label: 'Unassigned' },
];

const FilterSimcardInventory = ({
    isOpen,
    toggle,
    operatorOptions,
    onFilter,
    filters,
    isLoading,
}) => {

    const { values, dirty, setFieldValue, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: {
            operator: filters?.operator || null,
            assetStatus: filters?.assetStatus || null,
        },
        onSubmit: onFilter
    });

    return (
        <Nav navbar>
            <Dropdown nav direction="down" isOpen={isOpen} toggle={toggle}>
                <DropdownToggle nav>
                    <ButtonLinkIcon label="Filters" icon="fa-filter" />
                </DropdownToggle>
                <DropdownMenu right>
                    <div style={{ minWidth: 350 }} className="p-3">
                        <RowItem
                            label="Operator"
                            value={
                                <PrimeDropdown
                                    options={operatorOptions || []}
                                    value={values.operator}
                                    onChange={(e) => setFieldValue('operator', e.value)}
                                />
                            }
                            rightCol="col-7"
                        />
                        <RowItem
                            label="Status"
                            value={
                                <PrimeDropdown
                                    options={assetStatusOptions || []}
                                    value={values.assetStatus}
                                    onChange={(e) => setFieldValue('assetStatus', e.value)}
                                />
                            }
                            rightCol="col-7"
                        />
                        <Row className="mt-4">
                            <Col className="text-center">
                                <Button className="m-0" block color="default" size="sm" type="button" onClick={handleSubmit} disabled={isLoading || !dirty}>
                                    Apply
                                    {isLoading && <Spinner className="ml-2" color="light" size="sm" />}
                                </Button>
                                <Button className="m-0 mt-2" block color="secondary" size="sm" onClick={toggle}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </Nav>
    )
};


export default FilterSimcardInventory;
