import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormFeedback,
} from "reactstrap";

const InputTextGroup = (props) => {
  const {
    alternative,
    icon,
    invalid,
    error,
    placeholder,
    type,
    valid,
    ...rest
  } = props;
  return (
    <FormGroup
      className={classnames({
        "has-danger": invalid,
      })}
    >
      <InputGroup
        className={classnames("input-group-merge", {
          "input-group-alternative": alternative,
          "is-invalid": invalid,
        })}
      >
        <InputGroupAddon addonType="prepend">
          <InputGroupText
            className={classnames({
              "text-danger": invalid,
            })}
          >
            <i className={icon} />
          </InputGroupText>
        </InputGroupAddon>
        <Input placeholder={placeholder} type={type} {...rest} />
      </InputGroup>
      {invalid && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

InputTextGroup.propTypes = {
  alternative: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  valid: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

InputTextGroup.defaultProps = {
  alternative: true,
  error: null,
  icon: "ni ni-email-83",
  invalid: false,
  valid: false,
  placeholder: "",
  type: "text",
};

export default InputTextGroup;
