import React, { useState } from "react";
import classnames from "classnames";
import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { Field } from "formik";
import './TritronikInputPassword.scss';

const TritronikInputPassword = ({ label, name, small, required, ...props }) => {
  const [type, setType] = useState("password");

  const togglePassword = () => {
    setType((currentType) =>
      currentType === "password" ? "text" : "password"
    );
  };
  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }) => (
        <FormGroup
          className={classnames("mb-0 w-100", {
            "has-danger": Boolean(meta.touched && meta.error),
          })}
        >
          {label && (
            <Label className="control-label">
              {label}
              {required ? <span className="text-danger ml-1">*</span> : null}
            </Label>
          )}
          <InputGroup
            size={small ? "sm" : "md"}
            className={classnames({
              "is-invalid": Boolean(meta.touched && meta.error),
            })}
          >
            <Input
              {...field}
              {...props}
              invalid={Boolean(meta.touched && meta.error)}
              type={type}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  style={{ cursor: "pointer" }}
                  className={classnames(`text-default fa`, {
                    "fa-eye": type === "password",
                    "fa-eye-slash": type === "text",
                  })}
                  onClick={togglePassword}
                ></i>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          {meta.touched && meta.error && (
            <FormFeedback>{meta.error}</FormFeedback>
          )}
        </FormGroup>
      )}
    </Field>
  );
};

export default TritronikInputPassword;
