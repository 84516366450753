import { Loading } from "components";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import Swal from "sweetalert2";
import { PrimeMultiSelect } from "./../../../components";
import { useUpdateDeviceMutation } from './../service/deviceApi';

function ModalSetGroup({ isOpen, toggle, title, selectedRows, groupOptions, clearSelectedRows }) {
  const [loading, setLoading] = useState(false);

  const [updateDevice] = useUpdateDeviceMutation();

  const {
    handleSubmit,
    isSubmitting,
    setFieldValue,
    dirty,
    values,
    resetForm
  } = useFormik({
    initialValues: {
      groupIds: [],
    },
    onSubmit: async ({ groupIds }, { resetForm }) => {
      Swal.fire({
        icon: "question",
        title: "Are you sure want to update this?",
        text: `Device group`,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        reverseButtons: true,
        preConfirm: async () => {
          setLoading(true);
          try {
            for (let i=0; i < selectedRows.length; i++) {
              await updateDevice({
                id: selectedRows[i].id,
                body: { groupIds },
                params: { updateGroup: true }
              }).unwrap();
            }
            toast.success("Set group of devices success.");
            resetForm();
            clearSelectedRows();
            toggle();
          } finally {
            setLoading(false);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
  });

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={!loading || !isSubmitting}
    >
      <Form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h3 className="modal-title" id="modalSetGroup">
            {title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body py-2">
          <Row>
            <Col>
              <PrimeMultiSelect
                filter
                options={groupOptions}
                value={values.groupIds}
                onChange={(e) => setFieldValue('groupIds', e.value)}
                placeholder="Select Group"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <Button
                block
                color="default"
                type="submit"
                size="sm"
                disabled={loading || isSubmitting || !dirty}
              >
                Apply
                {(isSubmitting || loading) && <Loading className="ml-2" />}
              </Button>
            </Col>
          </Row>
          <Row className="my-2">
            <Col className="text-center">
              <Button
                block
                color="secondary"
                type="button"
                size="sm"
                onClick={() => {
                  resetForm();
                  toggle();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}

export default ModalSetGroup;
