import { useMemo } from "react";
import { useGetGatewayNodeRefQuery } from "../service/deviceApi";

export function useGatewayNodeRef() {
	const { data } = useGetGatewayNodeRefQuery();

	const gatewayNodeOptions = useMemo(() => {
		if (!data) return [];

		return data.map(({ key, value }) => {
			return {
				value: key,
				label: value
			}
		})
	}, [data]);

	return {
		gatewayNodeOptions
	}
}