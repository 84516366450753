import React from "react";
import { Formik } from 'formik';
import { Card, CardBody, Col, Container, Form, Row, Spinner } from "reactstrap";
import * as Yup from 'yup';
import { InputTextGroup, MyButton } from "./../../../../components";
import ResetPasswordEmailSentModal from './../../components/ResetPasswordEmailSentModal';
import './../Login/Login.css';
import { useRequestResetPasswordMutation } from './../../service/authApi';
import { APP_URL } from './../../../../config/config';
import { history } from './../../../../utils';
import { APP_CLIENT_LOGO } from "./../../../../config/config";

const ForgotPassword = () => {
  const [modalEmailSent, setModalEmailSent] = React.useState(false);

  const [requestResetPassword, { isLoading }] = useRequestResetPasswordMutation();

  const resetPasswordPageUrl = `${APP_URL}/auth/reset-password`;

  const onSubmit = async ({ email }) => {
    requestResetPassword({ email, resetPasswordPageUrl })
      .then(() => {
        setModalEmailSent(true);
      });
  }

  const onContinue = () => {
    setModalEmailSent(false);
    history.push('/auth/login');
  }

  return (
    <>
      <div className="header bg-white py-5 py-lg-8">
        <Container>
          <div className="header-body text-center mb-8">
          </div>
          <div className="brand-logo d-sm-none d-md-block">
            {/* <img id="brand-logo" alt="" src={APP_BRAND_LOGO} /> */}
            <h4 className="text-primary">Device Management Platform</h4>
          </div>

        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-3">
                <div className="mb-4 text-center mt-3">
                  <img src={APP_CLIENT_LOGO} alt="" width="175" height="auto" />
                </div>
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required('Email required!'),
                  })}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <Form role="form" onSubmit={formik.handleSubmit}>
                      <InputTextGroup
                        placeholder="Enter your email address"
                        icon="ni ni-email-83"
                        invalid={Boolean(formik.errors.email && formik.touched.email)}
                        error={formik.errors.email}
                        {...formik.getFieldProps('email')}
                      />
                      <div className="text-center mt-5">
                        <MyButton block type="submit">
                          Request Reset Password
                          {isLoading && <Spinner className="ml-2" color="light" size="sm" />}
                        </MyButton>
                        <a href="/auth/login" className="ml-0 btn btn-block btn-outline-default">Cancel</a>
                      </div>
                      <ResetPasswordEmailSentModal isOpen={modalEmailSent} toggle={() => setModalEmailSent(!modalEmailSent)} onContinue={onContinue} email={formik.values.email} />
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForgotPassword;
