import React from 'react';
import classnames from 'classnames';
import { Button } from 'reactstrap';

import { Can, UPDATE_DEVICE_AREA, CONFIG_DEVICE_GROUP, CHANGE_SIM_QUOTA, UNPROVISION_DEVICE, REBOOT_DEVICE, ENABLE_DISABLE_TUNNEL, UPDATE_DEVICE_FIRMWARE } from './../../../config'

const DeviceHeaderTable = ({
  onSetAreaClick,
  onSetGroupClick,
  onSetQuotaClick,
  onDeleteClick,
  selectedCount,
  onRebootClick,
  onEnableTunnelClick,
  onDisableTunnelClick,
  onUpdateFirmwareClick,
}) => {
  return (
    <div
      className={classnames(
        "justify-content-between align-items-center flex-wrap",
        {
          "d-none": selectedCount === 0,
          "d-flex": selectedCount > 0,
        }
      )}
    >
      <div className='d-lg-flex col-sm-12 col-md-8'>
        <Can I="do" this={UPDATE_DEVICE_AREA}>
          {() => (
            <Button
              key="set-area"
              className="btn-icon"
              color="default"
              type="button"
              size="sm"
              onClick={onSetAreaClick}
            >
              <span className="btn-inner--text">Set Area</span>
            </Button>
          )}
        </Can>
        <Can I="do" this={CONFIG_DEVICE_GROUP}>
          {() => (
            <Button
              key="set-group"
              className="btn-icon"
              color="default"
              type="button"
              size="sm"
              onClick={onSetGroupClick}
            >
              <span className="btn-inner--text">Set Group</span>
            </Button>
          )}
        </Can>
        <Can I="do" this={CHANGE_SIM_QUOTA}>
          {() => (
            <Button
              key="set-quota"
              className="btn-icon"
              color="default"
              type="button"
              size="sm"
              onClick={onSetQuotaClick}
            >
              <span className="btn-inner--text">Set Quota</span>
            </Button>
          )}
        </Can>
        <Can I="do" this={UPDATE_DEVICE_FIRMWARE}>
          {() => (
            <Button
              key="delete"
              className="btn-icon"
              color="primary"
              type="button"
              size="sm"
              onClick={onUpdateFirmwareClick}
            >
              <span className="btn-inner--text">Update Firmware</span>
            </Button>
          )}
        </Can>
        <Can I="do" this={ENABLE_DISABLE_TUNNEL}>
          {() => (
            <Button
              key="delete"
              className="btn-icon"
              color="primary"
              type="button"
              size="sm"
              onClick={onEnableTunnelClick}
            >
              <span className="btn-inner--text">Enable Tunnel</span>
            </Button>
          )}
        </Can>
        <Can I="do" this={ENABLE_DISABLE_TUNNEL}>
          {() => (
            <Button
              key="delete"
              className="btn-icon"
              color="danger"
              type="button"
              size="sm"
              onClick={onDisableTunnelClick}
            >
              <span className="btn-inner--text">Disable Tunnel</span>
            </Button>
          )}
        </Can>
        <Can I="do" this={REBOOT_DEVICE}>
          {() => (
            <Button
              key="delete"
              className="btn-icon"
              color="danger"
              type="button"
              size="sm"
              onClick={onRebootClick}
            >
              <span className="btn-inner--text">Reboot</span>
            </Button>
          )}
        </Can>
        <Can I="do" this={UNPROVISION_DEVICE}>
          {() => (
            <Button
              key="delete"
              className="btn-icon"
              color="danger"
              type="button"
              size="sm"
              onClick={onDeleteClick}
            >
              <span className="btn-inner--text">Unprovision</span>
            </Button>
          )}
        </Can>
      </div>
      <div className="col-sm-12 col-md-4 text-right pt-3">
        <p>
          {selectedCount} {selectedCount > 1 ? "devices" : "device"}{" "}
          selected.
        </p>
      </div>
    </div>
  )
}

export default DeviceHeaderTable;
