import classnames from 'classnames';
import { FieldArray, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Row, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Gap, RowItem, TritronikCheckbox, TritronikInputText, TritronikMultiSelect, TritronikSelect } from '../../../components';
import { useSetFeatureToRoleMutation } from '../service/featureApi';
import { useCreateRoleMutation } from '../service/roleApi';
import { useSetUserAreasMutation, useSetUserCustomersMutation, useSetUserRegionsMutation, useSetUserRoleMutation, useUpdateUserMutation } from '../service/userApi';
import { useFeatures, useRole } from './../../../hooks';
import { useCustomers } from './../../customers/hooks';
import { ModalAddArea } from './../../area/components';
import { useGroups } from './../../group/hooks';
import { ModalAddRegion } from './../../region/components';
import { useRegion } from './../../region/hooks';
import { useGetRegionAreasMutation } from './../../region/service/regionApi';
import ModalAddRole from './modals/ModalAddRole';


const FormEditEligibleUser = ({ data, toggleEditing, regionAreas }) => {
  const [loading, setLoading] = useState(false);
  const [editNewRole, setEditNewRole] = useState(false);

  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [modalRegion, setModalRegion] = useState(false);
  const [modalArea, setModalArea] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const [regionIds, setRegionIds] = useState(undefined);

  const { options: groupOptions } = useGroups();
  const { options: roleOptions, getRolePrivileges, getAvailablePrivileges } = useRole();
  const { optionsWithCustomerCount: regionOptions } = useRegion({ includeHierarchySummary: true }, false);
  const { options: featureOptions } = useFeatures();
  const { options: customerOptions } = useCustomers({ regionIds });

  const [getRegionAreas] = useGetRegionAreasMutation();

  const [updateUser] = useUpdateUserMutation();
  const [createRole] = useCreateRoleMutation();
  const [setUserRole] = useSetUserRoleMutation();
  const [setFeatureToRole] = useSetFeatureToRoleMutation();
  const [setUserRegion] = useSetUserRegionsMutation();
  const [setUserArea] = useSetUserAreasMutation();
  const [setCustomer] = useSetUserCustomersMutation();


  const handleRoleChange = (e, setFieldValue) => {
    setFieldValue('role', e.value);
    setFieldValue('privileges', getRolePrivileges(e.value));
  }

  const handleCopyRoleChange = (e, setFieldValue) => {
    setFieldValue('copyRoleId', e.value);
    setFieldValue('privileges', getRolePrivileges(e.value));
  }

  const handleNewRole = (e, setFieldValue) => {
    setRoleModalOpen(false);
    setFieldValue('newRole', true);
    setFieldValue('newRoleName', e.name);
    setFieldValue('copyRole', e.copyRole);
    setFieldValue('copyRoleId', e.copyRoleId);
    setFieldValue('privileges', getRolePrivileges(e.copyRoleId));
  }

  const handleClearNewRole = (setFieldValue) => {
    setFieldValue('role', '');
    setFieldValue('newRole', false);
    setFieldValue('newRoleName', '');
    setFieldValue('copyRole', false);
    setFieldValue('copyRoleId', '');
    setFieldValue('privileges', []);
  }

  const toggleEditNewRole = () => {
    setEditNewRole(!editNewRole);
  }

  const getAvailablePrivilegesOptions = useCallback((privileges) => {
    return getAvailablePrivileges(privileges, featureOptions);
  }, [getAvailablePrivileges, featureOptions]);

  const handleRegionChange = async (e, actions) => {
    if (e.value === '-1') {
      actions.form.setFieldValue('selectedRegion', e.value);
      actions.form.setFieldValue('regions', []);
      setRegionIds(undefined);
    } else {
      actions.form.setFieldValue('selectedRegion', '');

      const result = await getRegionAreas(e.value).unwrap();

      const region = _.find(regionOptions, { value: e.value });

      if (region) {
        actions.push({
          id: region.value,
          name: region.label,
          description: null,
          areas: result?.content || [],
        });
      }
      const regions = [{ id: e.value }, ...actions.form.values.regions];
      const regionIds = regions && regions.length > 0 ? regions.map((r) => r.id).join(',') : undefined;
      setRegionIds(regionIds);
    }
  }

  const handleCustomerChange = async (e, actions) => {
    if (e.value === '-1') {
      actions.form.setFieldValue('customers', []);
      actions.form.setFieldValue('selectedCustomer', e.value);
    } else {
      const customer = _.find(customerOptions, { value: e.value });
      if (customer) {
        actions.push({
          id: customer.value,
          name: customer.label,
        });
      }
      actions.form.setFieldValue('selectedCustomer', '');
    }
  }

  const handleSelectFeature = (e, actions) => {
    setSelectedFeature(e.value);
    const feature = _.find(featureOptions, { value: e.value });
    if (feature) {
      actions.push({
        id: feature.value,
        name: feature.label,
        description: null
      })
    }
  }

  const getFilteredRegions = (regions) => {
    return [{ value: '-1', label: 'All Region' }, ...regionOptions].filter((region) => regions && regions.length > 0 ? !regions.map((r) => r.id).includes(region.value) : true);
  }

  const getFilteredCustomers = (customers) => {
    return [{ value: '-1', label: 'All Customer' }, ...customerOptions].filter((cust) => customers && customers.length > 0 ? !customers.map((r) => r.id).includes(cust.value) : true);
  }

  const toggleModalRegion = () => {
    setModalRegion(!modalRegion);
  }

  const toggleModalArea = () => {
    setModalArea(!modalArea);
  }

  const onSubmit = async (values) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to update this?",
      text: `User: ${data.username}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        const { regions, customers, newRole, newRoleName, role, privileges, groupIds, selectedRegion, selectedCustomer } = values;
        setLoading(true);

        try {

          // Update user groups
          await updateUser({ body: { username: data.username, groupIds }, params: { updateGroup: true } }).unwrap();

          // role assignment
          if (newRole === true) {
            await createRole({ name: newRoleName }).unwrap().then(async ({ id }) => {
              // assign features to new role
              for (let i = 0; i < privileges.length; i++) {
                const feature = privileges[i];
                await setFeatureToRole({ featureId: feature.id, roleId: id }).unwrap();
              }
              // set user role
              await setUserRole({ roleId: id, username: data.username });
            });
          } else {
            // set user role
            await setUserRole({ roleId: role, username: data.username });
          }

          // set user eligible region and area (zones)
          if (selectedRegion === '-1') { // is All Region Selected
            await setUserRegion({ regionIds: '-1', username: data.username }).unwrap();
          } else {
            if (regions && regions.length > 0) {
              const userRegions = [];
              const userAreas = [];
              regions.forEach(region => {
                if (region.areas && region.areas.length > 0) {
                  region.areas.forEach((area) => {
                    userAreas.push(area.id);
                  });
                } else {
                  userRegions.push(region.id);
                }
              });

              if (userRegions && userRegions.length > 0) {
                await setUserRegion({ username: data.username, regionIds: userRegions.join(',') }).unwrap();
              }

              if (userAreas && userAreas.length > 0) {
                await setUserArea({ username: data.username, areaIds: userAreas.join(',') }).unwrap();
              }
            }
          }

          // set user eligible customer
          if (selectedCustomer === '-1') {
            await setCustomer({ username: data.username, customerIds: '-1' }).unwrap();
          } else {
            if (customers && customers.length > 0) {
              const customerIds = customers.map((cust) => cust.id).join(',');
              await setCustomer({ username: data.username, customerIds: customerIds }).unwrap();
            }
          }
          toast.success('Update user success.')
          setLoading(false);
          toggleEditing();
          return true;
        } catch (error) {
          setLoading(false);
          toast.error(`${error?.data?.error}: ${error?.data?.message}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  return (
    <Formik
      initialValues={{
        groupIds: data.groupIds,
        regions: regionAreas,
        areas: data.areas,
        customers: data.eligibleCustomers,
        roles: data.roles,
        newRole: false,
        copyRole: false,
        copyRoleId: '',
        newRoleName: '',
        role: data.roles && data.roles.length > 0 ? data.roles[0].id : '',
        privileges: data.combinePrivileges && data.combinePrivileges.map((priv) => priv.feature),
        selectedRegion: data.regionIdsParam === null ? '-1' : '',
        selectedCustomer: data.customerIdsParam === null ? '-1' : '',
      }}
      validationSchema={Yup.object().shape({
        role: Yup.string().when('newRole', { is: false, then: Yup.string().required('Please select role to be assigned!') }),
        newRoleName: Yup.string().when('newRole', { is: true, then: Yup.string().required('New role name is required!') }),
        copyRoleId: Yup.string().when(['newRole', 'copyRole'], { is: true, then: Yup.string().required('Select role to copy!') }),
        regions: Yup.array().ensure().when('selectedRegion', { is: '-1', then: Yup.array(), otherwise: Yup.array().min(1, 'Zone must be set.') }),
      })}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue, dirty, isValid }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="mt-4 mb-5">
            <Col className="px-4 border-right">
              <RowItem stripped label={(<h4 className="text-dark">Role</h4>)} value={(
                <TritronikSelect
                  name="role"
                  placeholder="Role"
                  options={roleOptions}
                  value={values.role}
                  invalid={Boolean(errors.role && touched.role)}
                  error={errors.role}
                  disabled={values.newRole}
                  onChange={(e) => {
                    handleRoleChange(e, setFieldValue);
                  }}
                />
              )} />

              <RowItem padding={0} stripped label={(
                <Button color="link" className="px-0 pb-2 pt-0 font-weight-normal text-default" onClick={() => setRoleModalOpen(!roleModalOpen)} disabled={values.newRole}>
                  Add New Role <i className="fa fa-plus-circle text-default fa-1x ml-2"></i>
                </Button>
              )} />

              <Gap height={15} />
              {values.newRole ? (
                <Row>
                  <Col style={{ backgroundColor: '#F7FAFC' }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="my-2">
                        {editNewRole && <TritronikInputText name="newRoleName" small />}
                        {!editNewRole && <span className="text-dark font-weight-bold">{values.newRoleName}</span>}
                      </div>
                      <div className="my-2">
                        <span className="btn btn-link p-0" onClick={toggleEditNewRole}><i className={classnames("fa", {
                          "fa-edit": !editNewRole,
                          "fa-save mr-1 text-dark": editNewRole
                        })}></i>{editNewRole && <span className="text-dark">Apply</span>}</span>
                        <span className="btn btn-link p-0" onClick={() => handleClearNewRole(setFieldValue)}><i className="fa fa-times-circle"></i></span>
                      </div>
                    </div>

                    <div className="ml-4">
                      <TritronikCheckbox name="copyRole" checked={values.copyRole} label="Copy privileges from existing role" disabled={!editNewRole} />
                      <RowItem stripped label="Role" value={(
                        <TritronikSelect
                          name="copyRoleId"
                          placeholder="Select Role"
                          value={values.copyRoleId}
                          options={roleOptions}
                          onChange={(e) => {
                            handleCopyRoleChange(e, setFieldValue);
                          }}
                          disabled={!values.copyRole}
                        />
                      )} />
                    </div>
                  </Col>
                </Row>
              ) : null}

              <FieldArray name="privileges">
                {(actions) => (
                  <div>
                    <RowItem label={(
                      <h4 className="text-dark">Privileges</h4>
                    )} value={(
                      <TritronikSelect filter options={getAvailablePrivilegesOptions(values.privileges)} value={selectedFeature} onChange={(e) => handleSelectFeature(e, actions)} disabled={!values.newRole} placeholder="Add Privilege" />
                    )} />
                    {values.privileges.map((priv, index) => (
                      <RowItem padding={0} stripped={(index % 2 === 0)} key={`privilege-${priv.id}-${index}`} label={(
                        <span>
                          <span className={classnames("btn btn-link mr-2", { "disabled": !values.newRole })} onClick={() => actions.remove(index)}><i className="fa fa-times-circle"></i></span>
                          {priv.name}
                        </span>
                      )} />
                    ))}
                  </div>
                )}
              </FieldArray>
              <RowItem label="Group" value={(
                <TritronikMultiSelect filter name="groupIds" placeholder="Group" options={groupOptions} />
              )} />

              <ModalAddRole toggle={() => setRoleModalOpen(!roleModalOpen)} isOpen={roleModalOpen} roleOptions={roleOptions} onSave={(newRole) => handleNewRole(newRole, setFieldValue)} />
            </Col>
            <Col className="px-4 border-left">
              <FieldArray name="regions">
                {(regionActions) => (
                  <div>
                    {errors.regions && values.selectedRegion !== '-1' && <div className="text-sm text-danger py-2">{errors.regions}</div>}
                    <RowItem stripped boldLabel label="Zone" value={(
                      <TritronikSelect
                        filter
                        name="zone"
                        placeholder="Add Zone"
                        options={getFilteredRegions(values.regions)}
                        value={values.selectedRegion}
                        onChange={(e) => {
                          handleRegionChange(e, regionActions);
                        }}
                      />
                    )} />
                    <RowItem padding={0} stripped label={(
                      <div>
                        <span className="btn btn-sm btn-link text-default text-sm font-weight-normal pb-2 pt-0" style={{ marginLeft: -8 }} onClick={toggleModalRegion}>
                          Add New Region <i className="fa fa-plus-circle ml-2"></i>
                        </span>
                        <span className="btn btn-sm btn-link text-default text-sm font-weight-normal pb-2 pt-0" style={{ marginLeft: -8 }} onClick={toggleModalArea}>
                          Add New Area <i className="fa fa-plus-circle ml-2"></i>
                        </span>
                      </div>
                    )} />
                    {values.regions && values.regions.map((region, idx) => (
                      <div key={idx}>
                        <RowItem stripped={idx % 2 !== 0} padding={0} label={(
                          <span>
                            <span className={classnames("btn btn-link mr-2")} onClick={() => {
                              regionActions.remove(idx);
                              regionActions.form.setFieldValue('selectedRegion', '');
                            }}><i className="fa fa-times-circle"></i></span>
                            {region.name}
                          </span>
                        )} />
                        <FieldArray name={`regions[${idx}].areas`}>
                          {(areaActions) => (
                            <div>
                              {values.regions[idx].areas && values.regions[idx].areas.map((area, index) => (
                                <RowItem padding={0} key={area.id} offset stripped={index % 2 === 0} label={(
                                  <span>
                                    <span className={classnames("btn btn-link mr-2")} onClick={() => areaActions.remove(index)}><i className="fa fa-times-circle"></i></span>
                                    {area.name}
                                  </span>
                                )} />
                              ))}
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <Gap height={15} />
              <FieldArray name="customers">
                {(customerActions) => (
                  <div>
                    {errors.customers && <div className="text-sm text-danger py-2">{errors.customers}</div>}
                    <div style={{ backgroundColor: "#F7FAFC" }}>
                      <RowItem stripped boldLabel label="Customer" value={(
                        <TritronikSelect
                          filter
                          name="customer"
                          placeholder="Add Customer"
                          options={getFilteredCustomers(values.customers)}
                          value={values.selectedCustomer}
                          onChange={(e) => {
                            handleCustomerChange(e, customerActions);
                          }}
                          disabled={(values.regions.length === 0 && values.selectedRegion !== '-1') || customerOptions.length === 0}
                        />
                      )} />
                    </div>
                    {values.customers && values.customers.map((customer, idx) => (
                      <div key={customer.id}>
                        <RowItem stripped={idx % 2 !== 0} padding={0} label={(
                          <span>
                            <span className={classnames("btn btn-link mr-2")} onClick={() => {
                              customerActions.remove(idx);
                              customerActions.form.setFieldValue('selectedCustomer', '');
                            }}><i className="fa fa-times-circle"></i></span>
                            {customer.name}
                          </span>
                        )} />
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Col>
          </Row>
          <div className="row mb-5">
            <div className="col text-right">
              <Button className="btn btn-outline-default px-5 py-1 ml-auto" onClick={toggleEditing}>
                Cancel
              </Button>
              <Button className="btn btn-default px-5 py-1" type="submit" disabled={loading || !dirty || !isValid}>
                Apply
                {loading && <Spinner className="ml-2" color="light" size="sm" />}
              </Button>
            </div>
          </div>
          <ModalAddRegion
            isOpen={modalRegion}
            toggle={toggleModalRegion}
          />
          <ModalAddArea
            isOpen={modalArea}
            toggle={toggleModalArea}
          />
        </Form>
      )}
    </Formik>
  )
}

export default FormEditEligibleUser
