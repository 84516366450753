import { ZEROTIER_API_TOKEN, ZEROTIER_NODE_ID } from './../../../config/config';

const headers = {
	'X-ZT1-AUTH': ZEROTIER_API_TOKEN
}

export default {
	getNetworks: () => {
		return fetch('/api/zerotier/controller/network', { headers });
	},
	getNetworkDetail: (id) => {
		return fetch(`/api/zerotier/controller/network/${id}`, { headers });
	},
	createNetwork: (data) => {
		return fetch(`/api/zerotier/controller/network/${ZEROTIER_NODE_ID}______`, {
			headers,
			body: JSON.stringify(data),
			method: 'POST'
		}).then(res => res.json());
	},
	updateNetwork: (id, data) => {
		return fetch(`/api/zerotier/controller/network/${id}`, {
			headers,
			body: JSON.stringify(data),
			method: 'POST'
		}).then(res => res.json());
	},
	deleteNetwork: (id) => {
		return fetch(`/api/zerotier/controller/network/${id}`, {
			headers,
			method: 'DELETE'
		}).then(res => res.json());
	},
	getNetworkMembers: (networkId) => {
		return fetch(`/api/zerotier/controller/network/${networkId}/member`, { headers }).then(res => res.json());
	},
	getNetworkMemberDetail: (networkId, memberId) => {
		return fetch(`/api/zerotier/controller/network/${networkId}/member/${memberId}`, { headers }).then(res => res.json());
	},
	getPeerDetail: (memberId) => {
		return fetch(`/api/zerotier/peer/${memberId}`, { headers }).then(res => res.json());
	},
	updateMember: (networkId, memberId, data) => {
		return fetch(`/api/zerotier/controller/network/${networkId}/member/${memberId}`, {
			headers,
			body: JSON.stringify(data),
			method: 'POST'
		}).then(res => res.json());
	},
	deleteMember: (networkId, memberId) => {
		return fetch(`/api/zerotier/controller/network/${networkId}/member/${memberId}`, {
			headers,
			method: 'DELETE'
		}).then(res => res.json());
	},
}