import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const rowStyle = (stripped, padding, paddingTop, paddingBottom) => ({
  backgroundColor: stripped ? "#F7FAFC" : "#FFFFFF",
  paddingBottom: paddingBottom ? paddingBottom : padding,
  paddingTop: paddingTop ? paddingTop : padding,
});

const RowItem = ({
  label,
  boldLabel,
  value,
  boldValue,
  valueRight,
  stripped,
  children,
  offset,
  padding,
  leftCol,
  rightCol,
  borderBottom,
  className,
  onlyLabelOffset,
  paddingTop,
  paddingBottom,
  ...props
}) => {
  return (
    <div
      className={classnames(
        "row",
        { "border-bottom": borderBottom },
        className
      )}
      style={rowStyle(stripped, padding, paddingTop, paddingBottom)}
      {...props}
    >
      <div
        className={classnames(
          { "offset-1": offset ? true : false, "font-weight-bold": boldLabel },
          leftCol
        )}
        style={{maxWidth: onlyLabelOffset ? "41.8%" : "100%"}}
      >
        {label}
      </div>
      {value && (
        <div
          className={classnames(
            {
              "text-right": valueRight,
              "font-weight-bold": boldValue,
              "d-flex justify-content-end": !rightCol,
            },
            rightCol
          )}
        >
          {value}
        </div>
      )}
      {children}
    </div>
  );
};

RowItem.defaultProps = {
  stripped: false,
  label: null,
  value: null,
  children: null,
  offset: false,
  padding: 12,
  boldLabel: false,
  boldValue: false,
  valueRight: false,
  leftCol: "col",
  rightCol: "col",
  borderBottom: false,
  hidden: false,
  onlyLabelOffset: false,
  paddingTop: null,
  paddingBottom: null
};

RowItem.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  stripped: PropTypes.bool,
  children: PropTypes.node,
  offset: PropTypes.bool,
  padding: PropTypes.number,
  boldLabel: PropTypes.bool,
  boldValue: PropTypes.bool,
  valueRight: PropTypes.bool,
  leftCol: PropTypes.string,
  rightCol: PropTypes.string,
  borderBottom: PropTypes.bool,
  hidden: PropTypes.bool,
  onlyLabelOffset: PropTypes.bool,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

export default RowItem;
