import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { Card, CardBody, Row, Col } from 'reactstrap'
import { StringParam, useQueryParams } from 'use-query-params'
import DataTable from "react-data-table-component"

import CreateSimcardInventoryModal from '../components/CreateSimcardInventoryModal'
import { PageContent, PrimeSearchInput, ButtonLinkIcon, CustomCellStatusBadge, Tags } from './../../../../components'
import { momentLastUpdate, paginationRowsPerPageOptions } from './../../../../utils';
import { useSimcardInventoryDatatables } from './../hooks'
import { customStyles } from './../../../../utils/datatableStyles'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useDeleteSimcardMutation } from './../services/simcardInventoryApi'
// import { Can } from './../../../../config'
import CustomActionButton from './../components/CustomActionButton';
import EditSimcardInventoryModal from '../components/EditSimcardInventoryModal'
import FilterSimcardInventory from '../components/FilterSimcardInventory'
import { useSIMOperator } from './../../../../hooks'

import { useUnAssignSimcardMutation } from './../services'

const SimcardInventoryList = () => {
    const [modalCreateSimcard, setModalCreateSimcard] = useState(false);
    const searchRef = useRef();
    const [modalEdit, setModalEdit] = useState(false);
    const [selectedSIM, setSelectedSIM] = useState(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const { options: operatorOptions } = useSIMOperator([{value: null, label: 'All'}]);

    const [deleteSIM] = useDeleteSimcardMutation();
    const [unAssignSimcard] = useUnAssignSimcardMutation();

    const [query, setQuery] = useQueryParams({
        smartSearch: StringParam,
        assetStatus: StringParam,
        operator: StringParam,
    });

    const {
        data,
        isLoading,
        isFetching,
        totalRows,
        pageSize,
        onChangePage,
        onChangeRowsPerPage,
        onSort,
        onSelectedRowsChange,
    } = useSimcardInventoryDatatables(query, { sortFields: 'creationTime', sortOrder: 'desc' });

    const onSearch = (searchText) => {
        setQuery({ smartSearch: searchText !== "" && searchText !== null ? searchText : undefined });
    }

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.changeValue(query.smartSearch || '');
        }
    }, [query]);

    const onCreateSimcardClick = () => {
        setModalCreateSimcard(true);
    }

    const toggleModalCreate = () => {
        setModalCreateSimcard(!modalCreateSimcard);
    }
    const toggleModalEdit = () => {
        setModalEdit(!modalEdit);
    }

    const onEditSIM = useCallback((sim) => {
        setSelectedSIM(sim);
        setModalEdit(true);
    }, [setModalEdit, setSelectedSIM]);

    const onDeleteSIM = useCallback((sim) => {
        Swal.fire({
            title: "Are you sure want to delete this?",
            text: `SIM: ${sim.imsi}?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes!",
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: async () => {
                return deleteSIM(sim.id)
                    .unwrap()
                    .then(() => {
                        toast.success("SIM deleted.");
                        return true;
                    });
            },
        });
    }, [deleteSIM]);

    const onUnassignSIM = useCallback((sim) => {
        Swal.fire({
            icon: "question",
            title: "Are you sure you want to unassign this SIM?",
            text: `IMSI: ${sim.imsi}.`,
            showLoaderOnConfirm: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            reverseButtons: true,
            preConfirm: async () => {
              return unAssignSimcard(sim.id)
                .unwrap()
                .then(() => {
                  toast.success("Unassign SIM success.");
                  return true;
                })
                .catch((error) => {
                  Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
                  return false;
                });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          });
    }, [unAssignSimcard]);

    const columns = useMemo(() => {
        return [
            {
                id: 'imsi',
                name: 'IMSI',
                selector: row => row.imsi,
                sortable: true,
                sortField: 'imsi',
            },
            {
                id: 'gsmNumber',
                name: 'GSM NUMBER',
                selector: row => row.gsmNumber,
                sortable: true,
                sortField: 'gsmNumber',
            },
            {
                id: 'operator',
                name: 'OPERATOR',
                selector: row => row.operator,
                sortable: true,
                sortField: 'operator',
            },
            {
                id: 'circuitNumber',
                name: 'SIA',
                selector: row => row.circuitNumber,
                sortable: true,
                sortField: 'circuitNumber',
            },
            {
                id: 'inventoryId',
                name: 'INVENTORY ID',
                selector: row => row.inventoryId,
                sortable: true,
                sortField: 'inventoryId',
            },
            {
                id: 'billingCycle',
                name: 'BILLING DATE',
                selector: row => row.billingCycle,
                sortable: true,
                sortField: 'billingCycle',
            },
            {
                id: 'creationTime',
                name: 'REGISTER DATE',
                selector: row => row.creationTime,
                sortable: true,
                sortField: 'creationTime',
                cell: row => row.creationTime ? momentLastUpdate(row.creationTime, 'DD MMMM YYYY') : '-',
            },
            {
                id: 'assetStatus',
                name: 'STATUS',
                selector: row => row.assetStatus,
                sortable: true,
                sortField: 'assetStatus',
                cell: row => (
                    <CustomCellStatusBadge
                        row={row}
                        name='assetStatus'
                        successFlag="ASSIGNED"
                        defaultFlag="UNASSIGNED"
                    />
                )
            },
            {
                name: '',
                width: '40px',
                allowOverflow: true,
                button: true,
                cell: (row) => (
                    <CustomActionButton
                        row={row} 
                        onEdit={onEditSIM}
                        onDelete={onDeleteSIM}
                        onUnassignSIM={onUnassignSIM}
                    />
                ),
            },
        ]
    }, [onEditSIM, onDeleteSIM, onUnassignSIM]);

    const onFilter = (values) => {
        if (values) {
            setQuery({
                assetStatus: values.assetStatus || undefined,
                operator: values.operator || undefined,
            });
        }
    }

    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
    }

    const onRemoveFilter = useCallback((key = null) => {
        if (key) {
            setQuery({ [key]: undefined });
        }
    }, [setQuery]);

    const onResetFilter = useCallback(() => {
        setQuery({
            smartSearch: undefined,
            assetStatus: undefined,
            operator: undefined,
        });
    }, [setQuery]);

    const filterTags = useMemo(() => {
        const tags = [];

        Object.keys(query).forEach((key) => {
            const value = query[key];
            if (value !== undefined) {
                tags.push({
                    key: key,
                    value: value,
                    label: value,
                    onRemove: () => onRemoveFilter(key)
                })
            }
        });

        return tags;
    }, [query, onRemoveFilter]);

    return (
        <PageContent title="SIM Inventory">
            <Card>
                <CardBody>
                    <Row className="mb-2">
                        <Col xs="12" md="6">
                            <h2 className="text-dark">All SIM</h2>
                        </Col>
                        <Col xs="12" md="6" className="d-flex justify-content-between justify-content-lg-end align-items-center">
                            <PrimeSearchInput
                                ref={searchRef}
                                block
                                onFilter={onSearch}
                                size="sm"
                                tooltip="Press 'Enter' to search."
                                tooltipOptions={{ position: "top" }}
                            />
                            <FilterSimcardInventory
                                isLoading={isFetching || isLoading}
                                onFilter={onFilter}
                                operatorOptions={operatorOptions}
                                filters={query}
                                isOpen={filterOpen}
                                toggle={toggleFilter}
                            />
                            <ButtonLinkIcon label="Add New" onClick={onCreateSimcardClick} />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Tags tags={filterTags} onReset={onResetFilter} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable
                                data={data}
                                columns={columns}
                                progressPending={isLoading}
                                keyField='id'
                                defaultSortAsc={false}
                                defaultSortFieldId='creationTime'
                                persistTableHead
                                highlightOnHover
                                noHeader
                                striped
                                onSelectedRowsChange={onSelectedRowsChange}
                                pagination={true}
                                paginationServer={true}
                                paginationTotalRows={totalRows}
                                paginationPerPage={pageSize}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                sortServer={true}
                                onSort={onSort}
                                customStyles={customStyles}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <CreateSimcardInventoryModal isOpen={modalCreateSimcard} toggle={toggleModalCreate} />
            <EditSimcardInventoryModal simcard={selectedSIM} isOpen={modalEdit} toggle={toggleModalEdit} />
        </PageContent>
    )
}

export default SimcardInventoryList
