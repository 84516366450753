import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const userActivityApi = createApi({
  reducerPath: 'userActivityApi',
  baseQuery: baseQuery,
  tagTypes: ['UserActivity'],
  endpoints: (builder) => ({
    getUserActivities: builder.query({
      query: (params) => ({
        url: `/ActivityLog/`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'UserActivity', id })),
            { type: 'UserActivity', id: 'LIST' },
          ]
          : [{ type: 'UserActivity', id: 'LIST' }],
    }),
    getUserActivity: builder.query({
      query: (id) => ({
        url: `/ActivityLog/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'UserActivity', id }],
    }),
    createUserActivity: builder.mutation({
      query: (body) => ({
        url: `/ActivityLog`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'UserActivity', id: 'LIST' }],
    }),
    updateUserActivity: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/ActivityLog/${id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userActivityApi.util.updateQueryData('getUserActivity', id, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'UserActivity', id }],
    }),
    deleteUserActivity: builder.mutation({
      query(id) {
        return {
          url: `/ActivityLog/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'UserActivity', id }],
    }),
  })
});

export const {
  useGetUserActivityQuery,
  useGetUserActivitiesQuery,
  useCreateUserActivityMutation,
  useUpdateUserActivityMutation,
  useDeleteUserActivityMutation,
} = userActivityApi;

