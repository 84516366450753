import { Form, Formik } from 'formik';
import React from 'react';
import {
  Button,
  Modal
} from "reactstrap";
import * as Yup from 'yup';
import { TritronikCheckbox, TritronikInputText, TritronikSelect } from '../../../../components';

const ModalAddRole = ({ isOpen, toggle, roleOptions, onSave }) => {

  const handleSubmit = async ({ name, copyRole, copyRoleId }) => {
    if (onSave) {
      onSave({name, copyRole, copyRoleId});
    }
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
    >
      <Formik
          initialValues={{
            name: '',
            copyRole: false,
            copyRoleId: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Role name required!'),
            copyRole: Yup.boolean(),
            copyRoleId: Yup.string().when('copyRole', { is: true, then: Yup.string().required('Select role to be copied!') })
          })}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <div className="modal-body">
                <h4>Add New Role</h4>
                <div className="row">
                  <div className="col-5">Role Name</div>
                  <div className="col-1 px-0 m-0">:</div>
                  <div className="col-6">
                    <TritronikInputText name="name" placeholder="New Role Name" />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <TritronikCheckbox name="copyRole" checked={values.copyRole} label="Copy privileges from existing role" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-5">Role Name</div>
                  <div className="col-1 px-0 m-0">:</div>
                  <div className="col-6">
                    <TritronikSelect
                      name="copyRoleId"
                      placeholder="Select Role"
                      value={values.copyRoleId}
                      options={roleOptions}
                      invalid={Boolean(errors.copyRoleId && touched.copyRoleId)}
                      error={errors.copyRoleId}
                      onChange={(e) => setFieldValue('copyRoleId', e.value)}
                    />
                  </div>
                </div>
            </div>
            <div className="modal-footer">
              <Button color="default" outline className="px-5 py-1" data-dismiss="modal" onClick={toggle}>Cancel</Button>
              <Button className="btn btn-default px-5 py-1 ml-auto" type="submit">
                Apply
              </Button>
            </div>
          </Form>
          )}
        </Formik>
    </Modal>
  )
}

export default ModalAddRole;
