import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./../../../services/baseApi";

export const roleApi = createApi({
  reducerPath: "roleApi",
  baseQuery: baseQuery,
  tagTypes: ["Role"],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: (params) => ({
        url: `/role`,
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "Role", id })),
              { type: "Role", id: "LIST" },
            ]
          : [{ type: "Role", id: "LIST" }],
    }),
    getRole: builder.query({
      query: (id) => ({ url: `/role/${id}` }),
      providesTags: (result, error, id) => [{ type: "Role", id }],
    }),
    createRole: builder.mutation({
      query: (body) => ({
        url: `/role`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Role", id: "LIST" }],
    }),
    updateRole: builder.mutation({
      query: (body) => ({
        url: `/role/${body.id}`,
        method: "PUT",
        body,
      }),
      onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          roleApi.util.updateQueryData("getRole", id, (draft) => {
            Object.assign(draft, patch);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Role", id }],
    }),
    deleteRole: builder.mutation({
      query(id) {
        return {
          url: `/role/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: "Role", id },
        { type: "Role", id: "LIST" },
      ],
    }),
    setFeaturesToRole: builder.mutation({
      query: ({ roleId, featureIds }) => ({
        url: `/role/${roleId}/features`,
        method: "PUT",
        body: {
          featureIds,
        },
      }),
      invalidatesTags: (res, err, { roleId }) => [{ type: "Role", id: roleId }],
    }),
  }),
});

export const {
  useGetRoleQuery,
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useSetFeaturesToRoleMutation,
} = roleApi;
