import React from "react";
import { Formik } from "formik";
import _ from "lodash";
import { Tooltip } from "primereact/tooltip";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  Spinner,
} from "reactstrap";
import {
  CustomLabel,
  InputChips,
  PageContent,
  RowItem,
  TritronikInputText,
  TritronikMultiSelect,
  TritronikSelect,
} from "../../../components";
import { history } from "../../../utils";
import { createAlertValidation } from "../validations";
import {
  useAlertChannel,
  useAlertSeverity,
  useAlertType,
  useTimeRange,
  useOptions,
} from "./../../../hooks";
import { useCreateAlertConfigurationMutation } from "./../../alert/service/alertConfigurationApi";
import { useAuth } from "./../../auth/hooks";
import { useRegion } from "./../../region/hooks";
import { useArea } from "./../../area/hooks";
import { useCustomerOptions } from "./../../../hooks/useCustomerOptions";

const CreateAlertConfigurationPage = () => {
  const { options: channelsOptions } = useAlertChannel();
  const { options: severityOptions } = useAlertSeverity();
  const { options: regionOptions } = useRegion();
  const { options: areaOptions } = useArea();
  const { options: alertTypeOptions } = useAlertType();
  const { options: timeRangeOptions } = useTimeRange();
  const { customerOptions } = useCustomerOptions();
  const { applyToOptions } = useOptions();

  const [createAlertConfiguration, { isLoading }] =
    useCreateAlertConfigurationMutation();

  const { user } = useAuth();
  const { allArea, allRegion } = user;

  const hasRegion = regionOptions && regionOptions.length > 0;
  const hasArea = areaOptions && areaOptions.length > 0;
  const hasCustomer = customerOptions && customerOptions.length > 0;

  const initialValues = {
    type: "",
    name: "",
    description: "",
    threshold: 10,
    parameter: "",
    severity: "",
    applyTo: hasRegion
      ? "region"
      : hasArea
      ? "area"
      : hasCustomer
      ? "customer"
      : "",
    idsOfRegion: allRegion ? [-1] : [],
    idsOfArea: allArea ? [-1] : [],
    idsOfCustomer: [],
    channelInCollection: [],
    additionalRecipients: [],
    hasThreshold: false,
    thresholdUnit: "",
    hasTimeRange: false,
    timeRange: "5m",
  };

  const handleAlertTypeChange = (e, setFieldValue) => {
    setFieldValue("type", e.value);

    const defaultData = _.find(alertTypeOptions, { value: e.value });
    if (defaultData) {
      setFieldValue("severity", defaultData.severity);
      setFieldValue("parameter", defaultData.parameter);
      setFieldValue("description", defaultData.description);
      setFieldValue("name", defaultData.label);
      setFieldValue("hasThreshold", defaultData.hasThreshold);
      setFieldValue("thresholdUnit", defaultData.thresholdUnit);
      setFieldValue("hasTimeRange", defaultData.hasTimeRange);

      if (e.value === "Signal Strength Alert") {
        setFieldValue("threshold", -80);
        setFieldValue("timeRange", defaultData.timeRange || "5m");
      }
    }
  };

  const onApplyToChange = (e, setFieldValue) => {
    setFieldValue("applyTo", e.value);
    switch (e.value) {
      case "region":
        setFieldValue("idsOfRegion", allRegion ? [-1] : []);
        setFieldValue("idsOfArea", []);
        setFieldValue("idsOfCustomer", []);

        break;
      case "area":
        setFieldValue("idsOfRegion", []);
        setFieldValue("idsOfArea", allArea ? [-1] : []);
        setFieldValue("idsOfCustomer", []);

        break;
      case "customer":
        setFieldValue("idsOfCustomer", []);
        setFieldValue("idsOfRegion", []);
        setFieldValue("idsOfArea", []);

        break;

      default:
        break;
    }
    if (e.value === "region") {
    } else {
    }
  };

  const onSubmit = async ({
    idsOfArea,
    idsOfRegion,
    idsOfCustomer,
    applyTo,
    ...form
  }) => {
    const mapIdsOfRegion =
      allRegion && idsOfRegion.length > 1
        ? idsOfRegion.filter((v) => v !== -1)
        : idsOfRegion;
    const mapIdsOfArea =
      allArea && idsOfArea.length > 1
        ? idsOfArea.filter((v) => v !== -1)
        : idsOfArea;

    const data = {
      ...form,
      ...(applyTo === "region" && { idsOfRegion: mapIdsOfRegion }),
      ...(applyTo === "area" && { idsOfArea: mapIdsOfArea }),
      ...(applyTo === "customer" && { idsOfCustomer }),
      owner: user ? user.username : undefined,
    };
    console.log("🚀 ~ file: CreateAlertConfigurationPage.js:154 ~ CreateAlertConfigurationPage ~ data", data)

    
    createAlertConfiguration({
      body: data,
      params: {
        updateAlertDevice: true,
        updateAlertCustomer: true,
        updateAlertGroupDevice: true,
        updateAlertGroupUser: true,
        updateAlertUser: true,
      },
    })
      .unwrap()
      .then(() => {
        toast.success(`Alert ${form.name} created.`);
        history.replace("/admin/alerts/configuration");
      })
      .catch((error) => {
        toast.error(`${error?.data?.error}: ${error?.data?.message}`);
      });
  };

  const handleRegionChange = (e, values, setFieldValue) => {
    const { idsOfRegion } = values;
    const currentAllRegionExists =
      idsOfRegion.filter((v) => v === -1).length > 0;
    const newAllRegionExists = e.value.filter((v) => v === -1).length > 0;
    if (currentAllRegionExists && e.value.length > 1) {
      setFieldValue(
        "idsOfRegion",
        e.value.filter((v) => v !== -1)
      );
    } else if (!currentAllRegionExists && newAllRegionExists) {
      setFieldValue(
        "idsOfRegion",
        e.value.filter((v) => v === -1)
      );
    } else {
      setFieldValue("idsOfRegion", e.value);
    }
  };

  const handleAreaChange = (e, values, setFieldValue) => {
    const { idsOfArea } = values;
    const currentAllAreaExists = idsOfArea.filter((v) => v === -1).length > 0;
    const newAllAreaExists = e.value.filter((v) => v === -1).length > 0;
    if (currentAllAreaExists && e.value.length > 1) {
      setFieldValue(
        "idsOfArea",
        e.value.filter((v) => v !== -1)
      );
    } else if (!currentAllAreaExists && newAllAreaExists) {
      setFieldValue(
        "idsOfArea",
        e.value.filter((v) => v === -1)
      );
    } else {
      setFieldValue("idsOfArea", e.value);
    }
  };

  const handleCustomerChange = (e, values, setFieldValue) => {
    const { idsOfCustomer } = values;
    const currentAllCustomerExists =
      idsOfCustomer.filter((v) => v === -1).length > 0;
    const newAllCustomerExists = e.value.filter((v) => v === -1).length > 0;
    if (currentAllCustomerExists && e.value.length > 1) {
      setFieldValue(
        "idsOfCustomer",
        e.value.filter((v) => v !== -1)
      );
    } else if (!currentAllCustomerExists && newAllCustomerExists) {
      setFieldValue(
        "idsOfCustomer",
        e.value.filter((v) => v === -1)
      );
    } else {
      setFieldValue("idsOfCustomer", e.value);
    }
  };

  return (
    <PageContent title="Alert Management">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col className="d-flex align-items-center">
              <Breadcrumb listClassName="bg-white m-0 p-0">
                <BreadcrumbItem>
                  <Link
                    className="text-dark text-lg text-default font-weight-bold"
                    to="/admin/alerts"
                  >
                    Open Alert
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link
                    className="text-dark text-lg text-default font-weight-bold"
                    to="/admin/alerts/configuration"
                  >
                    Rule Configuration
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span className="text-dark text-lg">Add New Alert</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <h2>Alert Setting</h2>
            </Col>
          </Row>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={createAlertValidation}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mt-2">
                  <Col>
                    <RowItem
                      label={<CustomLabel name="Alert Type" required />}
                      value={
                        <TritronikSelect
                          value={values.type}
                          options={alertTypeOptions}
                          onChange={(e) =>
                            handleAlertTypeChange(e, setFieldValue)
                          }
                          invalid={touched.type && errors.type}
                          error={errors.type}
                        />
                      }
                    />

                    <RowItem
                      label={<CustomLabel name="Alert Name" required />}
                      value={<TritronikInputText name="name" small />}
                    />

                    <RowItem
                      label="Description"
                      value={
                        <TritronikInputText
                          name="description"
                          type="textarea"
                        />
                      }
                    />

                    {values.hasThreshold && (
                      <div className="row">
                        <div className="col">
                          <CustomLabel
                            name={`Threshold (${values.thresholdUnit})`}
                            required
                          />
                        </div>
                        <div className="col d-flex align-items-center">
                          <TritronikInputText
                            block={false}
                            name="threshold"
                            small
                            style={{ width: 80 }}
                          />
                        </div>
                      </div>
                    )}

                    {values.hasTimeRange && (
                      <RowItem
                        label={<CustomLabel name="Time Range" required />}
                        value={
                          <TritronikSelect
                            value={values.timeRange}
                            options={timeRangeOptions}
                            onChange={(e) =>
                              setFieldValue("timeRange", e.value)
                            }
                            invalid={touched.timeRange && errors.timeRange}
                            error={errors.timeRange}
                          />
                        }
                      />
                    )}

                    <RowItem
                      label={<CustomLabel name="Severity" required />}
                      value={
                        <TritronikSelect
                          value={values.severity}
                          options={severityOptions}
                          onChange={(e) => setFieldValue("severity", e.value)}
                          invalid={touched.severity && errors.severity}
                          error={errors.severity}
                        />
                      }
                    />
                  </Col>
                  <Col>
                    <RowItem
                      label={<CustomLabel name="Apply To" required />}
                      value={
                        <TritronikSelect
                          value={values.applyTo}
                          options={applyToOptions}
                          onChange={(e) => onApplyToChange(e, setFieldValue)}
                        />
                      }
                    />

                    {values.applyTo === "region" && (
                      <RowItem
                        label={<CustomLabel name="By Region" required />}
                        value={
                          <TritronikMultiSelect
                            name="idsOfRegion"
                            options={regionOptions}
                            onChange={(e) =>
                              handleRegionChange(e, values, setFieldValue)
                            }
                            disabled={!hasRegion}
                          />
                        }
                      />
                    )}

                    {values.applyTo === "area" && (
                      <RowItem
                        label={<CustomLabel name="By Area" required />}
                        value={
                          <TritronikMultiSelect
                            name="idsOfArea"
                            options={areaOptions}
                            onChange={(e) =>
                              handleAreaChange(e, values, setFieldValue)
                            }
                            disabled={!hasArea}
                          />
                        }
                      />
                    )}

                    {values.applyTo === "customer" && (
                      <RowItem
                        label={<CustomLabel name="By Customer" required />}
                        value={
                          <TritronikMultiSelect
                            filter
                            name="idsOfCustomer"
                            options={customerOptions}
                            onChange={(e) =>
                              handleCustomerChange(e, values, setFieldValue)
                            }
                            placeholder="By Customer"
                          />
                        }
                      />
                    )}

                    <RowItem
                      label={<CustomLabel name="Alert Channel" required />}
                      value={
                        <TritronikMultiSelect
                          name="channelInCollection"
                          options={channelsOptions}
                          placeholder="Add Channel"
                        />
                      }
                    />

                    <RowItem
                      label={
                        <div className="d-flex align-items-center">
                          <span>Additional Recipients</span>
                          <Tooltip
                            target=".additional-recipients"
                            position="top"
                          />
                          <i
                            className="additional-recipients fa fa-question-circle ml-2 fa-1x"
                            data-pr-tooltip="Enter email address recipients. Separate by ',' or 'Enter'"
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"
                          ></i>
                        </div>
                      }
                      value={
                        <InputChips
                          value={values.additionalRecipients}
                          onChange={(e) =>
                            setFieldValue("additionalRecipients", e.value)
                          }
                          separator=","
                          itemTemplate={(item) => (
                            <div>
                              <i className="fa fa-envelope mr-1"></i>
                              <span>{item}</span>
                            </div>
                          )}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="text-right">
                    <Link
                      to="/admin/alerts/configuration"
                      className="btn btn-outline-default px-5 py-1"
                    >
                      Cancel
                    </Link>
                    <Button
                      className="btn btn-default px-5 py-1"
                      type="submit"
                      disabled={!dirty || isLoading}
                    >
                      Apply
                      {isLoading && (
                        <Spinner className="ml-2" color="light" size="sm" />
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </PageContent>
  );
};

CreateAlertConfigurationPage.propTypes = {};

export default CreateAlertConfigurationPage;
