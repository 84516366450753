import { useMemo } from "react";
import { useGetDevicesQuery } from "../service/deviceApi";
import { defaultOptions, usePagination } from './../../../hooks';
import { useAuth } from "./../../auth/hooks";

export function useDeviceDatatable(query = {}, options = defaultOptions) {
  const { user } = useAuth();

  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort
  } = usePagination({
    pageNumber: options?.pageNumber,
    pageSize: options?.pageSize,
    sortFields: options?.sortFields,
    sortOrder: options?.sortOrder
  });

  const { regionIds, areaIds, username, reachQuotaLimit, ...restParams } = query;
  const { data: paginatedResult, isLoading, isFetching, refetch } = useGetDevicesQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ? sortFields : undefined,
    sortOrder: sortOrder ? sortOrder : undefined,
    regionIds: areaIds ? undefined : regionIds,
    areaIds: areaIds,
    username: username ? username : (user ? user.username : undefined),
    reachQuotaLimit: reachQuotaLimit === 'true' ? true : (reachQuotaLimit === 'false' ? false : undefined),
    ...restParams,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    isFetching,
    pageSize,
    totalRows,
    refetch,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}
