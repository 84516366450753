import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FormNetwork from '../FormNetwork';
import PropTypes from 'prop-types';

const ModalFormNetwork = (props) => {
	return (
		<Modal
			className="modal-dialog-centered"
			size="lg"
			isOpen={props.isOpen}
			toggle={props.toggle}
			backdrop={false}
		>
			<ModalHeader toggle={props.toggle}>
				{props.title || 'Add Network'}
			</ModalHeader>
			<ModalBody>
				<FormNetwork
					network={props.network}
					onSubmit={props.onSubmit}
					isLoading={props.isLoading}
					onCancel={props.toggle}
					submitLabel={props.submitLabel}
				/>
			</ModalBody>
		</Modal>
	)
}

ModalFormNetwork.propTypes = {
	title: PropTypes.string,
	network: PropTypes.object,
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
	onSubmit: PropTypes.func,
	isLoading: PropTypes.bool,
	submitLabel: PropTypes.string,
}
export default ModalFormNetwork;