import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { GroupList } from "./pages";

function GroupRouter() {
  return (
    <Switch>
      <Route exact path={`/admin/groups/`} component={GroupList} />
      <Redirect to="/admin/groups" />
    </Switch>
  );
}

export default GroupRouter;
