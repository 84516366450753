import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: baseQuery,
  tagTypes: ['PERFORMANCE_DASHBOARD_OVERVIEW'],
  endpoints: (builder) => ({
    getDashboardSummary: builder.query({
      query: (params) => ({
        url: `/dashboard`,
        params,
      }),
    }),
    getAlertDataChart: builder.query({
      query: (params) => ({
        url: `/dashboard/alert/pie`,
        params,
      }),
    }),
    getDeviceMissingCIDCount: builder.query({
      query: (params) => ({
        url: '/device/count-missing-cid',
        params,
      })
    }),
    getOpenAlertSummary: builder.query({
      query: (params) => ({
        url: '/dashboard/alert/summary',
        params,
      })
    }),
    getBlendedFailureRate: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/failure-rate',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getBlendedPacketLoss: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/blended-packet-loss',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getMaxPingRTT: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/max-average-rtt',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getAveragePingRTT: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/average-rtt',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getMaxPacketLoss: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/packet-loss',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getUndeliveredDataChart: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/undelivered-data',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getSummaryNumberDevicesWithIssue: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/issue/summary',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),   
    getNumberDevicesFailureRate: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/issue/failure-rate',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getNumberDevicesHighRTT: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/issue/rtt',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getNumberDevicesPacketLoss: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/issue/packet-loss',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getNumberDevicesOutOfQuotaProvider: builder.query({
      query: (params) => ({
        url: 'dashboard-performance/issue/out-out-quota-provider',
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getNumberDevicesSn: builder.query({
      query: ({params, type}) => ({
        url: `dashboard-performance/issue/${type}/sn`,
        params,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
    getLastUpdatedTime: builder.query({
      query: () => ({
        url: `dashboard-performance/last-update-time`,
      }),
      providesTags: ['PERFORMANCE_DASHBOARD_OVERVIEW']
    }),
  })
});

export const {
  useGetDashboardSummaryQuery,
  useGetAlertDataChartQuery,
  useGetDeviceMissingCIDCountQuery,
  useGetOpenAlertSummaryQuery,
  useGetAveragePingRTTQuery,
  useGetBlendedFailureRateQuery,
  useGetBlendedPacketLossQuery,
  useGetMaxPingRTTQuery,
  useGetMaxPacketLossQuery,
  useGetUndeliveredDataChartQuery,
  useGetSummaryNumberDevicesWithIssueQuery,
  useGetNumberDevicesFailureRateQuery,
  useGetNumberDevicesHighRTTQuery,
  useGetNumberDevicesPacketLossQuery,
  useGetNumberDevicesOutOfQuotaProviderQuery,
  useGetNumberDevicesSnQuery,
  useGetLastUpdatedTimeQuery
} = dashboardApi;

