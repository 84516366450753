import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";

const Tag = ({ name, onCloseClick, color, style }) => {
  return (
    <Badge color={color} className="p-1" style={style}>
      {name}
      <span className="btn btn-sm btn-link p-0 m-0" onClick={onCloseClick}>
        <i className="fa fa-times ml-1"></i>
      </span>
    </Badge>
  );
};

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func,
  style: PropTypes.object,
};

Tag.defaultProps = {
  name: null,
  color: "primary",
  onCloseClick: () => {},
  style: {},
};

export default Tag;
