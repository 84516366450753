import { useMemo } from "react";
import { useAuth } from "../../auth/hooks";
import { useGetCustomersQuery } from "../service/customerApi";

export function useCustomers(params = {}) {
  const { user, isAdmin } = useAuth();

  const { data, isLoading } = useGetCustomersQuery({
    username: params.username ? params.username : (isAdmin ? undefined : user.username),
    ...params
  });

  const options = useMemo(() => {
    const opts = [];

    if (data && data.content) {
      data.content.forEach((d) => {
        opts.push({ value: d.id, label: d.name });
      });
    }

    return opts;
  }, [data]);

  return {
    data,
    isLoading,
    options,
  };
}