import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { unknown } from './../../../utils';

const getColor = (status) => {
    switch (status) {
      case "Excellent":
        return "#6ACE61";
      case "Good":
        return "#ffd600";
      case "Fair":
        return "#fb6340";
      case "Poor":
        return "#f5365c";
      default:
        return "#AB0312";
    }
}
const SignalStrengthStatus = ({ status, signal, type }) => {

    return type === 'status' ? (
      <Badge className="badge-dot mr-4">
        <i style={{ backgroundColor: getColor(status)}}/>
        <span className="text-dark">{status || unknown}</span>
      </Badge>
    ) : (
        <span>
            <i className="fa fa-signal mr-1" style={{ color: getColor(status)}}></i>
            <span style={{ color: getColor(status)}}>{signal} dBm</span>
        </span>
    );
}

SignalStrengthStatus.propTypes = {
    status: PropTypes.string,
    signal: PropTypes.any,
    type: PropTypes.oneOf(["status", "index"]),
};

SignalStrengthStatus.defaultProps = {
  signal: null,
  type: "status",
};

export default SignalStrengthStatus;
