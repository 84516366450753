import React from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import FormPolicy from "./FormPolicy";
import { useCreatePolicyMutation } from "../service/policyApi";

const AddPolicyModal = ({ isOpen, toggle }) => {
  const [createPolicy, { isLoading }] = useCreatePolicyMutation();

  const onSubmit = (policy) => {

    const valueToSend = {
      ...policy,
      ref: null,
      updateModified: null,
    };

    createPolicy(valueToSend)
      .unwrap()
      .then(() => {
        toast.success(`Policy (${policy.name}) created.`);
        toggle();
      });
  };

  return (
    <Modal className="modal-dialog-centered" size="md" isOpen={isOpen} backdrop={isLoading}>
      <FormPolicy onSubmit={onSubmit} onCancel={toggle} isLoading={isLoading} />
    </Modal>
  );
};

export default AddPolicyModal;
