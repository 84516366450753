import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Row } from "reactstrap";
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { FilterAlertHistory } from "../components";
import CurrentAlertTableDetail from "../components/CurrentAlertTableDetail";
import { useAlerts } from '../hooks/useAlerts';
import { ButtonLinkIcon, CustomExpandableIcon, PageContent, PrimeSearchInput, StatusBadge, Tags } from "./../../../components";
import { history, momentLastUpdate, paginationRowsPerPageOptions, unknown } from "./../../../utils";
import { CommaArrayParam } from './../../../utils/queryParams';
import { customStyles } from './../../../utils/datatableStyles';

const AlertListPage = () => {
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  const [alertTitle] = useState('Alert');
  const searchRef = useRef();
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    search: StringParam,
    severity: StringParam,
    lastState: StringParam,
    deviceIds: CommaArrayParam,
    sns: CommaArrayParam,
    name: StringParam,
  });

  const {
    data,
    isLoading,
    totalRows,
    pageSize,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  } = useAlerts(query, { sortFields: 'lastUpdated', sortOrder: 'desc' });

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.search || '');
    }
  }, [query]);

  const location = useLocation()

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const columns = useMemo(() => {
    const renderLink = (sn) => {
      if (!sn) return unknown;

      return (
        <a className="text-sm text-default" href="#device" onClick={(e) => {
          e.preventDefault();
          history.push({
            pathname: "/admin/devices",
            search: `?sn=${sn}`
          })
        }}>{sn}</a>
      )
    }

    const renderSeverity = (severity) => {
      let type = 'secondary';

      switch (_.toLower(severity)) {
        case 'minor': type = 'success'; break;
        case 'major': type = 'warning'; break;
        case 'critical': type = 'danger'; break;
        default: type = 'secondary'; break;
      }
      return (
        <StatusBadge label={_.upperFirst(_.toLower(severity))} type={type} />
      );
    }

    const renderLastState = (lastState) => {
      let state = '-';
      let type = 'none';
      switch (lastState) {
        case 'ALERTING':
          state = 'OPEN';
          type = 'danger';
          break;
        case 'OK':
          state = 'RESOLVED'
          type = 'success';
          break;

        default:
          state = 'NO DATA'
          type = 'none';
          break;
      }
      return (
        <StatusBadge label={state} type={type} />
      )
    }

    return [
      {
        name: 'DATETIME',
        selector: row => row?.lastUpdated,
        sortable: true,
        sortField: 'lastUpdated',
        hide: "md",
        format: ({ lastUpdated }) => momentLastUpdate(lastUpdated)
      },
      {
        name: 'NAME',
        selector: row => row?.name,
        sortField: 'name',
        sortable: true,
      },
      {
        name: 'SERIAL NUMBER',
        selector: row => row?.deviceSn,
        wrap: true,
        hide: "md",
        cell: ({ deviceSn }) => renderLink(deviceSn)
      },
      {
        name: 'SEVERITY',
        selector: row => row?.severity,
        sortable: true,
        sortField: 'severity',
        hide: "md",
        cell: ({ severity }) => renderSeverity(severity),
      },
      {
        name: 'STATUS',
        selector: row => row?.lastState,
        sortable: true,
        sortField: 'lastState',
        hide: "md",
        cell: ({ lastState }) => renderLastState(lastState),
      },
    ]
  }, []);

  const onFilter = (filter) => {
    if (filter) {
      const { severity, lastState } = filter;
      setQuery({
        severity: severity || undefined,
        lastState: lastState || undefined,
      });
    }
  }

  const onSearch = (searchText) => {
    setQuery({ search: searchText !== "" && searchText !== null ? searchText : undefined });
  };

  const onRemoveFilter = useCallback((key = null, value = null) => {
    if (!key) return;

    if (value === null) {
      setQuery({ [key]: undefined });
    } else {
      setQuery((q) => {
        const filterQ = q[key]?.filter(v => v !== value);
        return {
          [key]: filterQ && filterQ.length > 0 ? filterQ : undefined
        }
      });
    }

  }, [setQuery]);

  const onResetFilter = () => {
    searchRef.current.clearFilter();
    setQuery({
      id: undefined,
      search: undefined,
      severity: undefined,
      lastState: undefined,
      deviceIds: undefined,
      sns: undefined,
      name: undefined,
    });
  };

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            tags.push({
              key: key,
              value: v,
              label: v,
              onRemove: () => onRemoveFilter(key, v)
            })
          })
        } else {
          tags.push({
            key: key,
            value: value,
            label: value,
            onRemove: () => onRemoveFilter(key)
          })
        }
      }
    });

    return tags;
  }, [query, onRemoveFilter]);

  const rowPreExpanded = (row) => {
    if (location.state && location.state.alertId && location.state.alertId === row.id) {
      return true;
    }
    return false;
  }

  return (
    <PageContent title="Alert Management">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <Breadcrumb listClassName="bg-white m-0 p-0">
                <BreadcrumbItem>
                  <h2 className="text-dark">
                    {alertTitle}
                  </h2>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs="12" md="3" className="d-flex justify-content-lg-end align-items-center">
              <PrimeSearchInput
                ref={searchRef}
                block
                onFilter={onSearch}
                size="sm"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
            </Col>
            <Col xs="12" md="3" className="d-flex justify-content-lg-end align-items-center">
              <FilterAlertHistory
                loading={isLoading}
                filterSeverity={query.severity}
                filterLastState={query.lastState}
                onFilter={onFilter}
              />
              <ButtonLinkIcon label="Rule Configuration" icon="fa-cog" onClick={() => history.push('/admin/alerts/configuration')} />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Tags tags={filterTags} onReset={onResetFilter} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                title=""
                data={data}
                columns={columns}
                defaultSortField="lastUpdated"
                defaultSortAsc={false}
                striped
                noHeader
                highlightOnHover
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                expandableRows
                expandableRowExpanded={rowPreExpanded}
                expandableRowsComponent={CurrentAlertTableDetail}
                expandableIcon={{
                  collapsed: <CustomExpandableIcon type="collapsed" />,
                  expanded: <CustomExpandableIcon type="expanded" />,
                }}
                persistTableHead
                progressPending={isLoading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={pageSize}
                onChangePage={onChangePage}
                sortServer
                onSort={onSort}
                customStyles={customStyles}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
}

export default AlertListPage;
