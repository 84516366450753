import classnames from 'classnames';
import { Formik } from 'formik';
import _ from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import { CustomLabel, FormikToggleSwitch, InputChips, RowItem, TritronikInputText, TritronikMultiSelect, TritronikSelect } from '../../../../components';
import { CONFIGURE_ALERT_RULE } from '../../../../config';
import { Can } from '../../../../config/Can';
import { updateAlertValidation } from '../../validations';
import { useAlertChannel, useAlertSeverity, useAlertType, useTimeRange, useOptions } from './../../../../hooks';
import { useUpdateAlertConfigurationMutation } from './../../../alert/service/alertConfigurationApi';
import { useAuth } from './../../../auth/hooks';
import { useRegion } from './../../../region/hooks';
import { useArea } from './../../../area/hooks';
import { useCustomerOptions } from './../../../../hooks/useCustomerOptions';

export function AlertConfigurationForm({ alert, toggleEditing }) {
  const {
    id,
    type,
    name,
    description,
    parameter,
    threshold,
    status,
    severity,
    channelInCollection,
    allRegion,
    idsOfRegion,
    allArea,
    idsOfArea,
    idsOfCustomer,
    applyToAllCustomer,
    additionalRecipients,
    hasThreshold,
    thresholdUnit,
    hasTimeRange,
    timeRange,
  } = alert;

  const { options: channelsOptions } = useAlertChannel();
  const { options: severityOptions } = useAlertSeverity();
  const { options: regionOptions } = useRegion();
  const { options: areaOptions } = useArea();
  const { options: alertTypeOptions } = useAlertType();
  const { options: timeRangeOptions } = useTimeRange();
  const { customerOptions } = useCustomerOptions();
  const { applyToOptions } = useOptions();

  const [updateAlertConfiguration, { isLoading }] = useUpdateAlertConfigurationMutation();

  const applyByRegion = allRegion || idsOfRegion.length > 0;
  const applyByArea = allArea || idsOfArea.length > 0;
  const applyByCustomer = applyToAllCustomer || idsOfCustomer.length > 0;

  const { user } = useAuth();
  const { allArea: userAllArea, allRegion: userAllRegion } = user;

  const initialValues = {
    id: id || 0,
    type: type || '',
    name: name || '',
    description: description || '',
    parameter: parameter || '',
    threshold: threshold || 0,
    status: status || '',
    severity: severity || '',
    channelInCollection: channelInCollection || [],
    idsOfRegion: idsOfRegion || [],
    idsOfArea: idsOfArea || [],
    idsOfCustomer: idsOfCustomer || [],
    additionalRecipients: additionalRecipients,
    updateModified: Date.now(),
    applyTo: applyByRegion ? 'region' : (applyByArea ? 'area' : (applyByCustomer ? 'customer' : '')),
    thresholdUnit: thresholdUnit || '',
    hasThreshold: hasThreshold || false,
    hasTimeRange: hasTimeRange || false,
    timeRange: timeRange || '5m'
  };

  const handleAlertTypeChange = (e, setFieldValue) => {
    setFieldValue('type', e.value);

    const defaultData = _.find(alertTypeOptions, { value: e.value });
    if (defaultData) {
      setFieldValue('hasThreshold', defaultData.hasThreshold);
      setFieldValue('thresholdUnit', defaultData.thresholdUnit);
      setFieldValue('hasTimeRange', defaultData.hasTimeRange);
      setFieldValue('timeRange', defaultData.timeRange);
    }
  }

  const onSubmit = async ({ idsOfArea, idsOfRegion, idsOfCustomer, applyTo, ...form }) => {
    const mapIdsOfRegion = userAllRegion && idsOfRegion.length > 1 ? idsOfRegion.filter(v => v !== -1) : idsOfRegion;
    const mapIdsOfArea = userAllArea && idsOfArea.length > 1 ? idsOfArea.filter(v => v !== -1) : idsOfArea;
    const data = {
      ...form,
      ...(applyTo === 'region' && { idsOfRegion: mapIdsOfRegion }),
      ...(applyTo === 'area' && { idsOfArea: mapIdsOfArea }),
      ...(applyTo === 'customer' && { idsOfCustomer }),
    };

    Swal.fire({
      icon: "question",
      title: "Are you sure want to update this?",
      text: `Alert: ${name}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        return updateAlertConfiguration({
          body: data,
          params: {
            updateAlertDevice: true,
            updateAlertCustomer: true,
            updateAlertGroupDevice: true,
            updateAlertGroupUser: true,
            updateAlertUser: true
          }
        })
          .unwrap()
          .then(() => {
            return true;
          })
          .catch(error => {
            toast.error(error.message);
            return false;
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.value) {
        toast.success('Alert configuration updated.');
        toggleEditing();
      }
    });
  }

  const handleRegionChange = (e, values, setFieldValue) => {
    const { idsOfRegion } = values;
    const currentAllRegionExists = idsOfRegion.filter(v => v === -1).length > 0;
    const newAllRegionExists = e.value.filter(v => v === -1).length > 0;
    if (currentAllRegionExists && e.value.length > 1) {
      setFieldValue('idsOfRegion', e.value.filter(v => v !== -1));
    } else if (!currentAllRegionExists && newAllRegionExists) {
      setFieldValue('idsOfRegion', e.value.filter(v => v === -1));
    } else {
      setFieldValue('idsOfRegion', e.value);
    }
  }

  const handleAreaChange = (e, values, setFieldValue) => {
    const { idsOfArea } = values;
    const currentAllAreaExists = idsOfArea.filter(v => v === -1).length > 0;
    const newAllAreaExists = e.value.filter(v => v === -1).length > 0;
    if (currentAllAreaExists && e.value.length > 1) {
      setFieldValue('idsOfArea', e.value.filter(v => v !== -1));
    } else if (!currentAllAreaExists && newAllAreaExists) {
      setFieldValue('idsOfArea', e.value.filter(v => v === -1));
    } else {
      setFieldValue('idsOfArea', e.value);
    }
  }

  const handleCustomerChange = (e, values, setFieldValue) => {
    const { idsOfCustomer } = values;
    const currentAllCustomerExists = idsOfCustomer.filter(v => v === -1).length > 0;
    const newAllCustomerExists = e.value.filter(v => v === -1).length > 0;
    if (currentAllCustomerExists && e.value.length > 1) {
      setFieldValue('idsOfCustomer', e.value.filter(v => v !== -1));
    } else if (!currentAllCustomerExists && newAllCustomerExists) {
      setFieldValue('idsOfCustomer', e.value.filter(v => v === -1));
    } else {
      setFieldValue('idsOfCustomer', e.value);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updateAlertValidation}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, dirty, resetForm, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="mt-4">
            <Col className="px-5 border-right">
              <RowItem label={<CustomLabel name="Alert Type" required />} value={(
                <TritronikSelect
                  value={values.type}
                  onChange={(e) => handleAlertTypeChange(e, setFieldValue)}
                  options={alertTypeOptions}
                  invalid={errors.type && touched.type}
                  error={errors.type}
                  disabled
                />
              )} />
              <RowItem label={<CustomLabel name="Alert Name" required />} value={(
                <TritronikInputText name="name" small disabled />
              )} />
              <RowItem label="Description" value={(
                <TritronikInputText name="description" type="textarea" />
              )} />

              {values.hasThreshold && (
                <div className="row">
                  <div className="col"><CustomLabel name={`Threshold (${values.thresholdUnit})`} required /></div>
                  <div className="col d-flex align-items-center">
                    <TritronikInputText block={false} name="threshold" small style={{ width: 80 }} />
                  </div>
                </div>
              )}

              {values.hasTimeRange && (
                <RowItem label={<CustomLabel name="Time Range" required />} value={(
                  <TritronikSelect
                    value={values.timeRange}
                    options={timeRangeOptions}
                    onChange={(e) => setFieldValue('timeRange', e.value)}
                    invalid={touched.timeRange && errors.timeRange}
                    error={errors.timeRange}
                  />
                )} />
              )}

              <RowItem label={<CustomLabel name="Severity" required />} value={(
                <TritronikSelect
                  value={values.severity}
                  options={severityOptions}
                  onChange={(e) => setFieldValue('severity', e.value)}
                  invalid={touched.severity && errors.severity}
                  error={errors.severity}
                />
              )} />

              <RowItem label={<CustomLabel name="Status" />} valueRight value={(
                <div className="d-flex align-items-center w-100 justify-content-end">
                  <div className={classnames("mr-2", {
                    "text-success": values.status === 'Activated',
                    "text-dark": values.status === 'Deactivated',
                    "text-light": values.status === 'Suspended'
                  })}>{values.status}</div>
                  <FormikToggleSwitch checked={values.status === 'Activated'} value={values.status} onChange={(e) => {
                    setFieldValue('status', e.target.checked ? 'Activated' : 'Deactivated');
                  }} />
                </div>
              )} />

            </Col>
            <Col className="px-5 border-left">

              <RowItem label={<CustomLabel name="Apply To" required />} value={(
                <TritronikSelect
                  value={values.applyTo}
                  options={applyToOptions}
                  onChange={(e) => setFieldValue('applyTo', e.value)}
                  invalid={errors.applyTo && touched.applyTo}
                  error={errors.applyTo}
                  disabled
                />
              )} />
              {values.applyTo === 'region' && (
                <RowItem label={<CustomLabel name="By Region" required />} value={(
                  <TritronikMultiSelect filter name="idsOfRegion" options={regionOptions} onChange={(e) => handleRegionChange(e, values, setFieldValue)} placeholder="By Region" disabled />
                )} />
              )}
              {values.applyTo === 'area' && (
                <RowItem label={<CustomLabel name="By Area" required />} value={(
                  <TritronikMultiSelect filter name="idsOfArea" options={areaOptions} onChange={(e) => handleAreaChange(e, values, setFieldValue)} placeholder="By Area" disabled />
                )} />
              )}
              {values.applyTo === 'customer' && (
                <RowItem label={<CustomLabel name="By Customer" required />} value={(
                  <TritronikMultiSelect filter name="idsOfCustomer" options={customerOptions} onChange={(e) => handleCustomerChange(e, values, setFieldValue)} placeholder="By Customer" disabled />
                )} />
              )}

              <RowItem label={<CustomLabel name="Alert Channel" required />} value={(
                <TritronikMultiSelect name="channelInCollection" options={channelsOptions} />
              )} />


              <RowItem
                label={(
                  <div className="d-flex align-items-center">
                    <span>Additional Recipients</span>
                    <Tooltip target=".additional-recipients" position="top" />
                    <i
                      className="additional-recipients fa fa-question-circle ml-2 fa-1x"
                      data-pr-tooltip="Enter email address recipients. Separate by ',' or 'Enter'"
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"></i>
                  </div>
                )}
                value={(
                  <InputChips
                    value={values.additionalRecipients}
                    onChange={(e) => setFieldValue('additionalRecipients', e.value)}
                    separator=","
                    itemTemplate={(item) => (
                      <div>
                        <i className="fa fa-envelope mr-1"></i>
                        <span>{item}</span>
                      </div>
                    )}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="mt-4 mb-5">
            <Col className="px-5 text-right">
              <Button size="sm" type="button" className="btn btn-outline-default px-5 py-1" onClick={() => {
                resetForm();
                toggleEditing();
              }}>
                Cancel
              </Button>
              <Can I="do" this={CONFIGURE_ALERT_RULE}>
                {() => (
                  <Button size="sm" type="submit" className="btn btn-default px-5 py-1" disabled={!dirty || isLoading}>
                    Apply {isLoading && <Spinner className="ml-2" color="light" size="sm" />}
                  </Button>
                )}
              </Can>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
