import React from "react";
import { Formik } from "formik";
import { RowItem } from "components";
import { CustomLabel } from "components";
import { TritronikInputText, TritronikSelect, Loading } from "components";
import { Button, Col, Form, Row } from "reactstrap";
import { useGetPolicyQuery } from "features/policy/service/policyApi";

const FormPackagePolicy = ({ onCancel, onSubmit, isLoading, policy }) => {
    const { data: policyBandwidth} = useGetPolicyQuery();
    const bandwidthPolicyOptions = policyBandwidth?.content.map(item => {
        return {label: item.name, value: item}
    })

  return (
    <Formik
      initialValues={{
        packageName: policy?.packageName || "",
        policyUpstream: policy?.policyUpstream || "",
        policyDownstream: policy?.policyDownstream || "",
      }}
        onSubmit={onSubmit}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h3 className="modal-title">
              {policy ? "Edit Package Policy" : "Add New Package Policy"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={onCancel}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body py-2">
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="Name" />}
              value={
                <TritronikInputText small name="packageName" placeholder="Name" />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="Upstream" required />}
              value={
                <TritronikSelect
                  name="PolicyUpstream"
                  placeholder="Upstream"
                  options={bandwidthPolicyOptions}
                  value={values.policyUpstream}
                  onChange={(e) => setFieldValue("policyUpstream", e.value)}
                />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="Downstream" required />}
              value={
                <TritronikSelect
                  name="PolicyDownstream"
                  placeholder="Downstream"
                  options={bandwidthPolicyOptions}
                  value={values.policyDownstream}
                  onChange={(e) => setFieldValue("policyDownstream", e.value)}
                />
              }
            />
            <Row className="my-4">
              <Col className="text-right">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={onCancel}
                  className="px-5 py-1"
                >
                  Cancel
                </Button>
                <Button
                  className="px-5 py-1"
                  color="default"
                  type="submit"
                  size="sm"
                    disabled={isLoading || values.policyUpstream === '' || values.policyDownstream === '' || values.packageName === ''}
                >
                  Apply
                  {isLoading && <Loading className="ml-2" />}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormPackagePolicy;
