import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NotificationHistory } from "./pages";

function NotificationRoute() {
  return (
    <Switch>
      <Route exact path={`/admin/notifications/`} component={NotificationHistory} />
      <Redirect to="/admin/notifications/" />
    </Switch>
  );
}

export default NotificationRoute;
