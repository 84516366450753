import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { SimcardInventoryList } from "./pages";

function SimcardInventoryRoutes() {
  return (
    <Switch>
      <Route exact path={`/admin/inventory/simcard`} component={SimcardInventoryList} />

      <Redirect to="/admin/inventory/simcard" />
    </Switch>
  );
}

export default SimcardInventoryRoutes;
