import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { OnuInventoryList } from "./pages";

function OnuInventoryRoutes() {
  return (
    <Switch>
      <Route exact path={`/admin/inventory/onu`} component={OnuInventoryList} />

      <Redirect to="/admin/inventory/onu" />
    </Switch>
  );
}

export default OnuInventoryRoutes;