import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import { Can, CONFIGURE_DEVICE } from "./../../../../config";
import { ButtonLinkIcon } from "./../../../../components";
import DeviceOverview from "./DeviceOverview";
import SimCardOverview from "./SimCardOverview";
import DeviceConfigurationForm from "./DeviceConfigurationForm";
import DeviceTunnelOverview from "./DeviceTunnelOverview";

const DeviceOverviewTab = ({
  device,
  groupOptions,
  areaOptions,
  regionOptions,
  customerOptions,
  deviceTypeOptions,
}) => {
  const { simCards, hasTunnelDetails } = device;
  const [editing, setEditing] = useState(false);

  const toggleEditing = () => {
    setEditing(!editing);
  }

  const getLanPortStatus = () => {
    const { lanPorts } = device;
    const portCounts = lanPorts.length || 0;
    const portConnected = lanPorts.filter((port) => port.status === 'up').length || 0;
    return `${portConnected} of ${portCounts} Connected`;
  }

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-end px-4">
          <Can I="do" this={CONFIGURE_DEVICE}>
            {() => !editing && (
              <ButtonLinkIcon
                label="Edit"
                icon="fa-edit"
                onClick={() => setEditing(!editing)}
              />
            )}
          </Can>
        </Col>
      </Row>
      {!editing && (
        <>
          <Row>
            <Col className="border-right px-5 pb-5">
              <DeviceOverview device={device} getLanPortStatus={getLanPortStatus} />
            </Col>
            <Col className="border-left px-5 pb-5">
              <SimCardOverview deviceId={device.id} simCards={simCards} />
            </Col>
          </Row>
          {hasTunnelDetails && <DeviceTunnelOverview tunnels={device?.tunnelDetails} />}
        </>
      )}
      {editing && (
        <DeviceConfigurationForm
          device={device}
          toggleEditing={toggleEditing}
          getLanPortStatus={getLanPortStatus}
          groupOptions={groupOptions}
          areaOptions={areaOptions}
          regionOptions={regionOptions}
          customerOptions={customerOptions}
          deviceTypeOptions={deviceTypeOptions}
        />
      )}
    </>
  );
};

export default DeviceOverviewTab;
