import * as Yup from 'yup';

export const UserAddSchema = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  email: Yup.string().email('Email must be valid email!').required('Email is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),
  groupIds: Yup.array().of(Yup.string()).required('Please select group to be assigned!').min(1),
  // roleNames: Yup.array().of(Yup.string()).required('Please select role to be assigned!').min(1),
  username: Yup.string().required('Username is required for login!'),
  password: Yup.string().required('Password is required for login!').min(6, 'Password length minimum is 6 characters!').max(16, 'Password length maximum is 16 characters!'),
  newRole: Yup.boolean(),
  copyRoleId: Yup.string().when('newRole', { is: true, then: Yup.string().required('Select role to be copied!') }),
  role: Yup.string().when('newRole', { is: false, then: Yup.string().required('Please select role to be assigned!') }),
});

export const UserEditSchema = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  email: Yup.string().email('Email must be valid email!').required('Email is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),
  groupIds: Yup.array().of(Yup.string()).required('Please select group to be assigned!').min(1),
  username: Yup.string().required('Username is required for login!'),
  role: Yup.string().required('Please select role to be assigned!'),
});

export const profileValidation = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  email: Yup.string().email('Email must be valid email!').required('Email is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),
  username: Yup.string().required('Username is required for login!'),
  password: Yup.string().required('Password is required for login!').min(6, 'Password length minimum is 6 characters!').max(16, 'Password length maximum is 16 characters!'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password not match!').required('Confirm password required.'),
});

export const editProfileValidation = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  email: Yup.string().email('Email must be valid email!').required('Email is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),
  username: Yup.string().required('Username is required for login!'),
  // password: Yup.string().required('Password is required for login!').min(6, 'Password length minimum is 6 characters!').max(16, 'Password length maximum is 16 characters!'),
});

export const privilegeValidation = Yup.object().shape({
  newRole: Yup.boolean(),
  copyRoleId: Yup.string().when(['newRole', 'copyRole'], { is: true, then: Yup.string().required('Select role to be copied!') }),
  role: Yup.string().when('newRole', { is: false, then: Yup.string().required('Please select role to be assigned!') }),
});

export const eligibleDataValidation = Yup.object().shape({
  regions: Yup.array().when('selectedRegion', { is: '-1', then: Yup.array(), otherwise: Yup.array().min(1, 'Zone must be set.')}),
  customers: Yup.array().when('selectedCustomer', { is: '-1', then: Yup.array(), otherwise: Yup.array().min(1, 'Customer must be set.')}),
});

export const updateProfileValidation = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  email: Yup.string().email('Email must be valid email!').required('Email is required!'),
  phoneNumber: Yup.string().required('Phone number is required!'),
});

export const validationSteps = [profileValidation, privilegeValidation, eligibleDataValidation];
