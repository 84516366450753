import React, { useMemo } from "react";
import RoleListAction from "../components/RoleListAction";
import moment from "moment";
import { useDeleteRole } from "./useDeleteRole";

export function useRoleColumns() {
  const { handleDelete } = useDeleteRole();

  return useMemo(() => {
    return [
      {
        name: "NAME",
        selector: row => row.name,
        sortField: 'name',
        sortable: true,
        left: true,
      },
      {
        name: "PRIVILEGES",
        cell: (row) => (row ? row.rolePrivileges.length : "-"),
        sortable: false,
        center: true,
      },
      {
        name: "CREATED BY",
        cell: (row) => row?.createdBy ??  "-",
        sortable: false,
      },
      {
        name: "CREATED DATE",
        cell: (row) => row?.creationTime ? moment(row.creationTime).format('DD/MM/YYYY') : "-",
      },
      {
        name: '',
        width: '40px',
        allowOverflow: true,
        button: true,
        cell: (row) => (
            <RoleListAction
                row={row}
                onDelete={handleDelete}
            />
        ),
    },
    ];
  }, [handleDelete]);
}
