import { useCallback } from "react";
import { useCreateRole } from "./useCreateRole";
import { useUpdateRoleFeatures } from "./useUpdateRoleFeatures";

export function useCreateRoleAndPrivileges() {
  const { handleCreate, isLoading: isCreatingRole } = useCreateRole();
  const { handleUpdate, isLoading: isUpdatingRole } = useUpdateRoleFeatures();

  const handleCreateRole = useCallback(
    async ({ name, description, featureIds }) => {
      const role = await handleCreate({ name, description });
      if (role && role.id) {
        await handleUpdate(role.id, featureIds);
      }
    },
    [handleCreate, handleUpdate]
  );

  return {
    isLoading: isCreatingRole || isUpdatingRole,
    handleCreateRole,
  };
}
