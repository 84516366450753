import { useAuth } from "./../../auth";
import { useDownloadDevicesMutation } from "../service/deviceApi";
import { useSelector } from "react-redux";
import { selectColumnDefs } from "../reducers/deviceSlice";
import { columnOptions } from './../reducers/deviceSlice';
import { useMemo } from "react";
import moment from "moment";

export function useExports() {
    const { user } = useAuth();
    const [getData, result] = useDownloadDevicesMutation();
    const columnDefs = useSelector(selectColumnDefs);

    const headers = useMemo(() => {
        const headers = columnOptions.filter((col) => columnDefs.includes(col.value)).map((col) => {
            return {
                label: col.label,
                key: col.value
            }
        });
        return headers;
    }, [columnDefs]);

    const fetchData = (params = {}) => {
        return new Promise(async (resolve, reject) => {
            const { regionIds, areaIds, username, reachQuotaLimit, ...restParams } = params;
            try {
                const data = await getData({
                    regionIds: areaIds ? undefined : regionIds,
                    areaIds: areaIds,
                    username: username ? username : (user ? user.username : undefined),
                    reachQuotaLimit: reachQuotaLimit === 'true' ? true : (reachQuotaLimit === 'false' ? false : undefined),
                    ...restParams
                }).unwrap();
    
                if (!data?.content) {
                    resolve([]);
                }
    
                const results = data.content;
    
                const mappedResult = results.map((device) => {
                    const { simCards, lastDataReceive, groups, ...dataDevice } = device;

                    const sim1 = simCards && simCards.find(({ slotNumber }) => slotNumber === 1);
                    const sim2 = simCards && simCards.find(({ slotNumber }) => slotNumber === 2);

                    const simCardProps = {
                        sim1CircuitNumber: sim1?.circuitNumber || '-',
                        sim1GsmNumber: sim1?.gsmNumber || '-',
                        sim1Imsi: sim1?.imsi || '-',
                        sim1Status: sim1?.status || '-',
                        sim1Usage: sim1?.usageInHuman || '-',
                        sim1Signal: sim1?.signalStrengthIndex || '-',
                        sim1Operator: sim1?.operator || '-',
                        sim2CircuitNumber: sim2?.circuitNumber || '-',
                        sim2GsmNumber: sim2?.gsmNumber || '-',
                        sim2Imsi: sim2?.imsi || '-',
                        sim2Status: sim2?.status || '-',
                        sim2Usage: sim2?.usageInHuman || '-',
                        sim2Signal: sim2?.signalStrengthIndex || '-',
                        sim2Operator: sim2?.operator || '-',
                    }
    
                    return {
                        ...dataDevice,
                        ...simCardProps,
                        lastDataReceive: moment(lastDataReceive).format('DD/MM/YYYY HH:mm:ss'),
                        groups: groups.map(g => g.name).join(', ')
                    }
                });
    
                resolve(mappedResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    const fileNameCSV = `export_devices_${Date.now()}`;

    return {
        fetchData,
        headers,
        fileNameCSV,
        ...result
    };
}