import React from 'react';
import { Chip } from 'primereact/chip';
import PropTypes from 'prop-types';


const Tag = ({ label, onRemove, className }) => {
  return (
    <Chip
      className={className}
      removable
      label={label}
      onRemove={onRemove}
      style={{ marginRight: 5, marginTop: 5 }}
    />
  )
}

const Tags = ({ tags, onReset, resetLabel = 'Reset Filter' }) => {

  const renderTags = () => {
    if (!tags) return null;

    return tags.map(({ key, value, label, onRemove}, i) => (
      <Tag key={`q-${key}-${value}-${i}`} label={label} onRemove={onRemove} />
    ))
  };

  const renderResetFilter = () => {
    if (!tags || tags.length === 0) return null;
    return (<Tag label={resetLabel} onRemove={onReset} className="bg-default text-white" />)
  };

  return (
    <div className="d-flex flex-wrap">
      {renderTags()}
      {renderResetFilter()}
    </div>
  )
}

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.any,
    onRemove: PropTypes.func,
  })),
  onReset: PropTypes.func,
  resetLabel: PropTypes.string,
}

export default Tags;
