
import React from "react";
import { Route, Switch } from "react-router-dom";
import { RoleList } from "../pages"; 


function RoleRouter() {
  return (
    <Switch>
      <Route exact path={`/admin/roles`} component={RoleList} />
    </Switch>
  );
}

export default RoleRouter;
