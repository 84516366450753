import React from "react";

export function FormikCheckbox({label, id, checked, onChange, ...props}) {
  return (
    <div className="custom-control custom-checkbox mb-3">
      <input className="custom-control-input" type="checkbox" id={id} checked={checked} onChange={onChange} {...props} />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}