/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Formik } from 'formik';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { toast } from 'react-toastify';
// reactstrap components
import {
  Card,
  CardBody, Col, Container, Form, Row, Spinner
} from "reactstrap";
import { history } from "utils";
import * as Yup from 'yup';
import { InputTextGroup, MyButton, MyCheckbox } from "./../../../../components";
import { authSlice } from './../../reducers/authSlice';
import { useAuthenticateMutation } from "./../../service/authApi";
import './Login.css';
import { APP_CLIENT_LOGO } from "./../../../../config/config";

const Login = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  const [authenticate, { isLoading }] = useAuthenticateMutation();

  if (token) {
    return <Redirect to={{ pathname: '/' }} />
  }

  const onSubmit = async ({ username, password }) => {
    try {
      const result = await authenticate({ username, password }).unwrap();
      toast.success(`Welcome back ${username}`);
      dispatch(authSlice.actions.updateTokenAndUsername({ username, token: result.token }));
      history.push('/admin/dashboard');
    } catch (error) {
      console.error(error);
      toast.error(error?.data?.message || 'Failed to login, check your credentials.');
    }
  }

  return (
    <>
      <div className="header bg-white py-5 py-lg-8">
        <Container>
          <div className="header-body text-center mb-8">
          </div>
          <div className="brand-logo">
            {/* <img id="brand-logo" alt="" width="200" src={APP_BRAND_LOGO} /> */}
            <h4 className="text-primary">Device Management Platform</h4>
          </div>

        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="fill-default"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </div>
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-3">
                <div className="mb-4 text-center mt-3">
                  {/* <img src={APP_CLIENT_LOGO} alt="" width="175" height="auto" /> */}
                  <img src={APP_CLIENT_LOGO} alt="" width="175" height="auto" />
                  <p className="text-dark text-center mt-4">Welcome! Please login to continue.</p>
                </div>
                <Formik
                  initialValues={{
                    username: '',
                    password: '',
                    remember: false,
                  }}
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required('Username is required!'),
                    password: Yup.string().required('Password is required!'),
                  })}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <Form role="form" onSubmit={formik.handleSubmit}>
                      <InputTextGroup
                        placeholder="Email"
                        icon="ni ni-email-83"
                        invalid={Boolean(formik.errors.username && formik.touched.username)}
                        {...formik.getFieldProps('username')}
                      />
                      <InputTextGroup
                        placeholder="Password"
                        type="password"
                        icon="ni ni-lock-circle-open"
                        invalid={Boolean(formik.errors.password && formik.touched.password)}
                        {...formik.getFieldProps('password')}
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        <MyCheckbox id="rememberMe" label="Remember Me" alternative {...formik.getFieldProps('remember')} />
                        <div>
                          <a className="text-sm" href="/auth/forgot-password">Forgot Password?</a>
                        </div>
                      </div>
                      <div className="text-center mt-5">
                        <MyButton block type="submit">
                          Sign In
                          {isLoading && <Spinner className="ml-2" color="light" size="sm" />}
                        </MyButton>
                      </div>
                      <div className="text-right d-none">
                        <span className="btn btn-sm btn-link mt-1 px-0">
                          Signin with SSO
                        </span>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
