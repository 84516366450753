import React from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import * as Yup from "yup";
import { Gap, Loading, TritronikInputText } from "../../../components";
import { useCreateGroupMutation } from "../service/groupApi";

const ModalAddGroup = ({ isOpen, toggle }) => {
  const [createGroup, { isLoading }] = useCreateGroupMutation();

  const onSubmit = async (group) => {
    createGroup(group)
      .unwrap()
      .then(() => {
        toast.success(`Group ${group.name} created.`);
        toggle();
      });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <Formik
        initialValues={{
          id: 0,
          name: "",
          description: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Group name is required."),
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h3 className="modal-title">Add New Group</h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body py-2">
              <TritronikInputText
                label="Group Name"
                name="name"
                placeholder="Group Name"
                small
                required
              />
              <Gap height={15} />
              <TritronikInputText
                label="Description"
                name="description"
                placeholder="Description"
                small
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button block color="default" type="submit" size="sm" disabled={isLoading || !dirty}>
                    Create
                    {isLoading && <Loading className="ml-2" />}
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="text-center">
                  <Button
                    block
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalAddGroup;
