import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const RowItemLabel = ({ label, secondary }) => {
  return (
    <span>
      <span>
        <i
          className={classnames("mr-2 text-sm text-default", {
            "fas fa-check-circle": !secondary,
            "far fa-check-circle": secondary,
          })}
        ></i>
      </span>
      {label}
    </span>
  );
};

RowItemLabel.propTypes = {
  label: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
};

RowItemLabel.defaultProps = {
  label: null,
  secondary: false,
};

export default RowItemLabel;
