import * as Yup from 'yup';

export const createAlertValidation = Yup.object({
  type: Yup.string().required('Alert type required.'),
  name: Yup.string().required('Alert name required.'),
  severity: Yup.string().required('Alert severity required.'),
  threshold: Yup.number()
    .when(['hasThreshold', 'type'], {
      is: (hasThreshold, type) => hasThreshold && type !== 'Signal Strength Alert',
      then: Yup.number().min(1).required('Threshold required.'),
      otherwise: Yup.number().required('Threshold required.')
    }),
  applyTo: Yup.string(),
  channelInCollection: Yup.array().min(1, 'Please select channel to send alert.'),
  idsOfRegion: Yup.array().when('applyTo', { is: 'region', then: Yup.array().min(1, 'Please select at least one region to apply by region.')}),
  idsOfArea: Yup.array().when('applyTo', { is: 'area', then: Yup.array().min(1, 'Please select at least one area to apply by area.')}),
  idsOfCustomer: Yup.array().when('applyTo', { is: 'customer', then: Yup.array().min(1, 'Please select customer at least one to apply by customer.')}),
});

export const updateAlertValidation = Yup.object({
  type: Yup.string().required('Alert type required.'),
  name: Yup.string().required('Alert name required.'),
  severity: Yup.string().required('Alert severity required.'),
  threshold: Yup.number()
    .when(['hasThreshold', 'type'], {
      is: (hasThreshold, type) => hasThreshold && type !== 'Signal Strength Alert',
      then: Yup.number().min(1).required('Threshold required.'),
      otherwise: Yup.number().required('Threshold required.')
    }),
  applyTo: Yup.string().required('This field required.'),
  channelInCollection: Yup.array().min(1, 'Please select channel to send alert.'),
  idsOfRegion: Yup.array().when('applyTo', { is: 'region', then: Yup.array().min(1, 'Please select at least one region to apply by region.')}),
  idsOfArea: Yup.array().when('applyTo', { is: 'area', then: Yup.array().min(1, 'Please select at least one area to apply by area.')}),
  idsOfCustomer: Yup.array().when('applyTo', { is: 'customer', then: Yup.array().min(1, 'Please select at least one customer to apply by customer.')}),
});