import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";
import { useAlertSubscriptionDatatable } from "../../../alert/hooks/useAlertSubscriptionDatatable";
import { useNotificationSubscriptionDatatable } from "../../../notification/hooks/useNotificationSubscriptionDataTable";
import { useProfile } from "../../hooks/useProfile";
import { PageContent } from "./../../../../components";
import { useAlertSubscription } from './../../../alert/hooks/useAlertSubscription';
import { useNotificationSubscription } from './../../../notification/hooks/useNotificationSubscription';
import SubscriptionTable from "./SubscriptionTable";
import './Profile.scss';
import PreviewProfile from "./components/PreviewProfile";
import EditProfile from "./components/EditProfile";
import { confirmAction } from "./../../../../utils/swal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const Profile = () => {
  const [editMode, setEditMode] = useState(false);
  const { changeAlertSubscriptionByUsername } = useAlertSubscription();
  const { changeNotificationSubscriptionByUsername } = useNotificationSubscription();
  const dtAlertSubs = useAlertSubscriptionDatatable();
  const dtNotificationSubs = useNotificationSubscriptionDatatable();
  const { user, isUpdatingProfile, isUpdatingPassword, updateUser, updateUserPassword, uploadPhoto, isUploading } = useProfile();

  const toggleEdit = () => setEditMode(!editMode);

  const onSubmitProfile = async (data) => {
    confirmAction({
      text: 'Update Profile?',
      preConfirm: async () => {
        try {
          if (data.avatar !== '') {
            let body = new FormData();
            body.append('imageMultipart', data.avatar);
            await uploadPhoto({ body, username: data.username }).unwrap();
          }
          await updateUser({ body: data }).unwrap()
          return true;
        } catch (error) {
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        toggleEdit();
        toast.success(`Update profile success.`);
        window.location.reload(false);
      }
    });
  }

  const onChangeAlertSubscription = async (e, subscribe) => {
    e.preventDefault();
    changeAlertSubscriptionByUsername(user.username, subscribe);
  }

  const onChangeNotificationSubscription = async (e, subscribe) => {
    e.preventDefault();
    changeNotificationSubscriptionByUsername(user.username, subscribe);
  }

  return (
    <PageContent title={`Hello ${user.name},`}>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col className="d-flex align-items-center">
                  <h2 className="text-default">My Profile</h2>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  {!editMode && <Button color="link" onClick={toggleEdit}>Edit<i className="fa fa-edit ml-2"></i></Button>}
                </Col>
              </Row>
              {editMode
                ? <EditProfile user={user} isLoading={isUpdatingProfile || isUploading} onSubmitProfile={onSubmitProfile} onCancel={toggleEdit} />
                : <PreviewProfile user={user} isUpdatingPassword={isUpdatingPassword} updateUserPassword={updateUserPassword} />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col><h2 className="text-default">Setting</h2></Col>
              </Row>
              <Row>
                <Col md="6" xs="12" className="px-5 border-right">
                  <Row className="mb-2">
                    <Col className="d-flex align-items-center">
                      <span className="font-weight-bold">Alert Subscription</span>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end">
                      <a href="#allow-alert" className="text-default" onClick={(e) => onChangeAlertSubscription(e, true)}>
                        <i className="text-default text-underline">Allow</i>
                      </a>
                      <i className="mx-1 text-default">/</i>
                      <a href="#dissalow-alert" className="text-default text-underline" onClick={(e) => onChangeAlertSubscription(e, false)}>
                        <i className="text-default">Disallow</i>
                      </a>
                      <i className="text-default ml-2">All</i>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SubscriptionTable dt={dtAlertSubs} />
                    </Col>
                  </Row>
                </Col>
                <Col md="6" xs="12" className="px-5 border-left">
                  <Row className="mb-2">
                    <Col className="d-flex align-items-center">
                      <span className="font-weight-bold">Notification Subscription</span>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end">
                      <a href="#allow-notification" className="text-default" onClick={(e) => onChangeNotificationSubscription(e, true)}>
                        <i className="text-default text-underline">Allow</i>
                      </a>
                      <i className="mx-1 text-default">/</i>
                      <a href="#dissalow-notification" className="text-default text-underline" onClick={(e) => onChangeNotificationSubscription(e, false)}>
                        <i className="text-default">Disallow</i>
                      </a>
                      <i className="text-default ml-2">All</i>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SubscriptionTable dt={dtNotificationSubs} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageContent>
  );
}

export default Profile;
