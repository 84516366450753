import classNames from 'classnames';
import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Collapse } from 'reactstrap';

import { RowItem, Gap, TogglePasswordLabel } from './../../../components'

const FormConfirmation = ({ formik }) => {
  const { values } = formik;
  const [collapsible, setCollapsible] = useState({
    gateways: true,
    ...values.gateways.reduce((obj, item) => Object.assign(obj, {[`gw-${item.gatewayId}`]: true }), {})
  });

  const onCollapsibleStateChange = (field) => {
    setCollapsible((state) => ({
      ...state,
      [field]: !(Boolean(state[field]))
    }))
  }

  return (
    <div>
      <h2 className="ml-4">Confirmation</h2>
      <Gap height={30} />
      <Row className="px-3">
        <Col>
          <Card>
            <CardBody>
              <h4 style={{ marginLeft: -14 }}>Device Profile</h4>
              <Gap height={10} />
              <RowItem label="Serial Number" value={values.sn} />
              <RowItem stripped label="Type" value={values.type} />
              <RowItem label="IP Address" value={values.ipAddress} />
              <RowItem stripped label="Address" value={values.location} />
              <RowItem label="Username" value={values.username} />
              <RowItem stripped label="Password" value={<TogglePasswordLabel value={values.password} />} />
              <RowItem label="Customer" value={values.customerName} />
              <RowItem stripped label="Region" value={values.regionName} />
              <RowItem label="Area" value={values.areaName} />
              <RowItem stripped label="Group" value={values.groupNames} />
              <RowItem
                label="Gateway ID"
                value={
                  (
                    <span>
                      {`${values.gateways.length || 0} ${values.gateways.length > 1 ? 'Gateways' : 'Gateway'}`}
                      <button
                        type="button"
                        onClick={() => onCollapsibleStateChange('gateways')}
                        className="btn btn-sm btn-link bg-transparent ml-2"
                      >
                        <i
                          className={classNames("fa text-default", {
                            "fa-chevron-down": !(Boolean(collapsible['gateways'])),
                            "fa-chevron-up": Boolean(collapsible['gateways']),
                          })}
                        ></i>
                      </button>
                    </span>
                  )
                }
              />
              <Collapse isOpen={Boolean(collapsible['gateways'])}>
                {values.gateways && values.gateways.map((g, i) => (
                  <>
                    <RowItem
                      offset
                      key={`gateway-id-${g.id}`}
                      stripped={i % 2 === 0}
                      label={`Gateway ID - ${i + 1}`}
                      value={
                        (
                          <span>
                            {g.gatewayId}
                            <button
                              id={`toggle-gw-${i}-${g.id}`}
                              type="button"
                              onClick={() => onCollapsibleStateChange(`gw-${g.gatewayId}`)}
                              className="btn btn-sm btn-link bg-transparent ml-2"
                            >
                              <i
                                className={classNames("fa text-default", {
                                  "fa-chevron-down": !(Boolean(collapsible[`gw-${g.gatewayId}`])),
                                  "fa-chevron-up": Boolean(collapsible[`gw-${g.gatewayId}`]),
                                })}
                              ></i>
                            </button>
                          </span>
                        )
                      }
                    />
                    <Collapse isOpen={Boolean(collapsible[`gw-${g.gatewayId}`])}>
                      <RowItem
                        stripped={i % 2 === 1}
                        leftCol='offset-2'
                        label='Peer IP'
                        value={g.peerIp || '-'}
                      />
                      <RowItem
                        stripped={i % 2 === 0}
                        leftCol='offset-2'
                        label='Peer ID'
                        value={g.peerId || '-'}
                      />
                      <RowItem
                        stripped={i % 2 === 1}
                        leftCol='offset-2'
                        label='Gateway Node'
                        value={g.node || '-'}
                      />
                    </Collapse>
                  </>
                ))}
              </Collapse>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <h4 style={{ marginLeft: -14 }}>SIM Profile</h4>
              {values.simCards && values.simCards.map((simCard, idx) => (
                <div key={`sim-${idx + 1}`}>
                  <Gap height={idx === 0 ? 20 : 30} />
                  <h3 className="ml--2">SIM {idx + 1}</h3>
                  <RowItem stripped label="SIA" value={simCard.circuitNumber} />
                  <RowItem label="GSM Number" value={simCard.gsmNumber} />
                  <RowItem stripped label="IMSI" value={simCard.imsi} />
                  <RowItem label="Operator" value={simCard.operator} />
                  <RowItem stripped label="Quota Package" value={`${simCard.quotaPackage} ${simCard.quotaPackageUom}`} />
                </div>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default FormConfirmation
