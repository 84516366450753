import { useMemo } from "react";
import { useAuth } from './../../auth/hooks';
import { useUserIMSI } from './../../device/hooks/useUserIMSI';
import { useGetDeviceOverQuotaByCustomerQuery, useGetDeviceQuotaUsageInPercentageQuery } from "./../../device/service/usageApi";
import { useGetAlertDataChartQuery, useGetDashboardSummaryQuery } from './../service/dashboardApi';

const useDashboardData = (params) => {
  const { username, isAdmin } = useAuth();

  const imsi = useUserIMSI();

  const { data: dashboardSummary, isLoading: isLoadingSummary } = useGetDashboardSummaryQuery({
    username: username 
  }, { skip: !username });

  const { data: deviceUsagePercentageData, isLoading: isLoadingOQP } = useGetDeviceQuotaUsageInPercentageQuery(
    {
      params : {
        ...params
      },
      body: isAdmin ? undefined : imsi
    },
    { skip: !username || !params.startTs || !params.endTs }
  );

  const { data: alertDataChart, isLoading: isLoadingAlertSummary } = useGetAlertDataChartQuery({
    username: username,
    beginTs: params.startTs || undefined,
    endTs: params.endTs || undefined,
  }, { skip: !username || !params.startTs || !params.endTs });

  const { data: dataOverQuotaByCustomer, isLoading: isLoadingOQC } = useGetDeviceOverQuotaByCustomerQuery(
    { params: {
      fill: 'previous',
      ...params
      },
      body: isAdmin ? undefined : imsi
    },
    { skip: !username || (!isAdmin && !imsi) || !params.startTs || !params.endTs} 
  )

  const widget = useMemo(() => {
    if (!dashboardSummary) {
      return {
        countCustomer: 0,
        countDevice: 0,
        countOffLimitSimCard: 0,
        countConnectionLostSimCard: 0,
        countDeactivatedSimCard: 0,
        countRegion: 0,
      }
    }

    return dashboardSummary;
  }, [dashboardSummary]);

  return {
    isLoadingAlertSummary,
    isLoadingSummary,
    isLoadingOQP,
    isLoadingOQC,
    widget,
    deviceUsagePercentageData,
    alertDataChart,
    dataOverQuotaByCustomer,
  };
};

export default useDashboardData;
