import { PageContent, CustomCellStatusBadge } from "../../../../components";
import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { customStyles } from "../../../../../src/utils/datatableStyles";
import DataTable from "react-data-table-component";
import { useGetOnuQuery } from "../service";

const OnuInventoryList = () => {

  const {data: onu, isLoading} = useGetOnuQuery()

  const columns = [
    {
      name: "ID",
      center: true,
      hide: "md",
      selector: (row) => row?.id,
    },
    {
      name: "SERIAL NUMBER",
      center: true,
      hide: "md",
      selector: (row) => row?.sn,
    },
    {
      name: "CONNECTION STATUS",
      center: true,
      hide: "md",
      // selector: (row) => row?.registerStatus,
    },
    {
      name: "PASSWORD",
      center: true,
      hide: "md",
      cell: () => <div>***********</div>,
    },
    {
      name: "OLT ID",
      center: true,
      hide: "md",
      selector: (row) => row?.oltId,
    },
    {
      name: "SLOT ID",
      center: true,
      hide: "md",
      selector: (row) => row?.slotId,
    },
    {
      name: "STATUS",
      center: true,
      hide: "md",
      selector: (row) => row?.registerStatus,
      cell: (row) => <CustomCellStatusBadge row={row} name="registerStatus" successFlag="REGISTERED" dangerFlag="UNREGISTERED" />
    },
    // {
    //   name: "",
    //   button: true,
    //   allowOverflow: true,
    //   hide: "md",
    //   cell: (row) => (
    //     <PolicyActionButton
    //       row={row}
    //       onDelete={onDeletePolicy}
    //       onEdit={onEditPolicy}
    //     />
    //   ),
    // },
  ];
  return (
    <PageContent title="Device Inventory">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col xs="12" md="6">
              <h2 className="text-dark">ONU/ ONT</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={onu?.content}
                columns={columns}
                progressPending={isLoading}
                persistTableHead
                highlightOnHover
                noHeader
                striped
                pagination
                customStyles={customStyles}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default OnuInventoryList;
