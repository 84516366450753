import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { BatchActions, ButtonLinkIcon, CustomExpandableIcon, PageContent, PrimeSearchInput, StatusBadge, Tags } from '../../../components';
import { history, momentLastUpdate, paginationRowsPerPageOptions, unknown, uppercase } from "../../../utils";
import { FilterAlert, TableActionButton } from "../components";
import { AlertTableDetail } from '../components/AlertTableDetail';
import { useAlertDatatable } from '../hooks';
import { ADD_NEW_ALERT_RULE, Can } from './../../../config';
import { useDeleteAlertConfigurationMutation, useUpdateAlertConfigurationMutation } from './../../alert/service/alertConfigurationApi';
import { customStyles } from "./../../../utils/datatableStyles";

const defaultSortFields = 'name';

const AlertConfigurationPage = () => {
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const searchRef = useRef();
  const [query, setQuery] = useQueryParams({
    id: NumberParam,
    smartSearch: StringParam,
    severity: StringParam,
  });

  const {
    data,
    isLoading,
    totalRows,
    pageSize,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  } = useAlertDatatable(query, { sortFields: defaultSortFields });

  const [deleteAlertConfiguration] = useDeleteAlertConfigurationMutation();
  const [updateAlertConfiguration] = useUpdateAlertConfigurationMutation();

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.smartSearch || '');
    }
  }, [query]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const clearSelectedRows = useCallback(() => {
    setToggleCleared(!toggleCleared);
  }, [toggleCleared]);

  const handleBatchDelete = () => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to delete?",
      text: `${selectedRows.map((r) => r?.name)}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        await selectedRows.forEach(async (row) => {
          await deleteAlertConfiguration(row.id);
        });
        return true;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        clearSelectedRows();
      }
    });
  };

  const onUpdateConfiguration = useCallback(async (values, row) => {
    Swal.fire({
      title: 'Are you sure want to update this?',
      text: `Alert: ${row.name}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return updateAlertConfiguration({ body: { id: row.id, ...values } })
          .unwrap()
          .then(() => {
            toast.success(`Alert ${row.name} updated.`);
            return true;
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error.data?.error}: ${error.data?.message}`);
            return false;
          });
      },
    });
  }, [updateAlertConfiguration]);

  const onDeleteConfiguration = useCallback(async (row) => {
    Swal.fire({
      title: 'Are you sure want to delete this?',
      text: `Alert: ${row.name}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return deleteAlertConfiguration(row.id)
          .unwrap()
          .then(() => {
            toast.success(`Alert ${row.name} removed.`);
            return true; 
          })
          .catch(error => {
            Swal.showValidationMessage(`${error.data?.error}: ${error.data.message}`);
            return false;
          });
      },
    })
  }, [deleteAlertConfiguration]);

  const columns = useMemo(() => {
    const renderChannels = (channelInCollection) => {
      if (channelInCollection) {
        const length = 20;
        const value = channelInCollection.join(', ');
        return value.length > length ? value.substring(0, length - 3) + "..." : value;
      }
      return unknown;
    }
    
    return [
      {
        name: uppercase('Name'),
        selector: row => row?.name,
        sortable: true,
        sortField: 'name',
        left: true,
        grow: 2,
        wrap: true,
      },
      {
        name: uppercase('Type'),
        selector: row => row?.type,
        sortable: true,
        sortField: 'type',
        left: true,
        wrap: true,
      },
      {
        name: uppercase('Status'),
        sortable: true,
        sortField: 'status',
        selector: row => row?.status,
        hide: "md",
        cell: ({ status }) => <StatusBadge label={status || unknown} type={status === 'Activated' ? 'success' : (status === 'Deactivated' ? 'danger' : 'none')} />
      },
      {
        name: uppercase('Severity'),
        selector: row => row?.severity,
        sortable: true,
        sortField: 'severity',
        hide: "md",
        cell: ({ severity }) => <StatusBadge label={severity} type={severity === 'MINOR' ? 'success' : (severity === 'MAJOR' ? 'warning' : 'danger')} isUppercase />
      },
      {
        name: uppercase('Channel'),
        selector: row => row?.channelInCollection,
        sortable: false,
        hide: "md",
        cell: ({ channelInCollection }) => renderChannels(channelInCollection)
      },
      {
        name: uppercase('Updated'),
        selector: row => row?.updateModified,
        sortable: true,
        sortField: 'updateModified',
        hide: "md",
        cell: ({ updateModified }) => momentLastUpdate(updateModified)
      },
      {
        name: '',
        cell: (row) => <TableActionButton row={row} onDeleteClick={() => onDeleteConfiguration(row)} onUpdateConfiguration={onUpdateConfiguration} />,
        allowOverflow: true,
        button: true,
        maxWidth: '100px'
      }
    ]
  }, [onUpdateConfiguration, onDeleteConfiguration]);

  const onSearch = (searchText) => {
    setQuery({ smartSearch: searchText !== "" && searchText !== null ? searchText : undefined });
  }

  const onFilter = (filter) => {
    if (filter) {
      const { severity, lastState } = filter;
      setQuery({
        severity: severity || undefined,
        lastState: lastState || undefined,
      });
    }
  }

  const onRemoveFilter = useCallback((key = null) => {
    if (!key) return;
    setQuery({ [key]: undefined });
  }, [setQuery]);

  const onResetFilter = () => {
    searchRef.current.clearFilter();
    setQuery({
      id: undefined,
      smartSearch: undefined,
      severity: undefined,
    });
  };

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            tags.push({
              key: key,
              value: v,
              label: v,
              onRemove: () => onRemoveFilter(key, v)
            })
          })
        } else {
          tags.push({
            key: key,
            value: value,
            label: value,
            onRemove: () => onRemoveFilter(key)
          })
        }
      }
    });

    return tags;
  }, [query, onRemoveFilter]);

  return (
    <PageContent title="Alert Management">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <Breadcrumb listClassName="bg-white m-0 p-0 mb-1">
                <BreadcrumbItem>
                  <Link className="text-dark text-lg text-default font-weight-bold" to="/admin/alerts">
                    Open Alert
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span className="text-dark text-lg">Rule Configuration</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col className="d-flex justify-content-lg-end align-items-center" xs="12" md="3">
              <PrimeSearchInput
                ref={searchRef}
                block
                onFilter={onSearch}
                size="sm"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
            </Col>
            <Col className="d-flex justify-content-lg-end align-items-center" xs="12" md="3">
              <FilterAlert
                loading={isLoading}
                filterSeverity={query.severity}
                onFilter={onFilter}
              />
              <Can I="do" this={ADD_NEW_ALERT_RULE}>
                {() => (
                  <ButtonLinkIcon label="Add New" onClick={() => history.push('/admin/alerts/configuration/new')} />
                )}
              </Can>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Tags tags={filterTags} onReset={onResetFilter} />
            </Col>
          </Row>
          <BatchActions className="mb-2" selectedRows={selectedRows.length} pluralName="alerts" singularName="alert">
            <Button color="danger" size="sm" type="button" onClick={handleBatchDelete}>
              <i className="fa fa-trash mr-2"></i>
              Delete
            </Button>
          </BatchActions>
          <Row>
            <Col>
              <DataTable
                title=""
                data={data}
                columns={columns}
                defaultSortField={defaultSortFields}
                striped
                noHeader
                highlightOnHover
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                expandableRows
                expandableRowsComponent={AlertTableDetail}
                expandableIcon={{
                  collapsed: <CustomExpandableIcon type="collapsed" />,
                  expanded: <CustomExpandableIcon type="expanded" />,
                }}
                persistTableHead
                progressPending={isLoading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={pageSize}
                onChangePage={onChangePage}
                sortServer
                onSort={onSort}
                customStyles={customStyles}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
}

export default AlertConfigurationPage;
