import { useMemo } from "react";
import { useGetGroupsQuery } from "../service/groupApi";
import { defaultOptions, usePagination } from "./../../../hooks";
import { useAuth } from "./../../auth/hooks";

export const useGroupDatatable = (params = {}, options = defaultOptions) => {
  const { user, isAdmin } = useAuth();
  const { 
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort
  } = usePagination({ pageNumber: 1, pageSize: 10, sortFields: options.sortFields, sortOrder: options.sortOrder });

  const { data: paginatedResult, isLoading } = useGetGroupsQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    username: isAdmin ? undefined : user?.username,
    includeEntitySummary: true,
    ...params,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
};
