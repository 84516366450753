import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { unknown } from "utils";

export default function useLicenseColumns() {
  return useMemo(() => {
    const textBodyTemplate = (value) => {
      return value || unknown;
    };

    const linkTemplate = (data, field) => {
      return (
        <Link
          to={{
            pathname: `/admin/license/${data.id}/devices`,
          }}
        >
          {data[field]}
        </Link>
      );
    };

    const dateTemplate = (data, field, format = 'DD/MM/YYYY') => {
      if (!data[field]) return unknown;

      return moment(data[field]).format(format);
    }

    const availableLicenseTemplate = (data) => {
      const { usedLicense, totalLicense } = data;
      if (!usedLicense && !totalLicense) return unknown;

      return totalLicense - usedLicense;
    }

    return [
      {
        name: "PO ID",
        selector: (row) => row?.name,
        sortable: true,
        sortField: "name",
        cell: ({ name }) => textBodyTemplate(name),
      },
      {
        name: "TYPE",
        selector: (row) => row?.type,
        sortable: true,
        sortField: "type",
        cell: ({ type }) => textBodyTemplate(type),
      },
      {
        name: "# OF LICENSE",
        selector: (row) => row?.totalLicense,
        sortable: true,
        center: true,
        sortField: "totalLicense",
        cell: ({ totalLicense }) => textBodyTemplate(totalLicense),
      },
      {
        name: "ACTIVE DATE",
        selector: (row) => row?.licenseBeginTs,
        sortable: true,
        sortField: "licenseBeginTs",
        cell: (data) => dateTemplate(data, "licenseBeginTs"),
      },
      {
        name: "EXPIRY DATE",
        selector: (row) => row?.licenseEndTs,
        sortable: true,
        sortField: "licenseEndTs",
        cell: (data) => dateTemplate(data, "licenseEndTs"),
      },
      {
        name: "ACTIVE LICENSE",
        selector: (row) => row?.usedLicense,
        sortable: true,
        center: true,
        sortField: "usedLicense",
        cell: (data) => linkTemplate(data, "usedLicense"),

      },
      {
        name: "AVAILABLE LICENSE",
        selector: (row) => row?.usedLicense,
        sortable: true,
        center: true,
        sortField: "usedLicense",
        cell: availableLicenseTemplate,
      },
      {
        name: "STATUS",
        selector: (row) => row?.status,
        sortable: true,
        sortField: "status",
        cell: ({ licenseEndTs }) => {
          const now = moment();
          const isExpired = moment(licenseEndTs).isBefore(now);
          if (isExpired) {
            return 'Expired'
          }
          return 'Active'
        },
      },
    ];
  }, []);
}
