import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import DataTable from 'react-data-table-component'
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Row } from 'reactstrap'
import { useNotificationDatatable } from '../hooks'
import { PageContent, PrimeSearchInput, Tags } from './../../../components'
import { paginationRowsPerPageOptions } from './../../../utils'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { customStyles } from "./../../../utils/datatableStyles";

const NotificationHistory = () => {
  const searchRef = useRef();

  const [query, setQuery] = useQueryParams({ search: StringParam, id: NumberParam });

  const {
    ...dt
  } = useNotificationDatatable(query, {
    pageNumber: 1,
    pageSize: 10,
    sortFields: 'createdTs',
    sortOrder: 'DESC'
  });

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.search || '');
    }
  }, [query]);

  const onSearch = (searchText) => {
    setQuery({ search: searchText !== "" && searchText !== null ? searchText : undefined });
  };

  const onRemoveFilter = useCallback((key = null) => {
    if (!key) return;

    setQuery({ [key]: undefined });
  }, [setQuery]);

  const onResetFilter = () => {
    setQuery({ search: undefined, id: undefined });
  };

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined) {
        tags.push({
          key: key,
          value: value,
          label: value,
          onRemove: () => onRemoveFilter(key)
        })
      }
    });

    return tags;
  }, [query, onRemoveFilter]);

  return (
    <PageContent title="Notification Management">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <Breadcrumb listClassName="bg-white m-0 p-0">
                <BreadcrumbItem>
                  <h2>Notification</h2>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col className="d-flex justify-content-lg-end align-items-center" xs="12" md="6">
              <PrimeSearchInput
                ref={searchRef}
                block
                onFilter={onSearch}
                size="sm"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Tags tags={filterTags} onReset={onResetFilter} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={dt.data}
                columns={dt.columns}
                defaultSortField="createdTs"
                defaultSortAsc={false}
                striped
                noHeader
                highlightOnHover
                selectableRows
                persistTableHead
                progressPending={dt.isLoading}
                pagination
                paginationServer
                paginationTotalRows={dt.totalRows}
                onChangeRowsPerPage={dt.onChangeRowsPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={dt.pageSize}
                onChangePage={dt.onChangePage}
                sortServer
                onSort={dt.onSort}
                customStyles={customStyles}
                overflowY={true}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  )
}

NotificationHistory.propTypes = {

}

export default NotificationHistory
