import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from "react-data-table-component";
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Row } from "reactstrap";
import Swal from 'sweetalert2';
import { StringParam, useQueryParams } from 'use-query-params';
import { ModalAddRegion, ModalEditRegion, RegionHeaderTable } from '../components';
import { useRegionDatatable } from '../hooks';
import { useDeleteRegionBulkMutation, useDeleteRegionMutation } from '../service/regionApi';
import { ButtonLinkIcon, PageContent } from "./../../../components";
import { ADD_NEW_REGION, Can } from './../../../config';
import { history, paginationRowsPerPageOptions } from './../../../utils';
import { CommaArrayParam } from './../../../utils/queryParams';
import { useGroups } from './../../group/hooks';
import { CustomActionButton } from './../components';
import { customStyles } from "./../../../utils/datatableStyles";

const RegionListPage = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState({});

  const { options: groupOptions } = useGroups();

  const [deleteRegion] = useDeleteRegionMutation();
  const [deleteRegionBulk] = useDeleteRegionBulkMutation();

  const [query] = useQueryParams({
    ids: CommaArrayParam,
    areaIds: CommaArrayParam,
    groupIds: CommaArrayParam,
    name: StringParam,
  });

  const {
    data,
    isLoading,
    totalRows,
    pageSize,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    setParams,
    refetch,
  } = useRegionDatatable(query);

  useEffect(() => {
    setParams(query);
  }, [query, setParams]);

  const handleRowSelected = useCallback(({ selectedRows }) => {
    setSelectedRows(selectedRows);
  }, []);

  const handleDeleteBatch = useCallback(() => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to delete?",
      text: `${selectedRows.map((r) => r?.name).join(', ')}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const regionIds = selectedRows.map(r => r.id).join(',');
          await deleteRegionBulk(regionIds).unwrap();
          return true;
        } catch (error) {
          Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        toast.success("Delete regions success.");
        setToggleCleared(!toggleCleared);
        refetch();
        setSelectedRows([]);
      }
    });
  }, [selectedRows, deleteRegionBulk, toggleCleared, refetch]);

  const handleDelete = useCallback((data) => {
    Swal.fire({
      title: "Are you sure want to delete this?",
      text: `Region: ${data.name}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return deleteRegion(data.id)
          .unwrap()
          .then(() => {
            toast.success("Region deleted.");
            return true;
          });
      },
    });
  }, [deleteRegion]);

  const handleEdit = useCallback((customer) => {
    setModalEdit(true);
    setSelectedRegion(customer);
  }, [setModalEdit, setSelectedRegion]);

  const columns = useMemo(() => {
    const renderLink = (text, path, query = null) => {
      if (text === 0) {
        return 0;
      }

      return (
        <a href="#link" className="text-default" onClick={(e) => {
          e.preventDefault();
          history.push({
            pathname: path, 
            search: query ? `?${query}` : undefined
          });
        }}>{text}</a>
      );
    }
    return [
      {
        name: 'REGION NAME',
        selector: row => row?.name,
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'TOTAL AREA',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ hierarchyRegionSummary, id }) => hierarchyRegionSummary?.countArea
      },
      {
        name: 'TOTAL DEVICES',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ hierarchyRegionSummary, id }) => renderLink(hierarchyRegionSummary?.countDevice, `/admin/devices/region/${id}`)
      },
      {
        name: 'TOTAL CUSTOMER',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ hierarchyRegionSummary, id }) => renderLink(hierarchyRegionSummary?.countCustomer, '/admin/customers', `regionIds=${id}`)
      },
      {
        name: '',
        width: '40px',
        allowOverflow: true,
        button: true,
        cell: (row) => row.id === 999999 ? null : <CustomActionButton row={row} onDelete={handleDelete} onEdit={handleEdit} />,
      },
    ];
  }, [handleEdit, handleDelete]);

  const disableRowSelectionCondition = row => row.id === 999999;

  return (
    <PageContent title="All Region">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col className="d-flex align-items-center">
              <h2 className="text-dark">All Region</h2>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Can I="do" this={ADD_NEW_REGION}>
                {() => (<ButtonLinkIcon label="Add New" onClick={() => setModalAdd(!modalAdd)} />)}
              </Can>
            </Col>
          </Row>
          {(selectedRows && selectedRows.length > 0) && (
            <RegionHeaderTable selectedRows={selectedRows} onDelete={handleDeleteBatch} />
          )}
          <Row className="mb-2">
            <Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={data}
                columns={columns}
                defaultSortField="name"
                keyField="id"
                onSelectedRowsChange={handleRowSelected}
                striped
                noHeader
                highlightOnHover
                selectableRows
                clearSelectedRows={toggleCleared}
                selectableRowDisabled={disableRowSelectionCondition}
                pagination
                paginationServer
                customStyles={customStyles}
                persistTableHead
                progressPending={isLoading}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={pageSize}
                onChangePage={onChangePage}
                sortServer
                onSort={onSort}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalAddRegion
        isOpen={modalAdd}
        toggle={() => setModalAdd(!modalAdd)}
        groupOptions={groupOptions}
      />
      <ModalEditRegion
        region={selectedRegion}
        isOpen={modalEdit}
        toggle={() => setModalEdit(!modalEdit)}
        groupOptions={groupOptions}
      />
    </PageContent>
  )
}

export default RegionListPage;
