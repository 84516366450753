import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { history, paginationRowsPerPageOptions } from "utils";
import { customStyles3 } from "utils/datatableStyles";
import { usePerformanceDashboard } from "../../../context";
import { useGetNumberDevicesSnQuery } from "features/dashboard/service/dashboardApi";
import Swal from "sweetalert2";

const HighPingRTTTabContent = ({data, isLoading}) => {  
  const {filters} = usePerformanceDashboard()
  const [params, setParams] = useState(null);
  const type = 'rtt'
  const { data: sns, isFetching, isError } = useGetNumberDevicesSnQuery({params, type}, { skip: !params });

  useEffect(() => {
    if (isFetching) {
      Swal.fire({
        title: 'Please Wait',
        text: "We're redirecting you to the Devices page",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      });
    } else {
      Swal.close();
    }

    if (isError) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch data, please try again',
        allowOutsideClick: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Try again",
      }).then((result) => {
        if (result.isConfirmed) {
          let newParam = params
          setParams(null)
          setParams(newParam)
        } else {
          setParams(null)
        }
      });
    }
  }, [isFetching, isError, params]);

  const columns = [
    {
      name: "Area",
      selector: (row) => row.label_,
      sortable: true,
    },
    {
      name: "Number of Device with Issue",
      selector: (row) => row.value_,
      sortable: true,
      cell: (row) => (
        <div>
          <div>{row.value_}</div>
          <div>
            <button className="btn btn-sm btn-link p-0 m-0" onClick={() => onClick(row)}>
              See the device{row.value_ > 1 ? 's' : ''}
            </button>
          </div>
        </div>
      ),
    },
  ];

  const onSearchDevice = (key, value) => {
    return history.push({
      pathname: "/admin/devices",
      search: `?${key}=${value}`,
    })
  }

  const onClick = useCallback((params) => {
    const paramMap = {
          area: params.label_,
          startTs: filters.startTs,
          endTs: filters.endTs,
        }
    setParams(paramMap);
  }, [filters])

  useEffect(() => {
    if (sns) {
      onSearchDevice('smartSearch', sns);
    }
  }, [sns]);

  return (
    <div className="pt-2">
      <DataTable
        persistTableHead
        data={data?.content}
        columns={columns}
        striped
        noHeader
        pagination
        paginationRowsPerPageOptions={[5, ...paginationRowsPerPageOptions]}
        customStyles={customStyles3}
        progressPending={isLoading}
      />
    </div>
  );
};

export default HighPingRTTTabContent;
