import { defaultOptions, usePagination } from "hooks";
import { useMemo } from "react";
import { useGetDeviceLicenseQuery } from "../services/licenseApi";

export default function useDeviceLicenseDatatable(
  query = {},
  paginationOptions = defaultOptions
) {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination({
    pageNumber: paginationOptions?.pageNumber,
    pageSize: paginationOptions?.pageSize,
    sortFields: paginationOptions?.sortFields,
    sortOrder: paginationOptions?.sortOrder,
  });

  const {
    data: paginatedResult,
    isLoading,
    isFetching,
    refetch,
  } = useGetDeviceLicenseQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ? sortFields : undefined,
    sortOrder: sortOrder ? sortOrder : undefined,
    ...query,
  }, {
    skip: !query.licenseId
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    isFetching,
    pageSize,
    totalRows,
    refetch,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}
