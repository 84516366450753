import classnames from "classnames";
import { Can, CONFIGURE_BANDWIDTH_SCHEDULE, CONFIGURE_DEVICE } from "./../../../../config";
import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { NavItemContent } from "../../../../components";
import { DeviceConfiguration } from "../DeviceConfiguration";
import { DeviceOpenAlert } from "../DeviceOpenAlert";
import { DeviceOverviewTab } from "../DeviceOverview";
import { DeviceSchedule } from "../DeviceSchedule";
import { DeviceUsage } from "../DeviceUsage";

const DeviceTableDetail = ({
  data,
  groupOptions,
  areaOptions,
  uomOptions,
  quotaOptions,
  customerOptions,
  deviceTypeOptions,
  serviceTypeOptions,
  serviceApplicationOptions,
  unprovisionGsmNumbersOptions,
  refetch,
}) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav tabs className="row">
        <NavItem className="col">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            <NavItemContent
              title="Overview"
              icon="fa fa-info-circle"
              active={activeTab === "1"}
            />
          </NavLink>
        </NavItem>
        <Can I="do" this={CONFIGURE_DEVICE}>
          <NavItem className="col">
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggle("2")}
            >
              <NavItemContent
                title="Configuration"
                icon="fa fa-sliders-h"
                active={activeTab === "2"}
              />
            </NavLink>
          </NavItem>
        </Can>
        <NavItem className="col">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            <NavItemContent
              title="Usage"
              icon="fa fa-chart-pie"
              active={activeTab === "3"}
            />
          </NavLink>
        </NavItem>
        <NavItem className="col">
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            onClick={() => toggle("4")}
          >
            <NavItemContent
              title="Alert"
              icon="fa fa-exclamation-triangle"
              active={activeTab === "4"}
            />
          </NavLink>
        </NavItem>
        <Can I="do" this={CONFIGURE_BANDWIDTH_SCHEDULE}>
          {() => (
            <NavItem className="col">
              <NavLink
                className={classnames({ active: activeTab === "5" })}
                onClick={() => toggle("5")}
              >
                <NavItemContent
                  title="Schedule"
                  icon="fas fa-clock"
                  active={activeTab === "5"}
                />
              </NavLink>
            </NavItem>
          )}
        </Can>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <DeviceOverviewTab
            key={`overview-${data.id}`}
            device={data}
            groupOptions={groupOptions}
            areaOptions={areaOptions}
            customerOptions={customerOptions}
            deviceTypeOptions={deviceTypeOptions}
          />
        </TabPane>
        <TabPane tabId="2">
          <DeviceConfiguration
            key={`configuration-${data.id}`}
            device={data}
            groupOptions={groupOptions}
            areaOptions={areaOptions}
            customerOptions={customerOptions}
            uomOptions={uomOptions}
            quotaOptions={quotaOptions}
            serviceTypeOptions={serviceTypeOptions}
            serviceApplicationOptions={serviceApplicationOptions}
            unprovisionGsmNumbersOptions={unprovisionGsmNumbersOptions}
            refetch={refetch}
          />
        </TabPane>
        <TabPane tabId="3">
          <DeviceUsage key={`usage-${data.id}`} device={data} />
        </TabPane>
        <TabPane tabId="4">
          <DeviceOpenAlert key={`alert-${data.id}`} device={data} />
        </TabPane>
        <Can I="do" this={CONFIGURE_BANDWIDTH_SCHEDULE}>
          {() => (
            <TabPane tabId="5">
              <DeviceSchedule deviceId={data.id} />
            </TabPane>
          )}
        </Can>
      </TabContent>
    </div>
  );
};

export default DeviceTableDetail;
