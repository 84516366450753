export function useOptions() {

  const getLabelOptions = (options = [], value) => {
    return options.find((opt) => opt.value.toString() === value.toString())?.label;
  }

  const stringIdsToLabel = (options = [], stringIds) => {
    const arrIds = stringIds.split(',');
    const valueIds = arrIds.map((id) => {
      const opt = options.find((opt) => opt.value.toString() === id.toString());
      if (!opt) return id;

      return opt.label;
    })

    return valueIds.join(', ');
  }

  const arrayIdsToLabel = (options = [], arrayIds) => {
    const labels = arrayIds && arrayIds.map((id) => {
      const opt = options.find((opt) => opt.value.toString() === id.toString());
      if (!opt) return id;

      return opt.label;
    })

    return labels.join(', ');
  }

  const stringIdsToArray = (strIds = null) => {
    return strIds ? strIds.split(',') : [];
  }

  const arrayToStringIds = (array = []) => {
    return array && array.length > 0 ? array.join(',') : undefined;
  }

  const applyToOptions = [
    { value: 'region', label: 'By Region' },
    { value: 'area', label: 'By Area' },
    { value: 'customer', label: 'By Customer' },
  ];

  return {
    getLabelOptions,
    stringIdsToLabel,
    arrayIdsToLabel,
    stringIdsToArray,
    arrayToStringIds,
    applyToOptions,
  }
}