import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { ability } from './config/ability';
import { AbilityContext } from './config/Can';
import store, { persistor } from "./store";
import App from './App';

const Root = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <AbilityContext.Provider value={ability}>
          <App />
        </AbilityContext.Provider>
      </PersistGate>
    </Provider>
  );
};

export default Root;
