import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Button, Label, FormGroup } from 'reactstrap'
import { useFormik } from 'formik'

const ModalSaveAsName = ({ isOpen, toggle, onSubmit }) => {
  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: ({ name }) => {
      onSubmit(name);
    }
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Save As</ModalHeader>
        <ModalBody>
            <FormGroup>
              <Label>Template Name</Label>
              <Input name="name" value={values.name} onChange={(e) => setFieldValue('name', e.target.value)} />
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button
              color="secondary"
              type="button"
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button color="default" onClick={handleSubmit}>Save</Button>
        </ModalFooter>
    </Modal>
  )
}

ModalSaveAsName.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func
}

export default ModalSaveAsName
