import React from 'react';

export function FormikToggleSwitch({checked, onChange, ...props}) {
  return (
    <label className="custom-toggle custom-toggle-sm mb-0">
      <input type="checkbox" checked={checked} onChange={onChange} {...props} />
      <span className="custom-toggle-slider rounded-circle mb-0" />
    </label>
  )
};
