import React from "react";
import { Loading } from "components";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import classNames from "classnames";

const Widget02 = ({
  title,
  value,
  comparison,
  loading,
  onClick,
  valueClassName,
  onClickComparison,
}) => {
  const clickable = parseInt(value) === 0 ? false : true;
  const clickableComparison = parseInt(comparison) === 0 ? false : true;
  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col>
            <CardTitle className="text-muted">{title}</CardTitle>
          </Col>
        </Row>
        <div className="d-flex align-items-center">
          <span
            onClick={clickable ? onClick : null}
            style={{
              cursor: clickable && onClick ? "pointer" : "default",
              fontSize: 32,
            }}
            className={classNames(
              { "text-primary": !valueClassName },
              valueClassName
            )}
          >
            {loading ? <Loading /> : value}
          </span>
          {comparison && <div className="mx-2">/</div>}
          {comparison && (
            <span
              onClick={clickableComparison ? onClickComparison : null}
              style={{
                cursor: clickableComparison && onClickComparison ? "pointer" : "default",
              }}
            >{new Intl.NumberFormat("id").format(comparison)}</span>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

Widget02.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.any,
  comparison: PropTypes.any,
  loading: PropTypes.bool,
  valueClassName: PropTypes.string,
  onClickComparison: PropTypes.func,
};

Widget02.defaultProps = {
  title: "Title",
  value: 0,
  comparison: null,
  onClick: undefined,
  loading: false,
  valueClassName: undefined,
  onClickComparison: undefined,
};

export default Widget02;
