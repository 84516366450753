import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Collapse, UncontrolledCollapse } from "reactstrap";
import { Gap, RowItem, RowItemLabel } from "../../../components";

const UserOverview = ({ data, regionAreas }) => {
  const { roleNames, groups } = data;

  const UserZones = ({ data, regionAreas }) => {
    const [showAll, setShowAll] = useState(true);

    return (
      <div>
        <RowItem
          stripped
          boldLabel
          label="Zone"
          value={
            <span
              className="btn btn-link btn-sm text-default"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "Hide Zones" : "Show Zones"}
            </span>
          }
        />
        <Collapse isOpen={showAll}>
          {regionAreas &&
            regionAreas.map((region, index) => (
              <div key={`${region.id}${Math.random()}`}>
                <RowItem
                  stripped={index % 2 !== 0}
                  label={
                    <RowItemLabel label={region.name} />
                  }
                  value={
                    <span
                      className="btn btn-link btn-sm text-default"
                      id={`region-${region.id}`}
                    >
                      Show/Hide Area
                    </span>
                  }
                />
                <UncontrolledCollapse toggler={`#region-${region.id}`}>
                  {region.areas &&
                    region.areas.map((area, index) => (
                      <RowItem
                        offset
                        key={area.id}
                        stripped={index % 2 !== 0}
                        label={<RowItemLabel secondary label={area.name} />}
                      />
                    ))}
                </UncontrolledCollapse>
              </div>
            ))}
        </Collapse>
      </div>
    );
  };

  const UserPrivileges = ({ data }) => {
    const [showPrivileges, setShowPrivileges] = useState(true);
    const { privileges } = data;

    return (
      <div>
        <RowItem
          stripped
          boldLabel
          label={`Role: ${roleNames.join(", ")}`}
          value={
            <span
              className="btn btn-link btn-sm text-default"
              onClick={() => setShowPrivileges(!showPrivileges)}
            >
              {showPrivileges ? "Hide Privileges" : "Show Privileges"}
            </span>
          }
        />
        <Collapse isOpen={showPrivileges}>
          {privileges && Object.keys(privileges).map((category, i) => {
            return (
              <div key={i}>
                <RowItem
                  key={`category-${i}`}
                  stripped={i % 2 === 0}
                  label={<RowItemLabel label={category !== 'null' ? category : 'Uncategorized'} />}
                  value={(
                    <span
                      className="btn btn-link btn-sm text-default"
                      id={`toggle-category-${i}`}
                    >
                      Show/Hide
                    </span>
                  )}
                />
                <UncontrolledCollapse toggler={`toggle-category-${i}`}>
                  {privileges && privileges[category].map((feature) => {
                    return (
                      <RowItem
                        offset
                        key={`${feature.id}${Math.random()}`}
                        stripped={i % 2 === 0}
                        label={<RowItemLabel secondary label={feature.name} />}
                      />
                    )
                  })}
                </UncontrolledCollapse>
              </div>
            )
          })}
          {/* {combinePrivileges &&
            combinePrivileges.map((priv, idx) => (
              <RowItem
                key={`${priv.id}${Math.random()}`}
                stripped={idx % 2 === 0}
                label={<RowItemLabel label={priv.featureName} />}
              />
            ))} */}
        </Collapse>
      </div>
    );
  };

  const UserCustomers = ({ data }) => {
    const [showAllCustomers, setShowAllCustomers] = useState(true);
    const { eligibleCustomers } = data;
    return (
      <div>
        <RowItem
          stripped
          boldLabel
          label="Customer"
          value={
            <span
              className="btn btn-link btn-sm text-default"
              onClick={() => setShowAllCustomers(!showAllCustomers)}
            >
              {showAllCustomers ? "Hide Customers" : "Show Customers"}
            </span>
          }
        />
        <Collapse isOpen={showAllCustomers}>
          {eligibleCustomers &&
            eligibleCustomers.map((customer, index) => (
              <RowItem
                key={`${customer.id}${Math.random()}`}
                stripped={index % 2 !== 0}
                label={<RowItemLabel label={customer.name ? customer.name : '-'} />}
              />
            ))}
        </Collapse>
      </div>
    );
  };

  return (
    <Row className="mt-4 mb-5">
      <Col className="px-4 border-right">
        <UserPrivileges data={data} />
        <Gap height={10} />
        <RowItem
          stripped
          boldLabel
          label="Group"
          value={groups && groups.map((g) => g.name).join(", ")}
        />
      </Col>
      <Col className="px-4 border-left">
        <UserZones regionAreas={regionAreas} />
        <Gap height={10} />
        <UserCustomers data={data} />
      </Col>
    </Row>
  );
};

UserOverview.propTypes = {
  data: PropTypes.object.isRequired,
};

UserOverview.defaultProps = {
  data: {},
};

export default UserOverview;
