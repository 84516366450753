import React, { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { FormikToggleSwitch } from "../../../components";
import { useChangeSubscriptionNotificationMutation, useGetSubscribedNotificationsQuery } from "../service/notificationApi";
import { defaultOptions, usePagination } from "./../../../hooks";
import { useAuth } from "./../../auth/hooks";

export const useNotificationSubscriptionDatatable = (params = {}, options = defaultOptions) => {
  const { user } = useAuth();
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination({
    pageNumber: options?.pageNumber || undefined,
    pageSize: options?.pageSize || undefined,
    sortFields: options?.sortFields || undefined,
    sortOrder: options?.sortOrder || undefined,
  });

  const { data: paginatedResult, isLoading } = useGetSubscribedNotificationsQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    usernames: user ? user.username : undefined,
    ...params,
  });

  const [updateNotificationSubscription] = useChangeSubscriptionNotificationMutation();

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  const onSubscriptionChange = useCallback(async (id, subscribe, type) => {
    const action = subscribe ? 'Subscribe to' : 'Unsubscribe from'
    Swal.fire({
      icon: "question",
      title: "Are you sure want to do this?",
      text: `${action} ${type}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        return updateNotificationSubscription({ id, subscribe }).unwrap().then(() => {
          toast.success(`${action} ${type} success.`);
          return true;
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
  }, [updateNotificationSubscription]);

  const columns = useMemo(() => {
    return [
      {
        name: 'Notification Type',
        selector: row => row?.notificationType,
      },
      {
        name: 'Action',
        selector: row => row?.subscribe,
        right: true,
        cell: (row) => (
          <div className="d-flex align-items-center">
            <span className="mr-2">{row.subscribe ? 'Allowed' : 'Not Allowed'}</span>
            <FormikToggleSwitch
              checked={row.subscribe}
              onChange={() => onSubscriptionChange(row.id, !row.subscribe, row.notificationType)}
            />
          </div>
        )
      }
    ];
  }, [onSubscriptionChange]);

  return {
    data,
    columns,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
};
