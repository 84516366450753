import { useGetAlertHistoriesQuery } from "./../../alert/service/alertHistoryApi";
import { usePagination, defaultOptions } from "./../../../hooks";
import { useAuth } from "./../../auth/hooks";
import { useMemo } from "react";

export function useDeviceOpenAlert(deviceIds, options = defaultOptions) {
  const { user } = useAuth();

  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort
  } = usePagination({
    pageNumber: options.pageNumber || undefined,
    pageSize: options.pageSize || undefined,
    sortFields: options.sortFields || undefined,
    sortOrder: options.sortOrder || undefined,
  });

  const { data: paginatedResult, isLoading } = useGetAlertHistoriesQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    alertState: "ALERTING",
    username: user ? user.username : undefined,
    deviceIds
  }, {
    skip: !deviceIds
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}