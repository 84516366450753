import {
  ButtonLinkIcon,
  CustomExpandableIcon,
  PageContent,
  PrimeSearchInput,
} from "components";
import React, { useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Row } from "reactstrap";
import { paginationRowsPerPageOptions } from "utils";
import { customStyles } from "utils/datatableStyles";
import RoleDetailExpandable from "../components/RoleDetailExpandable";
import { useFeatureGroup } from "../hooks/useFeatureGroup";
import { useRoleColumns } from "../hooks/useRoleColumns";
import { useRoleDatatable } from "../hooks/useRoleDatatable";
import AddRoleModal from "../components/AddRoleModal";
import { useCreateRoleAndPrivileges } from "../hooks/useCreateRoleAndPrivileges";
import { toast } from "react-toastify";

const RoleList = () => {
  const searchRef = useRef();
  const [searchText, setSearchText] = useState("");
  const { featuresGroup } = useFeatureGroup();
  const columns = useRoleColumns();
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const dataTable = useRoleDatatable(
    {
      search: searchText !== "" ? searchText : undefined,
    },
    { sortFields: "name" }
  );
  const { handleCreateRole, isLoading } = useCreateRoleAndPrivileges();

  const onSearch = (text) => {
    setSearchText(text);
  };

  const toggleAddRoleModal = () => {
    setShowAddRoleModal((show) => !show);
  };

  const onSubmitAddRole = async (values) => {
    await handleCreateRole(values);
    setShowAddRoleModal(false);
    toast.success("Role created successfully.");
  };

  return (
    <PageContent title="Roles">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <h2 className="text-dark">All Role</h2>
            </Col>
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-lg-end align-items-center gap-2"
            >
              <PrimeSearchInput
                ref={searchRef}
                onFilter={onSearch}
                className="p-inputtext-sm p-d-block"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
              <ButtonLinkIcon
                className="ml-2"
                icon="fa-plus-circle"
                label="Add New"
                onClick={toggleAddRoleModal}
              />
            </Col>
          </Row>
          <DataTable
            title="All Roles"
            data={dataTable.data}
            columns={columns}
            defaultSortField="name"
            defaultSortAsc={true}
            keyField="id"
            striped
            noHeader
            highlightOnHover
            selectableRows
            pagination
            paginationServer
            customStyles={customStyles}
            expandableRows
            expandableRowsComponent={({ data }) => (
              <RoleDetailExpandable data={data} featuresGroup={featuresGroup} />
            )}
            expandableIcon={{
              collapsed: <CustomExpandableIcon type="collapsed" />,
              expanded: <CustomExpandableIcon type="expanded" />,
            }}
            persistTableHead
            progressPending={dataTable.isLoading}
            paginationTotalRows={dataTable.totalRows}
            onChangePage={dataTable.onChangePage}
            onChangeRowsPerPage={dataTable.onChangeRowsPerPage}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            paginationPerPage={dataTable.pageSize}
            sortServer
            onSort={dataTable.onSort}
          />
        </CardBody>
        <AddRoleModal
          isOpen={showAddRoleModal}
          toggleModal={toggleAddRoleModal}
          featuresGroup={featuresGroup}
          onSubmit={onSubmitAddRole}
          isLoading={isLoading}
        />
      </Card>
    </PageContent>
  );
};

export default RoleList;
