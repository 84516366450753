import { CustomExpandableIcon, PageContent } from "components";
import customStyles from "components/organisms/ReactDatatable/customStyles";
import { useGetOnuByOltIdQuery } from "features/inventory/onu/service";
import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { RegisteredOnuTableDetail } from "../components/registeredOnuTableDetail";

const RegisteredOnuByOltId = () => {
  const path = window.location.href;
  const arrId = path.split("/").splice(-2);
  const ipAddress = arrId[0];
  const oltId = arrId[1];

  const { data: onuList, isLoading } = useGetOnuByOltIdQuery(oltId);
  const linkToOlt = "/admin/olt/";

  const columns = [
    {
      name: "ONU ID",
      center: true,
      hide: "md",
      selector: (row) => row?.id,
    },
    {
      name: "SERIAL NUMBER",
      center: true,
      hide: "md",
      selector: (row) => row?.sn,
    },
    {
      name: "CONNECTION STATUS",
      center: true,
      hide: "md",
      // selector: (row) => row?.registerStatus,
    },
    {
      name: "OLT ID",
      center: true,
      hide: "md",
      selector: (row) => row?.oltId,
    },
    {
      name: "SLOT ID",
      center: true,
      hide: "md",
      selector: (row) => row?.slotId,
    },
  ];

  const ExpandedComponent = (props) => {
    return (
      <RegisteredOnuTableDetail {...props}/>
    )
  }
  return (
    <PageContent title="Optical Network Terminal (ONT/ ONU)">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <Breadcrumb listClassName="bg-white p-0 m-0">
                <BreadcrumbItem active className="text-lg">
                  <Link to={linkToOlt} className="text-lg">
                    OLT
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-lg">
                {ipAddress}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-lg-end align-items-center"
            >
              <Button size="sm" color="primary">
                <Link to={linkToOlt} style={{ color: "white" }}>
                  <i className="mdi mdi-arrow-left mr-2"></i>
                  Back
                </Link>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={onuList?.content}
                columns={columns}
                progressPending={isLoading}
                persistTableHead
                highlightOnHover
                noHeader
                striped
                pagination
                customStyles={customStyles}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                expandableIcon={{
                  collapsed: <CustomExpandableIcon type="collapsed" />,
                  expanded: <CustomExpandableIcon type="expanded" />,
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default RegisteredOnuByOltId;
