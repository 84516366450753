import React, { useState } from "react";
import { FieldArray } from "formik";
import classnames from "classnames";
import _ from "lodash";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  ButtonLinkIcon,
  CustomLabel,
  Gap,
  RowItem,
  TritronikInputPassword,
  TritronikInputText,
  TritronikMultiSelect,
  TritronikSelect,
} from "./../../../components";

const FormDeviceProfile = ({
  formik,
  areaOptions,
  groupOptions,
  customerOptions,
  regionOptions,
  onRegionChanges,
  validateDeviceCircuitNumber,
  validateGatewayId,
  gatewayNodeOptions,
}) => {
  const [showOther, setShowOther] = useState(true);

  const onGroupChange = (e) => {
    formik.setFieldValue("groupIds", e.value);
    formik.setFieldValue(
      "groupNames",
      groupOptions
        .filter((g) => e.value.includes(g.value))
        .map((group) => group.label)
        .join(", ")
    );
  };

  const onCustomerChange = (e) => {
    formik.setFieldValue("customerId", e.value);
    const cust = _.find(customerOptions, { value: e.value });
    if (cust) {
      formik.setFieldValue("customerName", cust.label);
    }
  };

  const onAreaChange = (e) => {
    formik.setFieldValue("areaId", e.value);
    const area = _.find(areaOptions, { value: e.value });
    if (area) {
      formik.setFieldValue("areaName", area.label);
    }
  };
  const onRegionChange = (e) => {
    formik.setFieldValue("regionId", e.value);
    formik.setFieldValue("areaId", "");
    formik.setFieldValue("areaName", "");
    onRegionChanges(e.value);
    const region = _.find(regionOptions, { value: e.value });
    if (region) {
      formik.setFieldValue("regionName", region.label);
    }
  };

  const addGateway = (actions) => {
    actions.push({
      id: 0,
      gatewayId: "",
      peerId: "",
      peerIp: "",
      node: "",
    });
  };

  return (
    <div>
      <h2 className="ml-lg-4">Device Profile</h2>
      <Row>
        <Col sm="12" lg="6" className="px-lg-5">
          <RowItem
            rightCol="col-12 col-lg-8"
            label={<CustomLabel name="Serial Number" required />}
            value={
              <TritronikInputText
                small
                name="sn"
                placeholder="Serial Number"
                readOnly
              />
            }
          />
          <RowItem
            rightCol="col-12 col-lg-8"
            label={<CustomLabel name="Device ID" required />}
            value={
              <TritronikInputText
                small
                name="circuitNumber"
                placeholder="Device ID"
                validate={(val) =>
                  validateDeviceCircuitNumber(formik.values.id, val)
                }
              />
            }
          />
          <RowItem
            rightCol="col-12 col-lg-8"
            label={<CustomLabel name="Customer" required />}
            value={
              <TritronikSelect
                name="customerId"
                placeholder="Customer"
                options={customerOptions}
                value={formik.values.customerId}
                onChange={onCustomerChange}
                invalid={formik.errors.customerId && formik.touched.customerId}
                error={formik.errors.customerId}
              />
            }
          />
          <RowItem
            rightCol="col-12 col-lg-8"
            label={<CustomLabel name="Address" required />}
            value={
              <TritronikInputText small name="location" placeholder="Address" />
            }
          />
        </Col>
        <Col sm="12" lg="6" className="px-lg-5">
          <RowItem
            rightCol="col-12 col-lg-8"
            label={<CustomLabel name="Type" required />}
            value={
              <TritronikInputText
                small
                name="type"
                placeholder="Device Type"
                readOnly
              />
            }
          />
          <RowItem
            rightCol="col-12 col-lg-8"
            label={<CustomLabel name="Region" required />}
            value={
              <TritronikSelect
                name="regionId"
                placeholder="Region"
                options={regionOptions}
                value={formik.values.regionId}
                onChange={onRegionChange}
                invalid={formik.errors.regionId && formik.touched.regionId}
                error={formik.errors.regionId}
              />
            }
          />
          <RowItem
            rightCol="col-12 col-lg-8"
            label={<CustomLabel name="Area" required />}
            value={
              <TritronikSelect
                name="areaId"
                placeholder="Zone"
                options={areaOptions}
                value={formik.values.areaId}
                onChange={onAreaChange}
                invalid={formik.errors.areaId && formik.touched.areaId}
                error={formik.errors.areaId}
                disabled={formik.values.regionId === ""}
              />
            }
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <FieldArray name="gateways">
            {(arrayHelper) => (
              <div>
                <div className="d-flex align-items-center justify-content-end border-top mx-4">
                  <ButtonLinkIcon
                    type="button"
                    label={"Add Gateway"}
                    className="mt-2"
                    onClick={() => addGateway(arrayHelper)}
                  />
                </div>
                {formik.values.gateways &&
                  formik.values.gateways.map((gw, index) => (
                    <div key={`node-gw-${index}`}>
                      <div
                        className={classnames({ "border-top mx-4": index > 0 })}
                      />
                      <Row
                        key={`gw-${index}`}
                        className={classnames({ "pt-3 mt-3": index > 0 })}
                      >
                        <Col sm="12" lg="6" className="px-lg-5">
                          <RowItem
                            rightCol="col-12 col-lg-8"
                            label={<CustomLabel name="Gateway ID" required/>}
                            value={
                              <TritronikInputText
                                small
                                name={`gateways.${index}.gatewayId`}
                                placeholder="Gateway ID"
                                // validate={(val) =>
                                //   validateGatewayId(formik.values.id, val)
                                // }
                              />
                            }
                          />
                        </Col>
                        <Col sm="12" lg="6" className="px-lg-5">
                          <RowItem
                            rightCol="col-12 col-lg-8"
                            label={<CustomLabel name="Peer ID" />}
                            value={
                              <TritronikInputText
                                small
                                name={`gateways.${index}.peerId`}
                                placeholder="Peer ID"
                              />
                            }
                          />
                        </Col>
                        <Col sm="12" lg="6" className="px-lg-5">
                          <RowItem
                            rightCol="col-12 col-lg-8"
                            label={<CustomLabel name="Peer IP" />}
                            value={
                              <TritronikInputText
                                small
                                name={`gateways.${index}.peerIp`}
                                placeholder="Peer IP"
                              />
                            }
                          />
                        </Col>
                        <Col sm="12" lg="6" className="px-lg-5">
                          <RowItem
                            rightCol="col-12 col-lg-8"
                            label={<CustomLabel name="Gateway Node" />}
                            value={
                              <div>
                                <TritronikSelect
                                  editable
                                  name={`gateways.${index}.node`}
                                  value={
                                    arrayHelper.form.values.gateways[index].node
                                  }
                                  onChange={(e) =>
                                    arrayHelper.form.setFieldValue(
                                      `gateways.${index}.node`,
                                      e.value
                                    )
                                  }
                                  placeholder="Gateway Node"
                                  options={gatewayNodeOptions}
                                />
                                <small className="text-gray">
                                  <i className="fa fa-info-circle fa-1x mr-1"></i> 
                                  To add new, please input directly on input text.
                                </small>
                              </div>
                            }
                          />
                        </Col>
                        <Col sm="12" className="px-lg-5">
                          <div className="d-flex justify-content-end mt-2">
                            {index > 0 && (
                              <Button
                                className="btn btn-danger btn-sm"
                                type="button"
                                onClick={() => arrayHelper.remove(index)}
                              >
                                <i className="fa fa-trash mr-2"></i>
                                Remove
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>
            )}
          </FieldArray>
        </Col>
      </Row>
      <Row>
        <Col className="px-5">
          <Gap height={30} />
          <div className="d-flex row align-items-center px-3">
            <span className="text-dark">Other Information</span>
            <button
              type="button"
              onClick={() => setShowOther(!showOther)}
              className="btn btn-sm bg-transparent ml-2"
            >
              <i
                className={classnames("fa text-default", {
                  "fa-chevron-down": !showOther,
                  "fa-chevron-up": showOther,
                })}
              ></i>
            </button>
          </div>
        </Col>
      </Row>
      <Collapse isOpen={showOther}>
        <Row>
          <Col className="col-6 px-5">
            <Gap height={30} />
            <RowItem
              label={<CustomLabel name="Username" />}
              value={
                <TritronikInputText
                  small
                  name="username"
                  placeholder="Username"
                />
              }
            />
            <Gap height={10} />
            <RowItem
              label={<CustomLabel name="Password" />}
              value={
                <TritronikInputPassword
                  small
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              }
            />
            <Gap height={10} />
            <RowItem
              label={<CustomLabel name="Confirm Password" />}
              value={
                <TritronikInputPassword
                  small
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
              }
            />
          </Col>
          <Col className="col-6 px-5">
            <Gap height={30} />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="Group" />}
              value={
                <TritronikMultiSelect
                  name="groupIds"
                  placeholder="Group"
                  options={groupOptions}
                  onChange={onGroupChange}
                />
              }
            />
            <Gap height={10} />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="IP Address" />}
              value={
                <TritronikInputText
                  small
                  name="ipAddress"
                  placeholder="IP Address"
                />
              }
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default FormDeviceProfile;
