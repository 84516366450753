import React from 'react'

const CustomLabel = ({ name, required, bold }) => {
  return (
    <span className={bold ? "font-weight-bold" : ""}>
      {name}
      {required ? <span className="font-weight-bold text-danger ml-1">*</span> : null}
    </span>
  )
}
export default CustomLabel;
