import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap';

import CombinationChart from '../charts/CombinationChart';
import { usePerformanceDashboard } from '../../context';
import { useGetUndeliveredDataChartQuery } from 'features/dashboard/service/dashboardApi';
import { Tooltip } from 'primereact/tooltip';

const UndeliveredDataChart = () => {
  const { filters } = usePerformanceDashboard();
  const { data, isLoading, isFetching } = useGetUndeliveredDataChartQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    regionAsString: filters.region ?? undefined,
  }, {
    skip: !filters.startTs || !filters.endTs
  });
  const tooltipStyle = {
    maxWidth: 'fit-content', 
    whiteSpace: 'nowrap'
  };

  return (
    <Card>
      <CardHeader className='border-0'>
          <h4>Undelivered Data
            <i className='mdi mdi-help-circle ml-2 custom-target-icon6'></i>
            <Tooltip target=".custom-target-icon6" position="right" content="((Expected data - Actual received) / Expected data) * 100" style={tooltipStyle}/>
          </h4>
      </CardHeader>
      <CardBody style={{ height: 250 }}>
        <CombinationChart data={data} unit='%' isLoading={isLoading || isFetching} threshold={10}/>
      </CardBody>
    </Card>
  )
}

export default UndeliveredDataChart;