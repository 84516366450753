import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./PrimeDatatable.scss";

const PrimeDatatable = (props) => {
  const { columns, stripped, className, selectionMode, expandable, ...rest } = props;
  const renderColumns =
    columns &&
    columns.map(({ field, header, ...col }) => (
      <Column key={field} field={field} header={header} {...col}></Column>
    ));
  return (
    <div className="prime-datatable">
      <DataTable
        className={classNames(className, {
          "p-datatable-striped": stripped,
        })}
        {...rest}
      >
        {selectionMode && <Column selectionMode={selectionMode} headerStyle={{ width: '2em' }}></Column>}
        {expandable && <Column expander style={{ width: '3.3em' }} />}
        {renderColumns}
      </DataTable>
    </div>
  );
};

PrimeDatatable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnResizeMode: PropTypes.string,
  expandable: PropTypes.bool,
  expandedRows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  lazy: PropTypes.bool,
  loading: PropTypes.bool,
  metaKeySelection: PropTypes.bool,
  resizableColumns: PropTypes.bool,
  reorderableColumns: PropTypes.bool,
  scrollable: PropTypes.bool,
  selectionMode: PropTypes.string,
  selection: PropTypes.any,
  stripped: PropTypes.bool,
  onFilter: PropTypes.func,
  onPage: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowCollapse: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowExpand: PropTypes.func,
  onRowToggle: PropTypes.func,
  onRowSelect: PropTypes.func,
  onRowUnselect: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onSort: PropTypes.func,
  rowExpansionTemplate: PropTypes.func,
};

PrimeDatatable.defaultProps = {
  className: "",
  columnResizeMode: "fit",
  currentPageReportTemplate: "{first}-{last} of {totalRecords}",
  expandable: false,
  paginator: true,
  paginatorTemplate:
    "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ",
  resizableColumns: true,
  rows: 10,
  rowHover: true,
  rowsPerPageOptions: [10, 20, 50],
  selectionMode: null,
  stripped: true,
};

export default PrimeDatatable;
