import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Col, DropdownMenu, DropdownToggle, Form, Nav, Row, Spinner, UncontrolledDropdown } from 'reactstrap';
import { FormikToggleSwitch, Gap } from '../../../components';
import PropTypes from 'prop-types';

const ActionItem = ({ label, value }) => {
	return (
		<div className="row d-flex justify-content-between align-items-center border-bottom mx-2 py-2">
			<div className="col-4 p-0">
				<span className="text-sm text-default font-weight-bold">{label}</span>
			</div>
			<div className="col-8 p-0 d-flex justify-content-end">
				{value}
			</div>
		</div>
	)
}

const CustomActionButtonMember = ({ row, onUpdate, isUpdating, onDelete, isDeleting }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const toggle = () => setMenuOpen(!menuOpen);

	const { values, handleSubmit, setFieldValue, handleReset, dirty } = useFormik({
		enableReinitialize: true,
		initialValues: {
			authorized: row.authorized,
			activeBridge: row.activeBridge,
		},
		onSubmit: (values) => onUpdate(row.nwid, row.id, values)
	});

	return (
		<div>
			<Nav navbar>
				<UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
					<DropdownToggle nav>
						<i className="fas fa-ellipsis-v text-default" />
					</DropdownToggle>
					<DropdownMenu right>
						<div style={{ width: 350 }} className="p-2">
							<Form onSubmit={handleSubmit} onReset={handleReset}>
								<ActionItem
									label="Authorized"
									value={(
										<div className="d-flex align-items-center">
											<FormikToggleSwitch
												checked={values.authorized}
												value={values.authorized}
												onChange={(e) => setFieldValue('authorized', e.target.checked)}
											/>
										</div>
									)}
								/>
								<Gap height={10} />
								<ActionItem
									label="Active Bridge"
									value={(
										<div className="d-flex align-items-center">
											<FormikToggleSwitch
												checked={values.activeBridge}
												value={values.activeBridge}
												onChange={(e) => setFieldValue('activeBridge', e.target.checked)}
											/>
										</div>
									)}
								/>
								<Gap height={10} />
								<Row className="mx-2 mt-2">
									<Col className="text-center p-0">
										<Button className="m-0" block color="default" size="sm" type="submit" disabled={isUpdating || !dirty}>
											Apply
											{isUpdating && <Spinner className="ml-2" color="light" size="sm" />}
										</Button>
										<Button className="m-0 mt-2" block color="secondary" size="sm" onClick={() => setMenuOpen(false)}>Cancel</Button>
										<Button className="m-0 mt-4" block color="danger" size="sm" onClick={() => onDelete(row.nwid, row.id)} disabled={isDeleting}>
											Delete Member
											{isDeleting && <Spinner className="ml-2" color="light" size="sm" />}
										</Button>
									</Col>
								</Row>
							</Form>
						</div>
					</DropdownMenu>
				</UncontrolledDropdown>
			</Nav>
		</div>
	);
}

CustomActionButtonMember.propTypes = {
	row: PropTypes.any.isRequired,
	onDelete: PropTypes.func,
	isDeleting: PropTypes.bool,
	onUpdate: PropTypes.func,
	isUpdating: PropTypes.bool,
}

export default CustomActionButtonMember;