import { useRegion } from "features/region/hooks";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { getTimeGroupping } from "utils";

const PerformanceDashboardContext = React.createContext({
  selectedRegion: null,
  filters: {
    startTs: moment().subtract(6, "days").startOf("day").valueOf(),
    endTs: moment().endOf("day").valueOf(),
    region: undefined,
    label: "Last 7 Days",
  },
  regionOptions: [],
  startDate: undefined,
  endDate: undefined,
  label: "Last 7 Days",
  lastUpdate: moment(),
  onRegionChange: (value, issue = undefined) => {},
  onDateChange: () => {},
  setFilters: (params) => {},
  setLastUpdate: () => {},
});

export const PerformanceDashboardProvider = ({ children }) => {
  const { options: regionOptions } = useRegion({}, false);
  const [filters, setFilters] = useQueryParams({
    startTs: NumberParam,
    endTs: NumberParam,
    timeGroupping: StringParam,
    region: StringParam,
    label: StringParam,
  });
  const [lastUpdate, setLastUpdate] = useState(moment());

  const value = useMemo(() => {
    const onDateChange = (start, end, label) => {
      setFilters({
        startTs: start.startOf("day").valueOf(),
        endTs: end.endOf("day").valueOf(),
        timeGroupping: getTimeGroupping(start, end),
        label: label,
      });
    };

    const onRegionChange = (value, issue) => {
      setFilters({
        region: value,
        tabIssue: issue ?? undefined,
      });
    };

    const startDate = filters.startTs ? moment(filters.startTs).startOf("day") : moment().subtract(6, "days").startOf("day");
    const endDate = filters.endTs ? moment(filters.endTs).endOf("day") : moment().endOf("day");

    return {
      selectedRegion: filters.region ?? "All Region",
      regionOptions,
      filters,
      startDate,
      endDate,
      label: filters.label ?? 'Last 7 Days',
      lastUpdate,
      setFilters,
      onDateChange,
      onRegionChange,
      setLastUpdate,
    };
  }, [regionOptions, filters, setFilters, lastUpdate]);

  useEffect(() => {
    if (!filters.startTs && !filters.endTs) {
      setFilters({
        startTs: moment().subtract(6, "days").startOf("day").valueOf(),
        endTs: moment().endOf("day").valueOf(),
      });
    } else {
      if (filters.startTs) {
        setFilters({
          startTs: moment(filters.startTs).startOf("day").valueOf(),
        });
      }
      if (filters.endTs) {
        setFilters({
          endTs: moment(filters.endTs).endOf("day").valueOf(),
        });
      }
    }
  }, [filters.endTs, filters.startTs, setFilters]);

  return (
    <PerformanceDashboardContext.Provider value={value}>
      {children}
    </PerformanceDashboardContext.Provider>
  );
};

export function usePerformanceDashboard() {
  const context = useContext(PerformanceDashboardContext);

  if (typeof context === undefined) {
    throw new Error(
      "usePerformanceDashboard should be used inside PerformanceDashboardProvider."
    );
  }

  return context;
}
