import React from 'react'
import { Row, Col } from 'reactstrap'
import DeviceWithHighPingFRChart from '../../performance-charts/DeviceWithHighPingFRChart'
import DeviceWithHighPingRTTChart from '../../performance-charts/DeviceWithHighPingRTTChart'
import DeviceWithHighPingPakcetLossChart from '../../performance-charts/DeviceWithHighPingPakcetLossChart'
import DeviceWithUsingOverQuotaChart from '../../performance-charts/DeviceWithUsingOverQuotaChart'

const DeviceWithIssueTabContent = () => {
  return (
    <Row className="mt-4">
      <Col md="12">
        <DeviceWithHighPingFRChart />
      </Col>
      <Col md="12">
        <DeviceWithHighPingRTTChart />
      </Col>
      <Col md="12">
        <DeviceWithHighPingPakcetLossChart />
      </Col>
      <Col md="12">
        <DeviceWithUsingOverQuotaChart />
      </Col>
    </Row>
  )
}

export default DeviceWithIssueTabContent