import React, { useState } from "react";
import { Formik } from "formik";
import { RowItem } from "components";
import { CustomLabel } from "components";
import { TritronikInputText, Loading } from "components";
import { Button, Col, Form, Row } from "reactstrap";

const FormRouter = ({ onCancel, onSubmit, isLoading, router }) => {
    const [hidePassword, setHidePassword] = useState(true)

    const togglePassword = () => {
        setHidePassword(!hidePassword)
    }
  
    return (
    <Formik
      initialValues={{
        ipAddress: router?.ipAddress || "",
        device: router?.device || "",
        os: router?.os || "",
        hostname: router?.hostname || "",
        username: router?.username || "",
        password: router?.password || "",
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h3 className="modal-title">
              {router ? "Edit Router" : "Add New Router"}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={onCancel}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body py-2">
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="IP Address" />}
              value={
                <TritronikInputText
                  small
                  name="ipAddress"
                  placeholder="IP Address"
                />
              }
            />
            <RowItem
              rightCol="col-8"
              hidden={router ? true : false}
              label={<CustomLabel name="Router Name" />}
              value={
                <TritronikInputText
                  small
                  name="device"
                  placeholder="Router Name"
                />
              }
            />
            <RowItem
              rightCol="col-8"
              hidden={router ? true : false}
              label={<CustomLabel name="OS" />}
              value={<TritronikInputText small name="os" placeholder="OS" />}
            />
            <RowItem
              rightCol="col-8"
              hidden={router ? true : false}
              label={<CustomLabel name="Hostname" />}
              value={
                <TritronikInputText
                  small
                  name="hostname"
                  placeholder="Hostname"
                />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="Username" />}
              value={
                <TritronikInputText
                  small
                  name="username"
                  placeholder="Username"
                />
              }
            />
            <RowItem
              rightCol="col-8"
              label={<CustomLabel name="Password" />}
              value={
                <div style={{ display: "flex", width: "100%" }}>
                  <TritronikInputText
                    type={hidePassword ? 'password': 'text'}
                    small
                    name="password"
                    placeholder="Password"
                  />
                  <Button size="sm" color="link" onClick={togglePassword}>
                    <i className={hidePassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                  </Button>
                </div>
              }
            />
            <Row className="my-4">
              <Col className="text-right">
                <Button
                  color="secondary"
                  type="button"
                  size="sm"
                  onClick={onCancel}
                  className="px-5 py-1"
                >
                  Cancel
                </Button>
                <Button
                  className="px-5 py-1"
                  color="default"
                  type="submit"
                  size="sm"
                  disabled={isLoading}
                >
                  Save
                  {isLoading && <Loading className="ml-2" />}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormRouter;
