import React from "react";
import { toast } from "react-toastify";
import {
  Modal
} from "reactstrap";
import { useUpdateSimcardMutation } from "../services";
import FormSimcard from "./FormSimcard";

const EditSimcardInventoryModal = ({ isOpen, toggle, simcard }) => {
  const [updateSimcard, { isLoading }] = useUpdateSimcardMutation();

  const onSubmit = async (body) => {
    updateSimcard({ id: simcard.id, ...body })
      .unwrap()
      .then(() => {
        toast.success(`SIM updated.`);
        toggle();
      })
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <FormSimcard simcard={simcard} onSubmit={onSubmit} onCancel={toggle} isLoading={isLoading} />
    </Modal>
  );
};

export default EditSimcardInventoryModal;
