import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'primereact/steps';
import './PrimeSteps.scss';

const PrimeSteps = ({id, model, activeIndex, readOnly, style, className, onSelect }) => {
  return (
    <Steps id={id} model={model} activeIndex={activeIndex} readOnly={readOnly} style={style} className={className} onSelect={onSelect} />
  )
}


PrimeSteps.propTypes = {
  id: PropTypes.string,
  model: PropTypes.array.isRequired,
  activeIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  onSelect: PropTypes.func
}

PrimeSteps.defaultProps = {
  id: null,
  model: null,
  activeIndex: 0,
  readOnly: true,
  style: null,
  className: null
}

export default PrimeSteps;
