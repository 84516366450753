import React from "react";
import { Card, CardBody } from "reactstrap";
import { ChartJS } from "./../../../components";

function ReportChart({data, title}) {
  return (
    <Card className="bg-white shadow">
      <CardBody>
        <div className="d-flex justify-content-between">
          <h2 className="text-default mb-0">Usage Report {title ? title : ''}</h2>
        </div>
        {/* Chart */}
        <div className="chart div2PDF">
          <ChartJS
            type="bar"
            data={data}
            redraw
            options={{
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: 16
              },
              legend: {
                display: true,
                position: 'top',
                labels: {
                  usePointStyle: false,
                  padding: 16
                }
              },
              scales: {
                xAxes: [{
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: 'Time',
                  }
                }],
                yAxes: [{
                  display: true,
                  ticks: {
                    beginAtZero: true,
                    precision: 0
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Total Devices',
                  }
                }]
              },
            }}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default ReportChart
