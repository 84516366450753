import { createApi } from '@reduxjs/toolkit/query/react';
import { simcardInventoryApi } from './../../simcards/services/simcardInventoryApi';

import { baseQuery } from './../../../../services/baseApi';

export const deviceInventoryApi = createApi({
    reducerPath: 'deviceInventoryApi',
    baseQuery: baseQuery,
    tagTypes: ['DeviceInv'],
    endpoints: (builder) => ({
        getDevices: builder.query({
            query: (params) => ({
                url: `/device/asset`,
                params: params
            }),
            providesTags: (result) =>
                result?.content
                    ? [
                        ...result.content.map(({ id }) => ({ type: 'DeviceInv', id })),
                        { type: 'DeviceInv', id: 'LIST' },
                    ]
                    : [{ type: 'DeviceInv', id: 'LIST' }],
        }),
        getDevice: builder.query({
            query: (id) => ({
                url: `/device/deviceId/${id}`
            }),
            providesTags: (result, error, id) => [{ type: 'DeviceInv', id }],
        }),
        createDevice: builder.mutation({
            query: (body) => ({
                url: `/device/asset`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'DeviceInv', id: 'LIST' }],
        }),
        updateDevice: builder.mutation({
            query: ({ id, body, params }) => ({
                url: `/device/${id}`,
                method: 'PUT',
                body,
                params,
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    deviceInventoryApi.util.updateQueryData('getDevice', id, (draft) => {
                        Object.assign(draft, patch)
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'DeviceInv', id }],
        }),
        deleteDevice: builder.mutation({
            query(id) {
                return {
                    url: `/device/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, id) => [{ type: 'DeviceInv', id }]
        }),
        isSerialNumberExists: builder.mutation({
            query: ({ id, sn }) => ({
                url: `/device/${id}/sn/${sn}/duplicate`,
                method: 'GET'
            }),
        }),
        checkSerialNumber: builder.mutation({
            query: (sn) => ({
                url: `/device/sn/${sn}/exist`,
                method: 'GET'
            }),
        }),
        unprovisionDevice: builder.mutation({
            query: (deviceId) => ({
                url: `/device/unprovision/${deviceId}`,
                method: 'PUT',
                body: {}
            }),
            invalidatesTags: (result, error, id) => [{ type: 'DeviceInv', id }],
						async onQueryStarted(id, { dispatch, queryFulfilled }) {
							queryFulfilled.then(() => {
								dispatch(simcardInventoryApi.util.invalidateTags([{ type: 'Unprov_GSMNumbers' }]));
								dispatch(simcardInventoryApi.endpoints.getSimcardGsmNumbers.initiate(undefined, { forceRefetch: true }))
							})
						}
        }),
        isSnHardwareExists: builder.mutation({
            query: ({ id, snHardware }) => ({
                url: `/device/${id}/sn-hardware/${snHardware}/duplicate`,
                method: 'GET'
            }),
        }),
    })
});

export const {
    useGetDevicesQuery,
    useGetDeviceQuery,
    useCreateDeviceMutation,
    useUpdateDeviceMutation,
    useDeleteDeviceMutation,
    useIsSerialNumberExistsMutation,
    useCheckSerialNumberMutation,
    useUnprovisionDeviceMutation,
    useIsSnHardwareExistsMutation,
} = deviceInventoryApi;

