import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../services/baseApi';

export const alertSubscriptionApi = createApi({
  reducerPath: 'alertSubscriptionApi',
  baseQuery: baseQuery,
  tagTypes: ['AlertSubscription'],
  endpoints: (builder) => ({
    getAlertSubscriptions: builder.query({
      query: (params) => ({
        url: `/alert/subscription`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'AlertSubscription', id })),
            { type: 'AlertSubscription', id: 'LIST' },
          ]
          : [{ type: 'AlertSubscription', id: 'LIST' }],
    }),
    createAlertSubscription: builder.mutation({
      query: ({ username, body }) => ({
        url: `/alert/subscription/${username}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'AlertSubscription', id: 'LIST' }],
    }),
    updateAlertSubscription: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/alert/subscription/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'AlertSubscription', id }],
    }),
    updateAlertSubscriptionByUsername: builder.mutation({
      query: ({ username, subscribe, body }) => ({
        url: `/alert/subscription/${username}/subscribe-all/${subscribe}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['AlertSubscription'],
    }),
  })
});

export const {
  useGetAlertSubscriptionsQuery,
  useCreateAlertSubscriptionMutation,
  useUpdateAlertSubscriptionMutation,
  useUpdateAlertSubscriptionByUsernameMutation,
} = alertSubscriptionApi;

