import React from "react";
import { Col, Row } from "reactstrap";
import { RowItem } from "../../../../components/molecules";
import { unknown } from "../../../../utils";

const RegisteredOnuOverview = ({ onu }) => {
  return (
    <Row>
      <Col className="border-right px-5 pb-5">
        <div>
          <h2 className="text-dark mt-3 ml--3">Overview</h2>
          <Row className="mt-4">
            <Col>
              <RowItem label="ONU Id" value={onu.id} />
              <RowItem label="Serial Number" value={onu.sn} stripped />
              <RowItem label="Connection Status" value={unknown} />
              <RowItem label="Password" value={"***********"} stripped />
              <RowItem label="OLT Id" value={onu.oltId} />
              <RowItem label="Slot Id" value={onu.slotId} stripped />
            </Col>
          </Row>
        </div>
      </Col>
      <Col className="border-left px-5 pb-5">
      </Col>
    </Row>
  );
};

export default RegisteredOnuOverview;
