import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { FieldArray } from 'formik';
import _ from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import { CustomLabel, Gap, RowItem, TritronikCheckbox, TritronikSelect } from '../../../components';
import ModalAddRole from './modals/ModalAddRole';


const FormPrivilege = ({ formik, roleOptions, getRolePrivileges, getAvailablePrivileges }) => {
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const { values, setFieldValue, errors, touched } = formik;

  const rolePrivileges = useMemo(() => {
    const selectedRoleId = values.role;
    if (selectedRoleId !== '') {
      return getRolePrivileges(selectedRoleId);
    }

    return [];
  }, [values.role, getRolePrivileges]);

  const copyRolePrivileges = useMemo(() => {
    const selectedRoleId = values.copyRoleId;
    if (selectedRoleId !== '') {
      return getRolePrivileges(selectedRoleId);
    }

    return [];
  }, [values.copyRoleId, getRolePrivileges]);

  useEffect(() => {
    setFieldValue('privileges', rolePrivileges);
  }, [rolePrivileges, setFieldValue]);

  useEffect(() => {
    setFieldValue('privileges', copyRolePrivileges);
  }, [copyRolePrivileges, setFieldValue]);

  const handleNewRole = (e) => {
    setRoleModalOpen(false);
    setFieldValue('newRole', true);
    setFieldValue('newRoleName', e.name);
    setFieldValue('copyRole', e.copyRole);
    setFieldValue('copyRoleId', e.copyRoleId);
  }

  const handleClearNewRole = (setFieldValue) => {
    setFieldValue('role', '');
    setFieldValue('newRole', false);
    setFieldValue('newRoleName', '');
    setFieldValue('copyRole', false);
    setFieldValue('copyRoleId', '');
    setFieldValue('privileges', []);
  }

  const handleRoleChange = (e, setFieldValue) => {
    setFieldValue('role', e.value);
  }

  const handleCopyRoleChange = (e, setFieldValue) => {
    setFieldValue('copyRoleId', e.value);
  }

  const privilegesOptions = useMemo(() => {
    const options = [];

    if (values.copyRole) {
      copyRolePrivileges.forEach((feature) => {
        options.push({ value: feature.id, label: feature.name });
      });
    } else {
      rolePrivileges.forEach((feature) => {
        options.push({ value: feature.id, label: feature.name });
      });
    }
    return options;
  }, [values.copyRole, copyRolePrivileges, rolePrivileges]);

  const availablePrivilegesOptions = useMemo(() => {
    return getAvailablePrivileges(values.privileges, privilegesOptions);
  }, [values.privileges, privilegesOptions, getAvailablePrivileges]);

  const handleSelectFeature = (e, actions) => {
    setSelectedFeature(e.value);
    const feature = _.find(privilegesOptions, { value: e.value });
    if (feature) {
      actions.push({
        id: feature.value,
        name: feature.label,
        description: null
      })
    }
  }

  return (
    <div>
      <h2 className="ml-4">Privilege</h2>
      <Row>
        <Col className="col-6 px-5 border-right">
          <Gap height={30} />
          <RowItem label={<CustomLabel name="Role" required />} value={(
            <TritronikSelect
              name="role"
              placeholder="Role"
              options={roleOptions}
              value={values.role}
              invalid={Boolean(errors.role && touched.role)}
              error={errors.role}
              disabled={values.newRole}
              onChange={(e) => {
                handleRoleChange(e, setFieldValue);
              }}
            />
          )} />

          <RowItem label={(
            <Button color="link" className="px-0 pb-2 pt-0" onClick={() => setRoleModalOpen(!roleModalOpen)} disabled={values.newRole}>
              Add New Role <i className="fa fa-plus-circle fa-1x ml-2"></i>
            </Button>
          )} />

          <Gap height={30} />
          {values.newRole ? (
            <Row>
              <Col style={{ backgroundColor: '#F7FAFC' }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span className="text-dark font-weight-bold">{values.newRoleName}</span>
                  </div>
                  <div>
                    <span className="btn btn-link" onClick={() => handleClearNewRole(setFieldValue)}><i className="fa fa-times-circle"></i></span>
                  </div>
                </div>

                <div className="ml-4">
                  <TritronikCheckbox name="copyRole" checked={values.copyRole} label="Copy privileges from existing role" disabled />
                  <RowItem stripped label={<CustomLabel name="Role" required />} value={(
                    <TritronikSelect
                      name="copyRoleId"
                      placeholder="Select Role"
                      value={values.copyRoleId}
                      options={roleOptions}
                      onChange={(e) => {
                        handleCopyRoleChange(e, setFieldValue);
                      }}
                      disabled={!values.copyRole}
                    />
                  )} />
                </div>
              </Col>
            </Row>
          ) : null}

          <ModalAddRole toggle={() => setRoleModalOpen(!roleModalOpen)} isOpen={roleModalOpen} roleOptions={roleOptions} onSave={(newRole) => handleNewRole(newRole)} />
        </Col>
        <Col className="col-6 px-5 border-left">
          <Gap height={35} />
          <FieldArray name="privileges">
            {(actions) => (
              <div>
                <RowItem label={<CustomLabel name="Privileges" />} value={(
                  <TritronikSelect filter options={availablePrivilegesOptions} value={selectedFeature} onChange={(e) => handleSelectFeature(e, actions)} disabled={!values.newRole} placeholder="Add Privilege" />
                )} />
                <Gap height={10} />
                {values.privileges.map((priv, index) => (
                  <RowItem padding={0} stripped={(index % 2 === 0)} key={priv.id} label={(
                    <span>
                      <span className={classnames("btn btn-link mr-2", { "disabled": !values.newRole })} onClick={() => actions.remove(index)}><i className="fa fa-times-circle"></i></span>
                      {priv.name}
                    </span>
                  )} />
                ))}
              </div>
            )}
          </FieldArray>
        </Col>
      </Row>
    </div>
  )
}

export default FormPrivilege
