import classnames from "classnames";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, matchPath, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { ModalAddArea } from "../../area/components";
import { ModalAddRegion } from "../../region/components";
import ConfigureTable from "../components/ConfigureTable";
import customStyles from "../components/DeviceTable/customStyles";
import DeviceTableDetail from "../components/DeviceTable/DeviceTableDetail";
import DownloadCSVButton from "../components/DownloadCSVButton";
import ExportToExcel from "../components/ExportToExcel";
import FilterDropdownDevice from "../components/FilterDropdownDevice";
import { ModalSetArea } from "../components/ModalSetArea";
import ModalSetGroup from "../components/ModalSetGroup";
import ModalSetQuota from "../components/ModalSetQuota";
import { useDeviceDatatable } from "../hooks";
import { setColumns, resetColumns } from "../reducers/deviceSlice";
import {
  useDisableTunnelDeviceBulkMutation,
  useEnableTunnelDeviceBulkMutation,
  useRebootDeviceBulkMutation,
  useUpdateFirmwareBulkMutation,
} from "../service/deviceApi";
import {
  // CustomExpandableIcon,
  Loading,
  PageContent,
  PrimeSearchInput,
  StatusBadge,
  Tags,
} from "./../../../components";
import {
  useDeviceType,
  useOptions,
  useQuotaPackage,
  useQuotaUom,
  useServiceApplications,
  useServiceTypes,
} from "./../../../hooks";
import {
  history,
  momentLastUpdate,
  paginationRowsPerPageOptions,
  unknown,
} from "./../../../utils";
import { CommaArrayParam } from "./../../../utils/queryParams";
import { useArea } from "./../../area/hooks";
import { useCustomers } from "./../../customers/hooks";
import { useGroups } from "./../../group/hooks";
import { useUnprovisionDeviceMutation } from "./../../inventory/devices";
import { useSimcardGsmNumbers } from "./../../inventory/simcards/hooks/useSimcardGsmNumbers";
import { useRegion } from "./../../region/hooks";
import {
  CustomActionButton,
  DeviceHeaderTable,
  SignalStrengthStatus,
} from "./../components";
import NiceModal from "@ebay/nice-modal-react";
import ModalAddCIDSimcard from "../components/ModalAddCIDSimcard";

export function DeviceList() {
  const dispatch = useDispatch();
  const params = useParams();
  const searchRef = useRef();

  const [modalQuota, setModalQuota] = useState(false);
  const [modalGroup, setModalGroup] = useState(false);
  const [modalSetArea, setModalSetArea] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [rebootDeviceBulk] = useRebootDeviceBulkMutation();
  const [enableTunnelDeviceBulk] = useEnableTunnelDeviceBulkMutation();
  const [disableTunnelDeviceBulk] = useDisableTunnelDeviceBulkMutation();
  const [updateFirmwareBulk] = useUpdateFirmwareBulkMutation();

  const modalAddCID = NiceModal.useModal(ModalAddCIDSimcard);

  const [title, setTitle] = useState("All Devices");

  const [query, setQuery] = useQueryParams({
    groupIds: CommaArrayParam,
    customerIds: CommaArrayParam,
    areaIds: CommaArrayParam,
    status: StringParam,
    username: StringParam,
    smartSearch: StringParam,
    simCardStatus: StringParam,
    connectionStatus: StringParam,
    customerName: StringParam,
    reachQuotaLimit: StringParam,
    sn: StringParam,
    id: NumberParam,
    signalStrength: StringParam,
  });

  const { options: groupOptions } = useGroups();
  const { options: regionOptions } = useRegion({}, false);
  const { options: areaOptions } = useArea({}, false);
  const { options: customerOptions } = useCustomers();
  const { options: uomOptions } = useQuotaUom();
  const { options: quotaOptions } = useQuotaPackage();
  const { options: deviceTypeOptions } = useDeviceType();
  const { options: serviceApplicationOptions } = useServiceApplications();
  const { options: serviceTypeOptions } = useServiceTypes();

  const { options: unprovisionGsmNumbersOptions } = useSimcardGsmNumbers();

  const [configTable, setConfigTable] = useState(false);
  const columnDefs = useSelector((state) => state.devices.columns);
  const [modalRegion, setModalRegion] = useState(false);
  const [modalArea, setModalArea] = useState(false);
  const location = useLocation();
  const { data: regionAreas } = useArea(
    { regionId: params.regionId || undefined },
    false,
    { skip: !params.regionId }
  );

  const { getLabelOptions } = useOptions();

  const [unprovisionDevice] = useUnprovisionDeviceMutation();

  const {
    data,
    isLoading,
    isFetching,
    totalRows,
    pageSize,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
    refetch,
  } = useDeviceDatatable({
    ...query,
    regionIds: params.regionId,
    // areaIds: params.areaId,
    areaIds:
    [...(query.areaIds || []), params.areaId].filter(Boolean).length > 0
      ? [...(query.areaIds || []), params.areaId].filter(Boolean)
      : undefined,
  });

  const regionDevice = matchPath(location.pathname, {
    path: "/admin/devices/region/:regionId",
    exact: true,
  });

  const areaDevice = matchPath(location.pathname, {
    path: "/admin/devices/region/:regionId/area/:areaId",
    exact: true,
  });

  const handleClearRows = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.search || "");
    }

    const queries = [regionDevice, areaDevice];
    Object.keys(query).forEach((key) => {
      queries.push(query[key] ? true : false);
    });

    if (queries.some((q) => q === true)) {
      setTitle("Devices");
    } else {
      setTitle("All Devices");
    }

    if (params.regionId && query.areaIds !== undefined) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        areaIds: undefined,
      }));
      toast.success(`"Area" filter has been reset`);
    }  
  }, [query, regionDevice, areaDevice, regionAreas, setQuery, params]);

  const getRegionName = (id) => {
    const r = _.find(regionOptions, { value: parseInt(id) });
    return (r && r.label) || <Loading size="sm" />;
  };

  const getAreaName = (id) => {
    const r = _.find(regionAreas, { id: parseInt(id) });
    return (r && r.name) || <Loading size="sm" />;
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const onSearch = (searchText) => {
    const formattedSearchText = searchText.toLowerCase() === 'missing sia' ? 'Missing CID' : searchText;
    setQuery({
      smartSearch:
        formattedSearchText !== "" && formattedSearchText !== null ? formattedSearchText : undefined,
    });
  };

  const onRemoveFilter = useCallback(
    (key = null, value = null) => {
      if (!key) return;

      if (value === null) {
        setQuery({ [key]: undefined });
      } else {
        setQuery((q) => {
          const filterQ = q[key]?.filter((v) => v !== value);
          return {
            [key]: filterQ && filterQ.length > 0 ? filterQ : undefined,
          };
        });
      }
    },
    [setQuery]
  );

  const onResetFilter = useCallback(() => {
    setQuery({
      groupIds: undefined,
      customerIds: undefined,
      areaIds : undefined,
      status: undefined,
      username: undefined,
      smartSearch: undefined,
      simCardStatus: undefined,
      connectionStatus: undefined,
      customerName: undefined,
      reachQuotaLimit: undefined,
      sn: undefined,
      id: undefined,
      signalStrength: undefined,
    });
  }, [setQuery]);

  const getOptionsLabel = useCallback(
    (key, value) => {
      switch (key) {
        case "customerIds":
          return getLabelOptions(customerOptions, value);
        case "groupIds":
          return getLabelOptions(groupOptions, value);
        case "regionIds":
          return getLabelOptions(regionOptions, value);
        case "areaIds":
          return getLabelOptions(areaOptions, value);
        default:
          return null;
      }
    },
    [getLabelOptions, customerOptions, groupOptions, regionOptions, areaOptions]
  );

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            let label = getOptionsLabel(key, v);
            label = label?.length > 100 ? `${label.substring(0, 97)}...` : label;
            tags.push({
              key: key,
              value: v,
              label: label,
              onRemove: () => onRemoveFilter(key, v),
            });
          });
        } else {
          let label = value;
          if (key === "reachQuotaLimit") {
            label = value === "true" ? "Reach Quota Limit" : "Not Reach Quota Limit";            
          }          
          if (label === "Missing CID") {
            label = "Missing SIA"            
          }
          label = label.length > 100 ? `${label.substring(0, 97)}...` : label;
          tags.push({
            key: key,
            value: value,
            label: label,
            onRemove: () => onRemoveFilter(key),
          });
        }
      }
    });

    return tags;
  }, [query, getOptionsLabel, onRemoveFilter]);

  const onDeleteDevicesClick = () => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to unprovision?",
      text: `${selectedRows.map((r) => r?.sn)}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          for (let i = 0; i < selectedRows.length; i++) {
            await unprovisionDevice(selectedRows[i].id).unwrap();
          }
          toast.success("Unprovision devices success.");
          handleClearRows();
          refetch();
          return true;
        } catch (error) {
          Swal.showValidationMessage(
            `${error?.data?.error}: ${error?.data?.message}`
          );
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const onSetQuotaClick = () => {
    setModalQuota(!modalQuota);
  };

  const onSetGroupClick = () => {
    setModalGroup(!modalGroup);
  };

  const onSetAreaClick = () => {
    setModalSetArea(!modalSetArea);
  };

  const handleSetColumns = (values) => {
    dispatch(setColumns(values));
  };
  const onResetColumnsConfig = (values) => {
    dispatch(resetColumns());
  };

  const onFilter = (filter) => {
    if (filter) {
      const {
        groupIds,
        customerIds,
        signalStrength,
        connectionStatus,
        simCardStatus,
        reachQuotaLimit,
        areaIds
      } = filter;

      setQuery({
        signalStrength: signalStrength || undefined,
        connectionStatus: connectionStatus || undefined,
        simCardStatus: simCardStatus || undefined,
        reachQuotaLimit: reachQuotaLimit || undefined,
        groupIds: groupIds && groupIds.length > 0 ? groupIds : undefined,
        customerIds: customerIds && customerIds.length > 0 ? customerIds : undefined,
        areaIds: areaIds && areaIds.length > 0 ? areaIds : undefined
      });
    }
  };

  const renderBreadcrumb = () => {
    const regionBreadcrumb = (
      <BreadcrumbItem>
        <UncontrolledDropdown>
          <DropdownToggle nav>
            <h3 className="text-default">
              {getRegionName(params.regionId)}
              <i className="fa fa-chevron-down fa-1x ml-2"></i>
            </h3>
          </DropdownToggle>
          <DropdownMenu>
            <Link
              to={{
                pathname: `/admin/devices/region/${params.regionId}`,
                search: location.search,
              }}
            >
              <DropdownItem>
                <span className="font-weight-bold">All Area</span>
              </DropdownItem>
            </Link>
            <DropdownItem divider />
            {regionAreas &&
              regionAreas.map((area, i) => (
                <DropdownItem
                  key={`select-area-${i}`}
                  onClick={() =>
                    history.push({
                      pathname: `/admin/devices/region/${params.regionId}/area/${area.id}`,
                      search: location.search,
                    })
                  }
                >
                  <span className="text-dark">{area.name}</span>
                </DropdownItem>
              ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </BreadcrumbItem>
    );

    const areaBreadcrumb = (
      <BreadcrumbItem>
        <UncontrolledDropdown>
          <DropdownToggle nav>
            <h3>{getAreaName(params.areaId)}</h3>
          </DropdownToggle>
        </UncontrolledDropdown>
      </BreadcrumbItem>
    );

    return (
      <Breadcrumb listClassName="bg-white m-0 p-0">
        <BreadcrumbItem>
          <UncontrolledDropdown>
            <DropdownToggle nav>
              <h3 className="text-default">
                {title}
                <i className="fa fa-chevron-down fa-1x ml-2"></i>
              </h3>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => history.push("/admin/devices")}>
                <span className="font-weight-bold">All Devices</span>
              </DropdownItem>
              <DropdownItem divider />
              {regionOptions &&
                regionOptions.map((r, i) => (
                  <DropdownItem
                    key={i}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/devices/region/${r.value}`,
                        search: location.search,
                      })
                    }
                  >
                    <span className="text-dark">{r.label}</span>
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </BreadcrumbItem>
        {regionDevice ? regionBreadcrumb : null}
        {areaDevice ? (
          <>
            {regionBreadcrumb}
            {areaBreadcrumb}
          </>
        ) : null}
      </Breadcrumb>
    );
  };

  const onUnprovisionDeviceClick = useCallback(
    (row) => {
      Swal.fire({
        title: "Are you sure want to unprovision this?",
        text: `Device: ${row.sn}, Type: ${row.type}.`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          return unprovisionDevice(row.id)
            .unwrap()
            .then(() => {
              toast.success("Device unprovisioned.");
              refetch();
              return true;
            })
            .catch((error) => {
              Swal.showValidationMessage(
                `${error?.data?.error}: ${error?.data?.message}`
              );
              return false;
            });
        },
      });
    },
    [unprovisionDevice, refetch]
  );

  const isSimHaveMissingCid = (simCards) => {
    if (simCards && simCards.length > 0) {
      // Check if any simCard has status 'Missing CID'
      return simCards.some(simCard => simCard.status === 'Missing CID');
  }
  return false; // If simCards array is empty or undefined
  }

  const columns = useMemo(() => {
    const renderDeviceState = ({ newDevice, unprovisionDevice }) => {
      return (
        <span>
          {newDevice && <Badge color="success">NEW</Badge>}
          {unprovisionDevice && <Badge color="warning">UNPROV...</Badge>}
        </span>
      );
    };

    const renderCustomer = ({ customer, newDevice, unprovisionDevice }) => {
      return (
        <div>
          <div>{customer?.name}</div>
          <div className="mt-1">
            {renderDeviceState({ newDevice, unprovisionDevice })}
          </div>
        </div>
      );
    };

    const getSimInfoBySlotNumber = (simCards, slotNumber, key) => {
      if (simCards && simCards.length === 0) {
        return false;
      }
      const sc = _.find(simCards, { slotNumber: slotNumber });

      if (!sc) {
        return false;
      }

      return sc[key];
    };

    const renderSimCardIMSI = (simCards, slotNumber) => {
      const imsi = getSimInfoBySlotNumber(simCards, slotNumber, "imsi");
      return imsi || unknown;
    };

    const renderSimCardGsmNumber = (simCards, slotNumber) => {
      const gsmNumber = getSimInfoBySlotNumber(
        simCards,
        slotNumber,
        "gsmNumber"
      );
      return gsmNumber || unknown;
    };

    const renderSimCardStatus = (simCards, slotNumber, device) => {
      const status = getSimInfoBySlotNumber(simCards, slotNumber, "status");
      if (!status) return unknown;

      const statusMap = {
        Activated: {
          badgeColor: "success",
          bgColor: "#2DCE89",
          icon: "mdi mdi-check",
        },
        Deactivated: {
          badgeColor: "warning",
          bgColor: "#F59E0B",
          icon: "mdi mdi-close",
        },
        "Missing CID": {
          badgeColor: "warning",
          bgColor: "#F5365C",
          icon: "mdi mdi-alert-outline",
        },
      };

      const statusBadge = statusMap[status] ?? {
        badgeColor: "none",
        bgColor: "none",
        icon: "none",
      };

      const onClick = () => {
        const sc = _.find(simCards, { slotNumber: slotNumber });
        if (sc) {
          modalAddCID.show({
            id: device.id,
            sn: device.sn,
            type: device.type,
            location: device.location,
            customerName: device.customerName,
            simCard: sc,
            area: device.area? device.area.name : null,
            region: device.region? device.region.name : null,
          })
        }
      }

      return (
        <div className="d-flex align-items-center">
          <Badge
            color={statusBadge.badgeColor}
            style={{
              color: "white",
              fontSize: "10.5px",
              textTransform: "capitalize",
              backgroundColor: statusBadge.bgColor,
            }}
          >
            <i className={statusBadge.icon} style={{ marginRight: "5px" }} />
            {status === 'Missing CID' ? 'Missing SIA' : status}
          </Badge>
          {status === "Missing CID" ? (
            <Button onClick={onClick} className="btn-link btn-round" size="sm" type="button" style={{ background: "none", border: "none"}}>
              Add
            </Button>
          ) : null}
        </div>
      );
    };

    const renderSimCardUsage = (simCards, slotNumber) => {
      const usageInPercentage = getSimInfoBySlotNumber(
        simCards,
        slotNumber,
        "usageInPercentage"
      );
      if (usageInPercentage === null) return unknown;

      const successFlag = Boolean(
        usageInPercentage > 0 && usageInPercentage <= 45
      );
      const warningFlag = Boolean(
        usageInPercentage > 45 && usageInPercentage <= 70
      );
      const dangerFlag = Boolean(usageInPercentage > 70);
      const color = successFlag
        ? "success"
        : warningFlag
        ? "warning"
        : dangerFlag
        ? "danger"
        : "primary";
      return (
        <div style={{ width: "100%" }} className="justify-content-center pt-3">
          <Progress
            striped
            max="100"
            value={Number(usageInPercentage)}
            style={{ height: 15 }}
            color={color}
            className="bg-light"
          >
            <span className="text-white">{`${usageInPercentage} %`}</span>
          </Progress>
        </div>
      );
    };

    const renderSimCardSignal = (simCards, slotNumber) => {
      const signalStrength = getSimInfoBySlotNumber(
        simCards,
        slotNumber,
        "signalStrength"
      );
      if (!signalStrength) return unknown;

      return <SignalStrengthStatus status={signalStrength} />;
    };

    const renderSimCardOperator = (simCards, slotNumber) => {
      const operator = getSimInfoBySlotNumber(simCards, slotNumber, "operator");
      return operator || unknown;
    };
    const renderSimCardCircuitNumber = (simCards, slotNumber) => {
      const operator = getSimInfoBySlotNumber(
        simCards,
        slotNumber,
        "circuitNumber"
      );
      return operator || unknown;
    };

    const textBodyTemplate = (value) => {
      return value || unknown;
    };

    return [
      {
        name: "SERIAL NUMBER",
        selector: (row) => row?.sn,
        sortable: true,
        sortField: "sn",
        omit: columnDefs?.includes("sn") ? false : true,
        reorder: true,
        cell: ({ sn }) => textBodyTemplate(sn),
        width: '150px'
      },
      {
        name: "SN HARDWARE",
        selector: (row) => row?.snHardware,
        sortable: true,
        sortField: "snHardware",
        grow: 2,
        omit: columnDefs?.includes("snHardware") ? false : true,
        reorder: true,
        cell: ({ snHardware }) => textBodyTemplate(snHardware),
        // width: '250px'
      },
      {
        name: "DEVICE ID",
        selector: (row) => row?.circuitNumber,
        sortable: true,
        sortField: "circuitNumber",
        left: true,
        omit: columnDefs?.includes("circuitNumber") ? false : true,
        reorder: true,
        // width: '250px'
      },
      {
        name: "CUSTOMER",
        sortable: true,
        sortField: "customer.name",
        wrap: true,
        omit: columnDefs?.includes("customer.name") ? false : true,
        cell: (row) => renderCustomer(row),
        reorder: true,
        // width: '150px'
      },
      {
        name: "CONNECTION",
        sortable: true,
        sortField: "connectionStatus",
        cell: ({ connectionStatus, simCards }) =>
          connectionStatus ? (
            connectionStatus === "Connected" && !isSimHaveMissingCid(simCards) ? (
              <i
                className="mdi mdi-wifi"
                style={{ fontSize: 14, color: "#2DCE89" }}
              />
            ) : (
              <i
                className="mdi mdi-wifi-off"
                style={{ fontSize: 14, color: "#F5365C" }}
              />
            )
          ) : (
            unknown
          ),
        omit: columnDefs.includes("connectionStatus") ? false : true,
        reorder: true,
        center: true
      },
      {
        name: "SIM 1 SIA",
        sortable: false,
        cell: ({ simCards }) => renderSimCardCircuitNumber(simCards, 1),
        omit: columnDefs?.includes("sim1CircuitNumber") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 1 GSM Number",
        sortable: false,
        cell: ({ simCards }) => renderSimCardGsmNumber(simCards, 1),
        omit: columnDefs?.includes("sim1GsmNumber") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 1 IMSI",
        sortable: false,
        cell: ({ simCards }) => renderSimCardIMSI(simCards, 1),
        omit: columnDefs?.includes("sim1Imsi") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 1 STATUS",
        sortable: false,
        cell: ({ simCards, ...device }) => renderSimCardStatus(simCards, 1, device),
        omit: columnDefs?.includes("sim1Status") ? false : true,
        reorder: true,
        width: "180px",
        // center: true,
      },
      {
        name: "SIM 1 USAGE",
        sortable: false,
        center: true,
        cell: ({ simCards }) => renderSimCardUsage(simCards, 1),
        omit: columnDefs?.includes("sim1Usage") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 1 SIGNAL",
        sortable: false,
        cell: ({ simCards }) => renderSimCardSignal(simCards, 1),
        omit: columnDefs.includes("sim1Signal") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 1 OPERATOR",
        sortable: false,
        cell: ({ simCards }) => renderSimCardOperator(simCards, 1),
        omit: columnDefs.includes("sim1Operator") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 2 SIA",
        sortable: false,
        cell: ({ simCards }) => renderSimCardCircuitNumber(simCards, 2),
        omit: columnDefs?.includes("sim2CircuitNumber") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 2 GSM Number",
        sortable: false,
        cell: ({ simCards }) => renderSimCardGsmNumber(simCards, 2),
        omit: columnDefs?.includes("sim2GsmNumber") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 2 IMSI",
        sortable: false,
        cell: ({ simCards }) => renderSimCardIMSI(simCards, 2),
        omit: columnDefs?.includes("sim2Imsi") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 2 STATUS",
        sortable: false,
        cell: ({ simCards, ...device }) => renderSimCardStatus(simCards, 2, device),
        omit: columnDefs?.includes("sim2Status") ? false : true,
        reorder: true,
        width: '180px',
        // center: true,
      },
      {
        name: "SIM 2 USAGE",
        sortable: false,
        center: true,
        cell: ({ simCards }) => renderSimCardUsage(simCards, 2),
        omit: columnDefs?.includes("sim2Usage") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 2 SIGNAL",
        sortable: false,
        cell: ({ simCards }) => renderSimCardSignal(simCards, 2),
        omit: columnDefs.includes("sim2Signal") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "SIM 2 OPERATOR",
        sortable: false,
        cell: ({ simCards }) => renderSimCardOperator(simCards, 2),
        omit: columnDefs.includes("sim2Operator") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "IP ADDRESS",
        selector: (row) => row?.ipDevice || "-",
        sortable: true,
        sortField: "ipDevice",
        omit: columnDefs?.includes("ipAddress") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "UPTIME DURATION",
        selector: (row) => row?.uptimeDurationInHuman,
        sortable: false,
        sortField: "uptimeDurationInHuman",
        omit: columnDefs?.includes("uptimeDurationInHuman") ? false : true,
        reorder: true,
        // width: '160px'
      },
      {
        name: "ADDRESS",
        selector: (row) => row?.location,
        sortField: "location",
        sortable: true,
        omit: columnDefs?.includes("location") ? false : true,
        reorder: true,
        cell: ({ location }) => textBodyTemplate(location),
      },
      {
        name: "PROVINCE",
        selector: (row) => row?.province,
        sortField: "province",
        sortable: true,
        omit: columnDefs?.includes("province") ? false : true,
        reorder: true,
        cell: ({ province }) => textBodyTemplate(province),
      },
      {
        name: "DISTRICT/CITY",
        selector: (row) => row?.province,
        sortField: "regency",
        sortable: true,
        omit: columnDefs?.includes("regency") ? false : true,
        reorder: true,
        cell: ({ regency }) => textBodyTemplate(regency),
      },
      {
        name: "AREA",
        sortable: true,
        sortField: "area.name",
        wrap: true,
        omit: columnDefs?.includes("area.name") ? false : true,
        cell: ({area}) => textBodyTemplate(area? area.name : '-'),
        reorder: true,
        width: '150px'
      },
      {
        name: "LAST UPDATE",
        selector: (row) => row?.lastDataReceive,
        format: (row) =>
          row.lastDataReceive
            ? momentLastUpdate(row.lastDataReceive, "DD/MM/YYYY HH:mm:ss")
            : unknown,
        sortable: true,
        sortField: "lastDataReceive",
        omit: columnDefs?.includes("lastDataReceive") ? false : true,
        reorder: true,
        width: '180px'
      },
      {
        name: "DEVICE TYPE",
        selector: (row) => row?.type,
        sortable: true,
        sortField: "type",
        left: true,
        omit: columnDefs?.includes("type") ? false : true,
        reorder: true,
        // width: '150px'
      },
      {
        name: "GROUP",
        sortable: false,
        grow: 2,
        left: true,
        omit: columnDefs?.includes("groups") ? false : true,
        cell: ({ groups }) =>
          (groups && groups.map((g) => g?.name).join(", ")) || "-",
        reorder: true,
      },
      {
        name: "LAN PORT STATUS",
        sortable: false,
        center: true,
        cell: ({ lanPortStatus }) => (
          <StatusBadge
            label={lanPortStatus}
            type={lanPortStatus === "Connected" ? "success" : "danger"}
          />
        ),
        omit: columnDefs?.includes("lanPortStatus") ? false : true,
        reorder: true,
        // width: '160px'
      },
      {
        width: "40px",
        cell: (row) => (
          isSimHaveMissingCid(row.simCards) ?
          <Button size="sm" color="link" disabled>
            <i className="fas fa-ellipsis-v text-silver"></i> 
          </Button> 
          :
          <CustomActionButton
            row={row}
            onDeleteClick={() => onUnprovisionDeviceClick(row)}
            uomOptions={uomOptions}
            groupOptions={groupOptions}
            areaOptions={areaOptions}
          />
        ),
        allowOverflow: true,
        button: true,
      },
    ];
  }, [columnDefs, modalAddCID, uomOptions, groupOptions, areaOptions, onUnprovisionDeviceClick]);

  const conditionalRowStyles = [
    {
      when: (row) => row.alerted === true,
      style: {
        backgroundColor: "rgb(255, 235, 234, 0.5)",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "rgb(255, 235, 234, 0.5)",
        },
      },
    },
    {
      when: (row) => row.validLicense !== true,
      style: {
        backgroundColor: "#eeeeee",
      },
    },
  ];

  const onRebootDeviceBulk = () => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to reboot selected devices?",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const ids = selectedRows.map((d) => d.id);
          await rebootDeviceBulk(ids).unwrap();
          toast.success("Command to reboot devices sent.");
          handleClearRows();
          refetch();
          return true;
        } catch (error) {
          Swal.showValidationMessage(
            `${error?.data?.error}: ${error?.data?.message}`
          );
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const onEnableDeviceTunnelBulk = () => {
    Swal.fire({
      icon: "question",
      title:
        "Are you sure you want to enable device tunnel on selected devices?",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const ids = selectedRows.map((d) => d.id);
          await enableTunnelDeviceBulk(ids).unwrap();
          toast.success("Command to enable device tunnel sent.");
          handleClearRows();
          refetch();
          return true;
        } catch (error) {
          Swal.showValidationMessage(
            `${error?.data?.error}: ${error?.data?.message}`
          );
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const onDisableDeviceTunnelBulk = () => {
    Swal.fire({
      icon: "question",
      title:
        "Are you sure you want to disable device tunnel on selected devices?",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const ids = selectedRows.map((d) => d.id);
          await disableTunnelDeviceBulk(ids).unwrap();
          toast.success("Command to disable device tunnel sent.");
          handleClearRows();
          refetch();
          return true;
        } catch (error) {
          Swal.showValidationMessage(
            `${error?.data?.error}: ${error?.data?.message}`
          );
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const onUpdateFirmwareBulk = () => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to update firmware on selected devices?",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const ids = selectedRows.map((d) => d.id);
          await updateFirmwareBulk(ids).unwrap();
          toast.success("Command to update firmware sent.");
          handleClearRows();
          refetch();
          return true;
        } catch (error) {
          Swal.showValidationMessage(
            `${error?.data?.error}: ${error?.data?.message}`
          );
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const ExpandedComponent = (props) => {
    return (
      <DeviceTableDetail
        {...props}
        groupOptions={groupOptions}
        areaOptions={areaOptions}
        customerOptions={customerOptions}
        uomOptions={uomOptions}
        quotaOptions={quotaOptions}
        deviceTypeOptions={deviceTypeOptions}
        serviceTypeOptions={serviceTypeOptions}
        serviceApplicationOptions={serviceApplicationOptions}
        unprovisionGsmNumbersOptions={unprovisionGsmNumbersOptions}
        refetch={refetch}
      />
    );
  };

  const checkSimStatus = (row) => {
      if (row.simCards && row.simCards.length > 0) {
        return row.simCards.some(simCard => simCard.status === 'Missing CID');
      }
      return false;
  }

  const mapRegionAreas = (arr) => {
    return arr.map((obj) => {
      return {value:obj.id, label: obj.name, region: obj.region.name}
    })
  }

  return (
    <PageContent title="Devices">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col className="d-flex align-items-center" xs="12" md="6">
              {renderBreadcrumb()}
            </Col>
            <Col className="d-flex flex-wrap justify-content-lg-end align-items-center">
              <PrimeSearchInput
                ref={searchRef}
                block
                onFilter={onSearch}
                size="sm"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
              <ConfigureTable
                isOpen={configTable}
                loading={isLoading}
                toggle={() => setConfigTable(!configTable)}
                onFilter={handleSetColumns}
                onResetDefault={onResetColumnsConfig}
                defaultColumns={columnDefs}
              />
              <button
                className="font-weight-normal btn btn-link text-default p-2"
                onClick={refetch}
              >
                <i
                  className={classnames("fa fa-sync", {
                    "fa-spin": isLoading || isFetching,
                  })}
                ></i>
              </button>
              <FilterDropdownDevice
                loading={isLoading}
                filteredGroupIds={query.groupIds}
                filteredCustomerIds={query.customerIds}
                filteredAreaIds={query.areaIds}
                filteredSimCardStatus={query.simCardStatus}
                filteredSignalStrength={query.signalStrength}
                filteredConnectionStatus={query.connectionStatus}
                filteredReachQuotaLimit={query.reachQuotaLimit}
                groupOptions={groupOptions}
                customerOptions={customerOptions}
                areaOptions={params.regionId ? mapRegionAreas([...regionAreas]) : areaOptions}
                onFilter={onFilter}
                toggleOnFilter
                regionId={params.regionId}
              />
              <DownloadCSVButton
                params={{
                  ...query,
                  regionIds: params.regionId,
                  // areaIds: params.areaId,
                  areaIds:
                  [...(query.areaIds || []), params.areaId].filter(Boolean).length > 0
                    ? [...(query.areaIds || []), params.areaId].filter(Boolean)
                    : undefined,
                }}
              />
              <ExportToExcel
                params={{
                  ...query,
                  regionIds: params.regionId,
                  // areaIds: params.areaId,
                  areaIds:
                  [...(query.areaIds || []), params.areaId].filter(Boolean).length > 0
                    ? [...(query.areaIds || []), params.areaId].filter(Boolean)
                    : undefined,
                }}
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Tags tags={filterTags} onReset={onResetFilter} />
            </Col>
          </Row>
          <DeviceHeaderTable
            onSetAreaClick={onSetAreaClick}
            onSetGroupClick={onSetGroupClick}
            onSetQuotaClick={onSetQuotaClick}
            onDeleteClick={onDeleteDevicesClick}
            onRebootClick={onRebootDeviceBulk}
            onEnableTunnelClick={onEnableDeviceTunnelBulk}
            onDisableTunnelClick={onDisableDeviceTunnelBulk}
            onUpdateFirmwareClick={onUpdateFirmwareBulk}
            selectedCount={selectedRows.length}
          />
          <DataTable
            title={title}
            data={data}
            columns={columns}
            responsive={true}
            keyField="id"
            defaultSortField="ipAddress"
            onSelectedRowsChange={handleRowSelected}
            striped
            noHeader
            highlightOnHover
            selectableRows
            clearSelectedRows={toggleCleared}
            expandableRows
            expandableRowDisabled={(row) => !row.validLicense || checkSimStatus(row)}
            expandableRowsComponent={ExpandedComponent}
            // expandableIcon={{
            //   collapsed: <CustomExpandableIcon type="collapsed" />,
            //   expanded: <CustomExpandableIcon type="expanded" />,
            // }}
            persistTableHead
            progressPending={isLoading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={onChangePage}
            sortServer
            onSort={onSort}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
          />
          <ModalSetQuota
            title="Set Quota"
            isOpen={modalQuota}
            toggle={() => setModalQuota(!modalQuota)}
            selectedRows={selectedRows}
            quotaOptions={quotaOptions}
            uomOptions={uomOptions}
            clearSelectedRows={handleClearRows}
          />
          <ModalSetGroup
            title="Set Group"
            isOpen={modalGroup}
            toggle={() => setModalGroup(!modalGroup)}
            selectedRows={selectedRows}
            groupOptions={groupOptions}
            clearSelectedRows={handleClearRows}
          />
          <ModalSetArea
            isOpen={modalSetArea}
            toggle={onSetAreaClick}
            selectedRows={selectedRows}
            areaOptions={areaOptions}
            clearSelectedRows={handleClearRows}
          />
          <ModalAddRegion
            isOpen={modalRegion}
            toggle={() => setModalRegion(!modalRegion)}
            groupOptions={groupOptions}
          />
          <ModalAddArea
            isOpen={modalArea}
            toggle={() => setModalArea(!modalArea)}
            groupOptions={groupOptions}
            regionOptions={regionOptions}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
}
