import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { createNetworkThunk } from "../networkSlice";

export function useCreateNetwork() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const createNetwork = useCallback(async (data) => {
		setIsLoading(true);
		await dispatch(createNetworkThunk(data));
		setIsLoading(false);
	}, [dispatch])

	return {
		createNetwork,
		isLoading
	}
}