import { useGetFeaturesGroupCategoryQuery } from "features/user/service/featureApi";
import { useMemo } from "react";

export function useFeatureGroup() {
  const { data, ...query } = useGetFeaturesGroupCategoryQuery();

  const featuresGroup = useMemo(() => {
    if (!data) {
      return [];
    }

    return Object.entries(data).map(([category, features]) => ({
      category,
      features,
    }));
  }, [data]);

  return {
    data,
    featuresGroup,
    ...query,
  };
}
