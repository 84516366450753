import React, { useState} from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'reactstrap'
import { unknown } from 'utils';
import { ModalUpdatePassword, RowItem } from './../../../../components';

const ProfileOverviewContent = props => {
  const [modalUpdatePassword, setModalUpdatePassword] = useState(false);

  const { user, onUpdatePassword, loading } = props;
  return (
    <Row>
      <Col md="6" xs="12" className="px-5 border-right">
        <RowItem label="Name" value={user?.name || unknown} stripped />
        <RowItem label="Email" value={user?.email || unknown} />
        <RowItem label="Phone Number" value={user?.phoneNumber || unknown} stripped />
        <RowItem label="Telegram Conversation ID" value={user?.telegramNumber || unknown} />
      </Col>
      <Col md="6" xs="12" className="px-5 border-left">
        <RowItem label="Username" value={user?.username || unknown} stripped />
        <RowItem label="Password" value={(
          <Button 
            color="default" 
            size="sm" 
            type="button" 
            onClick={() => setModalUpdatePassword(!modalUpdatePassword)}
            disabled={loading}
          >
            Update Password
          </Button>
        )} />
        <ModalUpdatePassword
          loading={loading}
          isOpen={modalUpdatePassword}
          maxPasswordLength={16}
          toggle={() => setModalUpdatePassword(!modalUpdatePassword)}
          onSubmit={({ newPassword, oldPassword }) => {
            onUpdatePassword(user.username, { oldPassword, newPassword }).then((result) => {
              if (result.value) {
                setModalUpdatePassword(!modalUpdatePassword);
              }
            })
          }}
        />
      </Col>
    </Row>
  )
}

ProfileOverviewContent.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    telegramNumber: PropTypes.string,
    username: PropTypes.string,
  }),
  onUpdatePassword: PropTypes.func,
  loading: PropTypes.bool
}

ProfileOverviewContent.defaultProps = {
  user: {
    name: '',
    email: '',
    phoneNumber: '',
    telegramNumber: '',
    username: '',
  },
  onUpdatePassword: () => {},
  loading: false
}

export default ProfileOverviewContent
