import React from 'react'
import { Route, Switch } from 'react-router'
import OLT from './OLT'
import { RegisteredOnuByOltId } from 'features/registeredOnu'

const oltRoute = () => {
  return (
    <Switch>
      <Route exact path={`/admin/olt/`} component={OLT} />
      <Route exact path={`/admin/olt/ont/:ipAddress/:routerId`} component={RegisteredOnuByOltId} />
    </Switch>
  )
}

export default oltRoute