import React from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import customStyles from './customStyles'
import { CustomExpandableIcon } from '../../atoms'
import { paginationRowsPerPageOptions } from '../../../utils'

function ReactDatatable(props) {
  return (
    <DataTable
      {...props}
      striped
      noHeader
      highlightOnHover
      expandableIcon={{
        collapsed: <CustomExpandableIcon type="collapsed" />,
        expanded: <CustomExpandableIcon type="expanded" />,
      }}
      persistTableHead
      paginationRowsPerPageOptions={paginationRowsPerPageOptions}
      customStyles={customStyles}
      overflowY={true}
    />
  )
}

ReactDatatable.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
    ]),
    selector: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    sortable: PropTypes.bool,
    sortFunction: PropTypes.func,
    format: PropTypes.func,
    cell: PropTypes.func,
    grow: PropTypes.number,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    right: PropTypes.bool,
    center: PropTypes.bool,
    compact: PropTypes.bool,
    ignoreRowClick: PropTypes.bool,
    button: PropTypes.bool,
    wrap: PropTypes.bool,
    allowOverflow: PropTypes.bool,
    hide: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf(['sm', 'md', 'lg'])
    ]),
    omit: PropTypes.bool,
    style: PropTypes.object,
    conditionalCellStyles: PropTypes.array,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  keyField: PropTypes.string,
  stripped: PropTypes.bool,
  highlightOnHover: PropTypes.bool,
  pointerOnHover: PropTypes.bool,
  noDataComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  responsive: PropTypes.bool,
  disabled: PropTypes.bool,
  onRowClicked: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  overflowY: PropTypes.bool,
  overflowYOffset: PropTypes.string,
  dense: PropTypes.bool,
  noTableHead: PropTypes.bool,
  persistTableHead: PropTypes.bool,
  direction: PropTypes.string,
}

ReactDatatable.defaultProps = {
  title: undefined,
  columns: [],
  data: [],
  keyField: 'id',
  stripped: false,
  highlightOnHover: false,
  pointerOnHover: false,
  noDataComponent: undefined,
  className: undefined,
  style: undefined,
  responsive: true,
  disabled: false,
  onRowClicked: undefined,
  onRowDoubleClicked: undefined,
  overflowY: false,
  overflowYOffset: '250px',
  dense: false,
  noTableHead: false,
  persistTableHead: undefined,
  direction: 'auto',
}

export default ReactDatatable

