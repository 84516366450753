import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./../../../services/baseApi";

export const packagePolicyApi = createApi({
  reducerPath: "packagePolicyApi",
  baseQuery: baseQuery,
  tagTypes: ["PolicyPackage", "ListPolicyPackage"],
  endpoints: (builder) => ({
    getPolicyPackage: builder.query({
      query: () => ({
        url: "/policy-package/",
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "PolicyPackage",
                id,
              })),
              { type: "PolicyPackage", id: "LIST" },
            ]
          : [{ type: "PolicyPackage", id: "LIST" }],
    }),
    updatePackagePolicy: builder.mutation({
      query: ({ id, body }) => ({
        url: `/policy-package/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "PolicyPackage", id: "LIST" }],
    }),
    createPackagePolicy: builder.mutation({
      query: (body) => ({
        url:'/policy-package/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: "PolicyPackage", id: "LIST" }],
    }),
    deleteById: builder.mutation({
      query: (id) => ({
        url:`/policy-package/${id}`,
        method:'DELETE',
      }),
      invalidatesTags: [{ type: "PolicyPackage", id: "LIST" }],
    }),
    getListPolicyPackage: builder.query({
      query: () => ({
        url: "/policy-package/list",
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "ListPolicyPackage",
                id,
              })),
              { type: "ListPolicyPackage", id: "LIST" },
            ]
          : [{ type: "ListPolicyPackage", id: "LIST" }],
    }),
  }),
});

export const { useGetPolicyPackageQuery, useUpdatePackagePolicyMutation, useCreatePackagePolicyMutation, useDeleteByIdMutation, useGetListPolicyPackageQuery } = packagePolicyApi;
