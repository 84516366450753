import React from "react";
import { Col, Row } from "reactstrap";

const PageHeader = () => {
  return (
    <div className="header-body">
      <Row className="d-flex justify-content-between align-items-center py-4">
        <Col>
          <h1 className="fullcalendar-title h1 text-white d-inline-block mb-0">
            Performance Dashboard
          </h1>
        </Col>
      </Row>
    </div>
  );
};

export default PageHeader;
