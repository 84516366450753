import { createApi } from '@reduxjs/toolkit/query/react';
import { deviceInventoryApi } from './../../inventory/devices';

import { baseQuery } from './../../../services/baseApi';
import { notificationApi } from './../../notification/service/notificationApi';
import { simcardInventoryApi } from './../../inventory/simcards';

export const deviceApi = createApi({
  reducerPath: "deviceApi",
  baseQuery: baseQuery,
  tagTypes: ["Device", "DeactivatedDevice", "Download", "Bandwidth"],
  endpoints: (builder) => ({
    getDevices: builder.query({
      query: (params) => ({
        url: `/device`,
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: "Device", id })),
              { type: "Device", id: "LIST" },
            ]
          : [{ type: "Device", id: "LIST" }],
    }),
    downloadDevices: builder.mutation({
      query: (params) => ({
        url: `/device`,
        method: "GET",
        params: params,
      }),
    }),
    getDeactivatedDevices: builder.query({
      query: (params) => ({
        url: `/device`,
        params: params,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "DeactivatedDevice",
                id,
              })),
              { type: "DeactivatedDevice", id: "LIST" },
            ]
          : [{ type: "DeactivatedDevice", id: "LIST" }],
    }),
    getDevice: builder.query({
      query: (id) => ({
        url: `/device/deviceId/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "Device", id }],
    }),
    createDevice: builder.mutation({
      query: ({ body, params }) => ({
        url: `/device`,
        method: "POST",
        body,
        params,
      }),
      invalidatesTags: [{ type: "Device", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            deviceInventoryApi.util.invalidateTags([
              { type: "DeviceInv", id: "LIST" },
            ])
          );
          dispatch(
            simcardInventoryApi.endpoints.getSimcardGsmNumbers.initiate(
              undefined,
              { forceRefetch: true }
            )
          );
        });
      },
    }),
    updateDevice: builder.mutation({
      query: ({ id, body, params }) => ({
        url: `/device/${id}`,
        method: "PUT",
        body,
        params,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          deviceApi.util.updateQueryData("getDevice", id, (draft) => {
            Object.assign(draft, patch);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Device", id }],
    }),
    deleteDevice: builder.mutation({
      query(id) {
        return {
          url: `/device/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [{ type: "Device", id }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(notificationApi.util.invalidateTags(["Notification"]));
        });
      },
    }),
    setPrimarySimCard: builder.mutation({
      query({ id, slotNumber }) {
        return {
          url: `/device/primary/${id}/${slotNumber}`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Device", id }],
    }),
    setQuotaLimitSimCard: builder.mutation({
      query({ id, slotNumber, limit, uom }) {
        return {
          url: `/device/quota/${id}/${slotNumber}/${limit}/${uom}`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Device", id }],
    }),
    getUnregisteredSerialNumber: builder.query({
      query: () => ({
        url: `/radius/radcheck/usernames/unadded`,
      }),
    }),
    getUserImsis: builder.query({
      query: (username) => ({
        url: `/device/sim-card/imsis`,
        params: { username },
      }),
    }),
    checkSerialNumber: builder.mutation({
      query: (sn) => ({
        url: `/device/sn/${sn}/exist`,
        method: "GET",
      }),
    }),
    checkCircuitNumber: builder.mutation({
      query: (circuitNumber) => ({
        url: `/device/circuitNumber/${circuitNumber}/exist`,
        method: "GET",
      }),
    }),
    getSimCardByCircuitNumber: builder.query({
      query: ({ deviceId, circuitNumber}) => ({
        url: `/device/${deviceId}/circuitNumber/${circuitNumber}`,
        method: "GET",
      }),
    }),
    blockDevice: builder.mutation({
      query(id) {
        return {
          url: `/device/block/${id}`,
          method: "PUT",
          body: {},
        };
      },
      invalidatesTags: [{ type: "DeactivatedDevice", id: "LIST" }],
    }),
    setDeviceArea: builder.mutation({
      query({ id, areaId }) {
        return {
          url: `/hierarchy/mapping/device/${id}/area/${areaId}`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Device", id }],
    }),
    removeDeviceArea: builder.mutation({
      query({ id, areaId }) {
        return {
          url: `/hierarchy/mapping/device/${id}/area/${areaId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Device", id }],
    }),
    setSimCardStatus: builder.mutation({
      query({ action, deviceId, simSlotNumber }) {
        return {
          url: `/device/sim-status/${action}/${deviceId}/${simSlotNumber}`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, { deviceId }) => [
        { type: "Device", id: deviceId },
      ],
    }),
    updateSimCard: builder.mutation({
      query({ deviceId, id, ...body }) {
        return {
          url: `/device/sim-card/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { deviceId }) => [
        { type: "Device", id: deviceId },
      ],
    }),
    deleteSimCard: builder.mutation({
      query(id) {
        return {
          url: `/device/sim-card/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [{ type: "Device", id: "LIST" }],
    }),
    isGsmNumberExists: builder.mutation({
      query: ({ id, gsmNumber }) => ({
        url: `/device/sim-card/${id}/gsm-number/${gsmNumber}/duplicate`,
        method: "GET",
      }),
    }),
    isCircuitNumberExists: builder.mutation({
      query: ({ id, circuitNumber }) => ({
        url: `/device/${id}/circuitNumber/${circuitNumber}/duplicate`,
        method: "GET",
      }),
    }),
    isSimcardCircuitNumberExist: builder.mutation({
      query: ({ id, circuitNumber }) => ({
        url: `/sim-card/${id}/circuitNumber/${circuitNumber}/duplicate`,
        method: "GET",
      }),
    }),
    rebootDevice: builder.mutation({
      query: (id) => ({
        url: `/action/reboot/${id}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Device", id }],
    }),
    disableTunnelDevice: builder.mutation({
      query: (deviceId) => ({
        url: `/action/disable-tunnel/${deviceId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Device", id }],
    }),
    enableTunnelDevice: builder.mutation({
      query: (deviceId) => ({
        url: `/action/enable-tunnel/${deviceId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Device", id }],
    }),
    updateFirmware: builder.mutation({
      query: (deviceId) => ({
        url: `/action/update-firmware/${deviceId}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Device", id }],
    }),
    availableUpdate: builder.query({
      query: (deviceId) => ({
        url: `/firmware/device/id/${deviceId}/exist`,
      }),
    }),
    firmwareInfo: builder.query({
      query: (deviceId) => ({
        url: `/firmware/device/id/${deviceId}/latest`,
      }),
    }),
    setRateLimit: builder.mutation({
      query: ({ deviceId, rateLimitUploadInKb, rateLimitDownloadInKb }) => ({
        url: `/action/rate-limit-in-kb/${deviceId}/${rateLimitUploadInKb}/${rateLimitDownloadInKb}`,
        method: "POST",
      }),
    }),
    setSimToDevice: builder.mutation({
      query: ({ simCardId, deviceId, slotNumber, primary }) => ({
        url: `/sim-card/set-device/${simCardId}/${deviceId}/${slotNumber}/${primary}`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, { deviceId }) => [
        { type: "Device", id: deviceId },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            simcardInventoryApi.util.invalidateTags([
              { type: "Unprov_GSMNumbers" },
            ])
          );
          dispatch(
            simcardInventoryApi.endpoints.getSimcardGsmNumbers.initiate(
              {},
              { forceRefetch: true }
            )
          );
        });
      },
    }),
    rebootDeviceBulk: builder.mutation({
      query: (ids) => ({
        url: `/action/reboot/bulk`,
        method: "POST",
        body: ids,
      }),
      invalidatesTags: [{ type: "Device", id: "LIST" }],
    }),
    enableTunnelDeviceBulk: builder.mutation({
      query: (ids) => ({
        url: `/action/enable-tunnel/bulk`,
        method: "POST",
        body: ids,
      }),
      invalidatesTags: [{ type: "Device", id: "LIST" }],
    }),
    disableTunnelDeviceBulk: builder.mutation({
      query: (ids) => ({
        url: `/action/disable-tunnel/bulk`,
        method: "POST",
        body: ids,
      }),
      invalidatesTags: [{ type: "Device", id: "LIST" }],
    }),
    updateFirmwareBulk: builder.mutation({
      query: (ids) => ({
        url: `/action/update-firmware/bulk`,
        method: "POST",
        body: ids,
      }),
      invalidatesTags: [{ type: "Device", id: "LIST" }],
    }),

    isGatewayIdExists: builder.mutation({
      query: ({ id, gatewayId }) => ({
        url: `/device/${id}/gateway/${gatewayId}/duplicate`,
        method: "GET",
      }),
    }),
    verifySimCard: builder.mutation({
      query: ({ simCardId }) => ({
        url: `/sim-card/verification/${simCardId}`,
        method: "POST",
      }),
      invalidatesTags: (res, err, { deviceId }) => [
        { type: "Device", id: deviceId },
      ],
    }),
    getGatewayNodeRef: builder.query({
      query: () => ({
        url: "/reference/gateway-node",
      }),
    }),
    getJobBandWidth: builder.query({
      query: (deviceId) => ({
        url: `/device/${deviceId}/jobs/bandwidth`,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "Bandwidth",
                id,
              })),
              { type: "Bandwidth", id: "LIST" },
            ]
          : [{ type: "Bandwidth", id: "LIST" }],
    }),
    createJobBandWidth: builder.mutation({
      query: ({ deviceId, body }) => ({
        url: `/device/${deviceId}/jobs/bandwidth`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Bandwidth", id: "LIST" }],
    }),
    updateJobBandwidth: builder.mutation({
      query: ({ deviceId, jobId, body }) => ({
        url: `/device/${deviceId}/jobs/bandwidth/${jobId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "Bandwidth", id: "LIST" }],
    }),
    updateDeviceSimCards: builder.mutation({
      query: ({ deviceId, data }) => ({
          url: `/sim-card/updateSimCards/${deviceId}`,
          method: 'PUT',
          body: data,
      }),
  })
  }),
});

export const {
	useGetDeviceQuery,
	useGetDevicesQuery,
	useGetDeactivatedDevicesQuery,
	useCreateDeviceMutation,
	useUpdateDeviceMutation,
	useDeleteDeviceMutation,
	useSetPrimarySimCardMutation,
	useSetQuotaLimitSimCardMutation,
	useGetUnregisteredSerialNumberQuery,
	useGetUserImsisQuery,
	useCheckCircuitNumberMutation,
	useCheckSerialNumberMutation,
	useBlockDeviceMutation,
	useSetDeviceAreaMutation,
	useRemoveDeviceAreaMutation,
	useSetSimCardStatusMutation,
	useUpdateSimCardMutation,
	useIsGsmNumberExistsMutation,
	useIsCircuitNumberExistsMutation,
	useRebootDeviceMutation,
	useDisableTunnelDeviceMutation,
	useEnableTunnelDeviceMutation,
	useUpdateFirmwareMutation,
	useAvailableUpdateQuery,
	useFirmwareInfoQuery,
	useSetRateLimitMutation,
	useSetSimToDeviceMutation,
	useRebootDeviceBulkMutation,
	useEnableTunnelDeviceBulkMutation,
	useDisableTunnelDeviceBulkMutation,
	useUpdateFirmwareBulkMutation,
	useDownloadDevicesMutation,
	useIsSimcardCircuitNumberExistMutation,
	useIsGatewayIdExistsMutation,
	useVerifySimCardMutation,
	useGetGatewayNodeRefQuery,
	useGetJobBandWidthQuery,
	useCreateJobBandWidthMutation,
  useUpdateJobBandwidthMutation,
  useGetSimCardByCircuitNumberQuery,
  useLazyGetSimCardByCircuitNumberQuery,
  useUpdateDeviceSimCardsMutation,
} = deviceApi;

