import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SplitButton } from 'primereact/splitbutton';
import './RefreshButtonWithOptions.scss';
import classNames from 'classnames';

function RefreshButtonWithOptions(props) {
  const { onClick, onItemClick, refreshing, selectedOption, ...rest } = props;
  const [selected, setSelected] = useState(selectedOption);

  useEffect(() => {
    setSelected(selectedOption)
  }, [selectedOption, setSelected]);

  const templateItem = useCallback((item, options) => {
    const icon = selected === item.label ? 'fa fa-check' : ''
    return (
      <span className={options.className} target={options.target} onClick={options.onClick}>
        <span className={classNames(options.labelClassName)}>{item.label}</span>
        <span className={classNames(options.iconClassName, icon)}></span>
      </span>
    )
  }, [selected]);

  const items = useMemo(() => [
    { label: 'Off', command: (e) => onItemClick('Off'), template: templateItem },
    { label: '5s', command: (e) => onItemClick('5s'), template: templateItem },
    { label: '10s', command: (e) => onItemClick('10s'), template: templateItem },
    { label: '30s', command: (e) => onItemClick('30s'), template: templateItem },
    { label: '1m', command: (e) => onItemClick('1m'), template: templateItem },
    { label: '5m', command: (e) => onItemClick('5m'), template: templateItem },
    { label: '15m', command: (e) => onItemClick('15m'), template: templateItem },
    { label: '30m', command: (e) => onItemClick('30m'), template: templateItem },
    { label: '1h', command: (e) => onItemClick('1h'), template: templateItem },
    { label: '2h', command: (e) => onItemClick('2h'), template: templateItem },
    { label: '1d', command: (e) => onItemClick('1d'), template: templateItem },
  ], [templateItem, onItemClick]);

  return (
    <div className="customRefresh">
      <SplitButton
        {...rest}
        icon={`fa fa-sync ${refreshing && 'fa-spin'}`}
        model={items}
        onClick={onClick}
      />
    </div>
  )
}

RefreshButtonWithOptions.propTypes = {
  onClick: PropTypes.func,
  onItemClick: PropTypes.func,
  refreshing: PropTypes.bool,
  selectedOption: PropTypes.string,
}

RefreshButtonWithOptions.defaultProps = {
  onClick: () => { },
  onItemClick: () => { },
  refreshing: false,
  selectedOption: 'Off'
}

export default RefreshButtonWithOptions

