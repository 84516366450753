import { useMemo } from "react";
import { useAuth } from "./../../auth/hooks";
import { useGetReportDeviceIntegrationFilterReferencesByUsernameQuery, useGetReportFilterReferenceByUsernameQuery } from "../service/reportApi";

export function useReportFilterReferences(reportType) {
  const { username } = useAuth();
  const { data: defaultFilters, isLoading: isLoadingDefaultFilters } = useGetReportFilterReferenceByUsernameQuery(username, { skip: !username });
  const { data: integrationFilters, isLoading: isLoadingIntegrationFilters } = useGetReportDeviceIntegrationFilterReferencesByUsernameQuery(username, { skip: !username });

  const filterOptions = useMemo(() => {
    const customerOptions = [];
    const areaOptions = [];
    const reachQuotaLimitOptions = [];
    const deviceTypeOptions = [];
    const serialNumberOptions = [];
    const operatorOptions = [];
    const imsiOptions = [];
    const locationOptions = [];
    const groupOptions = [];
    const integrationStatusOptions = [];
    const availabilityOptions = [
      { value: null, label: 'All' },
      { value: 'GTE:0.9', label: '> 90%' },
      { value: 'LTE:0.9', label: '< 90%' },
    ]

    const filters = reportType === 'integration' ? integrationFilters : defaultFilters;

    if (filters) {
      const { customers, reachQuotaLimits, deviceTypes, serialNumbers, operators, imsis, locations, areas, groups, deviceIntegrationStatuses } = filters;

      if (customers && customers.length > 0) {
        customers.forEach((v) => {
          customerOptions.push({ value: v.id, label: v.name });
        });
      }
      if (areas && areas.length > 0) {
        areas.forEach((v) => {
          areaOptions.push({ value: v.id, label: v.name });
        });
      }

      if (reachQuotaLimits && reachQuotaLimits.length > 0) {
        reachQuotaLimitOptions.push({ value: null, label: 'All' });
        reachQuotaLimits.forEach((v) => {
          reachQuotaLimitOptions.push({ value: v ? 'true' : 'false', label: v ? 'Yes' : 'No' });
        });
      }

      if (deviceTypes && deviceTypes.length > 0) {
        deviceTypes.forEach((v) => {
          deviceTypeOptions.push({ value: v, label: v });
        });
      }

      if (serialNumbers && serialNumbers.length > 0) {
        serialNumbers.forEach((v) => {
          serialNumberOptions.push({ value: v, label: v });
        });
      }

      if (operators && operators.length > 0) {
        operators.forEach((v) => {
          operatorOptions.push({ value: v, label: v });
        });
      }
      if (imsis && imsis.length > 0) {
        imsis.forEach((v) => {
          imsiOptions.push({ value: v, label: v });
        });
      }
      if (locations && locations.length > 0) {
        locations.forEach((v) => {
          locationOptions.push({ value: v, label: v });
        });
      }

      if (groups && groups.length > 0) {
        groups.forEach((v) => {
          groupOptions.push({ value: v.id, label: v.name });
        });
      }

      if (reportType === 'integration' && deviceIntegrationStatuses && deviceIntegrationStatuses.length > 0) {
        deviceIntegrationStatuses.forEach((v) => {
          integrationStatusOptions.push({ value: v, label: v });
        });
      }
    }

    return {
      customerOptions,
      areaOptions,
      reachQuotaLimitOptions,
      deviceTypeOptions,
      serialNumberOptions,
      operatorOptions,
      imsiOptions,
      locationOptions,
      groupOptions,
      integrationStatusOptions,
      availabilityOptions,
    };
  }, [defaultFilters, integrationFilters, reportType]);

  return {
    filterOptions,
    isLoadingDefaultFilters,
    isLoadingIntegrationFilters,
  }
}