import { AbilityBuilder, Ability } from '@casl/ability';
import store from './../store';
import { authApi } from './../features/auth/service/authApi';

function subjectName(item) {
  if (!item || typeof item === "string") {
    return item
  }
  return item.__type
}

export const ability = new Ability([], { subjectName });

export function defineAbilityFor(user) {
  const { can, rules } = new AbilityBuilder();

  if (typeof user !== 'undefined') {
    user.combinePrivileges && user.combinePrivileges.forEach(privilege => {
      const { featureName } = privilege;
      can('do', featureName);
    });
  }

  return rules;
}

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  const state = store.getState();
  const username = state.auth.username;
  const { data } = authApi.endpoints.getProfile.select(username)(state);
  if (data) {
    currentAuth = data
  }
  if (prevAuth !== currentAuth) {
    ability.update(defineAbilityFor(currentAuth));
  }
});
