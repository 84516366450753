import React, { useMemo } from "react";
import { PageContent } from "components";
import customStyles from "components/organisms/ReactDatatable/customStyles";
import DataTable from "react-data-table-component";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
} from "reactstrap";
import useLicenseColumns from "../hooks/useLicenseColumns";
import useLicenseDatatable from "../hooks/useLicenseDatatable";
import OverviewCards from "../components/OverviewCards";
import { StringParam, useQueryParams } from "use-query-params";
import moment from "moment";

const LicenseListPage = () => {
  const [queryParams] = useQueryParams({
    filterBy: StringParam
  });

  const buildFilters = useMemo(() => {
    if (!queryParams.filterBy) return {};

    switch (queryParams.filterBy) {
      case "nearlyExpired":
        return {
          gteLicenseEndTs: moment().add(7, 'days').valueOf(),
        };
      case "newLicense":
        return {
          gteLicenseBeginTs: moment().subtract(7, 'days').valueOf(),
        };
      default:
        return {};
    }
  }, [queryParams]);

  const {
    data,
    isLoading,
    totalRows,
    onChangePage,
    onChangeRowsPerPage,
    pageSize,
    onSort,
  } = useLicenseDatatable(buildFilters);
  const columns = useLicenseColumns();

  return (
    <PageContent title="Subscription License">
      <Card>
        <CardBody>
          <OverviewCards />
          <div className="mb-3 d-flex align-items-center justify-content-between">
            <div>
              <Breadcrumb listClassName="bg-white m-0 p-0">
                <BreadcrumbItem className="active">
                  <span className="text-dark text-lg">All Data</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
          <DataTable
            keyField="id"
            title="All Data"
            responsive
            persistTableHead
            columns={columns}
            data={data}
            striped
            noHeader
            highlightOnHover
            pagination
            paginationServer
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
            sortServer
            paginationTotalRows={totalRows}
            paginationPerPage={pageSize}
            customStyles={customStyles}
            progressPending={isLoading}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default LicenseListPage;
