import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AlertConfigurationPage, AlertListPage, CreateAlertConfigurationPage } from "./pages";

const AlertRoute = () => {
  return (
    <Switch>
      <Route exact path={`/admin/alerts/`} component={AlertListPage} />
      <Route exact path={`/admin/alerts/configuration`} component={AlertConfigurationPage} />
      <Route exact path={`/admin/alerts/configuration/new`} component={CreateAlertConfigurationPage} />
      <Redirect to="/admin/alerts/" />
    </Switch>
  );
}

export default AlertRoute;
