import React from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { useCreateRouterMutation } from "../service";
import FormRouter from "./FormRouter";

const AddNewRouterModal = ({ isOpen, toggle }) => {
  const [createRouter, { isLoading }] = useCreateRouterMutation();

  const onSubmit = (router) => {

    const valueToSend = {
      ...router,
      updateModified: null,
    };

    createRouter(valueToSend)
      .unwrap()
      .then(() => {
        toast.success(`Router (${router.device}) created.`);
        toggle();
      });
  };

  return (
    <Modal className="modal-dialog-centered" size="md" isOpen={isOpen} backdrop={isLoading}>
      <FormRouter onSubmit={onSubmit} onCancel={toggle} isLoading={isLoading}/>
    </Modal>
  );
};

export default AddNewRouterModal;
