import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../../../../services/baseApi";

export const routerPolicyApi = createApi({
  reducerPath: "routerPolicyApi",
  baseQuery: baseQuery,
  tagTypes: ["RouterPolicy"],
  endpoints: (builder) => ({
    getRouterPolicy: builder.query({
      query: () => ({
        url: "/RouterPolicy/",
      }),
    }),
    getRouterPolicyList: builder.query({
      query: () => ({
        url: "/RouterPolicy/list",
      }),
    }),
  }),
});

export const { useGetRouterPolicyQuery, useGetRouterPolicyListQuery } = routerPolicyApi;
