import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const oltApi = createApi({
    reducerPath: 'oltApi',
    baseQuery: baseQuery,
    tagTypes: ['Olt'],
    endpoints: (builder) => ({
        getOlt: builder.query({
            query: () => ({
                url:'/olt/'
            })
        })
    })
})

export const { useGetOltQuery } = oltApi;