import React, { useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Loading, PrimeDropdown, SLACard } from "./../../../components";
import ReportEChart from "./ReportEChart";
import { useGetSlaCustomerByUsernameQuery } from "../service/reportApi";

const SLAReportContent = ({ loading, pieChartData, panelData, tableData, dateRange, filters, lineChartData }) => {  
  const { UPTIME } = panelData;
  let data = pieChartData ? { ...pieChartData } : null; // Check if pieChartData is defined
  const [lineChartDateFilter, setLineChartDateFilter] = useState('day')
  const { data: originalCustomersData} = useGetSlaCustomerByUsernameQuery();
  const customerId = filters.customerIds.length === 1 ? filters.customerIds[0] : undefined
  const customerThreshold = customerId ? [...originalCustomersData].find(c => c.customerId === customerId)?.slaPercentage || null : null;  

  let textDate
  if (dateRange.endTime.diff(dateRange.startTime, 'days') <= 1) {
    textDate = `${dateRange.startTime.format('DD MMM YYYY')}`;
  } else {
    textDate = `${dateRange.startTime.format('DD MMM YYYY')} - ${dateRange.endTime.format('DD MMM YYYY')}`;
  }

  const updateLabels = (data) => {
    if (data) {
      const updatedLabels = data.labels.map((label, index) => {
        const value = data.datasets[0].data[index];
        return `${
          label === ">= 99.5%" ? "Achieved with" : "Not Achieved with"
        } ${label} (${value} device${value > 1 ? "s" : ""})`;
      });
      data.labels = updatedLabels;
    }
  };

  if (data) {
    updateLabels(data); // Update the labels in the copied data object
  }

  const lineChartDateOpts = [
    { label: 'Per day', value: 'day' },
    { label: 'Per week', value: 'week' },
    { label: 'Per month', value: 'month' },
  ]

  const onChangeLineChartDate = (params) => {
    setLineChartDateFilter(params.value)
  }

  const isObjValid = obj => {
    if (obj && typeof obj === 'object') {
        if (Object.keys(obj).length === 0) {
            return true; // Object is empty
        }
        // Object is not empty, check the length of obj.datasets
        return obj.datasets && Array.isArray(obj.datasets) && obj.datasets.length === 0;
    }
    return false; // Not an object or null
};

  return (
    <div id="slaReportContent" >
      <Row>
        <Col className="col-lg-12 col-sm-12">
          <Card className="mb-4 mt-2" style={{boxShadow: 'none', borderRadius: '8px', border: `1px solid #E9ECEF`}}>
            <CardBody className='pb-4'>
              <CardTitle tag="h4" className="mb-4 text-muted" style={{fontSize: '16px'}}>
                <Row>
                  <Col>Device Availability Trend</Col>
                  <Col hidden/* ={showDateFilter(dateRange, 360)} */ className="col-lg-2 col-sm-2"><PrimeDropdown  style={{height:'45px'}} options={lineChartDateOpts} value={lineChartDateFilter} onChange={onChangeLineChartDate}/></Col>
                </Row>
              </CardTitle>
              <div className="chart" id="lineChart">
                {loading && (
                  <div className="d-flex justify-content-center align-items-center h-100 mb-3">
                    <Loading size="lg" />
                  </div>
                )}
                {!loading && isObjValid(lineChartData) && (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <p>No data available</p>
                  </div>
                )}
                {!loading && !isObjValid(lineChartData) && (
                  <ReportEChart data={lineChartData} dateRange={dateRange}/>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
            <h4 className='text-muted' style={{fontSize: '16px'}}>Total for {textDate}</h4>
            </Col>
        <Col className="col-lg-12 col-sm-12">
                <Row className="d-flex align-items-center justify-content-center">
                  {loading && (
                    <>
                      <Col>
                        <SLACard title="Achieved Total" value={''} titleColor="#8898AA" valueColor="#495057" isLoading/>
                      </Col>
                      <Col>
                        <SLACard title="Not Achieved Total" value={''} titleColor="#8898AA" valueColor="#495057" isLoading/>
                      </Col>
                      <Col xs="auto" className="d-flex align-items-stretch">
                        <div style={{ height: '90px', width: '1px', backgroundColor: '#CED4DA80', marginBottom: '12px' }}></div>
                      </Col>
                      <Col>
                        <SLACard title="Minimum" value={''} titleColor="#8898AA" valueColor="#495057" isLoading/>
                      </Col>
                      <Col>
                        <SLACard title="Maximum" value={''} titleColor="#8898AA" valueColor="#495057" isLoading/>
                      </Col>
                      <Col>
                        <SLACard title="Average" value={''} titleColor="#8898AA" valueColor="#495057" isLoading/>
                      </Col>
                    </>
                  )}
                  {!loading &&
                    UPTIME && (
                      <>
                        <Col>
                          <SLACard title={filters.customerIds.length === 1 ? `Achieved Total (≥ ${customerThreshold ? customerThreshold : '-' }%)` : 'Achieved Total'} value={data.datasets[0].data[0]} unitValue='Devices' titleColor="#8898AA" valueColor="#495057"/>
                        </Col>
                        <Col>
                          <SLACard title={filters.customerIds.length === 1 ? `Not Achieved Total (< ${customerThreshold ? customerThreshold : '-' }%)` : 'Not Achieved Total'} value={data.datasets[0].data[1]} unitValue='Devices' titleColor="#8898AA" valueColor="#495057"/>
                        </Col>
                        <Col xs="auto" className="d-flex align-items-stretch">
                          <div
                            style={{
                              height: '90px',
                              width: '1px',
                              backgroundColor: '#CED4DA80',
                              marginBottom: '12px'
                            }}
                          ></div>
                        </Col>
                        {
                         UPTIME.map((ut, i) => {
                          const { label, value, unit, deviation } = ut;
                          return (
                            // <Col lg="4" sm="6" key={`sla-panel-${i}`}>
                            <Col key={`sla-panel-${i}`}>
                              <SLACard
                                title={label}
                                value={
                                  value
                                    ? value === "NaN"
                                      ? "0"
                                      : typeof value === "number"
                                      ? value.toFixed(2)
                                      : value
                                    : "0"
                                }
                                unitValue={unit}
                                status={
                                  deviation !== null ? deviation?.status : undefined
                                }
                                statusText={
                                  deviation !== null
                                    ? `${deviation?.value}${deviation?.unit} than ${deviation?.comparison}`
                                    : undefined
                                }
                                titleColor="#8898AA"
                                valueColor="#495057"
                              />
                            </Col>
                          );
                        }) 
                        }
                      </>
                    )}
                  {!loading && typeof UPTIME === "undefined" && (
                    <>
                      <Col>
                        <SLACard title="Achieved Total" value={0} unitValue={'Device'} titleColor="#8898AA" valueColor="#495057"/>
                      </Col>
                      <Col>
                        <SLACard title="Not Achieved Total" value={0} unitValue={'Device'} titleColor="#8898AA" valueColor="#495057"/>
                      </Col>
                      <Col xs="auto" className="d-flex align-items-stretch">
                        <div style={{ height: '90px', width: '1px', backgroundColor: '#CED4DA80', marginBottom: '12px' }}></div>
                      </Col>
                      <Col>
                        <SLACard title="Minimum" value={0} unitValue="H" titleColor="#8898AA" valueColor="#495057"/>
                      </Col>
                      <Col>
                        <SLACard title="Maximum" value={0} unitValue="H" titleColor="#8898AA" valueColor="#495057"/>
                      </Col>
                      <Col>
                        <SLACard title="Average" value={0} unitValue="H" titleColor="#8898AA" valueColor="#495057"/>
                      </Col>
                    </>
                  )}
                </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SLAReportContent;
