import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { ButtonLinkIcon, Tags, PageContent, PrimeSearchInput } from "../../../components";
import { ADD_NEW_GROUP, Can, DELETE_GROUP } from "../../../config";
import { GroupActionButton, ModalAddGroup, ModalEditGroup } from "../components";
import { useGroupDatatable } from "../hooks";
import { history, paginationRowsPerPageOptions } from './../../../utils';
import { useDeleteGroupMutation } from "./../service/groupApi";
import { customStyles } from "./../../../utils/datatableStyles";

const GroupHeaderTable = ({ selectedRows, onDelete }) => {
  const handleDelete = () => {
    onDelete(selectedRows);
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <Can I="do" this={DELETE_GROUP}>
          {() => (
            <Button key="delete" className="btn-icon btn-3" color="danger" type="button" size="sm" onClick={handleDelete}>
              <span className="btn-inner--text"><i className="fa fa-trash mr-1"></i>Delete</span>
            </Button>
          )}
        </Can>
      </div>
      <div className="text-right">
        <p>{selectedRows.length || 0} {selectedRows.length > 1 ? 'groups' : 'group'} selected.</p>
      </div>
    </div>
  )
}

const GroupList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});

  const searchRef = useRef();
  const [query, setQuery] = useQueryParams({ smartSearch: StringParam, id: NumberParam });

  const {
    data,
    isLoading,
    totalRows,
    pageSize,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useGroupDatatable(query);

  const [deleteGroup] = useDeleteGroupMutation();

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.search || '');
    }
  }, [query]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const onDeleteGroup = useCallback((group) => {
    Swal.fire({
      title: "Are you sure want to delete this?",
      text: `Group: ${group.name}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return deleteGroup(group.id)
          .unwrap()
          .then(() => {
            toast.success(`Group ${group.name} deleted.`);
            return true;
          })
          .catch((error) => {
            Swal.showValidationMessage(
              `${error.data.error}: ${error.data.message}`
            );
            return false;
          });
      },
    });
  }, [deleteGroup]);

  const handleMultipleDelete = useCallback(
    (groups) => {
      Swal.fire({
        icon: 'question',
        title: 'Are you sure you want to delete this?',
        text: `Groups: ${groups.map(g => g.name)}`,
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        reverseButtons: true,
        preConfirm: async () => {
          await groups.forEach(async (group) => {
            await deleteGroup(group.id);
          });
          return true;
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(async (result) => {
        if (result.value) {
          toast.success('Groups deleted.');
          setToggleCleared(true);
        }
      });
    }, [deleteGroup, setToggleCleared]);

  const onEditGroup = useCallback(
    (group) => {
      setModalEdit(true);
      setSelectedGroup(group);
    },
    [setModalEdit, setSelectedGroup],
  );

  const columns = useMemo(() => {
    const goToPath = (path, query) => {
      history.push({
        pathname: path,
        search: `?${query}`
      });
    }

    const renderLink = (text, path, query) => {
      if (text === 0) {
        return 0;
      }

      return (
        <a href="#link" className="text-default" onClick={(e) => {
          e.preventDefault();
          goToPath(path, query);
        }}>
          {text}
        </a>
      );
    }

    return [
      {
        name: 'GROUP NAME',
        selector: row => row?.name,
        sortable: true,
        sortField: 'name',
        left: true,
      },
      {
        name: 'TOTAL CUSTOMER',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ groupSummary }) => groupSummary?.totalCustomerIds,
        omit: true,
        // cell: ({ groupSummary, id }) => renderLink(groupSummary?.totalCustomerIds, '/admin/customers', 'groupIds', id)
      },
      {
        name: 'TOTAL DEVICES',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ groupSummary, id }) => renderLink(groupSummary?.totalDeviceIds, '/admin/devices', `groupIds=${id}`)
      },
      {
        name: 'CONNECTED DEVICES',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ groupSummary, id }) => renderLink(groupSummary?.totalConnectedDeviceIds, '/admin/devices', `connectionStatus=Connected&groupIds=${id}`),
      },
      {
        name: 'REACH QUOTA LIMIT',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ groupSummary, id }) => renderLink(groupSummary?.totalReachQuotaLimitSimCardIds, '/admin/devices', `reachQuotaLimit=true&groupIds=${id}`)
      },
      {
        name: 'SIM DEACTIVATED',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ groupSummary, id }) => renderLink(groupSummary?.totalDeactivatedSimCardIds, '/admin/devices', `simCardStatus=Deactivated&groupIds=${id}`),
      },

      {
        name: 'TOTAL USER',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ groupSummary, id }) => renderLink(groupSummary?.totalUsernames, '/admin/users', `groupIds=${id}`),
      },
      {
        name: '',
        width: '40px',
        allowOverflow: true,
        button: true,
        cell: (row) => <GroupActionButton row={row} onDelete={onDeleteGroup} onEdit={onEditGroup} />,
      },
    ]
  }, [onDeleteGroup, onEditGroup]);

  const onSearch = (searchText) => {
    setQuery({ smartSearch: searchText !== "" && searchText !== null ? searchText : undefined });
  };

  const onRemoveFilter = useCallback((key = null) => {
    if (!key) return;

    setQuery({ [key]: undefined });
  }, [setQuery]);

  const onResetFilter = () => {
    setQuery({ smartSearch: undefined, id: undefined });
  };

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined) {
        tags.push({
          key: key,
          value: value,
          label: value,
          onRemove: () => onRemoveFilter(key)
        })
      }
    });

    return tags;
  }, [query, onRemoveFilter]);

  return (
    <PageContent title="Groups">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col  xs="12" md="6">
              <h2 className="text-dark">All Groups</h2>
            </Col>
            <Col  xs="12" md="6" className="d-flex justify-content-between justify-content-lg-end align-items-center">
              <PrimeSearchInput
                ref={searchRef}
                block
                onFilter={onSearch}
                size="sm"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
              <Can I="do" this={ADD_NEW_GROUP}>
                {() => (
                  <ButtonLinkIcon
                    label="Add New"
                    onClick={() => setModalAdd(!modalAdd)}
                  />
                )}
              </Can>
            </Col>
          </Row>
          {(selectedRows && selectedRows.length > 0) && (
            <GroupHeaderTable selectedRows={selectedRows} onDelete={handleMultipleDelete} />
          )}
          <Row className="mb-2">
            <Col>
              <Tags tags={filterTags} onReset={onResetFilter} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={data}
                columns={columns}
                defaultSortField="name"
                keyField="id"
                onSelectedRowsChange={handleRowSelected}
                striped
                noHeader
                highlightOnHover
                selectableRows
                clearSelectedRows={toggleCleared}
                pagination
                paginationServer
                customStyles={customStyles}
                persistTableHead
                progressPending={isLoading}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={pageSize}
                onChangePage={onChangePage}
                sortServer
                onSort={onSort}
                overflowY={true}
                overflowYOffset="350px"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalAddGroup isOpen={modalAdd} toggle={() => setModalAdd(!modalAdd)} />
      <ModalEditGroup isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)} group={selectedGroup} />
    </PageContent>
  );
};

export default GroupList;
