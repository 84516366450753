import { useSetFeaturesToRoleMutation } from "features/user/service/roleApi";
import { useCallback } from "react";

export function useUpdateRoleFeatures() {
  const [update, result] = useSetFeaturesToRoleMutation();

  const handleUpdate = useCallback(
    async (roleId, featureIds) => {
      await update({ roleId, featureIds });
    },
    [update]
  );

  return {
    handleUpdate,
    update,
    ...result,
  };
}
