import React from 'react';
import classNames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import './TritronikMultiSelect.scss';
import { Label } from 'reactstrap';

const TritronikMultiSelect = ({ name, options, optionLabel, optionValue, label, required, ...props }) => {
  return (
    <Field name={name}>
      {({ field: { onBlur, ...fields }, form, meta }) => (
        <div className="form-group mb-0 w-100">
          {label && <Label className="control-label">{label}{required ? <span className="text-danger ml-1">*</span> : null}</Label>}
          <MultiSelect
            {...fields}
            {...props}
            optionLabel={optionLabel}
            optionValue={optionValue}
            options={options}
            className={classNames("form-control-sm", { "p-invalid": Boolean(meta.touched && meta.error)})}
          />
          {meta.touched && meta.error && <small className="p-invalid p-d-block text-warning">{meta.error}</small>}
        </div>
      )}
    </Field>
  )
}

TritronikMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
};

TritronikMultiSelect.defaultProps = {
  optionLabel: 'label',
  optionValue: 'value'
};

export default TritronikMultiSelect;
