/*eslint no-unused-vars: */
import React, { useMemo } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { momentLastUpdate, unknown } from "../../../utils";
import { useGetUserActivitiesQuery } from "../service/userActivityApi";
import { usePagination } from "./../../../hooks/usePagination";

const LIST_MODULE = {
  ALERT: "/admin/alerts/configuration",
  AREA: "/admin/areas",
  AUTHENTICATION: "/admin/users",
  CUSTOMER: "/admin/customers",
  DEVICE: "/admin/devices",
  NOTIFICATION: "/admin/notifications",
  REGION: "/admin/regions",
  ROLE: "ROLE",
  "SIM CARD": "/admin/devices",
  USER: "/admin/users",
};

const ACTIVE_MODULES = [
  'ALERT',
  'AUTHENTICATION',
  'CUSTOMER',
  'DEVICE',
  'NOTIFICATION',
  'USER',
  'SIM CARD',
];

export const useUserActivityDatatable = (params = {}) => {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = usePagination({ pageNumber: 1, pageSize: 10, sortFields: 'activityDate', sortOrder: 'desc' });

  const { data: paginatedResponse, isLoading, refetch } = useGetUserActivitiesQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : undefined,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    ...params,
  });

  const data = useMemo(() => {
    return paginatedResponse?.content ?? [];
  }, [paginatedResponse]);

  const totalRows = useMemo(() => {
    if (!paginatedResponse) return 0;

    return paginatedResponse.totalElements;
  }, [paginatedResponse]);

  const renderActivity = ({ entityType, entityId, activity }) => {
    if (_.toLower(activity).includes("delete")) {
      return activity || unknown;
    }
    if (ACTIVE_MODULES.includes(entityType)) {
      if (entityType === 'USER' || entityType === 'AUTHENTICATION') {
        return <Link to={`${LIST_MODULE[entityType]}?username=${entityId}`} className="text-default">{activity}</Link>
      }
      return <Link to={`${LIST_MODULE[entityType]}?id=${entityId}`} className="text-default">{activity}</Link>
    }
    return activity || unknown;
  }

  const columns = useMemo(() => {
    return [
      
      {
        name: 'TIME',
        sortable: true,
        cell: ({ activityDate }) => momentLastUpdate(activityDate)
      },
      {
        name: 'USERNAME',
        selector: row => row?.username,
        sortable: true,
        sortField: 'username',
      },
      {
        name: 'MODULE',
        selector: row => row?.module,
        sortable: true,
        sortField: 'module',
      },
      {
        name: 'ACTIVITY',
        selector: row => row?.activity,
        sortable: true,
        sortField: 'activity',
        cell: (row) => renderActivity(row)
      },
      {
        name: 'FIELD',
        selector: row => row?.field,
        sortable: true,
        sortField: 'field',
        cell: ({ field }) => field || unknown
      },
      {
        name: 'BEFORE',
        selector: row => row?.beforeValue,
        sortable: true,
        sortField: 'beforeValue',
        wrap: true,
        cell: ({ beforeValue }) => beforeValue || unknown
      },
      {
        name: 'AFTER',
        selector: row => row?.afterValue,
        sortable: true,
        sortField: 'afterValue',
        wrap: true,
        cell: ({ afterValue }) => afterValue || unknown
      },
      {
        name: 'IP ADDRESS',
        selector: row => row?.clientIpAddress,
        sortable: true,
        sortField: 'clientIpAddress',
        cell: ({ clientIpAddress }) => clientIpAddress || unknown
      },
    ];
  }, []);

  return {
    data,
    columns,
    isLoading,
    pageSize,
    pageNumber,
    sortFields,
    sortOrder,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
    refetch
  };
};
