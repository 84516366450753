import React from 'react'

const CustomExpandableIcon = ({type}) => {
  return type === 'collapsed' ?
  (
    <i className="fa fa-chevron-down text-default"></i>
  ) : (
    <i className="fa fa-chevron-up text-default"></i>
  )
}

export default CustomExpandableIcon;
