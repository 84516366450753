import { useAuth } from "./../../auth/hooks";
import { useGetReportTemplatesQuery } from "../service/reportApi";
import { useMemo } from "react";

export function useReportTemplates(reportType) {
  const { user } = useAuth();
  const { data, isLoading } = useGetReportTemplatesQuery({ ownerType: 'USER', ownerId: user.username }, { skip: !user.username });


  const templates = useMemo(() => {
    if (!data?.content) return [];

    return data.content && data.content.filter((tpl) => tpl.type === reportType);
  }, [data, reportType]);

  const templateOptions = useMemo(() => {
    const opts = [];

    if (templates && templates.length > 0) {
      templates.forEach((tpl) => {
        opts.push({ value: tpl.id, label: tpl.name });
      });
    }
    return opts;
  }, [templates]);

  return { templates, templateOptions, isLoading };
}