import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import _ from "lodash";

import { DeviceOverviewSimCard } from "./DeviceOverviewSimCard";
import { useVerifySimCardMutation } from "./../../service/deviceApi";
import { FieldArray } from "formik";
import { DeviceOverviewSimCardConfiguration } from "./DeviceOverviewSimCardConfiguration";
import { useDebounce } from "hooks/useDebounce";
import { useGetSimCardByCircuitNumberMutation } from "features/inventory/simcards";

const SimCardOverview = ({
  simCards,
  deviceId,
  isEditing,
  values,
  setFieldValue,
}) => {
  const [verifySimCard, { isLoading: isVerifying }] =
    useVerifySimCardMutation();
  const [getSimcard] = useGetSimCardByCircuitNumberMutation();

  const onVerifySimCarcClick = async (simCardId) => {
    await verifySimCard({ deviceId, simCardId }).unwrap();
  };

  const debounceValues = useDebounce(values, 1000);

  useEffect(() => {
    const promises = [];

    if (
      debounceValues &&
      debounceValues.simCards &&
      debounceValues.simCards.length > 0
    ) {
      debounceValues.simCards.forEach(async (sim) => {
        if (sim.circuitNumber && sim.circuitNumber !== "") {
          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                const simCard = await getSimcard(sim.circuitNumber).unwrap();
                resolve(simCard);
              } catch (error) {
                reject(error);
              }
            })
          );
        }
      });

      if (promises.length > 0) {
        Promise.all(promises).then((result) => {
          if (result && result.length > 0) {
            result.forEach((simCard, index) => {
              if (simCard.gsmNumber && simCard.gsmNumber !== "") {
                if (typeof setFieldValue === "function") {
                  setFieldValue(
                    `simCards.${index}.gsmNumber`,
                    simCard.gsmNumber
                  );
                }
              }
            });
          }
        });
      }
    }
  }, [deviceId, getSimcard, debounceValues, setFieldValue]);

  if (isEditing) {
    return (
      <div>
        <h2 className="text-dark mt-3 ml--3">SIM Overview</h2>
        <Row className="mt-3">
          <Col>
            <FieldArray name="simCards">
              {() => {
                return (
                  values.simCards &&
                  values.simCards.length > 0 &&
                  values.simCards.map((simCard, i) => (
                    <div key={simCard.id}>
                      <DeviceOverviewSimCardConfiguration
                        formIndex={i}
                        simCard={simCard}
                        verifySimCard={onVerifySimCarcClick}
                        isVerifying={isVerifying}
                      />
                      {i < simCards.length - 1 && <hr />}
                    </div>
                  ))
                );
              }}
            </FieldArray>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-dark mt-3 ml--3">SIM Overview</h2>
      <Row className="mt-3">
        <Col>
          {simCards &&
            _.orderBy(simCards, [(o) => o.slotNumber]).map((simCard, i) => (
              <div key={simCard.id}>
                <DeviceOverviewSimCard
                  simCard={simCard}
                  verifySimCard={onVerifySimCarcClick}
                  isVerifying={isVerifying}
                />
                {i < simCards.length - 1 && <hr />}
              </div>
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default SimCardOverview;
