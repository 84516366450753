import React, { useState } from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { NavItemContent } from "../../../../components/molecules/NavItemContent";
import { RegisteredOnuOverviewTab } from "../registeredOnuOverview";
import { RegisteredOnuConfiguration } from "../registeredOnuConfiguration";
import { RegisteredOnuSchedule } from "../registeredOnuSchedule";

const RegisteredOnuTableDetail = (props) => {
  const { data } = props;
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav tabs className="row">
        <NavItem className="col">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            <NavItemContent
              title="Overview"
              icon="fa fa-info-circle"
              active={activeTab === "1"}
            />
          </NavLink>
        </NavItem>
        <NavItem className="col">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            <NavItemContent
              title="Configuration"
              icon="fa fa-sliders-h"
              active={activeTab === "2"}
            />
          </NavLink>
        </NavItem>
        <NavItem className="col">
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            <NavItemContent
              title="Alert"
              icon="fa fa-exclamation-triangle"
              active={activeTab === "3"}
            />
          </NavLink>
        </NavItem>
        <NavItem className="col">
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            onClick={() => toggle("4")}
          >
            <NavItemContent
              title="Schedule"
              icon="fas fa-clock"
              active={activeTab === "4"}
            />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <RegisteredOnuOverviewTab onu={data} />
        </TabPane>
        <TabPane tabId="2">
          <RegisteredOnuConfiguration onuId={data.id}/>
        </TabPane>
        <TabPane tabId="3">
          <center className="mt-5 mb-5">There are No Records to Display</center>
        </TabPane>
        <TabPane tabId="4">
          <RegisteredOnuSchedule onuId={data.id}/>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default RegisteredOnuTableDetail;
