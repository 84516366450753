import React from "react";
import ReactGA from "react-ga";
import NiceModal from "@ebay/nice-modal-react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from "use-query-params";
import { AdminLayout, AuthLayout, PrivateRoute } from "./components";
import { GA_ID } from "./config";
import UserMiddleware from "./features/auth/components/UserMiddleware";
import { history } from "./utils";

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

const App = () => {
  history.listen((location) => {
    ReactGA.initialize(GA_ID);
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <>
      <NiceModal.Provider>
        <Router history={history}>
          <QueryParamProvider
            ReactRouterRoute={Route}
            stringifyOptions={queryStringifyOptions}
          >
            <UserMiddleware>
              <Switch>
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
                <PrivateRoute path="/admin" component={AdminLayout} />
                <Redirect from="/" to="/admin/dashboard" />
              </Switch>
            </UserMiddleware>
          </QueryParamProvider>
        </Router>
        <ToastContainer />
      </NiceModal.Provider>
    </>
  );
};

export default App;
