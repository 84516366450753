import React, { useMemo } from "react";
import { Widget02 } from "features/dashboard/components";
import { useGetLicenseSummaryQuery } from "../services/licenseApi";
import { StringParam, useQueryParams } from "use-query-params";

const OverviewCards = () => {
  const { data, isLoading } = useGetLicenseSummaryQuery();
  // eslint-disable-next-line no-unused-vars
  const [queryParams, setQuery] = useQueryParams({
    filterBy: StringParam
  });

  const onClickWidget = (key) => {
    switch (key) {
      case "nearlyExpired":
      case "newLicense":
        setQuery({ filterBy: key });
        break;
      default:
        setQuery({ filterBy: undefined });
        break;
    }
  };

  const summary = useMemo(() => {
    if (!data) {
      return {
        totalLicense: 0,
        totalAvailable: 0,
        totalNearlyExpiry: 0,
        totalNewAvailable: 0,
      };
    }

    const {
      totalLicense,
      totalAvailable,
      totalNearlyExpiry,
      totalNewAvailable,
    } = data;
    return {
      totalLicense,
      totalAvailable,
      totalNearlyExpiry,
      totalNewAvailable,
    };
  }, [data]);

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <Widget02
          loading={isLoading}
          title="Available License"
          value={summary.totalAvailable}
          comparison={summary.totalLicense}
          valueClassName="text-success"
          onClickComparison={() => onClickWidget("totalLicense")}
        />
      </div>
      <div className="col-12 col-md-4">
        <Widget02
          loading={isLoading}
          title="Nearly Expired"
          value={summary.totalNearlyExpiry}
          onClick={() => onClickWidget("nearlyExpired")}
        />
      </div>
      <div className="col-12 col-md-4">
        <Widget02
          loading={isLoading}
          title="New License"
          value={summary.totalNewAvailable}
          onClick={() => onClickWidget("newLicense")}
        />
      </div>
    </div>
  );
};

export default OverviewCards;
