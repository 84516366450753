import React from 'react';
import { Button } from "reactstrap";
import { DELETE_AREA, Can } from '../../../config';

const AreaHeaderTable = ({ selectedRows, onDelete }) => {
  const handleDelete = () => {
    onDelete(selectedRows);
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <Can I="do" this={DELETE_AREA}>
          {() => (
            <Button key="delete" className="btn-icon btn-3" color="danger" type="button" size="sm" onClick={handleDelete}>
              <span className="btn-inner--text"><i className="fa fa-trash mr-1"></i>Delete</span>
            </Button>
          )}
        </Can>
      </div>
      <div className="text-right">
        <p>{selectedRows.length || 0} {selectedRows.length > 1 ? 'areas' : 'area'} selected.</p>
      </div>
    </div>
  )
}


export default AreaHeaderTable;