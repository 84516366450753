import { useUpdatePolicyPackageInProfileMutation } from "features/inventory/onu/service";
import { useGetLineProfileQuery } from "features/inventory/onu/service";
import { useGetListPolicyPackageQuery } from "features/package-policy/service/packagePolicyApi";
import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button } from "reactstrap";
import { RowItem, Gap, TritronikSelect } from "../../../../components";
import Swal from "sweetalert2";

const RegisteredOnuConfiguration = ({ onuId }) => {
  const { data: policyListPackage } = useGetListPolicyPackageQuery();
  const { data: lineProfile } = useGetLineProfileQuery(onuId);
  const [updatePolicyPackageInProfile] =
    useUpdatePolicyPackageInProfileMutation();
  const init = lineProfile?.policyPackage?.id;
  const [selectedPackage, setSelectedPackage] = useState(
    lineProfile?.policyPackage
  );

  const policyListOptions = useMemo(() => {
    if (!policyListPackage) return [];

    return policyListPackage.map((item) => {
      return { label: item.packageName, value: item.id, data: item };
    });
  }, [policyListPackage]);

  const [selectedPolicyPackageId, setSelectedPolicyPackageId] = useState();

  useEffect(() => {
    setSelectedPolicyPackageId(init);
    setSelectedPackage(policyListOptions.find((pkg) => pkg.value === init));
  }, [init, policyListOptions]);

  const onApply = (id, policyPackageId) => {
    Swal.fire({
      text: "Are You Sure want to Apply Configuration?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if(result.isConfirmed) {
        updatePolicyPackageInProfile({ id:id, policyPackageId: policyPackageId})
          .unwrap()
          .then(() =>
              Swal.fire(
                "Success!",
                "Your configuration has been added.",
                "success"
              )
            )
            .catch(() =>
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
              })
            );
      }
    })
  };

  const onPolicyChange = (e) => {
    setSelectedPolicyPackageId(e.value);
    const packagePolicy = policyListOptions.find(
      (pkg) => pkg.value === e.value
    );
    setSelectedPackage(packagePolicy);
  };

  return (
    <Row className="mt-4">
      <Col md="6" sm="12" className="px-5 border-right">
        <Row>
          <Col>
            <h2 className="text-dark ml--3">Package Setting</h2>
            <Gap height={10} />
            <RowItem
              stripped
              label="Package"
              value={
                <TritronikSelect
                  name="packagePolicy"
                  onChange={onPolicyChange}
                  value={selectedPackage?.value}
                  options={policyListOptions}
                />
              }
            />
            <RowItem
              label="Downstream"
              offset
              value={selectedPackage?.data?.policyDownstream?.name}
            />
            <RowItem
              label="Upstream"
              stripped
              offset
              value={selectedPackage?.data?.policyUpstream?.name}
            />
            <RowItem
              value={
                <Button
                  className="px-5 py-1"
                  color="default"
                  size="sm"
                  onClick={() => onApply(onuId, selectedPolicyPackageId)}
                >
                  Apply
                </Button>
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RegisteredOnuConfiguration;
