import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import * as Yup from "yup";
import {
  Gap,
  Loading,
  TritronikInputText,
  TritronikMultiSelect,
  TritronikSelect
} from "../../../components";
import { useCreateAreaMutation, useSetAreaToGroupMutation, useSetAreaToRegionMutation } from './../service/areaApi';


export default function ModalAddArea({ isOpen, toggle, groupOptions, regionOptions }) {
  const [createArea, { isLoading }] = useCreateAreaMutation();
  const [setAreaToGroup] = useSetAreaToGroupMutation();
  const [setAreaToRegion] = useSetAreaToRegionMutation();

  const onSubmit = async ({ name, regionId, groupIds, description }) => {
    createArea({ id: 0, name, description })
      .unwrap()
      .then(async ({ id }) => {
        if (groupIds && groupIds.length > 0) {
          await groupIds.forEach(async (groupId) => {
            await setAreaToGroup({ groupId, areaId: id }).unwrap();
          });
        }
        if (regionId) {
          await setAreaToRegion({ regionId, areaId: id }).unwrap();
        }
        toast.success(`Area ${name} created.`);
        toggle();
      });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={isLoading}
    >
      <Formik
        initialValues={{
          name: "",
          description: "",
          regionId: '',
          groupIds: [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Area name is required."),
          regionId: Yup.string().required('Select region for this area.')
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, errors, touched, setFieldValue, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h3 className="modal-title" id="ModalAddArea">
                Add New Area
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body py-2">
              <TritronikInputText
                label="Area Name"
                name="name"
                placeholder="Area Name"
                small
                required
              />
              <Gap height={15} />
              <TritronikSelect
                name="regionId"
                label="Region"
                required
                placeholder="Select Region"
                options={regionOptions}
                value={values.regionId}
                invalid={Boolean(errors.regionId && touched.regionId)}
                error={errors.regionId}
                onChange={(e) => {
                  setFieldValue('regionId', e.value);
                }}
              />
              <Gap height={15} />
              <TritronikMultiSelect
                name="groupIds"
                placeholder="Add Group"
                label="Group"
                options={groupOptions}
              />
              <Row className="mt-4">
                <Col className="text-center">
                  <Button block color="default" type="submit" size="sm" disabled={isLoading || !dirty}>
                    Create
                    {isLoading && <Loading className="ml-2" />}
                  </Button>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="text-center">
                  <Button
                    block
                    color="secondary"
                    type="button"
                    size="sm"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
