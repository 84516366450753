import React, { useState } from "react";
import { PageContent} from "components";
import { Card, CardBody, Row, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import { customStyles } from "utils/datatableStyles";
import { useGetRouterPolicyQuery } from "../service/routerPolicyApi";

const RouterPolicy = () => {
  const [title] = useState("Router Policy");
  const { data: routerPolicies, isLoading } = useGetRouterPolicyQuery();

  const columns = [
    {
      name: "NAME",
      center: true,
      hide: "md",
      selector: (row) => row?.policyName,
    },
    {
      name: "BANDWIDTH LIMIT",
      center: true,
      hide: "md",
      selector: (row) => row?.bandwidthLimit,
    },
    {
      name: "BURST SIZE LIMIT",
      center: true,
      hide: "md",
      selector: (row) => row?.burstSizeLimit,
    },
  ];

  return (
    <PageContent title="Router Policy">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <h2 className="text-dark">{title}</h2>
            </Col>
          </Row>
          <DataTable
            title={title}
            columns={columns}
            data={routerPolicies?.content}
            striped
            noHeader
            highlightOnHover
            pagination
            persistTableHead
            customStyles={customStyles}
            progressPending={isLoading}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default RouterPolicy;
