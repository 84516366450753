import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";

import { useGetDataIntegrityHistoryQuery } from "features/device/service/usageApi";

function DataIntegrityChart(props) {
  const { data, isLoading, isFetching } = useGetDataIntegrityHistoryQuery(props);
  const { timeGroupping } = props;

  const minValueSpan = useMemo(() => {
    const arrTime = timeGroupping.match(/[a-zA-Z]+|[0-9]+/g);
    const timeMap = {
      m: 60,
      h: 3600,
      d: 86400,
    };

    if (arrTime) {
      const counter = Number(arrTime[0]);
      const multiplier = timeMap[arrTime[1]];

      return counter * multiplier * 1000;
    }

    return undefined;
  }, [timeGroupping]);

  const options = useMemo(() => {
    const series = [];
    let unit = "";

    if (data && data.datasets && data.datasets.length > 0) {
      data.datasets.forEach((ds) => {
        series.push({
          type: "line",
          symbol: "none",
          name: ds.label,
          data: ds.data.map((d, i) => {
            return {
              name: data.labels[i],
              value: [data.timestamps[i], d],
            };
          }),
          ...(ds.threshold && {
            markLine: {
              silent: true,
              lineStyle: {
                color: "#F90100",
              },
              data: [
                {
                  yAxis: ds.threshold,
                  label: {
                    formatter: `{c} ${ds.unit}`,
                  },
                },
              ],
            },
          })
        });
      });
      unit = data.datasets[0].unit;
    }

    return {
      title: {
        text: 'Undelivered Data'
      },
      legend: {
        show: false,
        top: 10,
      },
      dataZoom: [
        {
          type: "inside",
          rangeMode: ["value", "value"],
          ...(minValueSpan ? { minValueSpan } : {}),
        },
      ],
      grid: {
        top: 50,
        left: "10%",
        right: "10%",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
        valueFormatter: (value) => {
          return value || value === 0 ? value?.toFixed(2) + unit : "-";
        },
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: `{value} ${unit}`,
          },
        },
      ],
      xAxis: [
        {
          type: "time",
          axisLabel: {
            hideOverlap: true,
            formatter: {
              year: "{yyyy}",
              month: "{MMM} {yyyy}",
              day: "{d} {MMM}",
              hour: "{HH}:{mm}",
              minute: "{HH}:{mm}",
              second: "{HH}:{mm}:{ss}",
              millisecond: "{hh}:{mm}:{ss} {SSS}",
              none: "{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}",
            },
          },
          axisLine: {
            onZero: false,
          },
          interval: "auto",
          minInterval: minValueSpan,
        },
      ],
      series: series,
    };
  }, [data, minValueSpan]);

  return (
    <ReactECharts
      option={options}
      showLoading={isLoading || isFetching}
      style={{ height: "100%", width: "100%" }}
    />
  );
}

DataIntegrityChart.propTypes = {
  startTs: PropTypes.number.isRequired,
  endTs: PropTypes.number.isRequired,
  timeGroupping: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  sn: PropTypes.string,
  function: PropTypes.string,
};

DataIntegrityChart.defaultProps = {
  timeGroupping: "5m",
  status: 'undelivered',
  name: undefined,
  sn: undefined,
  function: undefined,
};

export default DataIntegrityChart;