import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap';

import CombinationChart from '../charts/CombinationChart';
import { usePerformanceDashboard } from '../../context';
import { useGetMaxPingRTTQuery } from 'features/dashboard/service/dashboardApi';
import { Tooltip } from 'primereact/tooltip';

const MaxPingRTTChart = () => {
  const { filters } = usePerformanceDashboard();
  const { data, isLoading, isFetching } = useGetMaxPingRTTQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    regionAsString: filters.region ?? undefined,
  }, {
    skip: !filters.startTs || !filters.endTs
  });
  const tooltipStyle = {
    maxWidth: 'fit-content', 
    whiteSpace: 'nowrap'
  };
  return (
    <Card>
      <CardHeader className='border-0'>
          <h4>Max Ping RTT
            <i className='mdi mdi-help-circle ml-2 custom-target-icon2'></i>
            <Tooltip target=".custom-target-icon2" position="right" content="Biggest RTT value per online device" style={tooltipStyle}/>
          </h4>
      </CardHeader>
      <CardBody style={{ height: 250 }}>
        <CombinationChart data={data} unit='ms' isLoading={isLoading || isFetching} threshold={100}/>
      </CardBody>
    </Card>
  )
}

export default MaxPingRTTChart;