import classNames from "classnames";
import React from "react";
import { NavItem, NavLink, Spinner } from "reactstrap";

const CustomNavItem = ({ text, count, active = false, onClick, loading = false}) => {
  return (
    <NavItem
      className={classNames("rounded-0 cursor-pointer", {
        "border-bottom-2 border-primary": active,
      })}
    >
      <NavLink
        className={classNames("px-3 py-2 rounded-0", {
          "active text-default": active,
        })}
        style={!active ? { color: '#8898AA' } : {}}
        onClick={onClick}
      >
        <span>
          <span className={classNames("font-weight-bold")} style={{ fontSize: '14px' }}>{text}</span>
            {loading ? (
              <Spinner size="sm" color="primary" className="ml-2" />
            ) : (
              count ? (
                <span className="badge badge-pill badge-danger ml-2">{count}</span>
              ) : null
            )}
        </span>
      </NavLink>
    </NavItem>
  );
};

export default CustomNavItem;
