import classnames from 'classnames';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Col, DropdownMenu, DropdownToggle, Form, Nav, Row, Spinner, UncontrolledDropdown } from 'reactstrap';
import { FormikToggleSwitch, Gap } from '../../../components';
import { Can, CONFIGURE_ALERT_RULE, DELETE_ALERT_RULE } from '../../../config';


const ActionItem = ({ label, value }) => {
  return (
    <div className="row d-flex justify-content-between align-items-center border-bottom mx-2 py-2">
      <div className="col-4 p-0">
        <span className="text-sm text-default font-weight-bold">{label}</span>
      </div>
      <div className="col-8 p-0 d-flex justify-content-end">
        {value}
      </div>
    </div>
  )
}

export const TableActionButton = ({row, onDeleteClick, onUpdateConfiguration }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const { values, handleSubmit, setFieldValue, handleReset, dirty, isSubmitting } = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: row.status || 'Deactivated'
    },
    onSubmit: (values) => onUpdateConfiguration(values, row),
  });

  return (
    <div>
      <Nav navbar>
        <UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
          <DropdownToggle nav>
            <i className="fas fa-ellipsis-v text-default" />
          </DropdownToggle>
          <DropdownMenu right>
            <div style={{ width: 350 }} className="p-2">
              <Form onSubmit={handleSubmit} onReset={handleReset}>
                <ActionItem
                  label="Change Status"
                  value={(
                    <div className="d-flex align-items-center">
                      <span className={classnames("mr-2", {
                        "text-danger": _.toLower(values.status) === 'deactivated',
                        "text-success": _.toLower(values.status) === 'activated',
                      })}>
                        {_.startCase(values.status)}
                      </span>
                      <Can I="do" this={CONFIGURE_ALERT_RULE} passThrough>
                        {(allowed) => (
                          <FormikToggleSwitch 
                            checked={_.toLower(values.status) === 'activated'} 
                            value={values.status} 
                            onChange={(e) => setFieldValue('status', e.target.checked ? 'Activated' : 'Deactivated')} 
                            disabled={!allowed}
                          />
                        )}
                      </Can>
                    </div>
                  )}
                />
                <Gap height={10} />
                <Row className="mx-2 mt-2">
                  <Col className="text-center p-0">
                    <Can I="do" this={CONFIGURE_ALERT_RULE} passThrough>
                      {(allowed) => (
                        <Button className="m-0" block color="default" size="sm" type="submit" disabled={isSubmitting || !dirty || !allowed}>
                          Apply
                          {isSubmitting && <Spinner className="ml-2" color="light" size="sm" />}
                        </Button>
                      )}
                    </Can>
                    <Button className="m-0 mt-2" block color="secondary" size="sm" onClick={() => setMenuOpen(false)}>Cancel</Button>
                    <Can I="do" this={DELETE_ALERT_RULE}>
                      {() => (
                        <Button className="m-0 mt-2" block color="danger" size="sm" onClick={onDeleteClick}>
                          Delete Alert
                        </Button>
                      )}
                    </Can>
                  </Col>
                </Row>
              </Form>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </div>
  );
}
