import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { confirmAction } from "../../../utils/swal";
import { useUpdateAlertSubscriptionByUsernameMutation } from "../service/alertSubscriptionApi";

export const useAlertSubscription = () => {

  const [updateAlertSubscriptionByUsername] = useUpdateAlertSubscriptionByUsernameMutation();

  const changeAlertSubscriptionByUsername = (username, subscribe) => {
    const action = subscribe ? 'Allow' : 'Disallow';
    return confirmAction({
      text: `${action} All Alert`,
      preConfirm: async () => {
        return updateAlertSubscriptionByUsername({ username, subscribe, body: {} }).unwrap().then(() => {
          toast.success(`Alert Subscriptions Updated.`);
          return true;
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  return { changeAlertSubscriptionByUsername };
}