import React, { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';

import { formatBits } from './../../../../utils';
import AggregateTable from '../AggregateTable';
import { useAggregate } from './../../hooks/useAggregate';

const DeviceBandwidthUsageChart = ({ data, simCards }) => {
    const { calculateAggregate } = useAggregate();

    const { options, aggregated } = useMemo(() => {
        const series = [];
        const aggregated = [];

        const getSimCardByImsi = (imsi) => {
            return simCards && _.find(simCards, { 'imsi': imsi });
        }


        if (data && data.datasets && data.datasets.length > 0) {
            data.datasets.forEach(dt => {
                const dataSeries = [];

                const labels = dt.label.split('-');
                const simCard = getSimCardByImsi(labels[1]);
                const simLabel = simCard ? `${dt.label} (SIM ${simCard.slotNumber})` : dt.label;

                const datas = dt.data;

                const agg = calculateAggregate(datas);

                if (agg !== null) {
                    aggregated.push({
                        name: simLabel,
                        min: formatBits(agg.min),
                        max: formatBits(agg.max),
                        avg: formatBits(agg.avg),
                        total: formatBits(agg.sum),
                    })
                }

                data.timestamps && data.timestamps.forEach((ts, i) => {
                    dataSeries.push([ts, dt.data[i] || null]);
                });

                series.push({
                    name: simLabel,
                    data: dataSeries,
                    type: 'line',
                    symbol: 'none',
                    smooth: false,
                })
            });
        }

        const options = {
            title: {
                show: true
            },
            legend: {
                show: true
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                },
                {
                    start: 0,
                    end: 100
                }
            ],
            xAxis: {
                type: 'time',
                boundaryGap: false,
                axisLabel: {
                    formatter: {
                        year: '{yyyy}',
                        month: '{MMM} {yyyy}',
                        day: '{d} {MMM}',
                        hour: '{HH}:{mm}',
                        minute: '{HH}:{mm}',
                        second: '{HH}:{mm}:{ss}',
                        millisecond: '{hh}:{mm}:{ss} {SSS}',
                        none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: (value) => formatBits(value)
                },
                min: 'dataMin',
                max: 'dataMax'
            },
            grid: {
                left: '5%',
                right: '5%',
            },
            series: series
        };

        return { options, aggregated };
    }, [data, simCards, calculateAggregate]);

    return (
        <Card>
            <CardBody style={{ width: '100%', height: '100%' }}>
                <ReactECharts option={options} style={{ width: '100%', height: 400 }} />
                {aggregated && aggregated.length > 0 && <AggregateTable data={aggregated} columns={['IMSI', 'Min', 'Max', 'Avg', 'Total']} /> }
            </CardBody>
        </Card>
    )
}

export default DeviceBandwidthUsageChart
