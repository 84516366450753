import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { deleteNetworkThunk } from "../networkSlice";

export function useDeleteNetwork() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const deleteNetwork = useCallback(async (id) => {
		setIsLoading(true);
		await dispatch(deleteNetworkThunk(id));
		setIsLoading(false);
	}, [dispatch]);

	return {
		deleteNetwork,
		isLoading
	}
}