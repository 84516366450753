import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux"
import { authApi, useLogoutMutation } from "../service/authApi";
import { authSlice } from "./../../auth/reducers/authSlice";

export function useAuth() {
  const dispatch = useDispatch();
  const [doLogout] = useLogoutMutation();

  const { token, username } = useSelector(state => state.auth);

  const { data: user } = authApi.endpoints.getProfile.useQueryState(username);

  const isAdmin = useMemo(() => {
    if (!user) return false;

    return user.roleNames.includes('Super Admin');
  }, [user]);


  const logout = useCallback(async () => {
    return doLogout(token).unwrap().then(() => {
      dispatch(authSlice.actions.logout());
    })
  }, [dispatch, doLogout, token]);

  return {
    token,
    user,
    username,
    isAdmin,
    logout,
  }
}