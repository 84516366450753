import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Swal from "sweetalert2";
import { StringParam, useQueryParams } from "use-query-params";
import { ButtonLinkIcon, PageContent, PrimeSearchInput, Tags } from "../../../components";
import { Can, CREATE_CUSTOMER, DELETE_CUSTOMER } from "../../../config";
import { CustomActionButton, ModalAddCustomer } from "../components";
import ModalEditCustomer from "../components/ModalEditCustomer";
import { useCustomers } from "../hooks";
import { useCustomerDatatable } from "../hooks/useCustomerDatatable";
import { useDeleteCustomerMutation } from "../service/customerApi";
import { useOptions } from './../../../hooks';
import { history, paginationRowsPerPageOptions } from './../../../utils';
import { CommaArrayParam } from './../../../utils/queryParams';
import { useArea } from "./../../area/hooks";
import { useGroups } from './../../group/hooks';
import { useRegion } from './../../region/hooks';
import { customStyles } from "./../../../utils/datatableStyles";

const CustomerHeaderTable = ({ selectedRows, onDelete }) => {
  const handleDelete = () => {
    onDelete(selectedRows);
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <Can I="do" this={DELETE_CUSTOMER}>
          {() => (
            <Button key="delete" className="btn-icon btn-3" color="danger" type="button" size="sm" onClick={handleDelete}>
              <span className="btn-inner--text"><i className="fa fa-trash mr-1"></i>Delete</span>
            </Button>
          )}
        </Can>
      </div>
      <div className="text-right">
        <p>{selectedRows.length || 0} {selectedRows.length > 1 ? 'customers' : 'customer'} selected.</p>
      </div>
    </div>
  )
}

const CustomerList = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const searchRef = useRef();
  const [query, setQuery] = useQueryParams({
    groupIds: CommaArrayParam,
    regionIds: CommaArrayParam,
    areaIds: CommaArrayParam,
    ids: CommaArrayParam,
    smartSearch: StringParam,
  });

  const {
    data,
    isLoading,
    totalRows,
    pageSize,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useCustomerDatatable(query);

  const [deleteCustomer] = useDeleteCustomerMutation();
  const { getLabelOptions } = useOptions();

  const { options: groupOptions } = useGroups();
  const { options: regionOptions } = useRegion({}, false);
  const { options: customerOptions } = useCustomers();
  const { options: areaOptions } = useArea({}, false);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.changeValue(query.smartSearch || '');
    }
  }, [query]);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const onDeleteCustomer = useCallback((customer) => {
    Swal.fire({
      title: "Are you sure want to delete this?",
      text: `Customer: ${customer.name}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return deleteCustomer(customer.id)
          .unwrap()
          .then(() => {
            toast.success("Customer deleted.");
            return true;
          });
      },
    });
  }, [deleteCustomer]);

  const handleMultipleDelete = useCallback((customers) => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure you want to delete this?',
      text: `Customers: ${customers.map(cust => cust.name)}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      reverseButtons: true,
      preConfirm: async () => {
        await customers.forEach(async (cust) => {
          await deleteCustomer(cust.id);
        });
        return true;
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.value) {
        toast.success('Customers deleted.');
        setToggleCleared(true);
      }
    });
  }, [deleteCustomer, setToggleCleared]);

  const onEditCustomer = useCallback(
    (customer) => {
      setModalEdit(true);
      setSelectedCustomer(customer);
    },
    [setModalEdit, setSelectedCustomer],
  );

  const columns = useMemo(() => {
    const goToPath = (path, queryParams) => {
      history.push({
        pathname: query.regionIds ? `${path}/region/${query.regionIds}` : path,
        search: `?${queryParams}`
      });
    }

    const renderLink = (text, path, queryParams) => {
      if (text === 0) {
        return 0;
      }

      return (
        <a href="#link" className="text-default" onClick={(e) => {
          e.preventDefault();
          goToPath(path, queryParams);
        }}>
          {text}
        </a>
      );
    }

    return [
      {
        name: 'CUSTOMER NAME',
        selector: row => row?.name,
        sortable: true,
        sortField: 'name',
        left: true,
      },
      {
        name: 'TOTAL DEVICES',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ customerSummary, id }) => renderLink(customerSummary?.totalDeviceIds, '/admin/devices', `customerIds=${id}`)
      },
      {
        name: 'CONNECTED DEVICES',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ customerSummary, id }) => renderLink(customerSummary?.totalConnectedDeviceIds, '/admin/devices', `connectionStatus=Connected&customerIds=${id}`),
      },
      {
        name: 'REACH QUOTA LIMIT',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ customerSummary, id }) => renderLink(customerSummary?.totalReachQuotaLimitSimCardIds, '/admin/devices', `reachQuotaLimit=true&customerIds=${id}`)
      },
      {
        name: 'SIM DEACTIVATED',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ customerSummary, id }) => renderLink(customerSummary?.totalDeactivatedSimCardIds, '/admin/devices', `simCardStatus=Deactivated&customerIds=${id}`)
      },

      {
        name: 'TOTAL USER',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ customerSummary, id }) => renderLink(customerSummary?.totalUsernames, '/admin/users', `customerIds=${id}`),
      },
      {
        name: '',
        width: '40px',
        allowOverflow: true,
        button: true,
        cell: (row) => <CustomActionButton row={row} onDelete={onDeleteCustomer} onEdit={onEditCustomer} />,
      },
    ]
  }, [onDeleteCustomer, onEditCustomer, query]);

  const onSearch = (searchText) => {
    setQuery({ smartSearch: searchText !== "" && searchText !== null ? searchText : undefined });
  }

  const onRemoveFilter = useCallback((key = null, value = null) => {
    if (!key) return;

    if (value === null) {
      setQuery({ [key]: undefined });
    } else {
      setQuery((q) => {
        const filterQ = q[key]?.filter(v => v !== value);
        return {
          [key]: filterQ && filterQ.length > 0 ? filterQ : undefined
        }
      });
    }

  }, [setQuery]);

  const onResetFilter = useCallback(() => {
    setQuery({
      ids: undefined,
      smartSearch: undefined,
      groupIds: undefined,
      regionIds: undefined,
      areaIds: undefined,
    });
  }, [setQuery]);

  const getOptionsLabel = useCallback((key, value) => {
    switch (key) {
      case 'ids': return getLabelOptions(customerOptions, value);
      case 'groupIds': return getLabelOptions(groupOptions, value);
      case 'regionIds': return getLabelOptions(regionOptions, value);
      case 'areaIds': return getLabelOptions(areaOptions, value);
      default: return null;
    }
  }, [getLabelOptions, customerOptions, groupOptions, regionOptions, areaOptions]);

  const filterTags = useMemo(() => {
    const tags = [];

    Object.keys(query).forEach((key) => {
      const value = query[key];
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            tags.push({
              key: key,
              value: v,
              label: getOptionsLabel(key, v),
              onRemove: () => onRemoveFilter(key, v)
            })
          })
        } else {
          tags.push({
            key: key,
            value: value,
            label: value,
            onRemove: () => onRemoveFilter(key)
          })
        }
      }
    });

    return tags;
  }, [query, getOptionsLabel, onRemoveFilter]);

  return (
    <PageContent title="Customers">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col xs="12" md="6">
              <h2 className="text-dark">All Customer</h2>
            </Col>
            <Col  xs="12" md="6" className="d-flex justify-content-between justify-content-lg-end align-items-center">
              <PrimeSearchInput
                ref={searchRef}
                block
                onFilter={onSearch}
                size="sm"
                tooltip="Press 'Enter' to search."
                tooltipOptions={{ position: "top" }}
              />
              <Can I="do" this={CREATE_CUSTOMER}>
                {() => (
                  <ButtonLinkIcon
                    label="Add New"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                )}
              </Can>
            </Col>
          </Row>
          {(selectedRows && selectedRows.length > 0) && (
            <CustomerHeaderTable selectedRows={selectedRows} onDelete={handleMultipleDelete} />
          )}
          <Row className="mb-2">
            <Col>
              <Tags tags={filterTags} onReset={onResetFilter} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={data}
                columns={columns}
                defaultSortField="name"
                keyField="id"
                onSelectedRowsChange={handleRowSelected}
                striped
                noHeader
                highlightOnHover
                selectableRows
                clearSelectedRows={toggleCleared}
                pagination
                paginationServer
                customStyles={customStyles}
                persistTableHead
                progressPending={isLoading}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={pageSize}
                onChangePage={onChangePage}
                sortServer
                onSort={onSort}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalAddCustomer isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />
      <ModalEditCustomer isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)} customer={selectedCustomer} />
    </PageContent>
  );
};

export default CustomerList;
