import { useGetSignalTrendQuery } from "../service/usageApi";

export function useSignalStrengthHistory(simCards, params) {

  const { data, isLoading } = useGetSignalTrendQuery({ metric: 'rssi', params }, { skip: !(simCards && simCards.length > 0 && params.imsi !== undefined) });

  return {
    data,
    isLoading
  }
}