import React, { useState } from "react";
import { PageContent } from "components";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import { useGetOltQuery } from "./service/oltApi";
import {customStyles} from "../../../src/utils/datatableStyles"
import { history } from "utils";
// import { FilterDropdownUser } from "features/user/components";

const OLT = () => {
  const [title] = useState("All Optical Line Terminal");

  const { data: olt } = useGetOltQuery();

  const columns = [
    {
      name: "ID",
      sortable: false,
      left: true,
      selector: (row) => row?.id,
      hide: "md",
    },
    {
      name: "IP ADDRESS",
      sortable: false,
      left: true,
      selector: (row) => row?.ipAddress,
      hide: "md",
    },
    {
      name: "LABEL",
      sortable: false,
      center: true,
      selector: (row) => row?.label,
      hide: "md",
    },
    {
      name: "LOCATION",
      sortable: false,
      left: true,
      selector: (row) => row?.location,
      hide: "md",
    },
    // {
    //   name: "PASSWORD",
    //   sortable: false,
    //   left: true,
    //   cell: () => <div>***********</div>,
    //   hide: "md",
    // },
    {
      name: "CONNECTED ONT",
      sortable: false,
      left: true,
      hide: "md",
    },
    {
      name: "LAST MODIFIED",
      sortable: false,
      center: true,
      selector: (row) => row?.updateModified,
      hide: "md",
    },
    {
      name: "STATUS",
      sortable: false,
      left: true,
      hide: "md",
    },
    {
      name: "ACTION",
      cell: (row) => 
      (<div>
        <Button size="sm" color="link" onClick={() => history.push(`/admin/olt/ont/${row.ipAddress}/${row.id}`)}>
          <i className="fas fa-search"></i> 
        </Button>
      </div> ),
      button: true,
      hide: "md",
    },
  ];

  return (
    <PageContent title="Optical Line Terminal (OLT)">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <h2>{title}</h2>
            </Col>
            {/* <Col xs='12' md='6' className="d-flex justify-content-end">
              <FilterDropdownUser/>
            </Col> */}
          </Row>
          <DataTable
            title={title}
            data={olt?.content}
            columns={columns}
            pagination
            striped
            noHeader
            highlightOnHover
            persistTableHead
            customStyles={customStyles}
          />
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default OLT;
