import React from "react";
import { Route, Switch } from "react-router-dom";
import { RouterInterface, RouterInterfaceUnit, RouterList } from "./pages";

function RouterListRouter() {
  return (
    <Switch>
      <Route exact path={`/admin/router-list/`} component={RouterList} />
      <Route exact path={`/admin/router-list/router-interface/:routerIpAddress/:routerId`} component={RouterInterface} />
      <Route exact path={`/admin/router-list/router-interface/:routerIpAddress/:routerId/:routerInterfaceName/:routerInterfaceId`} component={RouterInterfaceUnit} />
    </Switch>
  );
}

export default RouterListRouter;