import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./../../../../services/baseApi";

export const onuInventoryApi = createApi({
  reducerPath: "onuInventoryApi",
  baseQuery: baseQuery,
  tagTypes: ["Onu", "LineProfile", "JobPackages"],
  endpoints: (builder) => ({
    getOnu: builder.query({
      query: () => ({
        url: "/onu/",
      }),
    }),
    getOnuByOltId: builder.query({
      query: (oltId) => ({
        url: "/onu/",
        params: {oltId: oltId}
      }),
    }),
    getLineProfile: builder.query({
      query: (id) => ({
        url: `/onu/${id}/profile`,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "LineProfile",
                id,
              })),
              { type: "LineProfile", id: "LIST" },
            ]
          : [{ type: "LineProfile", id: "LIST" }],
    }),
    updatePolicyPackageInProfile: builder.mutation({
      query: ({ id, policyPackageId }) => ({
        url: `/onu/${id}/profile/package/${policyPackageId}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "LineProfile", id: "LIST" }],
    }),
    getJobPackages: builder.query({
      query: (id) => ({
        url: `/onu/${id}/jobs/package`,
      }),
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({
                type: "JobPackages",
                id,
              })),
              { type: "JobPackages", id: "LIST" },
            ]
          : [{ type: "JobPackages", id: "LIST" }],
    }),
    createJobPackage: builder.mutation({
      query: ({onuId, body}) => ({
        url: `/onu/${onuId}/jobs/package`,
        method: "POST",
        body
      }),
      invalidatesTags: [{ type: "JobPackages", id: "LIST" }],
    }),
    updateJobPackage: builder.mutation({
      query: ({onuId, jobId, body}) => ({
        url: `/onu/${onuId}/jobs/package/${jobId}`,
        method: "PUT",
        body
      }),
      invalidatesTags: [{ type: "JobPackages", id: "LIST" }],
    }),
  }),
});

export const {
  useGetOnuQuery,
  useGetOnuByOltIdQuery,
  useGetLineProfileQuery,
  useUpdatePolicyPackageInProfileMutation,
  useGetJobPackagesQuery,
  useCreateJobPackageMutation,
  useUpdateJobPackageMutation,
} = onuInventoryApi;
