import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";
import { history } from "utils";

const RouterActionButton = ({row, onDelete, onEdit}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);


  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={menuOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <i className="fas fa-ellipsis-v text-default" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={() => onDelete(row)}>
            <span className="text-danger">
              <i className="fa fa-trash text-danger mr-2"></i> Delete
            </span>
          </DropdownItem>
          <DropdownItem onClick={() => onEdit(row)}>
            <span className="text-default">
              <i className="fa fa-edit text-default mr-2"></i> Edit
            </span>
          </DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/router-list/router-interface/${row.ipAddress}/${row.id}`)}>
            <span className="text-default">
              <i className="fa fa-list text-default mr-2"></i> Router Interface
            </span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default RouterActionButton;
