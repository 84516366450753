export default {
  head: {},
  headRow: {
    style: {
      backgroundColor: "#F1F3F9"
    }
  },
  expanderCell: {
    style: {
      flex: '48px 48px',
      minWidth: '0',
    }
  },
  tableWrapper: {
		style: {
			minHeight: '530px'
		},
	},
}