import React from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import FormPackagePolicy from "./FormPackagePolicy";
import {useUpdatePackagePolicyMutation} from "../service/packagePolicyApi"

const EditPackagePolicyModal = ({ isOpen, toggle, policy }) => {
  const [updatePolicyPackage, { isLoading }] = useUpdatePackagePolicyMutation();

  const onSubmit = (body) => {
    const valueToSend = {
        ...body,
        id: policy.id
    }
    updatePolicyPackage({ id: policy.id, body:valueToSend })
      .unwrap()
      .then(() => {
        toast.success(`Policy ${policy.packageName} updated.`);
        toggle();
      });
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
        backdrop={isLoading}
    >
      <FormPackagePolicy
        policy={policy}
        onSubmit={onSubmit}
        onCancel={toggle}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default EditPackagePolicyModal;
