import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../services/baseApi';

export const referenceApi = createApi({
  reducerPath: 'referenceApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getReferences: builder.query({
      query: (reference) => ({
        url: `/reference/${reference}`,
      }),
    }),
    getAboutPage: builder.query({
      query: () => ({
        url: `/release-notes/versions/last`
      })
    }),
    getProvinces: builder.query({
      query: (params) => ({
        url: `/administrative-division/provinces`,
        params: params
      })
    }),
    getRegencies: builder.query({
      query: (params) => ({
        url: `/administrative-division/regencies`,
        params: params
      })
    }),
  })
});

export const {
  useGetReferencesQuery,
  useGetAboutPageQuery,
  useGetProvincesQuery,
  useGetRegenciesQuery,
} = referenceApi;

