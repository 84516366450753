import React, { useState, useCallback } from "react";
import { PageContent } from "components";
import { Card, CardBody, Row, Col} from "reactstrap";
import DataTable from "react-data-table-component";
import { customStyles} from "../../../../utils/datatableStyles";
import { useDeleteRouterMutation, useGetRouterQuery } from "../service";
import { ButtonLinkIcon } from "components";
import RouterActionButton from "../components/RouterActionButton";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AddNewRouterModal from "../components/AddNewRouterModal";
import EditRouterModal from "../components/EditRouterModal";


const RouterList = () => {
  const [title] = useState("All Router");
  const [selectedRouter, setSelectedRouter] = useState(null)

  const [modalCreate, setModalCreate] = useState(false);
  const toggleModalCreate = () => {
    setModalCreate(!modalCreate);
  };

  const [modalEdit, setModalEdit] = useState(false);
  const toggleModalEdit = () => {
    setModalEdit(!modalEdit);
  };

  const { data: routers, isLoading } = useGetRouterQuery();
  const [deleteRouter] = useDeleteRouterMutation()
  
  const columns = [
    {
      name: "IP",
      center: true,
      hide: "md",
      selector: (row) => row?.ipAddress,
    },
    {
      name: "ROUTER NAME",
      center: true,
      hide: "md",
      selector: (row) => row?.device,
    },
    {
      name: "OS",
      center: true,
      hide: "md",
      selector: (row) => row?.os,
    },
    {
      name: "HOSTNAME",
      center: true,
      hide: "md",
      selector: (row) => row?.hostname,
    },
    {
      name: "USERNAME",
      center: true,
      hide: "md",
      selector: (row) => row?.username,
    },
    {
      name: "ACTION",
      button: true,
      allowOverflow: true,
      hide: "md",
      cell: (row) => (
        <RouterActionButton row={row} onDelete={onDeleteRouter} onEdit={onEditRouter}/>
      ),
    },
  ];

  const onDeleteRouter = useCallback(
    (router) => {
      Swal.fire({
        title: "Are you sure want to delete this?",
        text: `Router: ${router.device}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          return deleteRouter(router.id)
            .unwrap()
            .then(() => {
              toast.success("Router deleted.");
              return true;
            });
        },
      });
    },
    [deleteRouter]
  );

  const onEditRouter = useCallback((router) => {
    setSelectedRouter(router);
    setModalEdit(true);
  }, [setModalEdit, setSelectedRouter])

  const onAddNewRouter = () => {
    setModalCreate(true)
  }

  
  return (
    <PageContent title="Router">
      <Card>
        <CardBody>
          <Row className="mb-2 d-flex align-items-center">
            <Col className="d-flex align-items-center" xs="12" md="6">
              <h2 className="text-dark">{title}</h2>
            </Col>
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-lg-end align-items-center"
            >
              <ButtonLinkIcon label="Add New" onClick={onAddNewRouter} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={routers?.content}
                columns={columns}
                progressPending={isLoading}
                persistTableHead
                highlightOnHover
                noHeader
                striped
                pagination
                customStyles={customStyles}
              />
            </Col>
          </Row> 
        </CardBody>
      </Card>
      <AddNewRouterModal isOpen={modalCreate} toggle={toggleModalCreate} />
      <EditRouterModal
        router={selectedRouter}
        isOpen={modalEdit}
        toggle={toggleModalEdit}
      />
    </PageContent>
  );
};

export default RouterList;
