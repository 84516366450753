import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateMemberThunk } from "../networkMemberSlice";

export function useUpdateMember() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const updateMember = useCallback(async (nwid, id, data) => {
		setIsLoading(true);
		await dispatch(updateMemberThunk({ nwid, id, ...data }));
		setIsLoading(false);
	}, [dispatch]);

	return {
		updateMember,
		isLoading
	}
}