import React from 'react'
import { OverviewStatusCard } from '../OverviewStatusCard'
import { Col, Row } from 'reactstrap'
import OverviewStatusItem from '../OverviewStatusCard/OverviewStatusItem'
import { usePerformanceDashboard } from 'features/dashboard/pages/performance-dashboard/context'
import { useGetSummaryNumberDevicesWithIssueQuery } from 'features/dashboard/service/dashboardApi'
import { Loading } from 'components'

const tabIssue = 'ping-packet-loss';

const PingPacketLossCard = () => {
  const { onRegionChange, filters } = usePerformanceDashboard();
  const { data, isFetching } = useGetSummaryNumberDevicesWithIssueQuery({
    startTs: filters.startTs,
    endTs: filters.endTs,
    type: 'packet_loss',
  }, {
    skip: !filters.startTs || !filters.endTs
  });

  const checkAlert = () => {
    let region = data?.content?.find(obj => obj.value_ !== '0')
    return region ? true : false
  }

  return (
    <OverviewStatusCard title="Ping Packet Loss" hasAlert={checkAlert()}>
      {isFetching ?
        <div className="d-flex justify-content-center align-items-center h-100 p-5">
          <Loading size="lg"/>
        </div>
        :
        <Row>
        {data?.content?.map((area, id) => {
          return (
            <Col sm="12" md="12" className='mb-3' key={id}>
                <OverviewStatusItem 
                  value={area.value_} 
                  icon={<i className='mdi mdi-18px mdi-router-wireless'></i>}
                  caption={area.label_}
                  onClickDetail={() => onRegionChange(area.label_, tabIssue)}
                  hasAlert={area.value_ !== '0'}
                />
              </Col>
          )
        })}
        </Row>
      }
    </OverviewStatusCard>
  )
}

export default PingPacketLossCard