import React, { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Swal from "sweetalert2";
import { paginationRowsPerPageOptions } from 'utils';
import { customStyles } from '../../../utils/datatableStyles';
import { useDeleteMember } from '../hooks/useDeleteMember';
import { useNetworkMembers } from '../hooks/useNetworkMembers';
import { useUpdateMember } from '../hooks/useUpdateMember';
import { StatusBadge } from './../../../components';
import CustomActionButtonMember from './CustomActionButtonMember';

const MemberTabView = ({ network }) => {
	const { data, isLoading } = useNetworkMembers(network.id);
	const { updateMember, isLoading: isUpdating } = useUpdateMember();
	const { deleteMember, isLoading: isDeleting } = useDeleteMember();

	const onSubmitUpdateAction = useCallback(async (nwid, id, data) => {
		Swal.fire({
			icon: "question",
			title: `Update member ${id}?`,
			showLoaderOnConfirm: true,
			showCancelButton: true,
			confirmButtonText: "Yes",
			reverseButtons: true,
			preConfirm: async () => {
				try {
					await updateMember(nwid, id, data);
					return true;
				} catch (error) {
					Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
					return false;
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	}, [updateMember]);

	const onDeleteMember = useCallback(async (nwid, id) => {
		Swal.fire({
			icon: "question",
			title: `Remove member ${id} from network ${nwid}?`,
			showLoaderOnConfirm: true,
			showCancelButton: true,
			confirmButtonText: "Yes",
			reverseButtons: true,
			preConfirm: async () => {
				try {
					await deleteMember(nwid, id);
					return true;
				} catch (error) {
					Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
					return false;
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		});
	}, [deleteMember]);

	const columns = useMemo(() => {
		const renderVersion = (row) => {
			return `v${row.vMajor}.${row.vMinor}.${row.vRev}`;
		}

		const renderAuthorizeStatus = ({ authorized }) => {
			return (
				<StatusBadge
					label={authorized ? 'TRUE' : 'FALSE'}
					type={authorized ? "success" : "danger"}
				/>
			)
		}
		const renderStatus = ({ peer }) => {
			const empty = isEmpty(peer);
			return (
				<StatusBadge
					label={empty ? 'OFFLINE' : 'ONLINE'}
					type={empty ? "danger" : "success"}
				/>
			)
		}

		const renderLatency = ({ peer }) => {
			const empty = isEmpty(peer);
			return empty ? '-' : `${peer.latency} ms`;
		}

		return [
			{
				name: 'ID',
				sortable: true,
				sortField: 'id',
				left: true,
				selector: row => row?.id,
			},
			{
				name: 'NAME',
				selector: row => row?.name,
				sortable: true,
				sortField: 'name',
				left: true,
				omit: true
			},
			{
				name: 'IP ASSIGNMENTS',
				selector: row => row?.ipAssignments,
				sortable: false,
				left: true,
				cell: ({ ipAssignments }) => ipAssignments?.join(', ')
			},
			{
				name: 'VERSION',
				sortable: false,
				left: true,
				cell: (row) => renderVersion(row)
			},
			{
				name: 'STATUS',
				sortable: false,
				cell: (row) => renderStatus(row)
			},
			{
				name: 'AUTHORIZED',
				selector: row => row?.authorized,
				sortable: true,
				sortField: 'authorized',
				cell: (row) => renderAuthorizeStatus(row)
			},
			{
				name: 'LATENCY',
				sortable: false,
				cell: (row) => renderLatency(row)
			},
			{
				name: 'CREATION TIME',
				selector: row => row?.creationTime,
				sortable: true,
				sortField: 'creationTime',
				center: true,
				hide: "md",
				cell: (row) => {
					if (moment(row.creationTime).isValid()) {
						return moment(row.creationTime).format('DD/MM/YYYY');
					} else {
						return '-'
					}
				}
			},
			{
				width: '40px',
				cell: (row) => (
					<CustomActionButtonMember
						row={row}
						onDelete={onDeleteMember}
						isDeleting={isDeleting}
						onUpdate={onSubmitUpdateAction}
						isUpdating={isUpdating}
					/>
				),
				allowOverflow: true,
				button: true,
			},
		]
	}, [isDeleting, isUpdating, onDeleteMember, onSubmitUpdateAction]);

	return (
		<div>
			<h2 className='text-dark ml-4'>All Members</h2>
			<div className='row mt-4'>
				<div className='col px-5'>
					<DataTable
						data={data}
						columns={columns}
						defaultSortField={'id'}
						keyField="id"
						striped
						noHeader
						highlightOnHover
						customStyles={{
							...customStyles,
							tableWrapper: {
								style: {
									minHeight: '300px'
								},
							},
						}}
						persistTableHead
						progressPending={isLoading}
						pagination
						paginationRowsPerPageOptions={paginationRowsPerPageOptions}
					/>
				</div>
			</div>
		</div>
	)
}
export default MemberTabView;