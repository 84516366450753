import React from "react";
import { Container } from "reactstrap";
import PropTypes from "prop-types";

const DefaultHeader = ({ title }) => {
  return (
    <div className="header bg-default" style={{
      paddingBottom: '14rem'
    }}>
      <Container fluid>
        <div className="header-body">
          <h1 className="text-white">{title}</h1>
        </div>
      </Container>
    </div>
  );
};

DefaultHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

DefaultHeader.defaultProps = {
  title: null,
};

export default DefaultHeader;
