import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Row, Col, Button, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, Spinner } from 'reactstrap';

import { ButtonLinkIcon, PrimeDropdown, PrimeMultiSelect, RowItem } from '../../../components';

const FilterReport = (props) => {
  const {
    filteredCustomerIds,
    filteredAreaIds,
    filteredDeviceType,
    filteredIMSI,
    filteredLocation,
    filteredSerialNumber,
    filteredOperator,
    filteredAvailability,
    filteredIntegrationStatus,
    reportType,
    customerOptions,
    areaOptions,
    deviceTypeOptions,
    imsiOptions,
    serialNumberOptions,
    operatorOptions,
    locationOptions,
    availabilityOptions,
    integrationStatusOptions,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerIds: filteredCustomerIds,
      imsi: filteredIMSI,
      areaIds: filteredAreaIds,
      deviceType: filteredDeviceType,
      serialNumber: filteredSerialNumber,
      operator: filteredOperator,
      location: filteredLocation,
      availability: filteredAvailability,
      integrationStatus: filteredIntegrationStatus
    },
    onSubmit: (values) => {
      if (typeof props.onFilter === 'function') {
        let filteredaData = {};

        switch (reportType) {
          case 'sla':
            filteredaData = {
              customerIds: values.customerIds,
              location: values.location,
              areaIds: values.areaIds,
              availability: values.availability,
              serialNumber: values.serialNumber,
            };
            break;
          case 'signal':
            filteredaData = {
              customerIds: values.customerIds,
              operator: values.operator,
              deviceType: values.deviceType,
              serialNumber: values.serialNumber,
              imsi: values.imsi,
              location: values.location,
              areaIds: values.areaIds,
            };
            break;
          case 'integration':
            filteredaData = {
              customerIds: values.customerIds,
              areaIds: values.areaIds,
              serialNumber: values.serialNumber,
              deviceType: values.deviceType,
              integrationStatus: values.integrationStatus
            };
            break;
          default:
            filteredaData = {
              customerIds: values.customerIds,
              deviceType: values.deviceType,
              serialNumber: values.serialNumber,
            };
            break;
        }
        props.onFilter(filteredaData);
      }
    }
  });

  return (
    <Nav navbar>
      <UncontrolledDropdown nav direction="down" isOpen={menuOpen} toggle={toggle}>
        <DropdownToggle nav>
          <ButtonLinkIcon label="Filters" icon="fa-filter" />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 400 }} className="p-3">
            {reportType === 'sla' && (
              <RowItem
                label="Availability"
                value={(
                  <PrimeDropdown
                    options={availabilityOptions}
                    placeholder="All"
                    value={formik.values.availability}
                    onChange={(e) => formik.setFieldValue('availability', e.value)}
                  />
                )}
                rightCol="col-7"
              />
            )}

            {(reportType === 'signal' || reportType === 'sla' || reportType === 'integration') && (
              <>
                <RowItem
                  label="Area"
                  value={(
                    <PrimeMultiSelect
                      filter
                      placeholder="All"
                      options={areaOptions}
                      value={formik.values.areaIds}
                      onChange={(e) => formik.setFieldValue('areaIds', e.value)}
                    />
                  )}
                  rightCol="col-7"
                />
              </>
            )}

            <RowItem
              label="Customer"
              value={(
                <PrimeMultiSelect
                  filter
                  options={customerOptions}
                  value={formik.values.customerIds}
                  onChange={(e) => formik.setFieldValue('customerIds', e.value)}
                  placeholder="All"
                />
              )}
              rightCol="col-7"
            />

            {(reportType === 'usage' || reportType === 'signal' || reportType === 'integration') && (
              <RowItem
                label="Device Type"
                value={(
                  <PrimeMultiSelect
                    filter
                    placeholder="All"
                    options={deviceTypeOptions}
                    value={formik.values.deviceType}
                    onChange={(e) => formik.setFieldValue('deviceType', e.value)}
                  />
                )}
                rightCol="col-7"
              />
            )}

            {reportType === 'signal' && (
              <RowItem
                label="IMSI"
                value={(
                  <PrimeMultiSelect
                    filter
                    placeholder="All"
                    options={imsiOptions}
                    value={formik.values.imsi}
                    onChange={(e) => formik.setFieldValue('imsi', e.value)}
                  />
                )}
                rightCol="col-7"
              />
            )}
            
            {(reportType === 'signal' || reportType === 'sla') && (
              <>
                <RowItem
                  label="Location"
                  value={(
                    <PrimeMultiSelect
                      filter
                      options={locationOptions}
                      placeholder="All"
                      value={formik.values.location}
                      onChange={(e) => formik.setFieldValue('location', e.value)}
                    />
                  )}
                  rightCol="col-7"
                />
              </>
            )}

            {reportType === 'signal' && (
              <RowItem
                label="Operator"
                value={(
                  <PrimeMultiSelect
                    filter
                    placeholder="All"
                    options={operatorOptions}
                    value={formik.values.operator}
                    onChange={(e) => formik.setFieldValue('operator', e.value)}
                  />
                )}
                rightCol="col-7"
              />
            )}

            <RowItem
              label="Serial Number"
              value={(
                <PrimeMultiSelect
                  filter
                  placeholder="All"
                  options={serialNumberOptions}
                  value={formik.values.serialNumber}
                  onChange={(e) => formik.setFieldValue('serialNumber', e.value)}
                />
              )}
              rightCol="col-7"
            />

            {(reportType === 'integration') && (
              <>
                <RowItem
                  label="Status"
                  value={(
                    <PrimeMultiSelect
                      filter
                      placeholder="All"
                      options={integrationStatusOptions}
                      value={formik.values.integrationStatus}
                      onChange={(e) => formik.setFieldValue('integrationStatus', e.value)}
                    />
                  )}
                  rightCol="col-7"
                />
              </>
            )}

            <Row className="mt-4">
              <Col className="text-center">
                <Button className="m-0" block color="default" size="sm" type="button" onClick={formik.handleSubmit} disabled={props.loading || !formik.dirty}>
                  Apply
                  {props.loading && <Spinner className="ml-2" color="light" size="sm" />}
                </Button>
                <Button className="m-0 mt-2" block color="secondary" size="sm" onClick={toggle}>Cancel</Button>
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  )
};

FilterReport.propTypes = {
  loading: PropTypes.bool,
  onFilter: PropTypes.func,
  filteredCustomerIds: PropTypes.array,
  filteredAreaIds: PropTypes.array,
  filteredIMSI: PropTypes.array,
  filteredDeviceType: PropTypes.array,
  filteredLocation: PropTypes.array,
  filteredSerialNumber: PropTypes.array,
  filteredOperator: PropTypes.array,
  filteredAvailability: PropTypes.string,
  filteredIntegrationStatus: PropTypes.array,
  reportType: PropTypes.string,
  customerOptions: PropTypes.array,
  areaOptions: PropTypes.array,
  deviceTypeOptions: PropTypes.array,
  imsiOptions: PropTypes.array,
  serialNumberOptions: PropTypes.array,
  operatorOptions: PropTypes.array,
  locationOptions: PropTypes.array,
  availabilityOptions: PropTypes.array,
  integrationStatusOptions: PropTypes.array,
};

FilterReport.defaultProps = {
  loading: false,
  onFilter: () => { },
  filteredCustomerIds: [],
  filteredAreaIds: [],
  filteredIMSI: [],
  filteredDeviceType: [],
  filteredLocation: [],
  filteredSerialNumber: [],
  filteredOperator: [],
  filteredAvailability: null,
  filteredIntegrationStatus: [],
  reportType: 'usage',
  customerOptions: [{ value: null, label: 'All' }],
  areaOptions: [{ value: null, label: 'All' }],
  deviceTypeOptions: [{ value: null, label: 'All' }],
  imsiOptions: [{ value: null, label: 'All' }],
  serialNumberOptions: [{ value: null, label: 'All' }],
  operatorOptions: [{ value: null, label: 'All' }],
  locationOptions: [{ value: null, label: 'All' }],
  availabilityOptions: [{ value: null, label: 'All' }],
  integrationStatusOptions: [{ value: null, label: 'All' }],
}

export default FilterReport;
