import { useCallback } from "react";
import min from 'lodash/min';
import max from 'lodash/max';
import sum from 'lodash/sum';

export function useAggregate() {

    const calculateAggregate = useCallback((data = []) => {
        if (data && data.length > 0) {
            // first, filter data that only has value
            const filteredData = data.filter((d) => {
                return d !== null /* && d !== 0 */ && d !== "" && d !== "null";
            });

            // calculate average data
            const minValue = min(filteredData) || 0;
            const maxValue = max(filteredData) || 0;
            const sumValue = sum(filteredData) || 0;

            const dataLength = filteredData.length || 1; // default to 1 to prevent devided by 0
            const avgValue = (sumValue / dataLength);
            
            return {
                min: minValue,
                max: maxValue,
                avg: avgValue,
                sum: sumValue,
            }
        }

        return null;
    }, []);

    return {
        calculateAggregate,
    };
}