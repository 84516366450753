import React, { useCallback, useMemo } from 'react'
import DataTable from 'react-data-table-component'
import { toast } from "react-toastify"
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Col, DropdownMenu, DropdownToggle, Nav, Row, UncontrolledDropdown } from 'reactstrap'
import Swal from 'sweetalert2'
import { useDeactivatedDeviceDatatable } from '../hooks'
import { useBlockDeviceMutation } from '../service/deviceApi'
import { PageContent } from './../../../components'
import { ACTIVATE_UNACTIVATED_DEVICE, BLOCK_UNACTIVATED_DEVICE, Can } from './../../../config'
import { history, momentLastUpdate, paginationRowsPerPageOptions } from './../../../utils'
import { customStyles } from "./../../../utils/datatableStyles";

export const UnactivatedDevicePage = () => {
  const {
    data,
    isLoading,
    totalRows,
    pageSize,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  } = useDeactivatedDeviceDatatable({ status: 'Deactivated' });

  const [blockDevice] = useBlockDeviceMutation();

  const activateDevice = React.useCallback((device) => {
    history.push({
      pathname: '/admin/devices/new',
      state: {
        sn: device.sn,
        type: device.type,
      }
    });
  }, [])

  const handleBlockAction = useCallback((row) => {
    Swal.fire({
      title: 'Are you sure want to block the device?',
      text: `SN: ${row.sn}, Type: ${row.type}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return blockDevice(row.id).unwrap()
          .then(() => {
            toast.success('Device Blocked.');
            return true;
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
            return false;
          })
      },
    });
  }, [blockDevice]);

  const columns = useMemo(() => {
    const renderActionButton = (row) => {
      return (
        <Nav navbar>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <i className="fas fa-ellipsis-v text-default" />
            </DropdownToggle>
            <DropdownMenu right className="p-0">
              <Can I="do" this={ACTIVATE_UNACTIVATED_DEVICE}>
                {() => (
                  <Button className="m-0" block color="default" size="sm" type="button" onClick={() => activateDevice(row)}>
                    Activate
                  </Button>
                )}
              </Can>
              <Can I="do" this={BLOCK_UNACTIVATED_DEVICE}>
                {() => (
                  <Button className="m-0" block color="danger" size="sm" type="button" onClick={() => handleBlockAction(row)}>
                    Block
                  </Button>
                )}
              </Can>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      )
    }
    return [
      {
        name: 'TYPE',
        selector: row => row?.type,
        sortable: true,
      },
      {
        name: "SERIAL NUMBER",
        selector: row => row?.sn,
        sortable: true,
      },
      {
        name: "CREATION DATE",
        sortable: true,
        cell: ({ creationTime }) => momentLastUpdate(creationTime, 'DD/MM/YYYY')
      },
      {
        name: "LAST UPDATE",
        sortable: true,
        hide: "md",
        cell: ({ lastDataReceive }) => momentLastUpdate(lastDataReceive, 'DD/MM/YYYY HH:mm:ss')
      },
      {
        name: "STATUS",
        selector: row => row?.status,
        sortable: true,
      },
      {
        name: '',
        cell: renderActionButton,
        allowOverflow: true,
        button: true,
        maxWidth: '100px'
      }
    ]
  }, [activateDevice, handleBlockAction]);

  return (
    <PageContent title="Devices">
      <Card>
        <CardBody>
          <Row className="mb-4">
            <Col className="d-flex align-items-center">
              <Breadcrumb listClassName="bg-white m-0 p-0">
                <BreadcrumbItem>
                  <span className="text-dark text-lg font-weight-bold">
                    Unactivated
                  </span>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={data}
                columns={columns}
                progressPending={isLoading}
                responsive={true}
                keyField="id"
                defaultSortField="sn"
                striped
                noHeader
                highlightOnHover
                persistTableHead
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationPerPage={pageSize}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                onChangePage={onChangePage}
                sortServer
                onSort={onSort}
                customStyles={customStyles}
                overflowY={true}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </PageContent>
  )
}
