import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { confirmAction } from "./../../../utils/swal";
import { useAuth } from "./../../auth/hooks";
import { useUpdateUserMutation } from "./../../user/service/userApi";
import { useUpdatePasswordMutation, useUploadProfilePictureMutation } from "../service/authApi";


export function useProfile() {
  const { user, logout } = useAuth();

  const [updateUser, { isLoading: isUpdatingProfile }] = useUpdateUserMutation();
  const [updatePassword, { isLoading: isUpdatingPassword }] = useUpdatePasswordMutation();
  const [uploadPhoto, { isLoading: isUploading }] = useUploadProfilePictureMutation();

  const updateProfile = (username, data) => {
    return confirmAction({
      text: 'Update Profile',
      preConfirm: async () => {
        return updateUser({ body: { username, ...data } }).unwrap().then(() => {
          toast.success(`Update profile success.`);
          return true;
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  const updateUserPassword = (username, data) => {
    return confirmAction({
      text: 'Update Password',
      preConfirm: async () => {
        return updatePassword({ username, ...data }).unwrap().then((res) => {
          toast.success(res.message);
          return true;
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  return {
    user,
    logout,
    updateProfile,
    updateUserPassword,
    updateUser,
    isUpdatingProfile,
    updatePassword,
    isUpdatingPassword,
    uploadPhoto,
    isUploading,
  };
}
