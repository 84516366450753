import { useMemo, useState } from "react";
import { useGetDeactivatedDevicesQuery } from "../service/deviceApi";
import { defaultOptions, usePagination } from "./../../../hooks";

export function useDeactivatedDeviceDatatable(defaultParams = {}, options = defaultOptions) {
  const [params, setParams] = useState(defaultParams);
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort
  } = usePagination({
    pageNumber: options?.pageNumber,
    pageSize: options?.pageSize,
    sortFields: options?.sortFields,
    sortOrder: options?.sortOrder
  });

  const { data: paginatedResult, isLoading } = useGetDeactivatedDevicesQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    ...params
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  return {
    data,
    isLoading,
    pageSize,
    totalRows,
    params,
    setParams,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
}
