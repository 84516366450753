import React from "react";
import { PerformanceDashboardProvider } from "./performance-dashboard/context";
import PerformanceDashboard from "./performance-dashboard";

const PerformanceDashboardPage = () => {
  return (
    <PerformanceDashboardProvider>
      <PerformanceDashboard />
    </PerformanceDashboardProvider>
  );
};

export default PerformanceDashboardPage;
