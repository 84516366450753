import { BootstrapDateRangePicker } from "components";
import moment from "moment";
import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { usePerformanceDashboard } from "./context";
import { history } from "utils";
import { useGetLastUpdatedTimeQuery } from "features/dashboard/service/dashboardApi";

const PageToolbar = ({ onBack }) => {
  const {
    onDateChange,
    onRegionChange,
    selectedRegion,
    regionOptions,
    startDate,
    endDate,
    label,
  } = usePerformanceDashboard();

  const {data: lastUpdatedTime, isLoading, isFetching, isError} = useGetLastUpdatedTimeQuery()

  const goBack = () => {
    if (typeof onBack !== "undefined" && typeof onBack === "function") {
      onBack();
    } else {
      history.goBack();
    }
  };

  const dateOptions = useMemo(() => {
    return {
      label,
      ranges: {
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 14 Days": [moment().subtract(13, "day"), moment()],
      },
      startDate: startDate,
      endDate: endDate,
    };
  }, [endDate, label, startDate]);

  return (
    <Card>
      <CardBody className="py-3">
        <Row>
          <Col className="d-flex flex-wrap justify-content-lg-start align-items-center">
            {selectedRegion !== "All Region" ? (
              <button onClick={goBack} className="btn btn-sm btn-link mr-2 p-0">
                <i className="mdi mdi-24px mdi-chevron-left"></i>
              </button>
            ) : null}
            <span style={{ fontSize: "14px" }}>Showing data from</span>
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle nav>
                <h4 className="text-default mb-0">
                  {selectedRegion}
                  <i className="fa fa-chevron-down fa-1x ml-2"></i>
                </h4>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  active={selectedRegion === "All Region"}
                  onClick={() => {
                    onRegionChange(undefined);
                  }}
                >
                  <span>All Region</span>
                </DropdownItem>
                <DropdownItem divider />
                {regionOptions &&
                  regionOptions.map((r, i) => (
                    <DropdownItem
                      active={selectedRegion === r.label}
                      key={i}
                      onClick={() => {
                        onRegionChange(r.label);
                      }}
                    >
                      <span className="text-dark">{r.label}</span>
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col className="d-flex flex-wrap justify-content-lg-end align-items-center">
            <span style={{ fontSize: "14px" }}>
              Last updated date: {isLoading || isFetching
                ? 'loading...' 
                : isError 
                  ? 'error loading date' 
                  : moment(lastUpdatedTime.lastUpdatedTime).format("DD/MM/YYYY HH:mm:ss")}
            </span>
          </Col>
          <div className="col-2">
            <BootstrapDateRangePicker
              startDate={startDate}
              endDate={endDate}
              options={dateOptions}
              onDateChange={onDateChange}
              label={label}
            />
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PageToolbar;
