import React from 'react';
import { Input } from 'reactstrap';

function FormSelect({options, ...props}) {
  return (
    <Input type="select" {...props}>
      {options && options.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
    </Input>
  )
}

export default FormSelect
