import React, { useMemo } from "react";
import { useGetNotificationsQuery } from "../service/notificationApi";
import { usePagination, defaultOptions } from "./../../../hooks/usePagination";
import { history, momentLastUpdate, unknown } from "./../../../utils";

export const useNotificationDatatable = (params = {}, options = defaultOptions) => {
  const {
    pageNumber,
    pageSize,
    sortFields,
    sortOrder,
    onChangePage,
    onChangeRowsPerPage,
    onSort
  } = usePagination({ 
    pageNumber: options.pageNumber,
    pageSize: options.pageSize,
    sortFields: options.sortFields,
    sortOrder: options.sortOrder
  });

  const { data: paginatedResult, isLoading } = useGetNotificationsQuery({
    pageNumber: pageNumber > 0 ? pageNumber - 1 : 0,
    pageSize,
    sortFields: sortFields ?? undefined,
    sortOrder: sortOrder ?? undefined,
    ...params,
  });

  const data = useMemo(() => {
    if (!paginatedResult?.content) return [];

    return paginatedResult.content;
  }, [paginatedResult]);

  const totalRows = useMemo(() => {
    if (!paginatedResult) return 0;

    return paginatedResult.totalElements;
  }, [paginatedResult]);

  const columns = useMemo(() => {
    const renderLink = (sn) => {
      if (!sn) return unknown;

      return (
        <a className="text-sm text-default" href="#device" onClick={(e) => {
          e.preventDefault();
          history.push({
            pathname: "/admin/devices",
            search: `?sn=${sn}`
          })
        }}>{sn}</a>
      )
    }

    return [
      {
        name: 'SERIAL NUMBER',
        selector: row => row?.deviceSn,
        sortable: false,
        cell: ({ deviceSn }) => renderLink(deviceSn)
      },
      {
        name: 'NAME',
        selector: row => row?.alertName,
        sortable: true,
        sortField: 'alertName',
      },
      {
        name: 'MESSAGE',
        wrap: true,
        selector: row => row?.message,
        sortable: true,
        sortField: 'message',
      },
      {
        name: 'DATETIME',
        selector: row => row?.createdTs,
        sortable: true,
        sortField: 'createdTs',
        cell: ({ createdTs }) => momentLastUpdate(createdTs)
      },
    ];
  }, []);

  return {
    data,
    columns,
    isLoading,
    pageSize,
    pageNumber,
    totalRows,
    onChangeRowsPerPage,
    onChangePage,
    onSort,
  };
};
