
import React from "react";
import { Route, Switch } from "react-router-dom";

import { UserAdd } from "./pages";
import UserList from "./pages/UserList";


function UserRouter() {
  return (
    <Switch>
      <Route exact path={`/admin/users/`} component={UserList} />
      <Route exact path={`/admin/users/new`} component={UserAdd} />
    </Switch>
  );
}

export default UserRouter;
