import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryUsage } from './../../../services/baseApi';

export const usageApi = createApi({
  reducerPath: 'usageApi',
  baseQuery: baseQueryUsage,
  tagTypes: ['Usage'],
  endpoints: (builder) => ({
    getUsageHistory: builder.query({
      query: (params) => ({ url: `/device-usage/chart/historical`, params: params })
    }),
    getSignalTrend: builder.query({
      query: ({ metric, params }) => ({
        url: `/gazelle-raisecom/chart/historical/${metric}`,
        params: params
      })
    }),
    getDeviceQuotaUsageInPercentage: builder.query({
      query: ({params, body}) => ({
        url: `/v1/device-usage/pie/count-by-limit-percentage`,
        method: 'POST',
        params,
        body,
      })
    }),
    getDeviceOverQuotaByCustomer: builder.query({
      query: ({params, body}) => ({
        url: `/v1/device-usage/chart/count-overquota-by-customer-name`,
        method: 'POST',
        params,
        body,
      })
    }),
    getDeviceBandwidthUsage: builder.query({
      query: ({ params, body }) => ({
        url: `/v1/device-bandwidth/chart/historical/`,
        method: 'POST',
        params,
        body,
      })
    }),
    getLatencyHistory: builder.query({
      query: (params) => ({ url: `/v1/device-tunnel-performance/chart/historical/`, params })
    }),
    getDataIntegrityHistory: builder.query({
      query: (params) => ({ url: `/v1/device-tunnel-performance/integrity/chart/historical/`, params })
    }),
  })
});

export const {
  useGetUsageHistoryQuery,
  useGetSignalTrendQuery,
  useGetDeviceQuotaUsageInPercentageQuery,
  useGetDeviceOverQuotaByCustomerQuery,
  useGetDeviceBandwidthUsageQuery,
  useGetLatencyHistoryQuery,
  useGetDataIntegrityHistoryQuery
} = usageApi;

