import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Row } from "reactstrap";
import { ButtonLinkIcon, PageContent } from "./../../../components";

import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { useAreaDatatable } from '../hooks';
import { useGroups } from './../../group/hooks';
import { useRegion } from './../../region/hooks';
import { CommaArrayParam } from './../../../utils/queryParams';
import { useDeleteAreaBulkMutation, useDeleteAreaMutation } from '../service/areaApi';
import { history, paginationRowsPerPageOptions } from './../../../utils';
import { AreaHeaderTable, ModalAddArea, CustomActionButton, ModalEditArea } from '../components';
import { ADD_NEW_AREA, Can } from './../../../config';
import { customStyles } from "./../../../utils/datatableStyles";

const AreaListPage = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const { options: groupOptions } = useGroups();
  const { options: regionOptions } = useRegion({}, false);

  const [deleteArea] = useDeleteAreaMutation();
  const [deleteAreaBulk] = useDeleteAreaBulkMutation();

  const [query] = useQueryParams({
    name: StringParam,
    regionId: NumberParam,
    regionIds: CommaArrayParam,
    ids: CommaArrayParam,
    groupIds: CommaArrayParam,
  });

  const {
    data,
    isLoading,
    isFetching,
    totalRows,
    pageSize,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    setParams,
    refetch,
  } = useAreaDatatable(query);

  useEffect(() => {
    setParams(query);
  }, [query, setParams]);

  const handleRowSelected = useCallback(({ selectedRows }) => {
    setSelectedRows(selectedRows);
  }, []);

  const handleDeleteBatch = useCallback(() => {
    Swal.fire({
      icon: "question",
      title: "Are you sure you want to delete?",
      text: `${selectedRows.map((area) => area?.name).join(', ')}`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          const areaIds = selectedRows.map((a) => a.id).join(',');
          await deleteAreaBulk(areaIds).unwrap();
          return true;
        } catch (error) {
          Swal.showValidationMessage(`${error?.data?.error}: ${error?.data?.message}`);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        toast.success("Delete areas success.");
        refetch();
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
      }
    });
  }, [selectedRows, toggleCleared, deleteAreaBulk, refetch]);

  const handleDelete = useCallback((data) => {
    Swal.fire({
      title: "Are you sure want to delete this?",
      text: `Area: ${data.name}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: async () => {
        return deleteArea(data.id)
          .unwrap()
          .then(() => {
            toast.success("Area deleted.");
            return true;
          });
      },
    });
  }, [deleteArea]);

  const handleEdit = useCallback((row) => {
    setModalEdit(true);
    setSelectedRow(row);
  }, [setModalEdit, setSelectedRow]);

  const columns = useMemo(() => {
    const renderLink = (text, path, query) => {
      if (text === 0) {
        return 0;
      }

      return (
        <a href="#link" className="text-default" onClick={(e) => {
          e.preventDefault();
          history.push({ 
            pathname: path, 
            search: query ? `?${query}` : undefined
          });
        }}>{text}</a>
      );
    }
    return [
      {
        name: 'AREA NAME',
        selector: row => row?.name,
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'TOTAL DEVICES',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ hierarchyAreaSummary, id, region }) => {
          if (region && region.id) {
            return renderLink(hierarchyAreaSummary?.countDevice, `/admin/devices/region/${region.id}/area/${id}`);
          }
          return hierarchyAreaSummary?.countDevice;
        }
      },
      {
        name: 'TOTAL CUSTOMER',
        sortable: false,
        center: true,
        hide: "sm",
        cell: ({ hierarchyAreaSummary, id }) => renderLink(hierarchyAreaSummary?.countCustomer, '/admin/customers', `areaIds=${id}`)
      },
      {
        name: '',
        width: '40px',
        allowOverflow: true,
        button: true,
        cell: (row) => row.id === 999999 ? null : <CustomActionButton row={row} onDelete={handleDelete} onEdit={handleEdit} />,
      },
    ];
  }, [handleEdit, handleDelete]);

  const disableRowSelectionCondition = row => row.id === 999999;

  return (
    <PageContent title="All Area">
      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col className="d-flex align-items-center">
              <h2 className="text-dark">All Area</h2>
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Can I="do" this={ADD_NEW_AREA}>
                {() => (<ButtonLinkIcon label="Add New" onClick={() => setModalAdd(!modalAdd)} />)}
              </Can>
            </Col>
          </Row>
          {(selectedRows && selectedRows.length > 0) && (
            <AreaHeaderTable selectedRows={selectedRows} onDelete={handleDeleteBatch} />
          )}
          <Row className="mb-2">
            <Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataTable
                data={data}
                columns={columns}
                defaultSortField="name"
                keyField="id"
                onSelectedRowsChange={handleRowSelected}
                striped
                noHeader
                highlightOnHover
                selectableRows
                clearSelectedRows={toggleCleared}
                selectableRowDisabled={disableRowSelectionCondition}
                pagination
                paginationServer
                customStyles={customStyles}
                persistTableHead
                progressPending={isLoading || isFetching}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                paginationPerPage={pageSize}
                onChangePage={onChangePage}
                sortServer
                onSort={onSort}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalAddArea
        isOpen={modalAdd}
        toggle={() => setModalAdd(!modalAdd)}
        groupOptions={groupOptions}
        regionOptions={regionOptions}
      />
      <ModalEditArea
        isOpen={modalEdit}
        toggle={() => setModalEdit(!modalEdit)}
        groupOptions={groupOptions}
        data={selectedRow}
      />
    </PageContent>
  )
}

export default AreaListPage;
