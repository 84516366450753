import { useMemo } from 'react';
import { useGetProvincesQuery } from '../services/referenceApi';

export function useProvinces(params) {
  const { data, isLoading, refetch } = useGetProvincesQuery(params);

  const options = useMemo(() => {
    const opts = [];

    if (data && data.content) {
      data.content.forEach((opt) => {
        opts.push({ value: opt.name, label: opt.name, id: opt.id });
      });
    }

    return opts;
  }, [data]);

  return {
    data,
    isLoading,
    options,
    refetch
  };
}