import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const alertHistoryApi = createApi({
  reducerPath: 'alertHistoryApi',
  baseQuery: baseQuery,
  tagTypes: ['AlertHistory', 'AlertHistoryDetails'],
  endpoints: (builder) => ({
    getAlertHistories: builder.query({
      query: (params) => ({
        url: `/alert/alert-trigger`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'AlertHistory', id })),
            { type: 'AlertHistory', id: 'LIST' },
          ]
          : [{ type: 'AlertHistory', id: 'LIST' }],
    }),
    getAlertHistoryDetails: builder.query({
      query: (id) => ({
        url: `/alert/alert-trigger/${id}/details`
      }),
      providesTags: (result, error, id) => [{ type: 'AlertHistoryDetails', id }],
    }),
  })
});

export const {
  useGetAlertHistoryDetailsQuery,
  useGetAlertHistoriesQuery,
} = alertHistoryApi;

