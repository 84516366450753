import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Button, Col, DropdownMenu, DropdownToggle, Nav, Row, Spinner, UncontrolledDropdown
} from "reactstrap";
import {
  ButtonLinkIcon,
  PrimeDropdown,
  PrimeMultiSelect,
  RowItem
} from "../../../components";

const statusOptions = [
  { value: null, label: "All" },
  { value: "activated", label: "Activated" },
  { value: "deactivated", label: "Deactivated" },
];

const FilterDropdownUser = (props) => {
  const {
    filteredGroupIds,
    filteredRegionIds,
    filteredAreaIds,
    filteredStatus,
    filteredCustomerIds,
    groupOptions,
    regionOptions,
    areaOptions,
    customerOptions,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = () => setMenuOpen(!menuOpen);
  const [changes, setChanges] = useState(false);

  const [status, setStatus] = useState(filteredStatus);
  const [groupIds, setGroupIds] = useState(filteredGroupIds ? filteredGroupIds.map((v) => parseInt(v)) : []);
  const [regionIds, setRegionIds] = useState(filteredRegionIds ? filteredRegionIds.map((v) => parseInt(v)) : []);
  const [areaIds, setAreaIds] = useState(filteredAreaIds ? filteredAreaIds.map((v) => parseInt(v)) : []);
  const [customerIds, setCustomerIds] = useState(filteredCustomerIds ? filteredCustomerIds.map((v) => parseInt(v)) : []);

  useEffect(() => {
    const selectedGroupIds = filteredGroupIds
      ? filteredGroupIds.map((v) => parseInt(v))
      : [];
    const selectedRegionIds = filteredRegionIds
      ? filteredRegionIds.map((v) => parseInt(v))
      : [];
    const selectedAreaIds = filteredAreaIds
      ? filteredAreaIds.map((v) => parseInt(v))
      : [];
    const selectedCustomerIds = filteredCustomerIds
      ? filteredCustomerIds.map((v) => parseInt(v))
      : [];

    setGroupIds(selectedGroupIds);
    setRegionIds(selectedRegionIds);
    setAreaIds(selectedAreaIds);
    setCustomerIds(selectedCustomerIds);
    setStatus(filteredStatus);
    // eslint-disable-next-line
  }, [
    filteredGroupIds,
    filteredRegionIds,
    filteredAreaIds,
    filteredCustomerIds,
    filteredStatus,
  ]);

  const onGroupChange = (e) => {
    setGroupIds(e.value);
    setChanges(true);
  };

  const onRegionChange = async (e) => {
    setRegionIds(e.value);
    if (typeof props.onRegionChanges === 'function') {
      props.onRegionChanges(e.value.length > 0 ? e.value.join(',') : undefined);
    }
    setChanges(true);
  };

  const onAreaChange = (e) => {
    setAreaIds(e.value);
    setChanges(true);
  };

  const onCustomerChange = (e) => {
    setCustomerIds(e.value);
    setChanges(true);
  };

  const onFilter = () => {
    if (typeof props.onFilter === "function") {
      props.onFilter({
        status: status,
        groupIds: groupIds,
        regionIds: regionIds,
        areaIds: areaIds,
        customerIds: customerIds,
      });
    }
    setChanges(false);
  };

  return (
    <Nav navbar>
      <UncontrolledDropdown
        nav
        direction="down"
        isOpen={menuOpen}
        toggle={toggle}
      >
        <DropdownToggle nav>
          <ButtonLinkIcon label="Filters" icon="fa-filter" />
        </DropdownToggle>
        <DropdownMenu right>
          <div style={{ minWidth: 350 }} className="p-3">
            <RowItem
              label="Status"
              value={
                <PrimeDropdown
                  options={statusOptions}
                  value={status}
                  onChange={(e) => {
                    setStatus(e.value);
                    setChanges(true);
                  }}
                />
              }
              rightCol="col-8"
            />
            <RowItem
              label="Group"
              value={
                <PrimeMultiSelect
                  filter
                  options={groupOptions}
                  value={groupIds}
                  onChange={onGroupChange}
                  placeholder="All Group"
                />
              }
              rightCol="col-8"
            />
            <RowItem
              label="Region"
              value={
                <PrimeMultiSelect
                  filter
                  options={regionOptions}
                  value={regionIds}
                  onChange={onRegionChange}
                  placeholder="All Region"
                />
              }
              rightCol="col-8"
            />
            <RowItem
              label="Area"
              value={
                <PrimeMultiSelect
                  filter
                  options={areaOptions}
                  value={areaIds}
                  onChange={onAreaChange}
                  placeholder="All Area"
                />
              }
              rightCol="col-8"
            />
            <RowItem
              label="Customer"
              value={
                <PrimeMultiSelect
                  filter
                  options={customerOptions}
                  value={customerIds}
                  onChange={onCustomerChange}
                  placeholder="All Customer"
                />
              }
              rightCol="col-8"
            />
            <Row className="mt-4">
              <Col className="text-center">
                <Button
                  className="m-0"
                  block
                  color="default"
                  size="sm"
                  type="button"
                  onClick={onFilter}
                  disabled={props.loading || !changes}
                >
                  Apply
                  {props.loading && (
                    <Spinner className="ml-2" color="light" size="sm" />
                  )}
                </Button>
                <Button
                  className="m-0 mt-2"
                  block
                  color="secondary"
                  size="sm"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

FilterDropdownUser.propTypes = {
  loading: PropTypes.bool,
  onFilter: PropTypes.func,
  filteredStatus: PropTypes.string,
  filteredGroupIds: PropTypes.array,
  filteredRegionIds: PropTypes.array,
  filteredAreaIds: PropTypes.array,
  filteredCustomerIds: PropTypes.array,
  groupOptions: PropTypes.array.isRequired,
  regionOptions: PropTypes.array.isRequired,
  areaOptions: PropTypes.array.isRequired,
  customerOptions: PropTypes.array.isRequired,
  onRegionChanges: PropTypes.func,
};

FilterDropdownUser.defaultProps = {
  loading: false,
  onFilter: () => {},
  onRegionChanges: () => {},
  filteredStatus: null,
  filteredGroupIds: [],
  filteredRegionIds: [],
  filteredAreaIds: [],
  filteredCustomerIds: [],
  groupOptions: [],
  regionOptions: [],
  areaOptions: [],
  customerOptions: [],
};

export default FilterDropdownUser;
