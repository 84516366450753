import React from 'react'
import { Card, CardBody } from "reactstrap";
import PerformanceChart from './ChartPerformance/PerformanceChart';
import DataIntegrityChart from './ChartPerformance/DataIntegrityChart';

const DeviceLatencyTrendEchart = ({ params }) => {
  const { sn, startTs, endTs, timeGroupping } = params;

  return (
    <Card>
      <CardBody style={{ width: "100%", height: "100%" }}>
        <div className='row'>
          <div className='col-12 col-md-6' style={{ height: 300 }}>
            <PerformanceChart sn={sn} startTs={startTs} endTs={endTs} timeGroupping={timeGroupping} metric='rttAvg' />
          </div>
          <div className='col-12 col-md-6' style={{ height: 300 }}>
            <PerformanceChart sn={sn} startTs={startTs} endTs={endTs} timeGroupping={timeGroupping} metric='packetLoss' />
          </div>
          <div className='col-12 col-md-6' style={{ height: 300 }}>
            <PerformanceChart sn={sn} startTs={startTs} endTs={endTs} timeGroupping={timeGroupping} metric='failureRate' />
          </div>
          <div className='col-12 col-md-6' style={{ height: 300 }}>
            <DataIntegrityChart sn={sn} startTs={startTs} endTs={endTs} timeGroupping={timeGroupping} status='undelivered' />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DeviceLatencyTrendEchart;