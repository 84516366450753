import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => ({
        url: `/user`,
        params: params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ username }) => ({ type: 'User', id: username })),
            { type: 'User', id: 'LIST' },
          ]
          : [{ type: 'User', id: 'LIST' }],
    }),
    getUser: builder.query({
      query: (username) => ({
        url: `/user/${username}`
      }),
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),
    createUser: builder.mutation({
      query: ({ body, params }) => ({
        url: `/user`,
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    updateUser: builder.mutation({
      query: ({ body: { username, ...patch }, params }) => ({
        url: `/user/${username}`,
        method: 'PUT',
        body: patch,
        params,
      }),
      async onQueryStarted({ body: { username, ...patch }}, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', username, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { body: { username } }) => [{ type: 'User', id: username }],
    }),
    deleteUser: builder.mutation({
      query(username) {
        return {
          url: `/user/${username}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'User', id }, { type: 'User', id: 'LIST' }],
    }),
    setUserRole: builder.mutation({
      query({ roleId, username }) {
        return {
          url: `/role/${roleId}/user/${username}/replace`,
          method: 'PUT'
        }
      },
      invalidatesTags: (result, error, { username }) => [{ type: 'User', id: username }],
    }),
    setUserRegions: builder.mutation({
      query({ regionIds, username }) {
        return {
          url: `/hierarchy/mapping/username/${username}/region/${regionIds}/replace`,
          method: 'PUT'
        }
      },
      invalidatesTags: (result, error, { username }) => [{ type: 'User', id: username }],
    }),
    setUserAreas: builder.mutation({
      query({ areaIds, username }) {
        return {
          url: `/hierarchy/mapping/username/${username}/area/${areaIds}/replace`,
          method: 'PUT'
        }
      },
      invalidatesTags: (result, error, { username }) => [{ type: 'User', id: username }],
    }),
    setUserCustomers: builder.mutation({
      query({ customerIds, username }) {
        return {
          url: `/hierarchy/mapping/username/${username}/customer/${customerIds}/replace`,
          method: 'PUT'
        }
      },
      invalidatesTags: (result, error, { username }) => [{ type: 'User', id: username }],
    }),
  })
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useSetUserRoleMutation,
  useSetUserRegionsMutation,
  useSetUserAreasMutation,
  useSetUserCustomersMutation,
} = userApi;

