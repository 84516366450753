import React from 'react';
import cx from 'classnames';
import * as FileSaver from "file-saver";
import * as XLSX from 'xlsx';
import { useExports } from '../hooks/useExports';
import _ from 'lodash';

const ExportToExcel = ({ className, params }) => {
    const { fetchData, isLoading, fileNameCSV, headers } = useExports();

    const exportToExcel = (params, fileName) => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        
        fetchData(params).then((json) => {

            const getHeaderByKey = (key) => headers.find(h => h.key === key)?.label || '';
            const mapData = json.map((d) => {
                const data = _.pick(d, headers.map(h => h.key));

                const exportData = Object.entries(data).map(([key, value]) => {
                    if (key === 'customer') {
                        return { [getHeaderByKey('customer.name')]: value.name };
                    }
                    if (key === 'area') {
                        return { [getHeaderByKey('area.name')]: value.name };
                    }

                    return { [getHeaderByKey(key)]: value };
                }).reduce((acc, d) => {
                    return {...acc, ...d };
                }, {});

                return exportData;
            });
            const ws = XLSX.utils.json_to_sheet(mapData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const excelData = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(excelData, fileName + fileExtension);
        })
        
    }

    return (
        <button className={cx("font-weight-normal btn btn-link text-default btn-md p-2", className)} onClick={() => exportToExcel(params, fileNameCSV)}>
            <i className={cx({ "fa fa-sync fa-spin": isLoading, "fa fa-file-excel": !isLoading })}></i>
        </button>
    )
}

export default ExportToExcel;